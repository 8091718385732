import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getLeadDetail, updateLead } from "../../../store/actions"
import LeadStatusOption from 'components/dropdowns/Lead/LeadStatus'
import LeadTypeOption from 'components/dropdowns/Lead/LeadType'
import LeadStageOption from 'components/dropdowns/Lead/LeadStatge'
import LeadSourceOption from 'components/dropdowns/Lead/LeadSource'
import OtherDropdowns from "components/dropdowns/Other/OtherDropdowns"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import SaveButton from "components/buttons/save"
import UpdateButton from "components/buttons/updateBtn"
import RsLink from "components/buttons/rsLink"
import {Row,Col,FormGroup,Label} from "reactstrap"
class EditLead extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmitLead = this.handleSubmitLead.bind(this);
  }
  componentDidMount() {
    const { match: { params }, onGetLeadDetail} = this.props;
    if (params && params.id && params.leadId) {
      onGetLeadDetail(params.leadId);
    }
  }
  handleSubmitLead = (values, actions) => {
    const payload = {
      id: values.id,
      leadId: values.leadId,
      agentId: parseInt(values.agentId),
      submittedDate:values.submittedDate,
      title:values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      phone: values.phone,
      phoneExt: values.phoneExt,
      leadTypeId: (values.leadTypeId) ? parseInt(values.leadTypeId) : 0,
      leadStatusId: (values.leadStatusId) ? parseInt(values.leadStatusId) : 0,
      group: (values.group) ? parseInt(values.group) : 0,
      stageId: (values.stageId) ? parseInt(values.stageId) : 0,
      leadSourceId: (values.leadSourceId) ? parseInt(values.leadSourceId) : 0,
      approxDealValue: (values.approxDealValue) ? parseInt(values.approxDealValue) : 0,
      referedBy:values.referedBy,
      referralCode:values.referralCode,
      notes: values.notes,
      expectedClosing: values.expectedClosing,  
      winLiklyhood: (values.winLiklyhood) ? parseInt(values.winLiklyhood) : 0,  
    }
    this.props.updateLead(payload);
    this.props.closed();
  }
  render() {
    const { closed, leads } = this.props;
    console.log(leads);
    return (
      <React.Fragment>
                    
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: leads?.id,
                          leadId: leads?.leadId,
                          agentId: leads?.agentId,
                          submittedDate: leads?.submittedDate,
                          title:leads?.title,
                          firstName: leads?.firstName,
                          lastName: leads?.lastName,
                          email: leads?.email,
                          phone: leads?.phone,
                          phoneExt: leads?.phoneExt,
                          mobile: leads?.mobile,
                          leadTypeId:leads?.leadTypeId,
                          leadStatusId:leads?.leadStatusId,   
                          stageId:leads?.stageId,  
                          group:leads?.group,   
                          leadSourceId:leads?.leadSourceId, 
                          approxDealValue:leads?.approxDealValue,
                          referedBy:leads?.referedBy,  
                          referralCode:leads?.referralCode,   
                          notes:leads?.notes,
                          expectedClosing: leads?.expectedClosing,  
                          winLiklyhood: leads?.winLiklyhood,    
                         
                        }}
                        validationSchema={Yup.object().shape({
                          title: Yup.string().required('This field is required'),
                          firstName: Yup.string().required("This Field in required"),
                          lastName: Yup.string().required("This Field in required"),
                          email: Yup.string().email('Invalid email').required('This Field in required'),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid'),
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <Row>
                            <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="submittedDate">
                                    {this.props.t("Submitted Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.submittedDate && touched.submittedDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.submittedDate}
                                    onChange={(value) => setFieldValue('submittedDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            <Col md="2">
                            <TitleDropdowns
                                name="title"
                                label="Title *"
                                fieldName="Agent:Title"
                                
                              />
                              
                              </Col>
                              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name *")}
                                  </Label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name *")}
                                  </Label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email *")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile *")}
                                  </Label>
                                  <Field
                                    name="mobile"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Secondary Phone")}
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <Field
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col> 
                              <Col md="3">
                               <LeadTypeOption />
                              </Col>

                              <Col md="3">
                                    <LeadStatusOption />
                               
                              </Col>  
                              
                              <Col md="3">
                                    <LeadStageOption />
                              </Col> 
                              <Col md="3">
                              <OtherDropdowns
                                name="group"
                                label="Group"
                                fieldName="Lead:Group"
                              />
                              </Col>
                              <Col md="3">
                              <LeadSourceOption />
                              </Col>    
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="approxDealValue">
                                    {this.props.t("Deal Value")}
                                  </Label>
                                  <Field
                                    name="approxDealValue"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.approxDealValue && touched.approxDealValue
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="approxDealValue"
                                  />
                                </FormGroup>
                              </Col>
                             
                                 
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="referedBy">
                                    {this.props.t("Referred by")}
                                  </Label>
                                  <Field
                                    name="referedBy"
                                    type="text"
                                    //value={values.referedBy}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.referedBy && touched.referedBy
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="referedBy"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="referralCode">
                                    {this.props.t("Referral Code")}
                                  </Label>
                                  <Field
                                    name="referralCode"
                                    type="text"
                                    //value={values.referedBy}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.referralCode && touched.referralCode
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="referralCode"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                    
                            <Row>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="notes">
                                    {this.props.t("Notes ")}
                                  </Label>
                                  <Field
                                    name="notes"
                                    onChange={handleChange}
                                    as="textarea"

                                    className="form-control"

                                    id="notes"

                                  />
                                </FormGroup>
                              </Col>



                            </Row>






                            <div><UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink></div>

                          </Form>
                        )}
                      </Formik>
                    
                    
      </React.Fragment>
    )
  }
}

EditLead.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object,
  history: PropTypes.object,
  error: PropTypes.any,
  onGetLeadDetail: PropTypes.func,
  leads: PropTypes.object,
  closed:PropTypes.object,
  updateLead:PropTypes.func
}
const mapStateToProps = ({ leads }) =>
  ({
    leads: leads.leads
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: (id) => dispatch(getLeadDetail(id)),
  updateLead:(data) => dispatch(updateLead(data))
 })
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(EditLead))
