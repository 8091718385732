import React, { Component } from "react"
import PropTypes from "prop-types"
import { useParams, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { getProjectUUIDAction} from "../../../store/projects/actions"
import { getTempById} from "../../../store/templates/actions"
import UnEditor from "../Email/unlayer-new"
import {
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    Tooltip
  } from "reactstrap"
import { param } from "jquery";

class projectMailEditor extends Component {
  
  constructor(props) {
    super(props);
    this.editor = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false

}
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction, onGetTempId } = this.props;
    this.isComponentMounted = true;
    this.loadTemplate();
    if (params && params.projectId && params.id) {
      ongetProjectUUIDAction(params.projectId);
      onGetTempId(params.templateId);
    }
 }  

  onLoad = () => { this.isEditorLoaded = true; this.loadTemplate(); } 
  loadTemplate = () => { if (!this.isEditorLoaded || !this.isComponentMounted) return; this.editor.loadDesign('<p>Your HTML content goes here</p>') }
      render() {
        const { projectData, datatemp } = this.props;
        //console.log(datatemp);
        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
            
              
               
                <Row>
                   
                    <Col lg="12">
                   
                        <UnEditor />
               
                
                   
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectMailEditor.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    onGetTempId:PropTypes.func,
    datatemp:PropTypes.object
  }
  const mapStateToProps = ({ Project, Template }) => (
    {
      projectData: Project.projectData,
      datatemp : Template.datatemp,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetTempId:(id) => dispatch(getTempById(id))
  });
export default connect( mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(projectMailEditor)))
