import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import {
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import { addFollowNoteTab, getFollowLeadTab,getListLeadTab } from "../../../store/leads/actions"
import SaveButton from "components/buttons/save";
class FollowLead extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }
        this.handleLeadFollowSubmit = this.handleLeadFollowSubmit.bind(this)
    }
    handleLeadFollowSubmit(value) {
        this.props.addFollowNoteTab(value, this.props.history)
        
    }
    componentDidMount() {
        const { onGetFollowLead } = this.props;
        onGetFollowLead(4);
    }
    render() {
        const { leadFollows } = this.props;
        return (
            <React.Fragment>
                <ToastContainer autoClose={2000} />
                <div className="h4 card-title">Set Next Follow-up</div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        addNote: (this.state && this.state.addNote) || "",
                        refId:71,
                        nextFollowupDate: new Date(),


                    }}
                    validationSchema={Yup.object().shape({
                        addNote: Yup.string().max(500,'Only 500 Characters are allowed').required("This is Required")
                    })}

                    onSubmit={this.handleLeadFollowSubmit}
                >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                        <Form
                            className="needs-validation"
                        >

                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        
                                        <textarea
                                            name="addNote"
                                            onChange={handleChange}
                                            type="textarea"
                                            className={
                                                "form-control" +
                                                (errors.addNote && touched.addNote
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            id="addNote"
                                        />
                                        <ErrorMessage
                                            name="addNote"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup className="mb15">

                                        <Label htmlFor="logDate">
                                            {this.props.t("Date")}
                                        </Label>
                                        <Flatpickr
                                            className={
                                                "form-control" +
                                                (errors.nextFollowupDate && touched.nextFollowupDate
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder=""
                                            value={values.nextFollowupDate}
                                            onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                        />
                                        <ErrorMessage
                                            name="logDate"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>

                            <div className="clearfix"><SaveButton /></div>


                        </Form>
                    )}
                </Formik>
                <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (noteLog, notekey) => (

                    <li className="event-list eventlist" key={"_note_" + notekey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{noteLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{noteLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                           
            </React.Fragment>
        )
    }
}
FollowLead.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    addFollowNoteTab: PropTypes.func,
    onGetFollowLead: PropTypes.func,
    leadFollows: PropTypes.array

}

const mapStateToProps = ({ leads }) => (
    {
        leadFollows: leads.leadFollows,

    })
const mapDispatchToProps = dispatch => ({
    addFollowNoteTab: (data) => dispatch(addFollowNoteTab(data)),
    onGetFollowLead: leadId => dispatch(getListLeadTab(leadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FollowLead))
