import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import {
  ADD_OPHOUSE_LEAD,
  CREATE_CLIENT_ADD_LISTING,
  CREATE_CLIENT_APPOINTMENT, CREATE_CLIENT_BUYER_CLOSING, CREATE_CLIENT_BUYER_PREF, CREATE_CLIENT_CHILD, CREATE_CLIENT_DOCUMENT,
  CREATE_CLIENT_IMPORTANT_DATE,
  CREATE_CLIENT_MESSAGE, CREATE_CLIENT_NOTE,
  CREATE_CLIENT_SELLER_CLOSING, CREATE_CLIENT_SELLER_LISTING,
  CREATE_CLIENT_SELLER_OPHOUSE,
  CREATE_CLIENT_SELLER_PREFERENCE,
  CREATE_CLIENT_SELLER_SHOWING, CREATE_NEW_CLIENT, DELETE_CLIENT_ADD_LISTING, DELETE_CLIENT_APPOINTMENT, DELETE_CLIENT_BUYER_CLOSING, DELETE_CLIENT_DOCUMENT, DELETE_CLIENT_IMPORTANT_DATE, DELETE_CLIENT_NOTE, DELETE_CLIENT_OPENHOUSE, DELETE_CLIENT_SELLER_CLOSING, DELETE_CLIENT_SELLER_LISTING, DELETE_CLIENT_SELLER_SHOWING, GET_CLIENTS, GET_CLIENTS_APPOINTMENT, GET_CLIENTS_DETAIL,
  GET_CLIENTS_DOCUMENT, GET_CLIENTS_MESSAGE,
  GET_CLIENTS_NOTE,
  GET_CLIENTS_SELLER_CLOSING,
  GET_CLIENTS_SELLER_LISTING,
  GET_CLIENTS_SELLER_SHOWING,
  GET_CLIENT_ADD_LISTING,
  GET_CLIENT_BUYER_CLOSING,
  GET_CLIENT_CHILD,
  GET_CLIENT_IMPORTANT_DATE,
  GET_CLIENT_RECENT_ACTIVITIES,
  GET_CLIENT_SELLER_OPHOUSE,
  GET_CLIENT_UPCOMING_ACTIVITIES,
  UPDATE_CLIENT_ADD_LISTING,
  UPDATE_CLIENT_APPOINTMENT,
  UPDATE_CLIENT_BUYER_CLOSING,
  UPDATE_CLIENT_DOCUMENT,
  UPDATE_CLIENT_NOTE,
  UPDATE_CLIENT_OPENHOUSE,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_SELLER_CLOSING,
  UPDATE_CLIENT_SELLER_LISTING,
  UPDATE_CLIENT_SELLER_SHOWING
} from "./actionTypes"

import {
  deleteAddListingAPI,
  deleteClientAppointmentAPI,
  deleteClientBuyerClosingAPIApi,
  deleteClientDocumentAPI,
  deleteClientImportantDateAPI,
  deleteClientNoteAPI,
  deleteClientOphouseAPI,
  deleteClientSellerClosingAPI,
  deleteClientSellerListingApi,
  deleteClientSellerShowingAPI,
  getClientAddListingUrl,
  getClientAppointment,
  getClientBuyerClosingAPIApi,
  getClientChildAPI,
  getClientDetailUrl, getClientDocumentUrl,
  getClientImportantDateAPI,
  getClientListUrl, getClientMessageUrl,
  getClientNoteUrl, getClientRecentActivitiesAPI, getClientSellerClosingUrl, getClientSellerListingUrl, getClientSellerOphouseUrl, getClientSellerShowingUrl, getClientUpcompingActivitiesAPI, postClientAddListing, postClientAppointment,
  postClientBuyerClosingAPI,
  postClientBuyerPrefAPI,
  postClientChildAPI,
  postClientDocument, postClientImportantDateAPI, postClientMessage,
  postClientNote, postClientSellerClosing,
  postClientSellerListing, postClientSellerOphouse, postClientSellerShowing, postNewClientAPI, postOpHouseLead, postQuestionClientSellerApi, updateAddListingAPI, updateClientAppointmentAPI, updateClientBuyerClosingAPIApi, updateClientDocumnetAPI, updateClientNoteAPI, updateClientOphouseAPI, updateClientProfileAPI, updateClientSellerListingUrl, updateClientSellerSellerAPI, updateClientSellerShowingAPI
} from '../../helpers/backend_helper'

import {
  addClientAddListingFail,
  addClientAddListingSuccess,
  addClientAppointmentFail, addClientAppointmentSuccess,
  addClientBuyerClosingError,
  addClientBuyerClosingSuccess,
  addClientBuyerPrefError,
  addClientBuyerPrefSuccess,
  addClientChildError,
  addClientChildSuccess,
  addClientDocumentFail, addClientImportantDateError, addClientImportantDateSuccess, addClientMessageFail,
  addClientMessageSuccess, addClientNoteFail,
  addClientNoteSuccess, addClientSellerListingFail,
  addClientSellerListingSuccess, addClientSellerOphouseFail, addClientSellerOphouseSuccess, addClientSellerPrefFail, addClientSellerPrefSuccess, addClientSellerShwoingFail,
  addClientSellerShwoingSuccess, addNewClientError, addNewClientSuccess, addOpHouseLeadFail, addOpHouseLeadSuccess, ClientAddListingUpdateError, ClientAddListingUpdateSuccess, deleteClientAddListingFail, deleteClientAddListingSuccess, deleteClientAppointmentFail, deleteClientAppointmentSuccess, deleteClientBuyerClosingFail, deleteClientBuyerClosingSuccess, deleteClientDocumentFail, deleteClientDocumentSuccess, deleteClientImportantFail, deleteClientImportantSuccess, deleteClientNoteFail, deleteClientNoteSuccess, deleteClientopenhouseFail, deleteClientopenhouseSuccess, deleteClientSellerClosingFail, deleteClientSellerClosingSuccess, deleteClientSellerListingFail, deleteClientSellerListingSuccess, deleteClientSellerShowingFail, deleteClientSellerShowingSuccess, getClientAddListing, getClientAddListingFail, getClientAddListingSuccess, getClientAppointmentFail, getClientAppointmentSuccess, getClientBuyerClosingFail, getClientBuyerClosingSuccess, getClientChildFail, getClientChildSuccess, getClientDetailFail,
  getClientDetailSuccess, getClientDocumentFail,
  getClientDocumentSuccess, getClientImportantDateFail, getClientImportantDateSuccess, getClientListFail,
  getClientListSuccess, getClientMessageFail,
  getClientMessageSuccess, getClientNoteFail,
  getClientNoteSuccess,
  getClientRecentActivitiesFail,
  getClientRecentActivitiesSuccess,
  getClientSellerClosingFail,
  getClientSellerClosingSuccess,
  getClientSellerListingFail,
  getClientSellerListingSuccess,
  getClientSellerOphouseFail,
  getClientSellerOphouseSuccess,
  getClientSellerShwoingFail,
  getClientSellerShwoingSuccess,
  getClientUpcomingActivitiesFail,
  getClientUpcomingActivitiesSuccess,
  updateClientAppointmentError,
  updateClientAppointmentSuccess,
  updateClientBuyerClosingError,
  updateClientBuyerClosingSuccess,
  updateClientDocumentError,
  updateClientDocumentSuccess,
  updateClientNoteError,
  updateClientNoteSuccess,
  updateClientOpenhouseError,
  updateClientProfileError,
  updateClientProfileSuccess,
  updateClientSellerClosingError,
  updateClientSellerClosingSuccess,
  updateClientSellerListing,
  updateClientSellerListingFail,
  updateClientSellerListingSuccess,
  updateClientSellerShowingError,
  updateClientSellerShowingSuccess
} from "./actions"
import { take } from "lodash";
import { yearData } from "common/data";

function* fetchAllClients({ agentID }) {
  try {
    const response = yield call(getClientListUrl, agentID)
    yield put(getClientListSuccess(response))
  } catch (error) {
    yield put(getClientListFail(error))
  }
}

function* fetchClientDetail({ clientId }) {
  try {
    const response = yield call(getClientDetailUrl, clientId)
    yield put(getClientDetailSuccess(response))
  } catch (error) {
    yield put(getClientDetailFail(error))
  }
}

function* submitClientMessage({ payload: { message, history } }) {
  try {
    //console.log(message);
    toast.loading("Please wait...")
    const response = yield call(postClientMessage, {
      messageFromId: parseInt(message.messageFromId),
      messageToId: parseInt(message.messageToId),
      messageFrom: message.messageFrom,
      messageTo: message.messageTo,
      messageText: message.addMessage,
      agentId: parseInt(message.agentId)

    })
    yield put(addClientMessageSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Message Added");
      const response = yield call(getClientMessageUrl, parseInt(message.messageToId))
      yield put(getClientMessageSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientMessageFail(error))
  }
}

function* fetchClientMessage({ clientId }) {
  try {
    const response = yield call(getClientMessageUrl, clientId)
    yield put(getClientMessageSuccess(response))
  } catch (error) {
    yield put(getClientMessageFail(error))
  }
}

function* submitClientDocument({ payload: { document, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientDocument, {
      documentFromId: parseInt(document.documentFromId),
      documentToId: parseInt(document.documentToId),
      documentTitle: document.documentTitle,
      documentFrom: document.documentFrom,
      documentTo: document.documentTo,
      documentLink: document.documentLink,
      documentContent: document.documentContent,
      documentStorageType:parseInt(document.documentStorageType),
      documentTypeId: 1,
      agentId: parseInt(document.agentId),
      isUrgent:(document.isUrgent==1) ? true : false

    })
    yield put(addClientMessageSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Document Added");
      const response = yield call(getClientDocumentUrl, parseInt(document.documentFromId))
      yield put(getClientDocumentSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientDocumentFail(error))
  }
}

function* fetchClientDocument({ clientId }) {
  try {
    const response = yield call(getClientDocumentUrl, clientId)
    yield put(getClientDocumentSuccess(response))
  } catch (error) {
    yield put(getClientDocumentFail(error))
  }
}

function* submitClientAppointment({ payload: { appointment, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientAppointment, {
      appointmentTitle: appointment.appointmentTitle,
      contactName: appointment.contactName,
      contactPhone: appointment.contactPhone,
      contactEmail: appointment.contactEmail,
      appointmentStartDate: appointment.appointmentStartDate,
      startTime: appointment.startTime,
      endTime: appointment.endTime,
      appointmentType: parseInt(appointment.appointmentType),
      appointmentPriority: parseInt(appointment.appointmentPriority),
      appointmentLocationType:parseInt(appointment.appointmentLocationType),
      appointmentLocation:appointment.appointmentLocation,
      clientId: parseInt(appointment.clientId),
      agentId: parseInt(appointment.agentId),
      addedBy: appointment.addedBy

    })
    yield put(addClientAppointmentSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Appointement Added");
      const response = yield call(getClientAppointment, appointment.agentId, appointment.clientId)
      yield put(getClientAppointmentSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientAppointmentFail(error))
  }
}


function* fetchClientAppointment({ payload: { agentId, clientId } }) {
  try {
    const response = yield call(getClientAppointment, agentId, clientId)
    yield put(getClientAppointmentSuccess(response))
  } catch (error) {
    yield put(getClientAppointmentFail(error))
  }
}

function* submitClientNote({ payload: { note, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientNote, {
      noteTitle: note.noteTitle,
      clientId: parseInt(note.clientId),
      agentId: parseInt(note.agentId)

    })
    yield put(addClientNoteSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Notes Added");
      const response = yield call(getClientNoteUrl, note.agentId, note.clientId)
      yield put(getClientNoteSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientNoteFail(error))
  }
}

function* fetchClientNote({ payload: { agentId, clientId } }) {
  try {
    //console.log(agentId)
    const response = yield call(getClientNoteUrl, agentId, clientId)
    yield put(getClientNoteSuccess(response))
  } catch (error) {
    yield put(getClientNoteFail(error))
  }
}


function* submitClientSellerShwoing({ payload: { showing, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postClientSellerShowing, {
      clientListingId: parseInt(showing.clientListingId),
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentOrganization,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      notificationMinutes: parseInt(30),
      clientId: parseInt(showing.clientId),
      agentId: parseInt(showing.agentId)

    })
    yield put(addClientSellerShwoingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Seller Showing Added");
      const response = yield call(getClientSellerShowingUrl, showing.clientId)
      yield put(getClientSellerShwoingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerShwoingFail(error))
  }
}

function* fetchClientSellerShowing({ clientId }) {
  try {
    const response = yield call(getClientSellerShowingUrl, clientId)
    yield put(getClientSellerShwoingSuccess(response))
  } catch (error) {
    yield put(getClientSellerShwoingFail(error))
  }
}

function* submitClientSellerClosing({ payload: { closing, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientSellerClosing, {
      clientListingId: parseInt(closing.clientListingId),
      closingDate: closing.closingDate,
      clientId: parseInt(closing.clientId),
      agentId: parseInt(closing.agentId)

    })
    yield put(addClientSellerShwoingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Seller Closing Added");
      const response = yield call(getClientSellerClosingUrl, closing.clientId)
      yield put(getClientSellerClosingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerShwoingFail(error))
  }
}

function* fetchClientSellerClosing({ clientId }) {
  try {
    const response = yield call(getClientSellerClosingUrl, clientId)
    yield put(getClientSellerClosingSuccess(response))
  } catch (error) {
    yield put(getClientSellerClosingFail(error))
  }
}


function* submitClientSellerListing({ payload: { listing, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientSellerListing, {
      mlsId: listing.mlsId,
      mlsurl: listing.mlsURL,
      listingDate: listing.listingDate,
      salePrice: parseInt(listing.salePrice),
      commissionEarned: parseInt(listing.commissionEarned),
      listingStatusId: parseInt(listing.listingStatusId),
      isExclusive: (listing.isExclusive==1) ? true : false,
      clientId: parseInt(listing.clientId),
      agentId: parseInt(listing.agentId)

    })
    yield put(addClientSellerListingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Listing Added");
      const response = yield call(getClientSellerListingUrl, listing.clientId)
      yield put(getClientSellerListingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerListingFail(error))
  }
}

function* onClientListingUpdate({ payload: listing }) {
//console.log(listing);
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientSellerListingUrl, listing)
    toast.dismiss();
    toast.success("Listing Updated");
    const res = yield call(getClientSellerListingUrl, listing.clientId)
    yield put(getClientSellerListingSuccess(res))
    //yield put(updateClientSellerListingSuccess(response))
  } catch (error) {
    yield put(updateClientSellerListingFail(error))
  }
}

function* onDeleteClientListing({ payload: listing }) {
  
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientSellerListingApi, listing.id)
    yield put(deleteClientSellerListingSuccess(response))
    toast.dismiss();
    toast.success("Listing Deleted");
    const res = yield call(getClientSellerListingUrl, listing.clientId)
    yield put(getClientSellerListingSuccess(res))
  } catch (error) {
    yield put(deleteClientSellerListingFail(error))
  }
}

function* fetchClientSellerListing({ clientId }) {
  try {
    const response = yield call(getClientSellerListingUrl, clientId)
    yield put(getClientSellerListingSuccess(response))
  } catch (error) {
    yield put(getClientSellerListingFail(error))
  }
}

function* submitClientSellerOphouse({ payload: { ophouse, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientSellerOphouse, {
      clientListingId: parseInt(ophouse.clientListingId),
      openhouseDate: ophouse.openhouseDate,
      startTime: ophouse.startTime,
      endTime: ophouse.endTime,
      agentName: ophouse.agentName,
      agentOrganization: ophouse.agentOrganization,
      agentContact: ophouse.agentContact,
      agentEmail: ophouse.agentEmail,
      openhouseUrl: ophouse.openhouseUrl,
      clientId: parseInt(ophouse.clientId),
      agentId: parseInt(ophouse.agentId),
      brochureLink: ophouse.brochureLink,
      brochureContent: ophouse.brochureContent,
      brochureStorageType: 0,

    })
    yield put(addClientSellerOphouseSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Open House Added");
      const response = yield call(getClientSellerOphouseUrl, ophouse.clientId)
      yield put(getClientSellerOphouseSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerOphouseFail(error))
  }
}
function* fetchClientSellerOphouse({ clientId }) {
  try {
    const response = yield call(getClientSellerOphouseUrl, clientId)
    yield put(getClientSellerOphouseSuccess(response))
  } catch (error) {
    yield put(getClientSellerOphouseFail(error))
  }
}

function* submitOpHouseLead({ payload: { lead, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postOpHouseLead, {
      firstName: lead.firstName,
      lastName: "",
      clientOpenhouseLeadSourceId: parseInt(lead.clientOpenhouseLeadSourceId),
      email: lead.email,
      mobile: lead.phone,
      questionAnswers: [
        {
          
          questionAnswerId: 1,
          answersOption: "1",
          isActive: true
        },
        {
          questionAnswerId: 2,
          answersOption: "1 Month",
          isActive: true
        },
        {
         
          questionAnswerId: 3,
          answersOption: "Yes",
          isActive: true
        }

      ]

    })
    yield put(addOpHouseLeadSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Lead Added");

    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addOpHouseLeadFail(error))
  }
}


function* submitSellerPref({ payload: { question, history } }) {
  try {


    const questionAnswers = [{
      clientId: parseInt(question.clientId),
      questionAnswerId: 15,
      answersOption: question.sellerQuest[15],
    },{
      clientId: parseInt(question.clientId),
      questionAnswerId: 16,
      answersOption: question.sellerQuest[16],
    },{
      clientId: parseInt(question.clientId),
      questionAnswerId: 17,
      answersOption: question.sellerQuest[17],
    },{
      clientId: parseInt(question.clientId),
      questionAnswerId: 18,
      answersOption: question.sellerQuest[18],
    },{
      clientId: parseInt(question.clientId),
      questionAnswerId: 20,
      answersOption: question.sellerQuest[20],
    },{
      clientId: parseInt(question.clientId),
      questionAnswerId: 21,
      answersOption: question.sellerQuest[21],
    }
    ];
   // console.log(questionAnswers);
    const response = yield call(postQuestionClientSellerApi, {
      questionAnswers
    })
    yield put(addClientSellerPrefSuccess(response))
    if (response.success == true) {
      toast.success("Seller Preference Added");
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientSellerPrefFail('Some Error'))
  }
}


function* submitClientAddListing({ payload: { listing, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientAddListing, {
      mlsId: listing.mlsId,
      mlsurl: listing.mlsurl,
      listingStatusId: 1,
      isInterested: true,
      clientId: parseInt(listing.clientId),
      agentId: parseInt(listing.agentId),
      addedBy: "agent",
      listedPrice: parseInt(listing.listedPrice),
      listingCity: listing.listingCity,
      notes: listing.notes,
      listingDate: new Date()

    })
    yield put(addClientAddListingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Listing Added");
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientAddListingFail(error))
  }
}
function* submitClientAddListingBulk({ payload: { listing, history } }) {
  try {
    console.log(listing);
    toast.loading("Please wait...")
    const response = yield call(postClientAddListing, listing)
    yield put(addClientAddListingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Listing Added");
      const response = yield call(getClientAddListingUrl, listing.clientId, listing.agentId)
      yield put(getClientAddListingSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientAddListingFail(error))
  }
}

function* fetchClientAddListing({ payload: { clientId, agentId } }) {
  try {
    const response = yield call(getClientAddListingUrl, clientId, agentId)
    yield put(getClientAddListingSuccess(response))
  } catch (error) {
    yield put(getClientAddListingFail(error))
  }
}

function* onDeleteAddListing({ payload: addListing }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteAddListingAPI, addListing.id)
    toast.dismiss();
    toast.success("Listing Deleted");
    yield put(deleteClientAddListingSuccess(response))
    const res = yield call(getClientAddListingUrl, addListing.clientId, addListing.agentId)
    yield put(getClientAddListingSuccess(res))
  } catch (error) {
    yield put(deleteClientAddListingFail(error))
  }
}

function* onUpdateAddListing({ payload:  addListing  }) {
  //console.log(addListing)
  try {
    toast.loading("Please wait...")
    const response = yield call(updateAddListingAPI,addListing)
    //yield put(ClientAddListingUpdateSuccess(response))
    const res = yield call(getClientAddListingUrl, addListing.clientId, addListing.agentId)
    toast.dismiss();
    toast.success("Listing Updated");
    yield put(getClientAddListingSuccess(res))
  } catch (error) {
    yield put(ClientAddListingUpdateError(error))
  }
}

function* onDeleteClientDocumet({ payload: document }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientDocumentAPI, document.id)
    yield put(deleteClientDocumentSuccess(response))
   
    toast.dismiss();
    toast.success("Document Deleted");
    const res = yield call(getClientDocumentUrl, document.documentFromId)
    yield put(getClientDocumentSuccess(res))
  } catch (error) {
    yield put(deleteClientDocumentFail(error))
  }
}

function* onDeleteClientAppointment({ payload: appointment }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientAppointmentAPI, appointment.id)
    yield put(deleteClientAppointmentSuccess(response))
    toast.dismiss();
    toast.success("Appointment Deleted");
    const res = yield call(getClientAppointment, appointment.agentId, appointment.clientId)
    yield put(getClientAppointmentSuccess(res))
  } catch (error) {
    yield put(deleteClientAppointmentFail(error))
  }
}

function* onClientAppointmentUpdate({ payload: appointment }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientAppointmentAPI, appointment)
    toast.dismiss();
    toast.success("Appointment Updated");
    const res = yield call(getClientAppointment, appointment.agentId, appointment.clientId)
    yield put(getClientAppointmentSuccess(res))
    //yield put(updateClientAppointmentSuccess(response))
  } catch (error) {
    yield put(updateClientAppointmentError(error))
  }
}

function* onClientDocumentUpdate({ payload: document }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientDocumnetAPI, document)
    toast.dismiss();
    toast.success("Document Updated");
    const res = yield call(getClientDocumentUrl, document.documentFromId)
    yield put(getClientDocumentSuccess(res))   
  } catch (error) {
    yield put(updateClientDocumentError(error))
  }
}

function* onDeleteClientNote({ payload: note }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientNoteAPI, note.id)
    yield put(deleteClientNoteSuccess(response))
    toast.dismiss();
    toast.success("Note Deleted");
    const res = yield call(getClientNoteUrl, note.agentId, note.clientId)
    yield put(getClientNoteSuccess(res))
  } catch (error) {
    yield put(deleteClientNoteFail(error))
  }
}

function* onClientNoteUpdate({ payload: note }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientNoteAPI, note)
    yield put(updateClientNoteSuccess(response))
    if (response.success == true) {
    toast.dismiss();
    toast.success("Note Updated");
    const response = yield call(getClientNoteUrl, note.agentId, note.clientId)
    yield put(getClientNoteSuccess(response))
    } else {
    toast.dismiss();
    toast.warn("Some Error. Please try after some time");
   }
   
  } catch (error) {
    yield put(updateClientNoteError(error))
  }
}

function* onDeleteClientOphouse({ payload: openhouse }) {
  //console.log(document);
  try {
    //toast.loading("Please wait...")
    const response = yield call(deleteClientOphouseAPI, openhouse.id)
    yield put(deleteClientopenhouseSuccess(response))
    const res = yield call(getClientSellerOphouseUrl, openhouse.clientId)
    yield put(getClientSellerOphouseSuccess(res))
  } catch (error) {
    yield put(deleteClientopenhouseFail(error))
  }
}

function* onClientSellerShwoingUpdate({ payload: showing }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientSellerShowingAPI, showing)
    toast.dismiss();
    toast.success("Showing Updated");
    const res = yield call(getClientSellerShowingUrl, showing.clientId)
    yield put(getClientSellerShwoingSuccess(res))
    
  } catch (error) {
    yield put(updateClientSellerShowingError(error))
  }
}

function* onDeleteClientSellerShwoing({ payload: showing }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientSellerShowingAPI, showing.id)
    yield put(deleteClientSellerShowingSuccess(response))
    toast.dismiss();
    toast.success("Showing Deleted");
    const res = yield call(getClientSellerShowingUrl, showing.clientId)
    yield put(getClientSellerShwoingSuccess(res))
   
  } catch (error) {
    yield put(deleteClientSellerShowingFail(error))
  }
}

function* onClientSellerClosingUpdate({ payload: closing }) {
  try {
    toast.loading("Please wait...")
    
    const response = yield call(updateClientSellerSellerAPI, closing)
    toast.dismiss();
    toast.success("Closing Updated");
    const res = yield call(getClientSellerClosingUrl, closing.clientId)
    yield put(getClientSellerClosingSuccess(res))
    
  } catch (error) {
    yield put(updateClientSellerClosingError(error))
  }
}

function* onDeleteClientSellerClosing({ payload: closing }) {
  
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientSellerClosingAPI, closing.id)
    //yield put(deleteClientSellerClosingSuccess(response))
    toast.dismiss();
    toast.success("Closing Deleted");
    const res = yield call(getClientSellerClosingUrl, closing.clientId)
    yield put(getClientSellerClosingSuccess(response))
   
  } catch (error) {
    yield put(deleteClientSellerClosingFail(error))
  }
}

function* addBQuestion({ payload: { bquestion, history } }) {
  try {

    //console.log(bquestion);
    //console.log(JSON.stringify(bquestion));   

    const questionAnswers = [{
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 1,
      answersOption: bquestion.buyerQuest[1],
    }, {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 2,
      answersOption: bquestion.buyerQuest[2],
    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 3,
      answersOption: bquestion.buyerQuest[3],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 26,
      answersOption: bquestion.buyerQuest[26],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 4,
      answersOption: bquestion.buyerQuest[4],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 8,
      answersOption: bquestion.buyerQuest[8],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 9,
      answersOption: bquestion.buyerQuest[9],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 10,
      answersOption: bquestion.buyerQuest[10],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 11,
      answersOption: bquestion.buyerQuest[11],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 12,
      answersOption: bquestion.buyerQuest[12],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 13,
      answersOption: bquestion.buyerQuest[13],

    },
    {
      clientId: parseInt(bquestion.clientId),
      questionAnswerId: 14,
      answersOption: bquestion.buyerQuest[14],

    }
    
    ];
    ///console.log(questionAnswers);
    const response = yield call(postClientBuyerPrefAPI, {
      questionAnswers
    })
    //console.log(response);
    yield put(addClientBuyerPrefSuccess(response))
    if (response.success == true) {
      toast.success("Buyers Question Added");
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientBuyerPrefError('Some Error'))
  }
}

function* fetchClientUpcomingActivities({ agentId }) {
  try {
    const response = yield call(getClientUpcompingActivitiesAPI, agentId)
    yield put(getClientUpcomingActivitiesSuccess(response))
  } catch (error) {
    yield put(getClientUpcomingActivitiesFail(error))
  }
}

function* fetchClientRecentActivities({ agentId }) {
  try {
    const response = yield call(getClientRecentActivitiesAPI, agentId)
    yield put(getClientRecentActivitiesSuccess(response))
  } catch (error) {
    yield put(getClientRecentActivitiesFail(error))
  }
}

function* submitClienImportantDate({ payload: { importantdate, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientImportantDateAPI, {
      clientId: parseInt(importantdate.clientId),
      agentId: parseInt(importantdate.agentId),
      dateTitle: importantdate.dateTitle,
      dateMonth: parseInt(importantdate.dateMonth),
      dateDay: parseInt(importantdate.dateDay),
      

    })
    yield put(addClientImportantDateSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Important Date Added");
      const response = yield call(getClientImportantDateAPI, importantdate.clientId)
    yield put(getClientImportantDateSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientImportantDateError(error))
  }
}

function* fetchClienImportantDate({ clientId }) {
  try {
    const response = yield call(getClientImportantDateAPI, clientId)
    yield put(getClientImportantDateSuccess(response))
  } catch (error) {
    yield put(getClientImportantDateFail(error))
  }
}

function* submitClienChild({ payload: { child, history } }) {
  try {

    toast.loading("Please wait...")
    const response = yield call(postClientChildAPI, {
      clientId: parseInt(child.clientId),
      childName: child.childName,
      birthDay: child.birthDay,
      cGender: 0,
      cChildIsActive:true
      

    })
    yield put(addClientChildSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Child Added");
      const response = yield call(getClientChildAPI, child.clientId)
      yield put(getClientChildSuccess(response))
    } else {
      toast.dismiss();
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientChildError(error))
  }
}

function* fetchClientChild({ clientId }) {
  try {
    const response = yield call(getClientChildAPI, clientId)
    yield put(getClientChildSuccess(response))
  } catch (error) {
    yield put(getClientChildFail(error))
  }
}

function* onDeleteClienImportantDate({ payload: importantdate }) {
  //console.log(document);
  try {
   
    const response = yield call(deleteClientImportantDateAPI, importantdate.id)
    yield put(deleteClientImportantSuccess(response))
    const res = yield call(getClientImportantDateAPI, importantdate.clientId)
    yield put(getClientImportantDateSuccess(res))
   
  } catch (error) {
    yield put(deleteClientImportantFail(error))
  }
}

function* onClientProfileUpdate({ payload: client }) {
  try {
    //toast.loading("Please wait...")
    const response = yield call(updateClientProfileAPI, client)
    const res = yield call(getClientDetailUrl, client.id)
    yield put(getClientDetailSuccess(res))
    //yield put(updateClientProfileSuccess(response))
  
  } catch (error) {
    yield put(updateClientProfileError(error))
  }
}


function* onSubmitNewClient({ payload: {  client, history } }) {
  try {
    //console.log(message);
    toast.loading("Please wait...")
    const response = yield call(postNewClientAPI, {
      agentId: parseInt(client.agentId),
      userLogin: client.email,
      title: client.title,
      firstName: client.firstName,
      lastName: client.lastName,
      clientStatusId: 1,
      clientTypeId:parseInt(client.clientTypeId),
      isActive:true,
      howOffenClient:parseInt(client.howOffenClient),
      notes: client.notes,
      mobile: client.mobile,
      phone: client.phone,
      email: client.email,
      office: client.office,
      phoneExt:client.phoneExt
      })
    yield put(addNewClientSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Client Added");
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addNewClientError(error))
  }
}


function* onSubmitClientBuyerCLosing({ payload: {  buyerclosing, history } }) {
  try {
    //console.log(message);
    toast.loading("Please wait...")
    const response = yield call(postClientBuyerClosingAPI, {
      clientId: parseInt(buyerclosing.clientId),
      agentId: parseInt(buyerclosing.agentId),
      mlsId: buyerclosing.mlsId,
      mlsurl: buyerclosing.mlsurl,
      closingDate: buyerclosing.closingDate,
      isActive: true
      })
    yield put(addClientBuyerClosingSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Buyer Closing Added");
      const response = yield call(getClientBuyerClosingAPIApi, buyerclosing.clientId)
      yield put(getClientBuyerClosingSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addClientBuyerClosingError(error))
  }
}

function* fetchClientBuyerClosing({ clientId }) {
  try {
    const response = yield call(getClientBuyerClosingAPIApi, clientId)
    yield put(getClientBuyerClosingSuccess(response))
  } catch (error) {
    yield put(getClientBuyerClosingFail(error))
  }
}

function* onDeleteClientBuyerClosing({ payload: buyerClosing }) {
  //console.log(document);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteClientBuyerClosingAPIApi, buyerClosing.id)
    yield put(deleteClientBuyerClosingSuccess(response))
    toast.dismiss();
    toast.success("Buyer Closing Deleted");
    const res = yield call(getClientBuyerClosingAPIApi, buyerClosing.clientId)
    yield put(getClientBuyerClosingSuccess(res))
   
  } catch (error) {
    yield put(deleteClientBuyerClosingFail(error))
  }
}

function* onClientBuyerClosing({ payload: buyerClosing }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateClientBuyerClosingAPIApi, buyerClosing)
    toast.dismiss();
    toast.success("Buyer Closing Updated");
    const res = yield call(getClientBuyerClosingAPIApi, buyerClosing.clientId)
    yield put(getClientBuyerClosingSuccess(res))
    
    
  } catch (error) {
    yield put(updateClientBuyerClosingError(error))
  }
}



function* onUpdateClientSellerOpenhouse({ payload: openhouse }) {
  try {
    //toast.loading("Please wait...")
    const response = yield call(updateClientOphouseAPI, openhouse)
    const res = yield call(getClientSellerOphouseUrl, openhouse.clientId)
    yield put(getClientSellerOphouseSuccess(res))
    
    
  } catch (error) {
    yield put(updateClientOpenhouseError(error))
  }
}



function* clientSaga() {
  yield takeEvery(GET_CLIENTS, fetchAllClients)

  yield takeEvery(GET_CLIENTS_DETAIL, fetchClientDetail)
  yield takeEvery(UPDATE_CLIENT_PROFILE, onClientProfileUpdate)

  yield takeEvery(CREATE_CLIENT_MESSAGE, submitClientMessage)
  yield takeEvery(GET_CLIENTS_MESSAGE, fetchClientMessage)

  yield takeEvery(CREATE_CLIENT_DOCUMENT, submitClientDocument)
  yield takeEvery(GET_CLIENTS_DOCUMENT, fetchClientDocument)
  yield takeEvery(DELETE_CLIENT_DOCUMENT, onDeleteClientDocumet)
  yield takeEvery(UPDATE_CLIENT_DOCUMENT, onClientDocumentUpdate)

  yield takeEvery(CREATE_CLIENT_APPOINTMENT, submitClientAppointment)
  yield takeEvery(GET_CLIENTS_APPOINTMENT, fetchClientAppointment)
  yield takeEvery(DELETE_CLIENT_APPOINTMENT, onDeleteClientAppointment)
  yield takeEvery(UPDATE_CLIENT_APPOINTMENT, onClientAppointmentUpdate)

  yield takeEvery(CREATE_CLIENT_NOTE, submitClientNote)
  yield takeEvery(GET_CLIENTS_NOTE, fetchClientNote)
  yield takeEvery(DELETE_CLIENT_NOTE, onDeleteClientNote)
  yield takeEvery(UPDATE_CLIENT_NOTE, onClientNoteUpdate)


  yield takeEvery(CREATE_CLIENT_SELLER_LISTING, submitClientSellerListing)
  yield takeEvery(GET_CLIENTS_SELLER_LISTING, fetchClientSellerListing)
  yield takeEvery(UPDATE_CLIENT_SELLER_LISTING, onClientListingUpdate)
  yield takeEvery(DELETE_CLIENT_SELLER_LISTING, onDeleteClientListing)

  yield takeEvery(CREATE_CLIENT_SELLER_SHOWING, submitClientSellerShwoing)
  yield takeEvery(GET_CLIENTS_SELLER_SHOWING, fetchClientSellerShowing)
  yield takeEvery(UPDATE_CLIENT_SELLER_SHOWING, onClientSellerShwoingUpdate)
  yield takeEvery(DELETE_CLIENT_SELLER_SHOWING, onDeleteClientSellerShwoing)

  yield takeEvery(CREATE_CLIENT_SELLER_CLOSING, submitClientSellerClosing)
  yield takeEvery(GET_CLIENTS_SELLER_CLOSING,fetchClientSellerClosing)
  yield takeEvery(UPDATE_CLIENT_SELLER_CLOSING, onClientSellerClosingUpdate)
  yield takeEvery(DELETE_CLIENT_SELLER_CLOSING, onDeleteClientSellerClosing)


  yield takeEvery(CREATE_CLIENT_SELLER_OPHOUSE, submitClientSellerOphouse)
  yield takeEvery(GET_CLIENT_SELLER_OPHOUSE, fetchClientSellerOphouse)
  yield takeEvery(DELETE_CLIENT_OPENHOUSE, onDeleteClientOphouse)
  yield takeEvery(UPDATE_CLIENT_OPENHOUSE, onUpdateClientSellerOpenhouse)


  yield takeEvery(ADD_OPHOUSE_LEAD, submitOpHouseLead)


  yield takeEvery(CREATE_CLIENT_SELLER_PREFERENCE, submitSellerPref)


  yield takeEvery(CREATE_CLIENT_ADD_LISTING, submitClientAddListingBulk)
  yield takeEvery(GET_CLIENT_ADD_LISTING, fetchClientAddListing)
  yield takeEvery(DELETE_CLIENT_ADD_LISTING, onDeleteAddListing)
  yield takeEvery(UPDATE_CLIENT_ADD_LISTING, onUpdateAddListing)
 

  

  
  yield takeEvery(CREATE_CLIENT_BUYER_PREF, addBQuestion)
  yield takeEvery(GET_CLIENT_UPCOMING_ACTIVITIES, fetchClientUpcomingActivities)
  yield takeEvery(GET_CLIENT_RECENT_ACTIVITIES, fetchClientRecentActivities)
  yield takeEvery(CREATE_CLIENT_IMPORTANT_DATE, submitClienImportantDate)
  yield takeEvery(GET_CLIENT_IMPORTANT_DATE, fetchClienImportantDate)
  yield takeEvery(CREATE_CLIENT_CHILD, submitClienChild)
  yield takeEvery(GET_CLIENT_CHILD, fetchClientChild)
  yield takeEvery(DELETE_CLIENT_IMPORTANT_DATE, onDeleteClienImportantDate)

  yield takeEvery(CREATE_NEW_CLIENT, onSubmitNewClient)
  yield takeEvery(CREATE_CLIENT_BUYER_CLOSING, onSubmitClientBuyerCLosing)
  yield takeEvery(GET_CLIENT_BUYER_CLOSING, fetchClientBuyerClosing)
  yield takeEvery(DELETE_CLIENT_BUYER_CLOSING, onDeleteClientBuyerClosing)
  yield takeEvery(UPDATE_CLIENT_BUYER_CLOSING, onClientBuyerClosing)
}


export default clientSaga
