import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import WelcomeComp from "../WelcomeComp"
import { API_URL } from "../../../helpers/app_url";
import axios from "axios"
import Moment from 'react-moment';
import FilterMenu from "./filter-menu";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"
class LeadTimeLine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leads:[]
    }
   
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
   
    axios.get(API_URL + 'Lead/allforagent',{ params: {
       agentId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
      .then(res => {
        this.setState({ leads: res.data  });
      })
}
  realtorDate(isoDate) {
    let date = new Date(isoDate);
    let dtString = ''
    let monthString = ''
    if (date.getDate() < 10) {
      dtString = '0' + date.getDate();
    } else {
      dtString = String(date.getDate())
    }
    if (date.getMonth()+1 < 10) {
      monthString = '0' + Number(date.getMonth()+1);
    } else {
      monthString = String(date.getMonth()+1);
    }
    //return date.getFullYear()+'-' + monthString + '-'+dtString
    return monthString +'/' + date.getFullYear()
  }

  render() {
    const { leads } = this.state;
    
    const lanes = [];
    const cards = [];
    const Dates = []
    var dateObj = new Date();

    const brColor = [{borderBottom:'3px solid #0D6EFD'},{borderBottom:'3px solid #198754'},{borderBottom:'3px solid #0DCAF0'}, {borderBottom:'3px solid #AB2E3C'},{borderBottom:'3px solid #FFC107'}, {borderBottom:'3px solid #000'}];


      var dateStrings = [];
      var dateFormatOptions = {
        month: 'numeric',
        year: 'numeric'
      };
      var HeaddateFormatOptions = {
        month: 'long',
        year: 'numeric'
      };
      dateStrings.push({
        "title": 'No Follow Up',
        "leads" : leads.filter(d => d.nextFollowup === null)
       });
      for (var i = 0; i < 5; ++i) {
    dateStrings.push({
       "title": dateObj.toLocaleString('en-US', HeaddateFormatOptions),
      
       "leads" : leads.filter(d => d.nextFollowup !== null && dateObj.toLocaleString('en-US', dateFormatOptions) === this.realtorDate(d.nextFollowup))
      })
      dateObj.setMonth(dateObj.getMonth() + 1);
      }
   //expectedClosing
    
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
           
          <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Follow Up View")}
            />
            </Col>
            <Col xl="5">
            <WelcomeComp />
            </Col>
            </Row>
            <FilterMenu />
            <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                        { dateStrings.map((dateString , index) => 
                            <Col sm="2" className="" key={dateString.title}>
                                <div className="topHeading" style={brColor[index]}>
                                <span className="sc-kEqXSa CIzYb">{dateString.title}</span>
                                  
                                </div>
                            </Col>
                         )}   
                        </Row>
                       <Row className="toRowContect">
                        { dateStrings.map(dateString => 
                        <Col sm="2" className=" " key={dateString.title}>
                           { dateString.leads.map(lead => 
                                  <div className="container p-0" key={lead.id} style={{marginTop:10}}>
                                  <div className="mb-3 card custom-card">
                                   
                                    <div className="card-body">
                                    <div className="float-end ms-2"><span className="badge rounded-pill badge-soft-success font-size-12">{ lead.leadStatusId  ? localization["LeadStatus_"+ lead.leadStatusId]  : 'N/A' }</span></div>
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2 lead-box-title"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                       
                                      </div>
                                      <div><i className=" bx bxs-timer align-middle"></i>: { lead.lastActivityType ? lead.lastActivityType : 'No Activity' } </div>
                                      <div> <i className=" bx  bxs-calendar align-middle"></i>:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to={`/lead/lead-detail/`+ lead.id + "/" + lead.leadId}><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                                  )}   
                                                               
                            </Col>
                         )}   
                           
                        </Row> 




                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LeadTimeLine.propTypes = {
  t: PropTypes.any,


}
export default connect()(withTranslation()(LeadTimeLine))
