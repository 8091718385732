import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map,isEmpty,size } from "lodash"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing-c.png"
import ProjectCount from "./ProjectCount";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getProjectUUIDAction, addProjectBudgeAllocation, getProjectBudgeAllocation, updateProjectBudgeAllocation, deleteProjectBudgeAllocation,
addProjectBudgeSpent, getProjectBudgeSpent, deleteProjectBudgeSpent,updateProjectBudgeSpent  } from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";



import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Modal,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"

class projectMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      verticalActiveTab: "1",
      allocation:'',
      spent:''
    }

   
    this.handleProjectBudgetSubmit = this.handleProjectBudgetSubmit.bind(this)
    this.handleProjectBudgetSpentSubmit = this.handleProjectBudgetSpentSubmit.bind(this)
    this.onClickAllocationDelete = this.onClickAllocationDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickSpentDelete = this.onClickSpentDelete.bind(this)
    this.toggleSpentCanvas = this.toggleSpentCanvas.bind(this);
  }
  handleProjectBudgetSubmit(value) {
    //console.log(value);
    this.props.addProjectBudgeAllocation(value, this.props.history);
  }
  handleProjectBudgetSpentSubmit(value) {
    //console.log(value);
    this.props.addProjectBudgeSpent(value, this.props.history);
  }
  
  componentDidMount() {

    const { match: { params }, onGetProjectBudgeAllocation, onGetProjectBudgeSpent, ongetProjectUUIDAction } = this.props;

    if (params && params.projectId && params.id) {
      
      onGetProjectBudgeAllocation(params.id);
      onGetProjectBudgeSpent(params.id)
      ongetProjectUUIDAction(params.projectId);
    }
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteAllocationModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickAllocationDelete = (allocation) => {
    this.setState({ allocation: allocation });
    this.setState({ deleteModal: true });
  };


  onClickSpentDelete = () => {
    this.setState(prevState => ({
      deleteSpentModal: !prevState.deleteSpentModal,
    }));
  };
  onClickSpentDelete = (spent) => {
    this.setState({ spent: spent });
    this.setState({ deleteSpentModal: true });
  };

 handleDeleteAllocation = () => {
 const { onDeleteAllocation } = this.props;
 const { allocation } = this.state;
 console.log(allocation);
  if (allocation.id !== undefined) {
    onDeleteAllocation(allocation);
  this.setState({ deleteModal: false });
  }
  };

  handleDeleteSpent = () => {
    const { onDeleteSpent } = this.props;
    const { spent } = this.state;
    console.log(spent);
     if (spent.id !== undefined) {
      onDeleteSpent(spent);
     this.setState({ deleteSpentModal: false });
     }
     };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }

  spenttoggle() {
    this.setState(prevState => ({
      isSpentRight: !prevState.isSpentRight
    }));
  }
  
 
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { allocations, spents } = this.props;
    if (!isEmpty(allocations) && size(prevProps.allocations) !== size(allocations)) {
      this.setState({ allocations: {}, isEdit: false });
    }

    if (!isEmpty(spents) && size(prevProps.spents) !== size(spents)) {
      this.setState({ spents: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ allocation: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleSpentCanvas() {
    this.setState({ spent: "", isEdit: false, isSpentRight: !this.state.isSpentRight });
    this.spenttoggle();
  }
  toggleRightCanvas = arg => {
    const allocation = arg;
    this.setState({
      allocation: {
        id:allocation.id,
        projectMarketingAllocationId: allocation.projectMarketingAllocationId,
        projectId:allocation.projectId,
        platform: allocation.platform,
        allocatedBudget: allocation.allocatedBudget,
        
      },
      isEdit: true,
    });
    this.toggle();
  };

  toggleSpentCanvas = arg => {
    const spent = arg;
    this.setState({
      spent: {
        id:spent.id,
        projectMarketingSpentId: spent.projectMarketingSpentId,
        projectId:spent.projectId,
        platform: spent.platform,
        alreadySpent: spent.alreadySpent,
        
      },
      isEdit: true,
    });
    this.spenttoggle();
  };
  render() {
    const { allocations, spents, projectData, onUpdateProjectBudgetAllocation, onUpdateProjectBudgetSpent } = this.props;
    const { isEdit, deleteModal, deleteSpentModal } = this.state;
    const allocation = this.state.allocation;
    const spent = this.state.spent;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAllocation}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <DeleteModal
          show={deleteSpentModal}
          onDeleteClick={this.handleDeleteSpent}
          onCloseClick={() => this.setState({ deleteSpentModal: false })}
        />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Marketing")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
            <Row>

              <Col lg="12">



                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectId: this.props.match.params.id,
                    platform: (this.state && this.state.platform) || "",
                    allocatedBudget:''
                  }}
                  validationSchema={Yup.object().shape({
                    platform: Yup.string().required("This is required"),
                    allocatedBudget:Yup.number().typeError('Enter only numbers').required('This is required')

                  })}

                  onSubmit={this.handleProjectBudgetSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <h5 className="card-title">Budget Allocation </h5>
                          <Row>


                            <Col sm="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="platform">
                                  {this.props.t("Platform")}
                                </Label>
                                <Field
                                  name="platform"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.platform && touched.platform
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="platform"
                                />
                                <ErrorMessage
                                  name="platform"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="allocatedBudget">
                                  {this.props.t("Amount")}
                                </Label>
                                <Field
                                  name="allocatedBudget"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.allocatedBudget && touched.allocatedBudget
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="allocatedBudget"
                                />
                                <ErrorMessage
                                  name="allocatedBudget"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                            </Col>

                          </Row>
                          <Row>
                            <Col sm="12">
                              
                              <div className="table-responsive">
                                <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                  <thead className="">
                                    <tr>
                                      <th>#</th>
                                      <th>Platform</th>
                                      <th>Amount</th>

                                      <th>Action</th>


                                    </tr>
                                  </thead>
                                  <tbody>

                                  {map(allocations, (allocation, prokey) => (     
                                <tr>
                                  <th>{prokey+1}</th>
                                  <td>{allocation.platform}</td>
                                  <td>{"$"+allocation.allocatedBudget}</td>
                                  <td>
                                    <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link} to="#" onClick={() => this.toggleRightCanvas(allocation)}>Edit</DropdownItem>
                                        <DropdownItem tag={Link} to="#" onClick={() => this.onClickAllocationDelete(allocation)}>Delete</DropdownItem>


                                      </DropdownMenu>
                                    </UncontrolledDropdown>

                                  </td>
                                </tr>
                                 ))}  

                                  </tbody>
                                </table>
                              </div>
                            </Col>
                          </Row>

                        </CardBody>
                      </Card>
                    </Form>
                  )}
                </Formik>


              </Col>
            </Row>

            <Row>

              <Col lg="12">



                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectId: this.props.match.params.id,
                    platform: (this.state && this.state.platform) || "",
                    alreadySpent:''
                  }}
                  validationSchema={Yup.object().shape({
                    platform: Yup.string().required("This is required"),
                    alreadySpent:Yup.number().typeError('Enter only numbers').required('This is required')

                  })}

                  onSubmit={this.handleProjectBudgetSpentSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <h5 className="card-title">Budget Spent </h5>
                          <Row>


                            <Col sm="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="platform">
                                  {this.props.t("Platform")}
                                </Label>
                                <Field
                                  name="platform"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.platform && touched.platform
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="platform"
                                />
                                <ErrorMessage
                                  name="platform"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="alreadySpent">
                                  {this.props.t("Amount")}
                                </Label>
                                <Field
                                  name="alreadySpent"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.alreadySpent && touched.alreadySpent
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="alreadySpent"
                                />
                                <ErrorMessage
                                  name="alreadySpent"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                            </Col>

                          </Row>
                          
                          <Row>
                            <Col sm="12">
                            <div className="table-responsive">
                            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                              <thead className="">
                                <tr>
                                  <th>#</th>
                                  <th>Platform</th>
                                  <th>Amount</th>

                                  <th>Action</th>


                                </tr>
                              </thead>
                              <tbody>
                              {map(spents, (spent, skey) => (     
                                <tr>
                                  <th>{skey+1}</th>
                                  <td>{spent.platform}</td>
                                  <td>{"$"+spent.alreadySpent}</td>
                                  <td>
                                    <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link} to="#" onClick={() => this.toggleSpentCanvas(spent)}>Edit</DropdownItem>
                                        <DropdownItem tag={Link} to="#" onClick={() => this.onClickSpentDelete(spent)}>Delete</DropdownItem>


                                      </DropdownMenu>
                                    </UncontrolledDropdown>

                                  </td>
                                </tr>
                                 ))}  
                              </tbody>
                            </table>
                          </div>
                            </Col>
                          </Row>

                        </CardBody>
                      </Card>
                      








                    </Form>
                  )}
                </Formik>


              </Col>
            </Row>
            <Row>

            </Row>
            </Col>
            </Row>
          </Container>
        </div>



        <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Budget Allocation
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:allocation.id,
                    projectMarketingAllocationId: allocation.projectMarketingAllocationId,
                    projectId:allocation.projectId,
                    platform: allocation.platform,
                    allocatedBudget: allocation.allocatedBudget,
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    platform: Yup.string().required("This is required"),
                    allocatedBudget:Yup.number().typeError('Enter only numbers').required('This is required')
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateAllocation = {
                        id:values.id,
                        projectMarketingAllocationId: values.projectMarketingAllocationId,
                        projectId:values.projectId,
                        platform: values.platform,
                        allocatedBudget: parseInt(values.allocatedBudget),
                      };

                      onUpdateProjectBudgetAllocation(updateAllocation);
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field
                                  name="platform"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.platform && touched.platform
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="platform"
                                />
                                <ErrorMessage
                                  name="platform"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="allocatedBudget">
                              {this.props.t("Amount")}
                            </Label>
                            <Field
                              name="allocatedBudget"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.allocatedBudget && touched.allocatedBudget
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="allocatedBudget"
                            />
                             <ErrorMessage
                              name="allocatedBudget"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                       

                      </Row>
                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>

            <Offcanvas
              isOpen={this.state.isSpentRight}
              direction="end"
              toggle={this.toggleSpentCanvas}
            >
              <OffcanvasHeader toggle={this.toggleSpentCanvas}>
                Update Budget Spent
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:spent.id,
                    projectMarketingSpentId: spent.projectMarketingSpentId,
                    projectId:spent.projectId,
                    platform: spent.platform,
                    alreadySpent: spent.alreadySpent,
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    platform: Yup.string().required("This is required"),
                    alreadySpent:Yup.number().typeError('Enter only numbers').required('This is required')
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateSpent = {
                        id:values.id,
                        projectMarketingSpentId: values.projectMarketingSpentId,
                        projectId:values.projectId,
                        platform: values.platform,
                        alreadySpent: parseInt(values.alreadySpent),
                      };

                      onUpdateProjectBudgetSpent(updateSpent);
                    } else {
                      
                    }
                    this.spenttoggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field
                                  name="platform"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.platform && touched.platform
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="platform"
                                />
                                <ErrorMessage
                                  name="platform"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="alreadySpent">
                              {this.props.t("Amount")}
                            </Label>
                            <Field
                              name="alreadySpent"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.alreadySpent && touched.alreadySpent
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="alreadySpent"
                            />
                             <ErrorMessage
                              name="alreadySpent"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                       

                      </Row>
                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}

projectMarket.propTypes = {
  t: PropTypes.any,
  addProjectBudgeAllocation:PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  onGetProjectBudgeAllocation:PropTypes.func,
  loading:PropTypes.func,
  allocations:PropTypes.array,
  addProjectBudgeSpent:PropTypes.func,
  onGetProjectBudgeSpent:PropTypes.func,
  spents:PropTypes.array,
  ongetProjectUUIDAction:PropTypes.func,
  projectData: PropTypes.object,
  onDeleteAllocation:PropTypes.func,
  onDeleteSpent:PropTypes.func,
  onUpdateProjectBudgetAllocation:PropTypes.func,
  onUpdateProjectBudgetSpent:PropTypes.func
}
const mapStateToProps = ({ Project }) => (
  {
    allocations: Project.allocations,
    spents:Project.spents,
    loading:Project.loading,
    projectData:Project.projectData

  })
const mapDispatchToProps = dispatch => ({
  addProjectBudgeAllocation: (data, history) => dispatch(addProjectBudgeAllocation(data, history)),
  onGetProjectBudgeAllocation:(projectId) => dispatch(getProjectBudgeAllocation(projectId)),
  addProjectBudgeSpent: (data, history) => dispatch(addProjectBudgeSpent(data, history)),
  onGetProjectBudgeSpent:(projectId) => dispatch(getProjectBudgeSpent(projectId)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onDeleteAllocation:(id) => dispatch(deleteProjectBudgeAllocation(id)),
  onDeleteSpent:(id) => dispatch(deleteProjectBudgeSpent(id)),
  onUpdateProjectBudgetAllocation:(data) => dispatch(updateProjectBudgeAllocation(data)),
  onUpdateProjectBudgetSpent:(data) => dispatch(updateProjectBudgeSpent(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectMarket)))
