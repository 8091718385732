import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EligibilitypagesMenu from "../menu/eligibilitypages-menu";
import LeadEligibilityBreadcrumb from "components/Common/LeadEligibilityBreadcrumb";

class LeadEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
       
        isRight:false
    }
  
    this.toggleAddNew = this.toggleAddNew.bind(this);
   
  }
  toggleAddNew() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
}

 
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">


            <Row className="mb20 project-header">
              <Col xl="6">
                <LeadEligibilityBreadcrumb
                  title={this.props.t("Lead")}

                  breadcrumbItem={this.props.t("Eligibility Questions")}
                />

              </Col>

            </Row>
             
            <Row>
              

              <Col xl="12">
              
                      <Card>
                        <CardBody>
                        <EligibilitypagesMenu />
                    <div className="link-page">
                      
                      <div className="open-bg" style={{marginTop:10, marginBottom:10}}>
                        <h5><strong>Purpose of Location factor</strong>: To evaluate the geographic preferences of a lead to you..</h5>
                        <h5>What to Enter</h5>

                        <ul>
                            <li><strong>Weight</strong>: Assign a numerical weight (1-10) to indicate how important location is in your overall lead evaluation. A higher value means higher importance.</li>
                            <li><strong>Scoring Values</strong>: Assign scores (1-10) for each location level to reflect their desirability.</li>
                            <li><strong>Primary Locations</strong>: List your most preferred cities or areas</li>
                            <li><strong>Secondary Locations</strong>: List cities or areas of secondary importance </li>
                            <li><strong>All Other Locations</strong>: Any other cities or areas that are less critical but still relevant.</li>
                        </ul>
                      
                        
                      </div>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:parseInt(localStorage.getItem("userId")),
                    weightage:'',
                    primaryScoringValue:'',
                    secondaryScoringValue:'',
                    otherScoringValue:'',
                    question: 'Location',
                  }}
                  validationSchema={Yup.object().shape({
                    weightage: Yup.number().typeError("Must be a number").required("This is required"),
                    primaryScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                    secondaryScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                    otherScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col sm="1">
                          <h4>Weight</h4>
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="8">
                          <h4>Scoring value for Locations</h4>
                        </Col>
                      </Row>
                      <Row>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="weightage"
                                          className={
                                            "form-control" +
                                            (errors.weightage && touched.weightage
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="weightage"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                       
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="8">
                    <Row>
                    <Col md="1">
                      <FormGroup className="mb-3">
                        <Label htmlFor="primaryScoringValue">
                          {this.props.t("Primary")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="primaryScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.primaryScoringValue && touched.primaryScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="primaryScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />

                                     
                                     </FormGroup>
                     
                    </Col>
                    <Col md="1"></Col>
                    <Col md="1">
                      <FormGroup className="mb-3">
                        <Label htmlFor="secondaryScoringValue">
                          {this.props.t("Secondary")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                    <Field
                                          name="secondaryScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.secondaryScoringValue && touched.secondaryScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="secondaryScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="1"></Col>
                    <Col md="1">
                      <FormGroup className="mb-3">
                        <Label htmlFor="otherScoringValue">
                          {this.props.t("All other")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                    <Field
                                          name="otherScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.otherScoringValue && touched.otherScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="otherScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                       
                    </Col>
                   
                    <Col sm="1">
                    </Col>
                    <Col sm="">
                    <button type="button" className="btn btn-primary w-md">{this.props.t("Edit")}</button>
                    </Col>
                  </Row>


                    </Col>
                      </Row>
                      </Form>
                  )}
                </Formik>



                      <Row>
                        <Col sm="12">
                        <h5 className="card-title" style={{marginBottom:20}}>Manage Locations 
                           
                            <Link to="#" className="btn btn-add float-end" onClick={this.toggleAddNew}>Add New</Link>
                         </h5>
                         
                           
                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
							<thead>
							  <tr>
								
								
								
								<th>Name</th>
                
                <th>Value</th> 
                <th>Action</th> 
							  </tr>
							</thead>
							<tbody>
                <tr>
                    <td>Pickering</td>
                    <td>Primary</td>
                    <td>
                    <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem href="#">Edit</DropdownItem>
                                        <DropdownItem  href="#">Delete</DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                    </td>
                    </tr>
							</tbody>
						  </table> 
                        </Col>
                      </Row>

                      </div>
                     
                      </CardBody>
                      </Card>
                 


                  
              </Col>

            </Row>
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleAddNew}
            >
              <OffcanvasHeader toggle={this.toggleAddNew}>
                Update Question
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    question: Yup.string().required("This is required"),
                    points: Yup.string().required("This is required"),


                  })}

                  onSubmit={values => {

                   
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                     
                          <Row>


                          <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="question">
                                  {this.props.t("Location")}
                                </Label>
                                <Field
                                  name="question"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.question && touched.question
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="question"
                                />
                                <ErrorMessage
                                  name="question"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="points">
                                  {this.props.t("Select")}
                                </Label>
                                <Field
                                    name="endHrs"
                                    as="select"
                                    
                                    onChange={handleChange}
                                    className={
                                        "form-control" +
                                        (errors.endHrs && touched.endHrs
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="hrs"
                                    >
                                    <option value=""></option>
                                    <option value="Primary">Primary</option>
                                    <option value="Secondary">Secondary</option>
                                         
                                    </Field>
                                <ErrorMessage
                                  name="points"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>     
                            <Col sm="12">
                              <button type="submit" className="btn btn-primary w-md float-left">Add New</button>
                            </Col>

                          </Row>


              
                    </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>

         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LeadEligibility.propTypes = {
  t: PropTypes.any,
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

   

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadEligibility))
