import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ClientSellerMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'property',
                label: 'Property info',
                link: `/clients/seller/property/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'open-house',
                label: 'Open House',
                link: `/clients/seller/open-house/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'questions',
                label: 'Seller Questions',
                link: `/clients/seller/questions/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'showings',
                label: 'Showings',
                link: `/clients/seller/showings/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'checklists',
                label: 'Checklists',
                link: `/clients/seller/checklists/${this.props.match.params.id}/${this.props.match.params.clientId}`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />             

              
            </React.Fragment>
        )
    }
}
ClientSellerMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ClientSellerMenu)
