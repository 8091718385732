import {
  ADD_OPHOUSE_LEAD,
  ADD_OPHOUSE_LEAD_FAIL,
  ADD_OPHOUSE_LEAD_SUCCESS,
  CREATE_CLIENT_ADD_LISTING,
  CREATE_CLIENT_ADD_LISTING_FAIL,
  CREATE_CLIENT_ADD_LISTING_SUCCESS,
  CREATE_CLIENT_APPOINTMENT,
  CREATE_CLIENT_APPOINTMENT_FAIL,
  CREATE_CLIENT_APPOINTMENT_SUCCESS,
  CREATE_CLIENT_BUYER_CLOSING,
  CREATE_CLIENT_BUYER_CLOSING_FAIL,
  CREATE_CLIENT_BUYER_CLOSING_SUCCESS,
  CREATE_CLIENT_BUYER_PREF,
  CREATE_CLIENT_BUYER_PREF_SUCCESS,
  CREATE_CLIENT_CHILD,
  CREATE_CLIENT_CHILD_FAIL,
  CREATE_CLIENT_CHILD_SUCCESS,
  CREATE_CLIENT_DOCUMENT, CREATE_CLIENT_DOCUMENT_FAIL, CREATE_CLIENT_DOCUMENT_SUCCESS, CREATE_CLIENT_IMPORTANT_DATE, CREATE_CLIENT_IMPORTANT_DATE_FAIL, CREATE_CLIENT_IMPORTANT_DATE_SUCCESS, CREATE_CLIENT_MESSAGE, CREATE_CLIENT_MESSAGE_FAIL,
  CREATE_CLIENT_MESSAGE_SUCCESS, CREATE_CLIENT_NOTE, CREATE_CLIENT_NOTE_FAIL, CREATE_CLIENT_NOTE_SUCCESS, CREATE_CLIENT_SELLER_CLOSING, CREATE_CLIENT_SELLER_CLOSING_FAIL, CREATE_CLIENT_SELLER_CLOSING_SUCCESS, CREATE_CLIENT_SELLER_LISTING, CREATE_CLIENT_SELLER_LISTING_FAIL, CREATE_CLIENT_SELLER_LISTING_SUCCESS, CREATE_CLIENT_SELLER_OPHOUSE, CREATE_CLIENT_SELLER_OPHOUSE_FAIL, CREATE_CLIENT_SELLER_OPHOUSE_SUCCESS, CREATE_CLIENT_SELLER_PREFERENCE, CREATE_CLIENT_SELLER_PREFERENCE_FAIL, CREATE_CLIENT_SELLER_PREFERENCE_SUCCESS, CREATE_CLIENT_SELLER_SHOWING, CREATE_CLIENT_SELLER_SHOWING_FAIL, CREATE_CLIENT_SELLER_SHOWING_SUCCESS, CREATE_NEW_CLIENT, CREATE_NEW_CLIENT_FAIL, CREATE_NEW_CLIENT_SUCCESS, DELETE_CLIENT_ADD_LISTING, DELETE_CLIENT_ADD_LISTING_FAIL, DELETE_CLIENT_ADD_LISTING_SUCCESS, DELETE_CLIENT_APPOINTMENT, DELETE_CLIENT_APPOINTMENT_FAIL, DELETE_CLIENT_APPOINTMENT_SUCCESS, DELETE_CLIENT_BUYER_CLOSING, DELETE_CLIENT_BUYER_CLOSING_FAIL, DELETE_CLIENT_BUYER_CLOSING_SUCCESS, DELETE_CLIENT_DOCUMENT, DELETE_CLIENT_DOCUMENT_FAIL, DELETE_CLIENT_DOCUMENT_SUCCESS, DELETE_CLIENT_IMPORTANT_DATE, DELETE_CLIENT_IMPORTANT_DATE_FAIL, DELETE_CLIENT_IMPORTANT_DATE_SUCCESS, DELETE_CLIENT_NOTE, DELETE_CLIENT_NOTE_FAIL, DELETE_CLIENT_NOTE_SUCCESS, DELETE_CLIENT_OPENHOUSE, DELETE_CLIENT_OPENHOUSE_FAIL, DELETE_CLIENT_OPENHOUSE_SUCCESS, DELETE_CLIENT_SELLER_CLOSING, DELETE_CLIENT_SELLER_CLOSING_FAIL, DELETE_CLIENT_SELLER_CLOSING_SUCCESS, DELETE_CLIENT_SELLER_LISTING, DELETE_CLIENT_SELLER_LISTING_FAIL, DELETE_CLIENT_SELLER_LISTING_SUCCESS, DELETE_CLIENT_SELLER_SHOWING, DELETE_CLIENT_SELLER_SHOWING_FAIL, DELETE_CLIENT_SELLER_SHOWING_SUCCESS, GET_CLIENTS, GET_CLIENTS_APPOINTMENT, GET_CLIENTS_APPOINTMENT_FAIL, GET_CLIENTS_APPOINTMENT_SUCCESS, GET_CLIENTS_DETAIL,
  GET_CLIENTS_DETAIL_FAIL, GET_CLIENTS_DETAIL_SUCCESS, GET_CLIENTS_DOCUMENT, GET_CLIENTS_DOCUMENT_FAIL, GET_CLIENTS_DOCUMENT_SUCCESS, GET_CLIENTS_FAIL,
  GET_CLIENTS_MESSAGE, GET_CLIENTS_MESSAGE_FAIL,
  GET_CLIENTS_MESSAGE_SUCCESS, GET_CLIENTS_NOTE, GET_CLIENTS_NOTE_FAIL, GET_CLIENTS_NOTE_SUCCESS, GET_CLIENTS_SELLER_CLOSING, GET_CLIENTS_SELLER_CLOSING_FAIL, GET_CLIENTS_SELLER_CLOSING_SUCCESS, GET_CLIENTS_SELLER_LISTING, GET_CLIENTS_SELLER_LISTING_FAIL, GET_CLIENTS_SELLER_LISTING_SUCCESS, GET_CLIENTS_SELLER_SHOWING, GET_CLIENTS_SELLER_SHOWING_FAIL, GET_CLIENTS_SELLER_SHOWING_SUCCESS, GET_CLIENTS_SUCCESS, GET_CLIENT_ADD_LISTING, GET_CLIENT_ADD_LISTING_FAIL, GET_CLIENT_ADD_LISTING_SUCCESS, GET_CLIENT_BUYER_CLOSING, GET_CLIENT_BUYER_CLOSING_FAIL, GET_CLIENT_BUYER_CLOSING_SUCCESS, GET_CLIENT_CHILD, GET_CLIENT_CHILD_FAIL, GET_CLIENT_CHILD_SUCCESS, GET_CLIENT_IMPORTANT_DATE, GET_CLIENT_IMPORTANT_DATE_FAIL, GET_CLIENT_IMPORTANT_DATE_SUCCESS, GET_CLIENT_RECENT_ACTIVITIES, GET_CLIENT_RECENT_ACTIVITIES_FAIL, GET_CLIENT_RECENT_ACTIVITIES_SUCCESS, GET_CLIENT_SELLER_OPHOUSE, GET_CLIENT_SELLER_OPHOUSE_FAIL, GET_CLIENT_SELLER_OPHOUSE_SUCCESS, GET_CLIENT_UPCOMING_ACTIVITIES, GET_CLIENT_UPCOMING_ACTIVITIES_FAIL, GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS, UPDATE_CLIENT_ADD_LISTING, UPDATE_CLIENT_ADD_LISTING_FAIL, UPDATE_CLIENT_ADD_LISTING_SUCCESS, UPDATE_CLIENT_APPOINTMENT, UPDATE_CLIENT_APPOINTMENT_FAIL, UPDATE_CLIENT_APPOINTMENT_SUCCESS, UPDATE_CLIENT_BUYER_CLOSING, UPDATE_CLIENT_BUYER_CLOSING_FAIL, UPDATE_CLIENT_BUYER_CLOSING_SUCCESS, UPDATE_CLIENT_DOCUMENT, UPDATE_CLIENT_DOCUMENT_FAIL, UPDATE_CLIENT_DOCUMENT_SUCCESS, UPDATE_CLIENT_NOTE, UPDATE_CLIENT_NOTE_FAIL, UPDATE_CLIENT_NOTE_SUCCESS, UPDATE_CLIENT_OPENHOUSE, UPDATE_CLIENT_OPENHOUSE_FAIL, UPDATE_CLIENT_OPENHOUSE_SUCCESS, UPDATE_CLIENT_PROFILE, UPDATE_CLIENT_PROFILE_FAIL, UPDATE_CLIENT_PROFILE_SUCCESS, UPDATE_CLIENT_SELLER_CLOSING, UPDATE_CLIENT_SELLER_CLOSING_FAIL, UPDATE_CLIENT_SELLER_CLOSING_SUCCESS, UPDATE_CLIENT_SELLER_LISTING, UPDATE_CLIENT_SELLER_LISTING_FAIL, UPDATE_CLIENT_SELLER_LISTING_SUCCESS, UPDATE_CLIENT_SELLER_SHOWING, UPDATE_CLIENT_SELLER_SHOWING_FAIL, UPDATE_CLIENT_SELLER_SHOWING_SUCCESS
} from "./actionTypes"

export const getClientList = agentID => (
  {
    type: GET_CLIENTS,
    agentID,
  })

export const getClientListSuccess = clientLists => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clientLists,
})

export const getClientListFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

export const getClientDetail = clientId => (
  {
    type: GET_CLIENTS_DETAIL,
    clientId
  })
export const getClientDetailSuccess = clientDetail => ({
  type: GET_CLIENTS_DETAIL_SUCCESS,
  payload: clientDetail
})
export const getClientDetailFail = error => ({
  type: GET_CLIENTS_DETAIL_FAIL,
  payload: error
})

export const addClientMessage = (message, history) => ({
  type: CREATE_CLIENT_MESSAGE,
  payload: { message, history }
})

export const addClientMessageSuccess = message => ({
  type: CREATE_CLIENT_MESSAGE_SUCCESS,
  payload: message
})

export const addClientMessageFail = error => ({
  type: CREATE_CLIENT_MESSAGE_FAIL,
  payload: error
})


export const getClientMessage = clientId => (
  {
    type: GET_CLIENTS_MESSAGE,
    clientId,
  })

export const getClientMessageSuccess = messages => ({
  type: GET_CLIENTS_MESSAGE_SUCCESS,
  payload: messages,
})

export const getClientMessageFail = error => ({
  type: GET_CLIENTS_MESSAGE_FAIL,
  payload: error,
})


export const addClientDocument = (document, history) => ({
  type: CREATE_CLIENT_DOCUMENT,
  payload: { document, history }
})

export const addClientDocumentSuccess = document => ({
  type: CREATE_CLIENT_DOCUMENT_SUCCESS,
  payload: document
})

export const addClientDocumentFail = error => ({
  type: CREATE_CLIENT_DOCUMENT_FAIL,
  payload: error
})


export const getClientDocument = clientId => (
  {
    type: GET_CLIENTS_DOCUMENT,
    clientId,
  })

export const getClientDocumentSuccess = documents => ({
  type: GET_CLIENTS_DOCUMENT_SUCCESS,
  payload: documents,
})

export const getClientDocumentFail = error => ({
  type: GET_CLIENTS_DOCUMENT_FAIL,
  payload: error,
})


export const addClientAppointment = (appointment, history) => ({
  type: CREATE_CLIENT_APPOINTMENT,
  payload: { appointment, history }
})

export const addClientAppointmentSuccess = appointment => ({
  type: CREATE_CLIENT_APPOINTMENT_SUCCESS,
  payload: appointment
})

export const addClientAppointmentFail = error => ({
  type: CREATE_CLIENT_APPOINTMENT_FAIL,
  payload: error
})


export const getClientAppointment = (agentId,clientId) => (
  {
    type: GET_CLIENTS_APPOINTMENT,
    payload: { agentId, clientId }
  })

export const getClientAppointmentSuccess = appointments => ({
  type: GET_CLIENTS_APPOINTMENT_SUCCESS,
  payload: appointments,
})

export const getClientAppointmentFail = error => ({
  type: GET_CLIENTS_APPOINTMENT_FAIL,
  payload: error,
})



export const addClientNote = (note, history) => ({
  type: CREATE_CLIENT_NOTE,
  payload: { note, history }
})

export const addClientNoteSuccess = note => ({
  type: CREATE_CLIENT_NOTE_SUCCESS,
  payload: note
})

export const addClientNoteFail = error => ({
  type: CREATE_CLIENT_NOTE_FAIL,
  payload: error
})


export const getClientNote = (agentId, clientId) => (
  {
    type: GET_CLIENTS_NOTE,
    payload: { agentId, clientId },
  })

export const getClientNoteSuccess = notes => ({
  type: GET_CLIENTS_NOTE_SUCCESS,
  payload: notes,
})

export const getClientNoteFail = error => ({
  type: GET_CLIENTS_NOTE_FAIL,
  payload: error,
})


export const addClientSellerShwoing = (showing, history) => ({
  type: CREATE_CLIENT_SELLER_SHOWING,
  payload: { showing, history }
})

export const addClientSellerShwoingSuccess = showing => ({
  type: CREATE_CLIENT_SELLER_SHOWING_SUCCESS,
  payload: showing
})

export const addClientSellerShwoingFail = error => ({
  type: CREATE_CLIENT_SELLER_SHOWING_FAIL,
  payload: error
})


export const getClientSellerShwoing = (clientId) => (
  {
    type: GET_CLIENTS_SELLER_SHOWING,
    clientId
  })

export const getClientSellerShwoingSuccess = showings => ({
  type: GET_CLIENTS_SELLER_SHOWING_SUCCESS,
  payload: showings,
})

export const getClientSellerShwoingFail = error => ({
  type: GET_CLIENTS_SELLER_SHOWING_FAIL,
  payload: error,
})


export const addClientSellerClosing = (closing, history) => ({
  type: CREATE_CLIENT_SELLER_CLOSING,
  payload: { closing, history }
})

export const addClientSellerClosingSuccess = closing => ({
  type: CREATE_CLIENT_SELLER_CLOSING_SUCCESS,
  payload: closing
})

export const addClientSellerClosingFail = error => ({
  type: CREATE_CLIENT_SELLER_CLOSING_FAIL,
  payload: error
})

export const getClientSellerClosing = (clientId) => (
  {
    type: GET_CLIENTS_SELLER_CLOSING,
    clientId
  })

export const getClientSellerClosingSuccess = closings => ({
  type: GET_CLIENTS_SELLER_CLOSING_SUCCESS,
  payload: closings,
})

export const getClientSellerClosingFail = error => ({
  type: GET_CLIENTS_SELLER_CLOSING_FAIL,
  payload: error,
})


export const addClientSellerListing = (listing, history) => (
  {
    type: CREATE_CLIENT_SELLER_LISTING,
    payload: { listing, history }
  })

export const addClientSellerListingSuccess = listing => ({
  type: CREATE_CLIENT_SELLER_LISTING_SUCCESS,
  payload: listing
})

export const addClientSellerListingFail = error => ({
  type: CREATE_CLIENT_SELLER_LISTING_FAIL,
  payload: error
})

export const updateClientSellerListing = listing => (
{
  
  type: UPDATE_CLIENT_SELLER_LISTING,
  payload: listing,
})

export const updateClientSellerListingSuccess = listing => ({
  type: UPDATE_CLIENT_SELLER_LISTING_SUCCESS,
  payload: listing,
})

export const updateClientSellerListingFail = error => ({
  type: UPDATE_CLIENT_SELLER_LISTING_FAIL,
  payload: error,
})


export const deleteClientSellerListing = id => ({
  type: DELETE_CLIENT_SELLER_LISTING,
  payload: id,
})

export const deleteClientSellerListingSuccess = lsiting => ({
  type: DELETE_CLIENT_SELLER_LISTING_SUCCESS,
  payload: lsiting,
})

export const deleteClientSellerListingFail = error => ({
  type: DELETE_CLIENT_SELLER_LISTING_FAIL,
  payload: error,
})


export const getClientSellerListing = (clientId) => (
  {
    type: GET_CLIENTS_SELLER_LISTING,
    clientId
  })

export const getClientSellerListingSuccess = listings => ({
  type: GET_CLIENTS_SELLER_LISTING_SUCCESS,
  payload: listings,
})

export const getClientSellerListingFail = error => ({
  type: GET_CLIENTS_SELLER_LISTING_FAIL,
  payload: error,
})

export const addClientSellerOphouse = (ophouse, history) => (
  {
    type: CREATE_CLIENT_SELLER_OPHOUSE,
    payload: { ophouse, history }
  })

export const addClientSellerOphouseSuccess = ophouse => ({
  type: CREATE_CLIENT_SELLER_OPHOUSE_SUCCESS,
  payload: ophouse
})

export const addClientSellerOphouseFail = error => ({
  type: CREATE_CLIENT_SELLER_OPHOUSE_FAIL,
  payload: error
})

export const getClientSellerOphouse = (clientId) => (
  {
    type: GET_CLIENT_SELLER_OPHOUSE,
    clientId
  })

export const getClientSellerOphouseSuccess = ophouses => ({
  type: GET_CLIENT_SELLER_OPHOUSE_SUCCESS,
  payload: ophouses,
})

export const getClientSellerOphouseFail = error => ({
  type: GET_CLIENT_SELLER_OPHOUSE_FAIL,
  payload: error,
})


export const addOpHouseLead = (lead, history) => {
  return {
    type: ADD_OPHOUSE_LEAD,
    payload: { lead, history },
  }
}


export const addOpHouseLeadSuccess = lead => ({
  type: ADD_OPHOUSE_LEAD_SUCCESS,
  payload: lead,
})

export const addOpHouseLeadFail = error => ({
  type: ADD_OPHOUSE_LEAD_FAIL,
  payload: error,
})


export const addClientSellerPref = (question, history) => ({
  type: CREATE_CLIENT_SELLER_PREFERENCE,
  payload: { question, history }
})

export const addClientSellerPrefSuccess = question => ({
  type: CREATE_CLIENT_SELLER_PREFERENCE_SUCCESS,
  payload: question
})

export const addClientSellerPrefFail = error => ({
  type: CREATE_CLIENT_SELLER_PREFERENCE_FAIL,
  payload: error
})




export const addClientAddListing = (listing, history) => ({
  type: CREATE_CLIENT_ADD_LISTING,
  payload: { listing, history }
})

export const addClientAddListingSuccess = listing => ({
  type: CREATE_CLIENT_ADD_LISTING_SUCCESS,
  payload: listing
})

export const addClientAddListingFail = error => ({
  type: CREATE_CLIENT_ADD_LISTING_FAIL,
  payload: error
})


export const getClientAddListing = (clientId,agentId) => (
  {
    type: GET_CLIENT_ADD_LISTING,
    payload: { clientId, agentId }
  })

export const getClientAddListingSuccess = addListings => ({
  type: GET_CLIENT_ADD_LISTING_SUCCESS,
  payload: addListings,
})

export const getClientAddListingFail = error => ({
  type: GET_CLIENT_ADD_LISTING_FAIL,
  payload: error,
})



export const ClientAddListingUpdate = addListing => {
  return {
    type: UPDATE_CLIENT_ADD_LISTING,
    payload: addListing,
  }
}

export const ClientAddListingUpdateSuccess = addListing => {
  return {
    type: UPDATE_CLIENT_ADD_LISTING_SUCCESS,
    payload: addListing,
  }
}

export const ClientAddListingUpdateError = error => {
  return {
    type: UPDATE_CLIENT_ADD_LISTING_FAIL,
    payload: error,
  }
}


export const deleteClientAddListing = id => ({
  type: DELETE_CLIENT_ADD_LISTING,
  payload: id,
})

export const deleteClientAddListingSuccess = addListing => ({
  type: DELETE_CLIENT_ADD_LISTING_SUCCESS,
  payload: addListing,
})

export const deleteClientAddListingFail = error => ({
  type: DELETE_CLIENT_ADD_LISTING_FAIL,
  payload: error,
})


export const deleteClientDocument = id => ({
  type: DELETE_CLIENT_DOCUMENT,
  payload: id,
})

export const deleteClientDocumentSuccess = document => ({
  type: DELETE_CLIENT_DOCUMENT_SUCCESS,
  payload: document,
})

export const deleteClientDocumentFail = error => ({
  type: DELETE_CLIENT_DOCUMENT_FAIL,
  payload: error,
})


export const deleteClientAppointment = id => ({
  type: DELETE_CLIENT_APPOINTMENT,
  payload: id,
})

export const deleteClientAppointmentSuccess = appointment => ({
  type: DELETE_CLIENT_APPOINTMENT_SUCCESS,
  payload: appointment,
})

export const deleteClientAppointmentFail = error => ({
  type: DELETE_CLIENT_APPOINTMENT_FAIL,
  payload: error,
})


export const updateClientAppointment = appointment => {
  //console.log(appointment)
  return {
    type: UPDATE_CLIENT_APPOINTMENT,
    payload: appointment,
  }
}

export const updateClientAppointmentSuccess = appointment => {
  return {
    type: UPDATE_CLIENT_APPOINTMENT_SUCCESS,
    payload: appointment,
  }
}

export const updateClientAppointmentError = error => {
  return {
    type: UPDATE_CLIENT_APPOINTMENT_FAIL,
    payload: error,
  }
}


export const updateClientDocument = document => {
  //console.log(appointment)
  return {
    type: UPDATE_CLIENT_DOCUMENT,
    payload: document,
  }
}

export const updateClientDocumentSuccess = document => {
  return {
    type: UPDATE_CLIENT_DOCUMENT_SUCCESS,
    payload: document,
  }
}

export const updateClientDocumentError = error => {
  return {
    type: UPDATE_CLIENT_DOCUMENT_FAIL,
    payload: error,
  }
}


export const deleteClientNote = id => ({
  type: DELETE_CLIENT_NOTE,
  payload: id,
})

export const deleteClientNoteSuccess = note => ({
  type: DELETE_CLIENT_NOTE_SUCCESS,
  payload: note,
})

export const deleteClientNoteFail = error => ({
  type: DELETE_CLIENT_NOTE_FAIL,
  payload: error,
})

export const updateClientNote = note => {

  return {
    type: UPDATE_CLIENT_NOTE,
    payload: note,
  }
}

export const updateClientNoteSuccess = note => {
  return {
    type: UPDATE_CLIENT_NOTE_SUCCESS,
    payload: note,
  }
}

export const updateClientNoteError = error => {
  return {
    type: UPDATE_CLIENT_NOTE_FAIL,
    payload: error,
  }
}


export const deleteClientopenhouse = id => ({
  type: DELETE_CLIENT_OPENHOUSE,
  payload: id,
})

export const deleteClientopenhouseSuccess = openhouse => ({
  type: DELETE_CLIENT_OPENHOUSE_SUCCESS,
  payload: openhouse,
})

export const deleteClientopenhouseFail = error => ({
  type: DELETE_CLIENT_OPENHOUSE_FAIL,
  payload: error,
})


export const updateClientOpenhouse = openhouse => {

  return {
    type: UPDATE_CLIENT_OPENHOUSE,
    payload: openhouse,
  }
}

export const updateClientOpenhouseSuccess = openhouse => {
  return {
    type: UPDATE_CLIENT_OPENHOUSE_SUCCESS,
    payload: openhouse,
  }
}

export const updateClientOpenhouseError = error => {
  return {
    type: UPDATE_CLIENT_OPENHOUSE_FAIL,
    payload: error,
  }
}


export const updateClientSellerShowing = showing => {

  return {
    type: UPDATE_CLIENT_SELLER_SHOWING,
    payload: showing,
  }
}

export const updateClientSellerShowingSuccess = showing => {
  return {
    type: UPDATE_CLIENT_SELLER_SHOWING_SUCCESS,
    payload: showing,
  }
}

export const updateClientSellerShowingError = error => {
  return {
    type: UPDATE_CLIENT_SELLER_SHOWING_FAIL,
    payload: error,
  }
}


export const deleteClientSellerShowing = id => ({
  type: DELETE_CLIENT_SELLER_SHOWING,
  payload: id,
})

export const deleteClientSellerShowingSuccess = showing => ({
  type: DELETE_CLIENT_SELLER_SHOWING_SUCCESS,
  payload: showing,
})

export const deleteClientSellerShowingFail = error => ({
  type: DELETE_CLIENT_SELLER_SHOWING_FAIL,
  payload: error,
})


export const updateClientSellerClosing = clientSellerClosing => {

  return {
    type: UPDATE_CLIENT_SELLER_CLOSING,
    payload: clientSellerClosing,
  }
}

export const updateClientSellerClosingSuccess = clientSellerClosing => {
  return {
    type: UPDATE_CLIENT_SELLER_CLOSING_SUCCESS,
    payload: clientSellerClosing,
  }
}

export const updateClientSellerClosingError = error => {
  return {
    type: UPDATE_CLIENT_SELLER_CLOSING_FAIL,
    payload: error,
  }
}


export const deleteClientSellerClosing = id => ({
  type: DELETE_CLIENT_SELLER_CLOSING,
  payload: id,
})

export const deleteClientSellerClosingSuccess = closing => ({
  type: DELETE_CLIENT_SELLER_CLOSING_SUCCESS,
  payload: closing,
})

export const deleteClientSellerClosingFail = error => ({
  type: DELETE_CLIENT_SELLER_CLOSING_FAIL,
  payload: error,
})


export const addClientBuyerPref = (bquestion, history) => {
  return {
    type: CREATE_CLIENT_BUYER_PREF,
    payload: { bquestion, history },
  }
}

export const addClientBuyerPrefSuccess = bquestion => {
  return {
    type: CREATE_CLIENT_BUYER_PREF_SUCCESS,
    payload: bquestion,
  }

}

export const addClientBuyerPrefError = error => {
  return {
    type: CREATE_CLIENT_BUYER_PREF_SUCCESS,
    payload: error,
  }

}

export const getClientUpcomingActivities = (agentId) => (
  {
    type: GET_CLIENT_UPCOMING_ACTIVITIES,
    agentId
  })

export const getClientUpcomingActivitiesSuccess = uactivities => ({
  type: GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS,
  payload: uactivities,
})

export const getClientUpcomingActivitiesFail = error => ({
  type: GET_CLIENT_UPCOMING_ACTIVITIES_FAIL,
  payload: error,
})


export const getClientRecentActivities = (agentId) => (
  {
    type: GET_CLIENT_RECENT_ACTIVITIES,
    agentId
  })

export const getClientRecentActivitiesSuccess = ractivities => ({
  type: GET_CLIENT_RECENT_ACTIVITIES_SUCCESS,
  payload: ractivities,
})

export const getClientRecentActivitiesFail = error => ({
  type: GET_CLIENT_RECENT_ACTIVITIES_FAIL,
  payload: error,
})


export const addClientImportantDate = (importantdate, history) => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE,
    payload: { importantdate, history },
  }
}

export const addClientImportantDateSuccess = importantdate => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE_SUCCESS,
    payload: importantdate,
  }

}

export const addClientImportantDateError = error => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE_FAIL,
    payload: error,
  }

}



export const getClientImportantDate = (clientId) => (
  {
    type: GET_CLIENT_IMPORTANT_DATE,
    clientId
  })

export const getClientImportantDateSuccess = importantdates => ({
  type: GET_CLIENT_IMPORTANT_DATE_SUCCESS,
  payload: importantdates,
})

export const getClientImportantDateFail = error => ({
  type: GET_CLIENT_IMPORTANT_DATE_FAIL,
  payload: error,
})

export const addClientChild = (child, history) => {
  return {
    type: CREATE_CLIENT_CHILD,
    payload: { child, history },
  }
}

export const addClientChildSuccess = child => {
  return {
    type: CREATE_CLIENT_CHILD_SUCCESS,
    payload: child,
  }

}

export const addClientChildError = error => {
  return {
    type: CREATE_CLIENT_CHILD_FAIL,
    payload: error,
  }

}


export const getClientChild = (clientId) => (
  {
    type: GET_CLIENT_CHILD,
    clientId
  })

export const getClientChildSuccess = childs => ({
  type: GET_CLIENT_CHILD_SUCCESS,
  payload: childs,
})

export const getClientChildFail = error => ({
  type: GET_CLIENT_CHILD_FAIL,
  payload: error,
})


export const deleteClientImportant = id => ({
  type: DELETE_CLIENT_IMPORTANT_DATE,
  payload: id,
})

export const deleteClientImportantSuccess = importantdate => ({
  type: DELETE_CLIENT_IMPORTANT_DATE_SUCCESS,
  payload: importantdate,
})

export const deleteClientImportantFail = error => ({
  type: DELETE_CLIENT_IMPORTANT_DATE_FAIL,
  payload: error,
})

export const updateClientProfile = client => {
  //console.log(appointment)
  return {
    type: UPDATE_CLIENT_PROFILE,
    payload: client,
  }
}

export const updateClientProfileSuccess = client => {
  return {
    type: UPDATE_CLIENT_PROFILE_SUCCESS,
    payload: client,
  }
}

export const updateClientProfileError = error => {
  return {
    type: UPDATE_CLIENT_PROFILE_FAIL,
    payload: error,
  }
}


export const addNewClient = (client, history) => {
  return {
    type: CREATE_NEW_CLIENT,
    payload: { client, history },
  }
}

export const addNewClientSuccess = client => {
  return {
    type: CREATE_NEW_CLIENT_SUCCESS,
    payload: client,
  }

}

export const addNewClientError = error => {
  return {
    type: CREATE_NEW_CLIENT_FAIL,
    payload: error,
  }

}


export const addClientBuyerClosing = (buyerclosing, history) => {
  return {
    type: CREATE_CLIENT_BUYER_CLOSING,
    payload: { buyerclosing, history },
  }
}

export const addClientBuyerClosingSuccess = buyerclosing => {
  return {
    type: CREATE_CLIENT_BUYER_CLOSING_SUCCESS,
    payload: buyerclosing,
  }

}

export const addClientBuyerClosingError = error => {
  return {
    type: CREATE_CLIENT_BUYER_CLOSING_FAIL,
    payload: error,
  }

}


export const getClientBuyerClosing = (clientId) => (
  {
    type: GET_CLIENT_BUYER_CLOSING,
    clientId
  })

export const getClientBuyerClosingSuccess = buyerClosings => ({
  type: GET_CLIENT_BUYER_CLOSING_SUCCESS,
  payload: buyerClosings,
})

export const getClientBuyerClosingFail = error => ({
  type: GET_CLIENT_BUYER_CLOSING_FAIL,
  payload: error,
})


export const deleteClientBuyerClosing = id => ({
  type: DELETE_CLIENT_BUYER_CLOSING,
  payload: id,
})

export const deleteClientBuyerClosingSuccess = buyerClosing => ({
  type: DELETE_CLIENT_BUYER_CLOSING_SUCCESS,
  payload: buyerClosing,
})

export const deleteClientBuyerClosingFail = error => ({
  type: DELETE_CLIENT_BUYER_CLOSING_FAIL,
  payload: error,
})


export const updateClientBuyerClosing = buyerClosing => {

  return {
    type: UPDATE_CLIENT_BUYER_CLOSING,
    payload: buyerClosing,
  }
}

export const updateClientBuyerClosingSuccess = buyerClosing => {
  return {
    type: UPDATE_CLIENT_BUYER_CLOSING_SUCCESS,
    payload: buyerClosing,
  }
}

export const updateClientBuyerClosingError = error => {
  return {
    type: UPDATE_CLIENT_BUYER_CLOSING_FAIL,
    payload: error,
  }
}