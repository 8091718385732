import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import notes from "../../../assets/images/rsicons/notes.png"
import phonelog from "../../../assets/images/rsicons/phone-log.png"
import emaillog from "../../../assets/images/rsicons/email-log.png"
import follow from "../../../assets/images/rsicons/follow-up.png"
import {
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import { getAllActLeadTab } from "../../../store/leads/actions"
class LeadActivities extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }
       
    }
   
    componentDidMount() {
        const {  onGetAllActLead } = this.props;
        const { match: { params }} = this.props;
        onGetAllActLead(10);
        console.log(params);
    }
    render() {
      //const ClientLink = ({ data });
      //console.log(ClientLink)
        const { activities } = this.props;
        const allLocal = localStorage.getItem('localData')
        const localization = JSON.parse(allLocal);
        
        return (
            <React.Fragment>
                <ToastContainer autoClose={2000} />
               
                
                <div className="mb-5 h4 card-title">All Activities</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(activities, (allactivity, allactkey) => (

                                    <li className="event-list eventlist" key={"_allact_" + allactkey}>
                                      <div className="event-timeline-dot">
                                       {(() => {
                                                  if(allactivity.activitySubType==8 || allactivity.activitySubType==9 || allactivity.activitySubType==7){
                                                    return (
                                                      <>
                                                       <img src={emaillog} />
                                                      </>
                                                    )
                                                  }else if(allactivity.activitySubType==3 || allactivity.activitySubType==4 || allactivity.activitySubType==5){
                                                    return (
                                                      <>
                                                       <img src={phonelog} />
                                                      </>
                                                    )
                                                  }else if(allactivity.activitySubType==10){
                                                    return (
                                                      <>
                                                       <img src={follow} />
                                                      </>
                                                    )
                                                  }else{
                                                    return (
                                                      <>
                                                       <img src={notes} />
                                                      </>
                                                    )

                                                  }
                                            })()}
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{allactivity.addedDate}</Moment>

                                            
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ allactivity.activityType+"_"+allactivity.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{allactivity.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul> 
            </React.Fragment>
        )
    }
}
LeadActivities.propTypes = {
    t: PropTypes.any,
    activities: PropTypes.array,
    match:PropTypes.object,
    onGetAllActLead: PropTypes.func

}

const mapStateToProps = ({ leads }) => (
    {
        activities: leads.activities,

    })
const mapDispatchToProps = dispatch => ({
    onGetAllActLead: leadId => dispatch(getAllActLeadTab(leadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadActivities))
