import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
//import { withTranslation } from "react-i18next"
import { Formik, FieldArray,Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"
import { addLeadAPP , getAppLeadTab} from "../../../store/leads/actions"
import SaveButton from "components/buttons/save";
class LeadListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mlsFields: [{ mlsId: "", mlsLink: "" }]
      
    }
    this.handelValidListLead = this.handelValidListLead.bind(this)
  }  

 

  handelValidListLead(value) {
   
    console.log(value)
  }
  componentDidMount(){
    const {
       params  } = this.props;

    if (params && params.id) {
      console.log(params.id)
      getTabList(params.id);
    
        const { onGetListLead } = this.props;
        onGetListLead(4);

    } 


  }
  
  render() {
    
   
    return (
      <React.Fragment>
        {/* Your other components */}

        <div className="h4 card-title">Listing</div>
        <Formik
          initialValues={{
            mlsFields: this.state.mlsFields
          }}
          validationSchema={Yup.object().shape({
            mlsFields: Yup.array().of(
              Yup.object().shape({
                mlsId: Yup.string().required('This is required'),
                mlsLink: Yup.string().required('This is required')
              })
            )
          })}
          onSubmit={(values, { setSubmitting }) => {
            // Handle form submission with array of MLS IDs and links
            console.log(values.mlsFields);
            // Call your API here with values.mlsFields
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form className="needs-validation">
              <FieldArray
                name="mlsFields"
                render={arrayHelpers => (
                  <div>
                    {values.mlsFields.map((mlsField, index) => (
                      <div key={index}>
                        <div className="row">
                          <div className="col-md-5">
                          <Label htmlFor="Notes" className="label-text mt-3">MLS ID </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsId`}
                              value={mlsField.mlsId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsId &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsId
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsId`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-md-5">
                          <Label htmlFor="Notes" className="label-text mt-3">MLS LINK </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsLink`}
                              value={mlsField.mlsLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsLink &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsLink
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsLink`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {index === 0 && (
                            
                            <div className="col-md-2">
                              <Label htmlFor="Notes" className="label-text mt-3 d-block">.</Label>
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push({ mlsId: '', mlsLink: '' })}
                                className="btn btn-primary"
                              >
                                <i className=" bx bx-plus-medical" />
                              </button>
                            </div>
                          )}
                           {index !== 0 && (
                            
                            <div className="col-md-2">
                              <Label htmlFor="Notes" className="label-text mt-3 d-block">&nbsp;;</Label>
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className="btn btn-primary"
                              >
                               <i className=" bx bx-minus" />

                              </button>
                            </div>
                          )}
                          
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
              <SaveButton iconClass=" bx bx-mail-send">Send Link</SaveButton>
              
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
LeadListing.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  params: PropTypes.object,
  onGetListLead: PropTypes.func,
    history: PropTypes.object,
  getTabList: PropTypes.object,


}
const mapStateToProps = ({ leads }) => (
  {
    getTabList: LeadListing.getTabList,
    
  })
const mapDispatchToProps = dispatch => ({
  GetTabList: id => dispatch(getTabList(id)),
  onGetListLead: leadId => dispatch(getAppLeadTab(leadId)),
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LeadListing))
