import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class BudgetPiechart extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      series: [5.8, 40, 54.2],
      options: {
        labels: ["Newspaper", "Google Ads", "Facebook"],
        colors:['#8c52ff', '#cb6ce6','#7ed957','#ffde59','#ff66c4'],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
       
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height="350"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default BudgetPiechart
