import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

// Login Redux States
import { ADD_BUYER_QUES, ADD_CONTACT, GET_CONTACT_DETAIL, ADD_TAB_NOTE, ADD_TAB_EMAIL, ADD_TAB_CALL, GET_TAB_CALL, GET_TAB_EMAIL, 
  GET_TAB_NOTE, ADD_CONTACT_APP, GET_CONTACT_APP,
   GET_CONTACT_APP_DETAIL,GET_ALL_CONTACT_ACTIVITY } from "./actionTypes"
import {
  addContactSuccess, contactapiError, getContactSuccess, getContactDetailSuccess, getbuyerQuestionSuccess,
  addBuyerQuestSucess, addTabNoteSucess, addTabEmailSucess,
   addTabCallSucess, getCalltabSuccess, getEmailtabSuccess,getNotetabSuccess,addContactAPPSuccess,
   getAppContatTabSuccess,getAppContatTabDetailSuccess,getAllContactActivityTabSuccess

} from "./actions"

import {
  postContact, getContactDetail,
  postQuestion, postContactNote, postEmailNote1,
  postEmailNote2, postEmailNote3, postCallNote1,
  postCallNote2, postCallNote3, getNotesLogContact,
  getEmailLogContact,getNotetabLogContact,postContactAPP,getContactAPP, getContactAppDetail,getAllContactActivityApi
} from "../../helpers/backend_helper"


function* addContacts({ payload: { contact, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postContact, {
      agentId: parseInt(localStorage.getItem("userId")),
      firstName: contact.fname,
      lastName: contact.lname,
      //contactStatusId: (contact.contactStatusId) ? parseInt(contact.contactStatusId) : 0,
      contactTypeId: (contact.contactTypeId) ? parseInt(contact.contactTypeId) : 0,
      contactSourceId: 1,
      email: contact.email,
      phone: contact.phone,
      officePhone: contact.officePhone,
      mobile: contact.mobile,
      addressLine1: contact.name,
      addressLine2: contact.suite,
      city: contact.city,
      province: contact.province,
      postal: contact.zip_code,
      country: contact.country,
      longitude: contact.longitude,
      latitude: contact.latitude,
      organizationName: contact.organizationName,
      jobTitle: contact.jobTitle,
      lastContact: contact.lastContact,
      isClient: true,
      isVendor: true,
      isActive: true,
      //referedBy: 0,
      //howOffenContact: parseInt(contact.howOffenContact),
     // notes: ''
    })

    yield put(addContactSuccess(response))
    //console.log(response);
    history.push("/contacts")
  }
  catch (error) {
    //console.log(error);
    yield put(contactapiError(error.message))
  }
}

function* fetchContactDetail({ contactId }) {
  try {
    const response = yield call(getContactDetail, contactId)
    yield put(getContactDetailSuccess(response))
  } catch (error) {
    yield put(getBuilderDetailFail(error))
  }
}

function* addBQuestion({ payload: { bquestion, history } }) {
  try {

    //console.log(bquestion);
    //console.log(JSON.stringify(bquestion));   

    const questionAnswers = [{
      contactId: bquestion.contactId,
      questionAnswerId: 1,
      answersOption: bquestion.buyerQuest[1],
    }, {
      contactId: bquestion.contactId,
      questionAnswerId: 2,
      answersOption: bquestion.buyerQuest[2],
    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 3,
      answersOption: bquestion.buyerQuest[3],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 26,
      answersOption: bquestion.buyerQuest[26],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 4,
      answersOption: bquestion.buyerQuest[4],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 8,
      answersOption: bquestion.buyerQuest[8],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 9,
      answersOption: bquestion.buyerQuest[9],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 10,
      answersOption: bquestion.buyerQuest[10],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 11,
      answersOption: bquestion.buyerQuest[11],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 12,
      answersOption: bquestion.buyerQuest[12],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 13,
      answersOption: bquestion.buyerQuest[13],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 14,
      answersOption: bquestion.buyerQuest[14],

    }
    ];
    console.log(questionAnswers);
    const response = yield call(postQuestion, {
      questionAnswers
    })
    //console.log(response);
    yield put(addBuyerQuestSucess(response))
    if (response.success == true) {
      toast.success("Buyers Question Added");
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* addContactNote({ payload: { notes, history } }) {
  try {

    //console.log(contact);
    const response = yield call(postContactNote, {
      remarks: notes.contactNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: notes.contactId,
      addedDate: new Date()

    })
    yield put(addTabNoteSucess(response))
    if (response.success == true) {
      toast.success("Notes Added");
      const response = yield call(getNotetabLogContact, notes.contactId)
      yield put(getNotetabSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}


function* addContactEmail1({ payload: { notes, history } }) {
  try {
    let eLink = '';
    if (notes.actionType == 'Email Sent') {
      eLink = postEmailNote1;
    } else if (notes.actionType == 'Email Received') {
      eLink = postEmailNote2;
    } else {
      eLink = postEmailNote3;
    }

    const response = yield call(eLink, {
      remarks: notes.emailNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: notes.contactId,
       addedDate: notes.logDate

    })
    yield put(addTabEmailSucess(response))
    if (response.success == true) {
      toast.success("Email Log Added");
      const response = yield call(getEmailLogContact, notes.contactId)

      yield put(getEmailtabSuccess(response))
      const res = yield call(getAllContactActivityApi, notes.contactId)
  
      yield put(getAllContactActivityTabSuccess(res))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}


function* addContactCall({ payload: { notes, history } }) {
  //console.log(notes.actionType);
  try {
    let eLink = '';
    if (notes.actionType == 'No Answer') {
      eLink = postCallNote1;
    } else if (notes.actionType == 'Left Voicemail') {
      eLink = postCallNote2;
    } else {
      eLink = postCallNote3;
    }

    const response = yield call(eLink, {
      remarks: notes.callNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: notes.contactId,
      addedDate: notes.logDate


    })
    yield put(addTabCallSucess(response))
    if (response.success == true) {
      toast.success("Call Log Added");
      const response = yield call(getNotesLogContact, notes.contactId)
      yield put(getCalltabSuccess(response))

      const res = yield call(getAllContactActivityApi, notes.contactId)
  
    yield put(getAllContactActivityTabSuccess(res))


    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* fetchCallNote({ contactId }) {
  try {
    const response = yield call(getNotesLogContact, contactId)
    //console.log(response);
    yield put(getCalltabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchEmailNote({ contactId }) {
  try {
    const response = yield call(getEmailLogContact, contactId)

    yield put(getEmailtabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchNotes({ contactId }) {
  try {
    const response = yield call(getNotetabLogContact, contactId)
    yield put(getNotetabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* addContactsAPP({ payload: { appointment, history } }) {
  try {
    //toast.loading("Please wait...")
    //console.log(appointment);
    const response = yield call(postContactAPP, {
      //agentId: parseInt(localStorage.getItem("userId")),
        appointmentTitle: appointment.appAjenda,
        contactName: appointment.contactName,
        contactPhone:  appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appADate,
        startTime: appointment.appStat,
        endTime: appointment.appEnd,
        appointmentType: parseInt(appointment.apptype),
        appointmentLocationType: parseInt(appointment.appointmentLocationType),
        appointmentPriority: parseInt(appointment.appPriority),
        agentId: parseInt(localStorage.getItem("userId")),
        refId: appointment.refId,
      
    })

    yield put(addContactAPPSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Appointment Added");
      const response = yield call(getContactAPP, parseInt(localStorage.getItem("userId")))
      yield put(getAppContatTabSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {
    //console.log(error);
    yield put(contactapiError(error.message))
  }
}

function* fetchAppContact({ agentId, ContactId }) {
  try {
    const response = yield call(getContactAPP, agentId, ContactId)
    yield put(getAppContatTabSuccess(response))
    //console.log(response);
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchAppContactDetail({ id }) {
  try {
    const response = yield call(getContactAppDetail, id)
    yield put(getAppContatTabDetailSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchAllActivityTab({ contactId }) {
  try {
    const response = yield call(getAllContactActivityApi, contactId)
    //console.log(response);
    yield put(getAllContactActivityTabSuccess(response))
    
  } catch (error) {
    yield put(contactapiError(error))
  }
}



function* contactSaga() {
  yield takeEvery(ADD_CONTACT, addContacts)
  yield takeEvery(GET_CONTACT_DETAIL, fetchContactDetail),
  yield takeEvery(ADD_BUYER_QUES, addBQuestion)
  yield takeEvery(ADD_TAB_NOTE, addContactNote)
  yield takeEvery(ADD_TAB_EMAIL, addContactEmail1)
  yield takeEvery(ADD_TAB_CALL, addContactCall)
  yield takeEvery(GET_TAB_CALL, fetchCallNote)
  yield takeEvery(GET_TAB_EMAIL, fetchEmailNote)
  yield takeEvery(GET_TAB_NOTE, fetchNotes)
  yield takeEvery(ADD_CONTACT_APP, addContactsAPP)
  yield takeEvery(GET_CONTACT_APP, fetchAppContact)
  yield takeEvery(GET_CONTACT_APP_DETAIL, fetchAppContactDetail)
  yield takeEvery(GET_ALL_CONTACT_ACTIVITY, fetchAllActivityTab)
}

export default contactSaga
