import React from "react"
import LetestContact from "./LetestContact";
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import {
  Label,
  Form,
  Input,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import axios from "axios"
  export default class AllContact extends React.Component {
    state = {
      
    }

    componentDidMount() {
      //console.log(localStorage.getItem("authUser"));
      
    }



    render () {
      return (
    
    <React.Fragment>
       <Row>
            <Col lg="12">
           
             <LetestContact />
            </Col>
            </Row>
    </React.Fragment>
  )
}
  }


