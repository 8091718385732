import React, { useState } from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import PropTypes from 'prop-types';
import { Alert } from "reactstrap";
import { API_URL } from 'helpers/app_url';
 

const UploadLead = ({ handleAcceptedFiles, handleRejectedFiles, fileUploaded, rejectedFiles, uploadedFileName, uploadedFileSize, removeFile, onSchemaExtracted }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    
    const uploadFileToAPI = async (file) => {
        setUploading(true);
        setUploadError(null);
        const formData = new FormData();
        formData.append('Id', 0);
        //formData.append('ImportLogId', '3fa85f64-5717-4562-b3fc-2c963f66afa6')
        formData.append('AgentId', parseInt(localStorage.getItem("userId")));
        formData.append('ImportPageName', 'Leads');
        formData.append('FilePath', 'Lead Upload');
        formData.append('ExcelFile', file);
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        try {
          const response = await axios.post(`${API_URL}ImportLog/UploadExcelFile`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization' : `Bearer ${token}`
            },
          });
          //console.log('File uploaded successfully:', response.data.importLog.actualColumnSchema);
          handleAcceptedFiles([file]);
          onSchemaExtracted(response.data.importLog);
        } catch (error) {
          console.error('Error uploading file:', error);
          setUploadError('Failed to upload file. Please try again.');
          handleRejectedFiles([{ file }]);
        } finally {
          setUploading(false);
        }
      };
      const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          uploadFileToAPI(file);
        }
      };
  return (
    <div className="upload-file-container">
      <h2 className="text-center">Upload your file</h2>
      <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      <Dropzone
        onDrop={onDrop}
        onDropRejected={handleRejectedFiles}
        accept=".csv, .xlsx, .xls"
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone import-box">
            {!fileUploaded ? (
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-1">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
                <h6>All .csv, .xlsx, and .xls file types are supported.</h6>
              </div>
            ) : (
              <div className="upload-file-details">
                <h4>{uploadedFileName}</h4>
                <h6>{uploadedFileSize}</h6>
                <span className="removeBtn" onClick={removeFile}>Remove File</span>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {uploading && <p>Uploading...</p>}
      {uploadError && <Alert color="danger" className="mt-3">{uploadError}</Alert>}
      {rejectedFiles.length > 0 && (
        <Alert color="danger" className="mt-3">
          <strong>Invalid file type(s):</strong> {rejectedFiles.join(", ")}. Please upload only .csv, .xlsx, or .xls files.
        </Alert>
      )}
     
    </div>
  );
};
UploadLead.propTypes = {
    handleAcceptedFiles: PropTypes.func.isRequired,
    handleRejectedFiles: PropTypes.func.isRequired,
    fileUploaded: PropTypes.bool.isRequired,
    rejectedFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
    uploadedFileName: PropTypes.string.isRequired,
    uploadedFileSize: PropTypes.string.isRequired,
    removeFile: PropTypes.func.isRequired,
    onSchemaExtracted: PropTypes.func
  };
export default UploadLead;
