import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import PermissionAlert from "../../components/Common/permissionalert";
import RsCard from "components/Rscards/Rscard";
import RSPieChart from "components/charts/RSPieChart";
import RSBarChart from "components/charts/RSBarChart";
import RsAdNew from "components/buttons/Adnew";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Won vs lost %", iconClass: "bx bx-copy-alt", description: "5" },
       
        {
          title: "Hot leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx bx-plus-medical",
          description: "20",
        },
      ]
    }
  }
  
  render() {
    const userModule = localStorage.getItem('userModule');
    const leadSourceData = {
      series: [44, 55, 41, 17],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Buyer", "Seller", "Both", "Investor"],
        colors: ["#F14C52", "#31C1F7", "#F1B44C", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    const barchartdata = {
      series: [
        {
          data: [380, 430, 450, 475],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: ["Facebook", "Google", "Instagram", "Other"],
        },
      },
    };

    const lossreportData = {
      series: [23, 77, 41],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["No contact",  "Lost to another agent", "Lost due to Commission rate"],
        colors: ["#F14C52", "#31C1F7", "#F1B44C"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    return (
      <React.Fragment>
            { userModule.indexOf("BrokerAgent.RealtorLeadsDashboard.View") > -1  ?
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Dashboard")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  <div className="">
                  <RsAdNew to="/lead/leadlist" className="btn btn-light btn-md me-2 view-leads" iconClass="bx bxs-grid">View All Leads</RsAdNew>
                  <RsAdNew to="/lead/import-lead" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Lead</RsAdNew>
                  </div>
                 
                </div>
            </Col>
            </Row>
            <Row className=""> 
            
              <Col xl="12">
              
                <Row className="mb20" style={{marginTop:20}}> 
            {this.state.reports.map((report, key) => (
            <Col md="3" className="" key={key}>
                <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
              </Col>
            ))}
            </Row>
            <Card>

            <Row>
            <Col lg={4}>
               <RSPieChart title="Lead By Type" series={leadSourceData.series} options={leadSourceData.options} />
            </Col>
            <Col lg="4">
            <RSBarChart  series={barchartdata.series} options={barchartdata.options} />
            </Col>
            <Col lg={4}>
                <RSPieChart title="Loss Report" series={lossreportData.series} options={lossreportData.options} />
            </Col>
            </Row>
            </Card>
            </Col>
            </Row>
           
            
          </Container>
        </div>
       :   <PermissionAlert />
      }
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(Dashboard))
