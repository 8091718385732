import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import "react-rangeslider/lib/index.css";
import notes from "../../assets/images/rsicons/notes.png";
import follow from "../../assets/images/rsicons/follow-up.png";
import phonelog from "../../assets/images/rsicons/phone-log.png";
import emaillog from "../../assets/images/rsicons/email-log.png";
import prefrence from "../../assets/images/rsicons/prefrence.png";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CallLog from "./tab-comp/CallLog";
import EmaillLog from "./tab-comp/EmaillLog";
import SmsLog from "./tab-comp/SmsLog";
import AppointmentLog from "./tab-comp/AppointmentLog";
import LeadNote from "./tab-comp/LeadNote";
import FollowLead from "./tab-comp/FollowLead";
import LeadActivities from "./tab-comp/AllLeadActivity";
import LeadListing from "./tab-comp/Lead-listing";
import { API_URL } from "../../helpers/app_url";
import EditLead from "./forms/lead-edit";
import { Container,Row,Col, Card, CardBody, Table, Collapse, FormGroup, Label, Button, ButtonGroup, Nav, NavItem, NavLink,
  TabContent, TabPane, InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Moment from "react-moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLeadDetail, getAllActLeadTab,
  updateLead,
  convertLeadIntoClient,
  convertLeadIntoContact,
} from "../../store/actions";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";

class leadDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isRight: false,
      isRight2: false,
      isRight3: false,
      isBackdrop: false,
      col9: false,
      col15: false,
      col10: false,
      col11: false,
      col12: true,
      selectedGroup: null,
      activeTab: "1",
      verticalActiveTab: "1",
      volume: 0,
      leadData: [],
      buyersQuestions: [],
      sources: [],
      leadStatus: [],
      leadTypes: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
      titles: [],
      total: 0,
      numbers: {
        comp1: 0,
        comp2: 0,
        comp3: 0,
        comp4: 0,
        comp5: 0,
      },
      leadId: "",
      Id: "",
    };
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);
    this.t_col12 = this.t_col12.bind(this);
    this.t_col15 = this.t_col15.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvas2() {
    this.setState({ isRight2: !this.state.isRight2 });
  }

  toggleRightCanvas3() {
    this.setState({ isRight3: !this.state.isRight3 });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  handleOnChange = value => {
    this.setState({
      volume: value,
    });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false,
    });
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false,
    });
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false,
    });
  }
  componentDidMount() {
    const {
      match: { params },
      onGetLeadDetail,
      onGetAllActLead,
      
    } = this.props;
    if (params && params.id && params.leadId) {
      onGetLeadDetail(params.leadId);
      onGetAllActLead(params.id);
      this.setState({ leadId: params.leadId });
      this.setState({ Id: params.id });
    }
   
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    axios
      .get(API_URL + "LeadSource/all", { headers: { Authorization: str } })
      .then(res => {
        const sources = res.data;
        this.setState({ sources });
      }),
      axios
        .get(API_URL + "LeadStatus/all", { headers: { Authorization: str } })
        .then(res => {
          const leadStatus = res.data;
          this.setState({ leadStatus });
        }),
      axios
        .get(API_URL + "LeadType/all", { headers: { Authorization: str } })
        .then(res => {
          const leadTypes = res.data;
          this.setState({ leadTypes });
        }),
      axios
        .get(API_URL + "QuestionAnswer/allforagenttype", {
          params: {
            forType: 1,
          },
          headers: { Authorization: str },
        })
        .then(res => {
          const buyersQuestions = res.data;
          this.setState({ buyersQuestions });
        });
    axios
      .get(API_URL + "FieldValue/allbyname", {
        params: {
          fieldName: "Lead:Group",
        },
        headers: { Authorization: str },
      })
      .then(res => {
        const groupDrops = res.data;
        this.setState({ groupDrops });
      }),
      axios
        .get(API_URL + "FieldValue/allbyname", {
          params: {
            fieldName: "Lead:Marital Status",
          },
          headers: { Authorization: str },
        })
        .then(res => {
          const maritalStat = res.data;
          this.setState({ maritalStat });
        }),
      axios
        .get(API_URL + "FieldValue/allbyname", {
          params: {
            fieldName: "Lead:Win Liklyhood",
          },
          headers: { Authorization: str },
        })
        .then(res => {
          const likelihoods = res.data;
          this.setState({ likelihoods });
        }),
      axios
        .get(API_URL + "FieldValue/allbyname", {
          params: {
            fieldName: "Lead:Preferred Medium",
          },
          headers: { Authorization: str },
        })
        .then(res => {
          const prefMediums = res.data;
          this.setState({ prefMediums });
        }),
      axios
        .get(API_URL + "FieldValue/allbyname", {
          params: {
            fieldName: "Agent:Title",
          },
          headers: { Authorization: str },
        })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        });
  }


  render() {
    const {leads, onConvertLeadToClient,onCovertLeadIntoContact} = this.props;
    const { activities } = this.props;
    const allLocal = localStorage.getItem("localData");
    const localization = JSON.parse(allLocal);
    const userModule = localStorage.getItem("userModule");
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm="3" className="pe-0">
                <Card className="overflow-hidden">
                 
                  <CardBody className="pt-3">
                    <Row>
                      <Col sm="6">
                        <div className="pt-2">
                          <div className="text-center">
                           
                              <RsLink onClick={this.toggleRightCanvas3} className="btn-light border-blue w-md" iconClass="bx bx-plus-medical">Convert to Contact</RsLink>
                              
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="pt-2">
                          <div className="text-center">
                          <RsLink onClick={this.toggleRightCanvas2} className="btn-light border-green w-md" iconClass="bx bx-plus-medical">Convert to Client</RsLink>
                              
                           
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      {" "}
                      {userModule.indexOf("BrokerAgent.RealtorLeads.Edit") >
                      -1 ? (
                        <RsLink onClick={this.toggleRightCanvas} className="btn-purpul w-md float-end" iconClass=" bx bx-edit-alt">Edit</RsLink>
                       
                      ) : (
                        ""
                      )}
                    </h4>
                    <div className="clearfix"></div>
                    {!isEmpty(leads) && (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th scope="row"> Name</th>
                              <td> {leads.title+ " "+ leads.firstName +" "+ leads.lastName} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Address</th>
                              <td></td>
                            </tr>
                           
                            

                            <tr>
                              <th scope="row"> Phone</th>
                              <td> {leads.phone} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Mobile</th>
                              <td> {leads.mobile} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Email</th>
                              <td> {leads.email} </td>
                            </tr>
                            <tr>
                              <th scope="row">Source</th>
                              <td>
                                {" "}
                                {leads.leadSourceId
                                  ? localization[
                                      "LeadSource_" + leads.leadSourceId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Status</th>
                              <td>
                                {leads.leadStatusId
                                  ? localization[
                                      "LeadStatus_" + leads.leadStatusId
                                    ]
                                  : "N/A"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>
                                {" "}
                                {leads.leadTypeId
                                  ? localization["LeadType_" + leads.leadTypeId]
                                  : "N/A"}{" "}
                              </td>
                            </tr>


                            <tr>
                              <th scope="row">Stage</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Group</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Deal Value</th>
                              <td>
                                {leads.approxDealValue}
                               </td> 
                            </tr>
                            
                            <tr>
                              <th scope="row">Referred by</th>
                              <td>
                                {leads.referedBy}
                               </td> 
                            </tr>
                            <tr>
                              <th scope="row">Referral Code</th>
                              <td>
                                {leads.referralCode}
                               </td> 
                            </tr>
        
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Offcanvas
                  isOpen={this.state.isRight}
                  direction="end"
                  toggle={this.toggleRightCanvas}
                  style={{width:800}}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas}>
                    Update Lead
                  </OffcanvasHeader>
                  <OffcanvasBody>
                      <EditLead
                       closed={this.toggleRightCanvas} 
                       match={this.props.match}
                      />
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={this.state.isRight2}
                  direction="end"
                  toggle={this.toggleRightCanvas2}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                    <h2 className="text-center">Review</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <h2 className="offcanvas-heading">Profile Info</h2>
                    <p className="offcanvas-info">
                      {" "}
                      To make any changes to profile Info, please Edit the Lead
                    </p>
                    <br />
                    <p className="form-label">
                      Name : {leads.firstName + " " + leads.lastName}{" "}
                    </p>
                    <p className="form-label">Phone : {leads.phone}</p>
                    <p className="form-label">Email : {leads.email} </p>
                    <p className="form-label">Address : </p>
                    <hr />
                    <h2 className="offcanvas-heading">
                      Type of Client (<small>Check all that apply</small>)
                    </h2>
                    {/* <h5  >Check all that apply</h5> */}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        clientId: 0,
                        buyerType: 1,
                        sellerType: 1,
                      }}
                      validationSchema={Yup.object().shape({
                        loginEmail: Yup.string().required("email is required"),
                        confirmEmail: Yup.string().oneOf(
                          [Yup.ref("loginEmail"), null],
                          "Email must match"
                        ),
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                          clientId: values.clientId,
                        };
                        onConvertLeadToClient(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isBuyer"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isBuyer"
                                onClick={e => handleChange(e)}
                              />
                              <Label
                                htmlFor="isBuyer"
                                className="form-check-label"
                              >
                                {this.props.t("Buyer")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 1)}
                                  className={
                                    values.sellerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Rent
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 2)}
                                  className={
                                    values.sellerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Purchase
                                </Button>
                              </ButtonGroup>
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isSeller"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isSeller"
                                onClick={e => handleChange(e)}
                                // checked='true'
                              />
                              <Label
                                htmlFor="isSeller"
                                className="form-check-label"
                              >
                                {this.props.t("Seller")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              {/* <h5>Rnet </h5>
                            <Switch 
                            name="buyType"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={18}
                            width={50}
                             value={values.buyType}
                             onChange={(value) => setFieldValue('buyType', !value)}
                            />
                            <h5>Rnet </h5> */}
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 1)}
                                  className={
                                    values.buyerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Sell
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 2)}
                                  className={
                                    values.buyerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Lease
                                </Button>
                              </ButtonGroup>
                              {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isInvestor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isInvestor"
                              className="form-check-label"
                            >
                              {this.props.t("Investor")}
                            </Label>
                          </FormGroup>
                          <hr />
                          <h2 className="offcanvas-heading">Agreement Info</h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="openhouseDate">
                              {this.props.t("Sign Date")}
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.openhouseDate && touched.openhouseDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.openhouseDate}
                                onChange={value =>
                                  setFieldValue("openhouseDate", value[0])
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>

                          <hr />

                          <h2 className="offcanvas-heading">
                            Client Login Info
                          </h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="loginEmail">
                              {this.props.t("Login email")}
                            </Label>
                            <Field
                              name="loginEmail"
                              type="text"
                              place
                              // placeholder="Login email"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.loginEmail && touched.loginEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="loginEmail"
                            />
                            <ErrorMessage
                              name="loginEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label htmlFor="confirmEmail">
                              {this.props.t("Confirm email")}
                            </Label>
                            <Field
                              name="confirmEmail"
                              type="text"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.confirmEmail && touched.confirmEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="confirmEmail"
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <p className="offcanvas-info">
                            The login email cannot be changed at a later date
                          </p>
                          <p className="offcanvas-info">
                            A system generated password is sent to the client
                          </p>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
                <Offcanvas
                  isOpen={this.state.isRight3}
                  direction="end"
                  toggle={this.toggleRightCanvas3}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas3}>
                    <h2 className="text-center">Convert to Contact</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        type: leads.name,
                      }}
                      validationSchema={Yup.object().shape({
                        // loginEmail: Yup.string().required('email is required'),
                        // confirmEmail: Yup.string()
                        // .oneOf([Yup.ref('loginEmail'), null], 'Email must match')
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                        };
                        onCovertLeadIntoContact(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}

                          <FormGroup className="mb-3">
                            <Label htmlFor="name">{this.props.t("Name")}</Label>
                            {/* <p className="form-label" >Name : {leads.firstName+" "+leads.lastName} </p> */}
                            <Field
                              name="name"
                              type="text"
                              value={leads.firstName + " " + leads.lastName}
                              // placeholder="Login email"
                              //value={values.referedBy}

                              className="form-control"
                              id="name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="type"
                              className="form-label label-text"
                            >
                              Type
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.type && touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                              name="type"
                              id="type"
                            >
                              <option value="">Select</option>
                              <option value="Silver">Silver </option>
                              <option value="Gold">Gold</option>
                              <option value="VIP">VIP</option>
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="frequency"
                              className="form-label label-text"
                            >
                              Contact frequency
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.frequency && touched.frequency
                                  ? " is-invalid"
                                  : "")
                              }
                              name="frequency"
                              id="frequency"
                            >
                              <option value="">Select</option>
                              <option value="Monthly">Monthly </option>
                              <option value="Quatterly">Quatterly</option>
                              <option value="Bi-Annual">Bi-Annual</option>
                              <option value="Annual">Annual</option>
                            </Field>
                            <ErrorMessage
                              name="frequency"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              name="isVendor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isVendor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isVendor"
                              className="form-check-label"
                            >
                              {this.props.t("Is a Vendor")}
                            </Label>
                          </FormGroup>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
              </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Detail")}
            />
           
            </Col>
            <Col sm='4'>
                <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
               
                <Row>
                  <Col xl="12">
                    <Card>
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFlushOne">
                            <button
                              className="accordion-button fw-medium collapsed"
                              type="button"
                              onClick={this.t_col15}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={prefrence} className="primg" /> 
                              Qualification Preferences
                            </button>
                          </h2>

                          <Collapse
                            isOpen={this.state.col15}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Card>
                                <CardBody>
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      LookingForCity:
                                        (this.state && this.state.addNote) ||
                                        "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                      LookingForCity:
                                        Yup.string().required(
                                          "This is Required"
                                        ),
                                    })}
                                    onSubmit={this.handleLeadNoteSubmit}
                                  >
                                    {({
                                      errors,
                                      touched,
                                      values,
                                      handleChange,
                                      setFieldValue,
                                    }) => (
                                      <Form className="needs-validation">
                                        <div className="">
                                          <Row className="mb20">
                                            <Col sm="12" md="6">
                                              <FormGroup className="mb-3">
                                                <Label
                                                  htmlFor="question1"
                                                  className="label-text"
                                                >
                                                  {this.props.t(
                                                    "Looking in City*"
                                                  )}
                                                </Label>
                                                <Field
                                                  as="select"
                                                  name="LookingForCity"
                                                  id="LookingForCity"
                                                  className="form-control"
                                                >
                                                  <option value=""></option>
                                                  <option value="Toronto">
                                                    Toronto
                                                  </option>
                                                  <option value="Pickering ">
                                                    Pickering
                                                  </option>
                                                </Field>
                                                <ErrorMessage
                                                  name="LookingForCity"
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row className="mb15">
                                            <Col lg="12">
                                            <SaveButton>Save Questions</SaveButton>
                                              
                                            </Col>
                                          </Row>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </CardBody>
                              </Card>
                            </div>
                          </Collapse>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFlushOne">
                            <button
                              className="accordion-button fw-medium collapsed"
                              type="button"
                              onClick={this.t_col9}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={prefrence} className="primg" />
                              Buyer Preferences
                            </button>
                          </h2>

                          <Collapse
                            isOpen={this.state.col9}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Card>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    leadId: 4,
                                    CurrentRent:
                                      (this.state && this.state.CurrentRent) ||
                                      "",
                                    DownPayment:
                                      (this.state && this.state.DownPayment) ||
                                      "",
                                    PaymentValue:
                                      (this.state && this.state.PaymentValue) ||
                                      "",
                                    PriceRangeStart:
                                      (this.state &&
                                        this.state.PriceRangeStart) ||
                                      "",
                                    PriceRangeEnd:
                                      (this.state &&
                                        this.state.PriceRangeEnd) ||
                                      "",
                                    BuyBeforeSell:
                                      (this.state &&
                                        this.state.BuyBeforeSell) ||
                                      false,
                                    SignedToSell:
                                      (this.state && this.state.SignedToSell) ||
                                      false,
                                    PreapprovedLender:
                                      (this.state &&
                                        this.state.PreapprovedLender) ||
                                      false,
                                    BedRoom:
                                      (this.state && this.state.BedRoom) || "",
                                    Bath: (this.state && this.state.Bath) || "",
                                    SqFt: (this.state && this.state.SqFt) || "",
                                    PrefferedLocation:
                                      (this.state &&
                                        this.state.PrefferedLocation) ||
                                      "",
                                    PropType:
                                      (this.state && this.state.PropType) || "",
                                    // Date: (this.state && this.state.Date) ||  new Date(),
                                    Desc: (this.state && this.state.Desc) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    CurrentRent:
                                      Yup.string().required("This is Required"),
                                    DownPayment:
                                      Yup.string().required("This is Required"),
                                    PaymentValue: Yup.number("Must be a number")
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required"),
                                    PriceRangeStart: Yup.number(
                                      "Must be a number"
                                    )
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required"),
                                    PriceRangeEnd: Yup.number(
                                      "Must be a number"
                                    )
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required")
                                      .when(
                                        "PriceRangeStart",
                                        PriceRangeStart => {
                                          if (PriceRangeStart) {
                                            return Yup.number(
                                              "Must be a number"
                                            )
                                              .min(
                                                PriceRangeStart + 1,
                                                "Max Price Must be Greater than Min Price"
                                              )
                                              .typeError("This is required");
                                          }
                                        }
                                      ),
                                    BuyBeforeSell: Yup.bool(),
                                    SignedToSell: Yup.bool(),
                                    PreapprovedLender: Yup.bool(),
                                    BedRoom: Yup.number()
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required"),
                                    Bath: Yup.number("Must be a number")
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required"),
                                    SqFt: Yup.number("Must be a number").min(
                                      1,
                                      "Value must be greater than 0"
                                    ),
                                    PrefferedLocation:
                                      Yup.string().required("This is Required"),
                                    PropType:
                                      Yup.string().required("This is Required"),
                                    Date: Yup.string().required(
                                      "This is Required"
                                    ),
                                    Desc: Yup.string().required(
                                      "This is Required"
                                    ),
                                  })}
                                  onSubmit={this.handleLeadNoteSubmit}
                                >
                                  {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    setFieldValue,
                                  }) => (
                                    // <Form
                                    // className="needs-validation"
                                    // >
                                    // <Form
                                    //   className="needs-validation"
                                    // >
                                    //   <CardBody>
                                    //     <Row className="mb15">
                                    //       {
                                    //         this.state.buyersQuestions
                                    //           .map(buyersQuestion =>

                                    //             <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                    //               <FormGroup>
                                    //               <Field type="hidden"  value={buyersQuestion.id} name={`questionAnswers.${buyersQuestion.id}.questionAnswerId`} />
                                    //               {/* <Field type="text"  onChange={handleChange} className="form-control" value={buyersQuestion.id} name={`questionAnswers.${buyersQuestion.id}.questionAnswerId`} /> */}
                                    //                 <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                    //                   {buyersQuestion.question}
                                    //                 </Label>
                                    //                 {(() => {
                                    //                   if (buyersQuestion.questionType == 1) {

                                    //                     const asns = buyersQuestion.answersOptions;
                                    //                     const res = asns.split(',');
                                    //                     const result = res.map((item, index) => <option key={index} value={item}

                                    //                     >{item}</option>);
                                    //                     return (
                                    //                       <div className="">
                                    //                         <Field as="select" className="form-control col-8" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                    //                           <option value="">Select</option>
                                    //                           {result}
                                    //                         </Field>
                                    //                       </div>
                                    //                     )
                                    //                   } else if (buyersQuestion.questionType == 2) {

                                    //                     return (
                                    //                       <div className="square-switch-left">
                                    //                         <input
                                    //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                    //                           type="checkbox"
                                    //                           id={"square-switch-left" + buyersQuestion.id}

                                    //                           onChange={() =>
                                    //                             this.setState({ sq1: !this.state.sq1 })
                                    //                           }
                                    //                         />
                                    //                         <label
                                    //                           htmlFor={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                    //                           data-on-label="Yes"
                                    //                           data-off-label="No"
                                    //                         />

                                    //                       </div>
                                    //                     )
                                    //                   } else if (buyersQuestion.questionType == 6) {
                                    //                     return (
                                    //                       <div>
                                    //                        <Field
                                    //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                    //                           type="text"
                                    //                           onChange={handleChange}
                                    //                           className="form-control"

                                    //                         />
                                    //                       </div>
                                    //                     )
                                    //                   } else if (buyersQuestion.questionType == 7) {
                                    //                     return (
                                    //                       <div>
                                    //                         <Field
                                    //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                    //                           type="text"
                                    //                           onChange={handleChange}
                                    //                           className="form-control"

                                    //                         />
                                    //                       </div>
                                    //                     )
                                    //                   } else if (buyersQuestion.questionType == 8) {
                                    //                     return (
                                    //                       <div>
                                    //                         <textarea name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />

                                    //                       </div>
                                    //                     )
                                    //                   } else {
                                    //                     return (
                                    //                       <div></div>
                                    //                     )
                                    //                   }
                                    //                 })()}

                                    //               </FormGroup>
                                    //             </div>

                                    //           )}
                                    //     </Row>

                                    //     <Row className="mb15">

                                    //       <Col lg="12">
                                    //         <Button
                                    //           color="primary"
                                    //         >
                                    //           Save Questions
                                    //         </Button>
                                    //       </Col>
                                    //     </Row>
                                    //   </CardBody>
                                    //   </Form>

                                    <Form className="needs-validation">
                                      <CardBody>
                                        <Row className="mb15">
                                          <FormGroup>
                                            <div className="row mb-3">
                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="CurrentRent"
                                                    className="form-label label-text"
                                                  >
                                                    Current Rent or Own?*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.CurrentRent &&
                                                      touched.CurrentRent
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    name="CurrentRent"
                                                    id="CurrentRent"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="Rent">
                                                      Rent{" "}
                                                    </option>
                                                    <option value="Own">
                                                      Own
                                                    </option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="CurrentRent"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2 ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="DownPayment"
                                                    className="form-label label-text "
                                                  >
                                                    Down Payment Type*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.DownPayment &&
                                                      touched.DownPayment
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    name="DownPayment"
                                                    id="DownPayment"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="1">
                                                      Percent
                                                    </option>
                                                    <option value="2">
                                                      Amount
                                                    </option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="DownPayment"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="PaymentValue"
                                                    className="form-label label-text"
                                                  >
                                                    Down Payment Value*
                                                  </Label>
                                                  <Field
                                                    type="number"
                                                    className={
                                                      "form-control" +
                                                      (errors.PaymentValue &&
                                                      touched.PaymentValue
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    name="PaymentValue"
                                                    id="PaymentValue"
                                                  />
                                                  <ErrorMessage
                                                    name="PaymentValue"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="PriceRangeStart"
                                                    className="form-label label-text"
                                                  >
                                                    Min Price*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.PriceRangeStart &&
                                                      touched.PriceRangeStart
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    name="PriceRangeStart"
                                                    id="PriceRangeStart"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="15000">
                                                      15000
                                                    </option>
                                                    <option value="25000">
                                                      25000
                                                    </option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="PriceRangeStart"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="PriceRangeEnd"
                                                    className="form-label label-text"
                                                  >
                                                    Max Price*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.PriceRangeEnd &&
                                                      touched.PriceRangeEnd
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    name="PriceRangeEnd"
                                                    id="PriceRangeEnd"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="15000">
                                                      15000
                                                    </option>
                                                    <option value="45000">
                                                      45000
                                                    </option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="PriceRangeEnd"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3" />

                                            <div className="row mb-3">
                                              <div>
                                                <Field
                                                  type="checkbox"
                                                  className="me-1"
                                                  name="BuyBeforeSell"
                                                  id="BuyBeforeSell"
                                                />
                                                <Label
                                                  htmlFor="BuyBeforeSell"
                                                  className="form-label label-text"
                                                >
                                                  Do you need to sell before you
                                                  buy?
                                                </Label>
                                              </div>

                                              <div>
                                                <Field
                                                  type="checkbox"
                                                  className="me-1"
                                                  name="SignedToSell"
                                                  id="SignedToSell"
                                                />
                                                <Label
                                                  htmlFor="SignedToSell"
                                                  className="form-label label-text"
                                                >
                                                  Have you signed a listing
                                                  aggrement to sell your home
                                                </Label>
                                              </div>

                                              <div>
                                                <Field
                                                  type="checkbox"
                                                  className="me-1"
                                                  name="PreapprovedLender"
                                                  id="PreapprovedLender"
                                                />
                                                <Label
                                                  htmlFor="PreapprovedLender"
                                                  className="form-label label-text"
                                                >
                                                  Are you pre-approved by a
                                                  lender
                                                </Label>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3" />

                                            <div className="row mb-3">
                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="BedRoom"
                                                    className="form-label label-text"
                                                  >
                                                    Bedroom*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.BedRoom &&
                                                      touched.BedRoom
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    id="BedRoom"
                                                    name="BedRoom"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="BedRoom"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2 ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="Bath"
                                                    className="form-label label-text"
                                                  >
                                                    Bath*
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className={
                                                      "form-control" +
                                                      (errors.Bath &&
                                                      touched.Bath
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    id="Bath"
                                                    name="Bath"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="1">1</option>
                                                    <option value="1.5">
                                                      1.5
                                                    </option>
                                                    <option value="2">2</option>
                                                    <option value="2.5">
                                                      25
                                                    </option>
                                                    <option value="3">3</option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name="Bath"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="SqFt"
                                                    className="form-label label-text"
                                                  >
                                                    How much Sq. ft
                                                  </Label>
                                                  <Field
                                                    type="number"
                                                    className={
                                                      "form-control" +
                                                      (errors.SqFt &&
                                                      touched.SqFt
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    id="SqFt"
                                                    name="SqFt"
                                                  />
                                                  <ErrorMessage
                                                    name="SqFt"
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-2  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="PropType"
                                                    className="form-label label-text"
                                                  >
                                                    Type
                                                  </Label>
                                                  <Field
                                                    as="select"
                                                    className="form-control"
                                                    id="PropType"
                                                    name="PropType"
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="1">
                                                      Condo
                                                    </option>
                                                    <option value="2">
                                                      Townhouse
                                                    </option>
                                                    <option value="3">
                                                      Semi-Detached
                                                    </option>
                                                    <option value="4">
                                                      Detached
                                                    </option>
                                                  </Field>
                                                </FormGroup>
                                              </div>

                                              <div className="col-sm-3  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="Date"
                                                    className="form-label label-text"
                                                  >
                                                    By what are you looking to
                                                    move in
                                                  </Label>
                                                  <Field
                                                    type="date"
                                                    className="form-control "
                                                    name="Date"
                                                    id="Date"
                                                  />
                                                </FormGroup>
                                              </div>
                                              <div className="col-sm-3  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="PrefferedLocation"
                                                    className="form-label label-text"
                                                  >
                                                    Preferred location
                                                  </Label>
                                                  <Field
                                                    type="text"
                                                    className="form-control"
                                                    id="PrefferedLocation"
                                                    name="PrefferedLocation"
                                                  />
                                                </FormGroup>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3" />

                                            <div className="row">
                                              <div className="  ">
                                                <FormGroup>
                                                  <Label
                                                    htmlFor="Desc"
                                                    className="form-label label-text "
                                                  >
                                                    What else are you looking
                                                    for in your home?
                                                  </Label>
                                                  <Field
                                                    as="textarea"
                                                    Row="5"
                                                    className="form-control"
                                                    id="Desc"
                                                    name="Desc"
                                                  />
                                                </FormGroup>
                                              </div>
                                            </div>
                                          </FormGroup>
                                        </Row>

                                        <Row className="mb15">
                                          <Col sm="12" md="6">
                                          <SaveButton>Save Questions</SaveButton>
                                            
                                          </Col>
                                          
                                          <Col
                                            sm="12"
                                            md="6"
                                            className="text-end"
                                          >
                                            <SaveButton iconClass=" bx bx-plus-medical">Add New</SaveButton>
                                           
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Form>
                                  )}
                                </Formik>
                              </Card>
                            </div>
                          </Collapse>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFlushTwo">
                            <button
                              className="accordion-button fw-medium collapsed"
                              type="button"
                              onClick={this.t_col10}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={prefrence} className="primg" />
                              Seller Preferences
                            </button>
                          </h2>

                          <Collapse
                            isOpen={this.state.col10}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Card>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    leadId: 4,
                                    Reason:
                                      (this.state && this.state.Reason) || "",
                                    ExpectedPrice:
                                      (this.state &&
                                        this.state.ExpectedPrice) ||
                                      "",
                                    ByWhen:
                                      (this.state && this.state.ByWhen) || "",
                                    ListedProperty:
                                      (this.state &&
                                        this.state.ListedProperty) ||
                                      false,
                                    LookingForNew:
                                      (this.state &&
                                        this.state.LookingForNew) ||
                                      false,
                                    Neighbourhood:
                                      (this.state &&
                                        this.state.Neighbourhood) ||
                                      "",
                                    Desc: (this.state && this.state.Desc) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    Reason: Yup.string(),
                                    ExpectedPrice: Yup.number(
                                      "Must be a number"
                                    )
                                      .min(1, "Value must be greater than 0")
                                      .required("This is Required"),
                                    ByWhen: Yup.string(),
                                    ListedProperty: Yup.bool(),
                                    LookingForNew: Yup.bool(),
                                    Neighbourhood: Yup.string(),
                                    Desc: Yup.string(),
                                  })}
                                  onSubmit={values => {
                                    // setTimeout(() => {
                                    //   alert(JSON.stringify(values, '', 2));
                                    // }, 500);
                                  }}
                                >
                                  {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    setFieldValue,
                                  }) => (
                                    <Form className="needs-validation">
                                      <CardBody>
                                        <Row className="mb15">
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="Reason"
                                                className="label-text"
                                              >
                                                Reason to Sell
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.Reason &&
                                                  touched.Reason
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="Reason"
                                                type="text"
                                                placeholder=""
                                                name="Reason"
                                              />
                                              <ErrorMessage
                                                name="Reason"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="ExpectedPrice"
                                                className="label-text"
                                              >
                                                {" "}
                                                Expected price*{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.ExpectedPrice &&
                                                  touched.ExpectedPrice
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                type="number"
                                                placeholder=""
                                                name="ExpectedPrice"
                                              />
                                              <ErrorMessage
                                                name="ExpectedPrice"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="ByWhen"
                                                className="label-text"
                                              >
                                                {" "}
                                                By When to move out
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.ByWhen &&
                                                  touched.ByWhen
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                type="text"
                                                name="ByWhen"
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name="ByWhen"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          {/* <Col lg="3">
                                        <FormGroup>
                                            <Label htmlFor='test' className='form-label label-text'>Type</Label>
                                            <Field as="select" className='form-control' id='test'>
                                                  <option value="">Select</option>
                                                  <option value="">Option 1</option>
                                                  <option value="">Option 2</option>
                                            </Field>
                                        </FormGroup>
                                        </Col> */}
                                        </Row>

                                        <hr className="mt-2 mb-3" />

                                        <Row className="">
                                          <FormGroup>
                                            <Field
                                              type="checkbox"
                                              className="me-1"
                                              name="ListedProperty"
                                              id="ListedProperty"
                                            />
                                            <Label
                                              htmlFor="ListedProperty"
                                              className="form-label label-text"
                                            >
                                              Have you listed the property
                                              previously
                                            </Label>
                                          </FormGroup>
                                        </Row>

                                        <Row className="">
                                          <FormGroup>
                                            <Field
                                              type="checkbox"
                                              className="me-1"
                                              name="LookingForNew"
                                              id="LookingForNew"
                                            />
                                            <Label
                                              htmlFor="LookingForNew"
                                              className="form-label label-text"
                                            >
                                              Are you looking to buy a new
                                              property after selling
                                            </Label>
                                          </FormGroup>
                                        </Row>

                                        <hr className="mt-2 mb-3" />

                                        <Row className="mb15">
                                          <Col lg="12">
                                            <FormGroup>
                                              <Label
                                                htmlFor="Neighbourhood"
                                                className="label-text"
                                              >
                                                {" "}
                                                Tell a little bit about your
                                                home/neighbourhood{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.Neighbourhood &&
                                                  touched.Neighbourhood
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                as="textarea"
                                                name="Neighbourhood"
                                                placeholder="Recently upgraded backyard. Excellent neighbors."
                                              />
                                              <ErrorMessage
                                                name="Neighbourhood"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        {/* <hr className="mt-2 mb-3"/> */}

                                        <Row className="mb15">
                                          <Col lg="12">
                                            <FormGroup>
                                              <Label
                                                htmlFor="Desc"
                                                className="label-text"
                                              >
                                                {" "}
                                                Seller notes{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.Desc && touched.Desc
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                as="textarea"
                                                placeholder="Motivated to sell."
                                                name="Desc"
                                              />
                                              <ErrorMessage
                                                name="Desc"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row className="mb15">
                                          <Col lg="12">
                                          <SaveButton> Save Questions</SaveButton>
                                           
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Form>
                                  )}
                                </Formik>
                              </Card>
                            </div>
                          </Collapse>
                        </div>

                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingFlushThree"
                          >
                            <button
                              className="accordion-button fw-medium collapsed"
                              type="button"
                              onClick={this.t_col11}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={prefrence} className="primg" />
                              Investor Preferences
                            </button>
                          </h2>
                          <Collapse
                            isOpen={this.state.col11}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Card>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    leadId: 4,
                                    Location:
                                      (this.state && this.state.Location) || "",
                                    PropertyType:
                                      (this.state && this.state.PropertyType) ||
                                      "",
                                    PriceRangeStart:
                                      (this.state &&
                                        this.state.PriceRangeStart) ||
                                      "",
                                    PriceRangeEnd:
                                      (this.state &&
                                        this.state.PriceRangeEnd) ||
                                      "",
                                    ExpectedProfit:
                                      (this.state &&
                                        this.state.ExpectedProfit) ||
                                      "",
                                    RiskCapacity:
                                      (this.state && this.state.RiskCapacity) ||
                                      "",
                                    Strategy:
                                      (this.state && this.state.Strategy) || "",
                                    Notes:
                                      (this.state && this.state.Notes) || "",
                                    HowManyDeals:
                                      (this.state && this.state.Notes) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    Location:
                                      Yup.string().required("This is Required"),
                                    PropertyType:
                                      Yup.string().required("This is Required"),
                                    HowManyDeals: Yup.number().min(
                                      1,
                                      "Value must be greater than 0"
                                    ),
                                    PriceRangeStart: Yup.number().min(
                                      1,
                                      "Value must be greater than 0"
                                    ),
                                    PriceRangeEnd: Yup.number().when(
                                      "PriceRangeStart",
                                      PriceRangeStart => {
                                        if (PriceRangeStart) {
                                          return Yup.number("Must be a number")
                                            .min(
                                              PriceRangeStart + 1,
                                              "Max Price Must be Greater than Min Price"
                                            )
                                            .typeError("This is required");
                                        }
                                      }
                                    ),
                                    ExpectedProfit: Yup.number(),
                                    RiskCapacity: Yup.string(),
                                    Strategy: Yup.string(),
                                    Notes: Yup.string(),
                                  })}
                                  onSubmit={values => {
                                    // setTimeout(() => {
                                    //   alert(JSON.stringify(values, '', 2));
                                    // }, 500);
                                  }}
                                >
                                  {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    setFieldValue,
                                  }) => (
                                    <Form className="needs-validation">
                                      <CardBody>
                                        <Row className="mb15">
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="Location"
                                                className="label-text"
                                              >
                                                Geographic locations preferred*{" "}
                                              </Label>
                                              <Field
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.Location &&
                                                  touched.Location
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="Location"
                                                name="Location"
                                              />
                                              <ErrorMessage
                                                name="Location"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="PropertyType"
                                                className="label-text"
                                              >
                                                Type of property interested*{" "}
                                              </Label>
                                              <Field
                                                as="select"
                                                className={
                                                  "form-control" +
                                                  (errors.PropertyType &&
                                                  touched.PropertyType
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="PropertyType"
                                                name="PropertyType"
                                              >
                                                <option value="0">
                                                  Select...
                                                </option>
                                                <option value="1">
                                                  Residential
                                                </option>
                                                <option value="2">
                                                  Commercial
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name="PropertyType"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="PriceRangeStart"
                                                className="label-text"
                                              >
                                                Price range{" "}
                                              </Label>
                                              <Field
                                                type="number"
                                                className={
                                                  "form-control" +
                                                  (errors.PriceRangeStart &&
                                                  touched.PriceRangeStart
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                name="PriceRangeStart"
                                                id="PriceRangeStart"
                                              />
                                              <ErrorMessage
                                                name="PriceRangeStart"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="PriceRangeEnd"
                                                className="label-text"
                                              >
                                                Price range End
                                              </Label>
                                              <Field
                                                type="number"
                                                className={
                                                  "form-control" +
                                                  (errors.PriceRangeEnd &&
                                                  touched.PriceRangeEnd
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                name="PriceRangeEnd"
                                                id="PriceRangeEnd"
                                              />
                                              <ErrorMessage
                                                name="PriceRangeEnd"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row className="mb15">
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="HowManyDeals"
                                                className="label-text"
                                              >
                                                {" "}
                                                How many deals do you typically
                                                have going at one time?{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.HowManyDeals &&
                                                  touched.HowManyDeals
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                type="number"
                                                name="HowManyDeals"
                                                // placeholder="2-3"
                                              />
                                              <ErrorMessage
                                                name="HowManyDeals"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col lg="4">
                                            <FormGroup>
                                              <Label
                                                htmlFor="ExpectedProfit"
                                                className="label-text"
                                              >
                                                Expected profit / deal{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.ExpectedProfit &&
                                                  touched.ExpectedProfit
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                type="number"
                                                name="ExpectedProfit"
                                                placeholder="30%"
                                              />
                                              <ErrorMessage
                                                name="ExpectedProfit"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="3">
                                            <FormGroup>
                                              <Label
                                                htmlFor="RiskCapacity"
                                                className="label-text"
                                              >
                                                Risk capacity{" "}
                                              </Label>

                                              <Field
                                                as="select"
                                                name="RiskCapacity"
                                                className={
                                                  "form-control" +
                                                  (errors.RiskCapacity &&
                                                  touched.RiskCapacity
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option value="0">
                                                  Select...
                                                </option>
                                                <option value="1">
                                                  Low Risk
                                                </option>
                                                <option value="2">
                                                  Medium Risk
                                                </option>
                                                <option value="2">
                                                  High Risk
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name="RiskCapacity"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row className="mb15">
                                          <Col lg="12">
                                            <FormGroup>
                                              <Label className="label-text">
                                                Investment strategy{" "}
                                              </Label>
                                              <br />
                                              {/* <Label >Investment strategy </Label> */}
                                              <div className="form-check form-check-inline">
                                                <Field
                                                  className="form-check-input"
                                                  name="Strategy"
                                                  type="checkbox"
                                                  id="inlineCheckbox1"
                                                  value="option1"
                                                />
                                                <Label
                                                  className="form-check-label label-text"
                                                  htmlFor="inlineCheckbox1"
                                                >
                                                  Flip
                                                </Label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                <Field
                                                  className="form-check-input"
                                                  name="Strategy"
                                                  type="checkbox"
                                                  id="inlineCheckbox2"
                                                  value="option2"
                                                />
                                                <Label
                                                  className="form-check-label label-text"
                                                  htmlFor="inlineCheckbox2"
                                                >
                                                  Hold/Rent
                                                </Label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                <Field
                                                  className="form-check-input"
                                                  name="Strategy"
                                                  type="checkbox"
                                                  id="inlineCheckbox3"
                                                  value="option3"
                                                />
                                                <Label
                                                  className="form-check-label label-text"
                                                  htmlFor="inlineCheckbox3"
                                                >
                                                  Sell
                                                </Label>
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row className="mb15">
                                          <Col lg="12">
                                            <FormGroup>
                                              <Label
                                                htmlFor="Notes"
                                                className="label-text"
                                              >
                                                Investor notes{" "}
                                              </Label>
                                              <Field
                                                className={
                                                  "form-control" +
                                                  (errors.Notes && touched.Notes
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                as="textarea"
                                                name="Notes"
                                                placeholder=""
                                              ></Field>
                                              <ErrorMessage
                                                name="Notes"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row className="mb15">
                                          <Col lg="12">
                                                <SaveButton> Save Questions</SaveButton>

                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Form>
                                  )}
                                </Formik>
                              </Card>
                            </div>
                          </Collapse>
                        </div>

                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingFlushThree"
                          >
                            <button
                              className="accordion-button fw-medium collapsed"
                              type="button"
                              onClick={this.t_col12}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={prefrence} className="primg" />
                              Lead Activities
                            </button>
                          </h2>
                          <Collapse
                            isOpen={this.state.col12}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Card>
                                <CardBody>
                                  {/* <Nav tabs className="leadTab">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <span className="d-none d-sm-block"><img src={all} /><br />{this.props.t("All")}</span>
                          <span className="d-block d-sm-none"><img src={all} /></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={appointment} /><br />{this.props.t("Appointments")}</span>
                          <span className="d-block d-sm-none"><img src={appointment} /></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={notes} /><br />{this.props.t("Notes")}</span>
                          <span className="d-block d-sm-none"><img src={notes} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={follow} /><br />{this.props.t("Follow-ups")}</span>
                          <span className="d-block d-sm-none"><img src={follow} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={phonelog} /><br />{this.props.t("Call Logs")}</span>
                          <span className="d-block d-sm-none"><img src={phonelog} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggle("6")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={emaillog} /><br />{this.props.t("Email Logs")}</span>
                          <span className="d-block d-sm-none"><img src={emaillog} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "7",
                          })}
                          onClick={() => {
                            this.toggle("7")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={smslog} /><br />{this.props.t("SMS Logs")}</span>
                          <span className="d-block d-sm-none"><img src={smslog} /></span>
                        </NavLink>
                      </NavItem>


                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "8",
                          })}
                          onClick={() => {
                            this.toggle("8")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={listing} /><br />{this.props.t("Listings")}</span>
                          <span className="d-block d-sm-none"><img src={listing} /></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "10",
                          })}
                          onClick={() => {
                            this.toggle("10")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={project} /><br />{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><img src={project} /></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "11",
                          })}
                          onClick={() => {
                            this.toggle("11")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={project} /><br />{this.props.t("Collections")}</span>
                          <span className="d-block d-sm-none"><img src={project} /></span>
                        </NavLink>
                      </NavItem>            
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "9",
                          })}
                          onClick={() => {
                            this.toggle("9")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={project} /><br />{this.props.t("Projects")}</span>
                          <span className="d-block d-sm-none"><img src={project} /></span>
                        </NavLink>
                      </NavItem>
            
                    </Nav> */}
                                  <Nav
                                    tabs
                                    className="projectTab projectNav contact-detail-nav"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                          this.toggle("1");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("All ")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                          this.toggle("2");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Appointments")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "3",
                                        })}
                                        onClick={() => {
                                          this.toggle("3");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Notes")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "4",
                                        })}
                                        onClick={() => {
                                          this.toggle("4");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Follow-ups")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "5",
                                        })}
                                        onClick={() => {
                                          this.toggle("5");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Call Logs")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "6",
                                        })}
                                        onClick={() => {
                                          this.toggle("6");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Email Logs")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "7",
                                        })}
                                        onClick={() => {
                                          this.toggle("7");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("SMS Logs")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "8",
                                        })}
                                        onClick={() => {
                                          this.toggle("8");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Listings")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "10",
                                        })}
                                        onClick={() => {
                                          this.toggle("10");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Marketing")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "11",
                                        })}
                                        onClick={() => {
                                          this.toggle("11");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Collections")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "9",
                                        })}
                                        onClick={() => {
                                          this.toggle("9");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Projects")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  <TabContent
                                    activeTab={this.state.activeTab}
                                    className="p-3 text-muted"
                                  >
                                    <TabPane tabId="1">
                                      <div className="mb-5 h4 card-title">
                                        All Activities
                                      </div>
                                      <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </TabPane>
                                    <TabPane tabId="2">
                                      <AppointmentLog data={leads} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                      <LeadNote />
                                    </TabPane>

                                    <TabPane tabId="4">
                                      <FollowLead />
                                    </TabPane>
                                    <TabPane tabId="5">
                                      <CallLog />
                                    </TabPane>
                                    <TabPane tabId="6">
                                      <EmaillLog />
                                    </TabPane>

                                    <TabPane tabId="7">
                                      <SmsLog />
                                    </TabPane>

                                    <TabPane tabId="8">
                                      <LeadListing />
                                    </TabPane>

                                    <TabPane tabId="9">
                                      <div className="mb-5 h4 card-title">
                                        Projects
                                      </div>
                                      <div className="table-rep-plugin">
                                        <div
                                          className="table-responsive mb-0"
                                          data-pattern="priority-columns"
                                        >
                                          <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                          >
                                            <Thead>
                                              <Tr>
                                                <Th>{this.props.t("Date")}</Th>
                                                <Th>
                                                  {this.props.t("Project")}
                                                </Th>
                                                <Th>
                                                  {this.props.t("Source")}
                                                </Th>
                                              </Tr>
                                            </Thead>
                                            <Tbody>
                                              <Tr>
                                                <Th>Nov 26, 2021</Th>
                                                <Th>
                                                  <span className="co-name">
                                                    {" "}
                                                    Liberty Market Tower
                                                  </span>
                                                </Th>
                                                <Th></Th>
                                              </Tr>
                                            </Tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </TabPane>

                                    <TabPane tabId="10">
                                      <div className="mb-5 h4 card-title">
                                        Marketing
                                      </div>
                                      <div className="table-rep-plugin">
                                        <div
                                          className="table-responsive mb-0"
                                          data-pattern="priority-columns"
                                        >
                                          <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                          >
                                            <Thead>
                                              <Tr>
                                                <Th>{this.props.t("Type")}</Th>
                                                <Th>{this.props.t("Name")}</Th>
                                                <Th>{this.props.t("Date")}</Th>
                                              </Tr>
                                            </Thead>
                                            <Tbody>
                                              <Tr>
                                                <Th>Newsletter</Th>
                                                <Th>
                                                  <span className="co-name">
                                                    {" "}
                                                    Toronto News
                                                  </span>
                                                </Th>
                                                <Th>5th August, 2023</Th>
                                              </Tr>
                                            </Tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </TabPane>

                                    <TabPane tabId="11">
                                      <div className="mb-5 h4 card-title">
                                        Collection
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                          <thead className="">
                                            <tr>
                                              <th className="align-middle">
                                                <div className="form-check font-size-16 align-middle">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheck1"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="customCheck1"
                                                  >
                                                    &nbsp;
                                                  </label>
                                                </div>
                                              </th>
                                              <th className="align-middle">
                                                Name
                                              </th>
                                              <th className="align-middle">
                                                Description
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div className="form-check font-size-16 align-middle">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheck1"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="customCheck1"
                                                  >
                                                    &nbsp;
                                                  </label>
                                                </div>
                                              </td>
                                              <td>Toronto-600k</td>
                                              <td>
                                                Looking for condos with maximum
                                                price of $600,000 1 BR
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </TabPane>
                                  </TabContent>
                                </CardBody>
                              </Card>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
leadDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object,
  onGetAllActLead: PropTypes.func,
  activities: PropTypes.array,
  onConvertLeadToClient: PropTypes.func,
  onCovertLeadIntoContact: PropTypes.func,
};
const mapStateToProps = ({ leads }) =>
  //console.log(contact),
  ({
    leads: leads.leads,
    activities: leads.activities,
   
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  onGetAllActLead: leadId => dispatch(getAllActLeadTab(leadId)),
  onConvertLeadToClient: data => dispatch(convertLeadIntoClient(data)),
  onCovertLeadIntoContact: data => dispatch(convertLeadIntoContact(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(leadDetail));
