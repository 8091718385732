import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import ProjectFilterMenu from "./lead-filter-menu";
class PendingWorksheetLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: ''
    }
   
  }
  render() {
    var days = [];
    days.push({
        "title": '1 Day',
    })
    days.push({
        "title": '2 Days',
    })
    days.push({
        "title": '3 Days',
    })
    days.push({
        "title": '4 Days',
    })
    days.push({
        "title": 'More than 4 Days',
    })
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            {/* Render Breadcrumbs */}

            <Row className="mb20 project-header">
              <Col lg="12">
                <Breadcrumbs
                  title={this.props.t("Project Lead")}
                  breadcrumbItem={this.props.t("Worksheet Pending")}
                />
              </Col>

              {/* <Col lg="6">

              </Col> */}
            </Row>
            <Row>
                <ProjectFilterMenu />
                
            </Row>
            <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { days.map((day,index) => 
                            <Col sm="2" className="" key={day.title}>
                                <div className="topHeading" style={{backgroundColor:'#000000'}}>
                                  <h4 style={{fontSize:'12px', color:'#FFFFFF'}}>{day.title}</h4>
                                </div>
                            </Col>
                         )} 
                         </Row>                 
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PendingWorksheetLead.propTypes = {

  className: PropTypes.any,
  t: PropTypes.any,
  loading:PropTypes.object
  
}

const mapStateToProps = ({ Project, leads }) => ({
 
  loading: Project.loading,
 
})

const mapDispatchToProps = dispatch => ({
 
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(PendingWorksheetLead)))