import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup, Input, InputGroup, Label, CardBody,Card,Modal
} from "reactstrap"
import CollectionChart from "./chart/collectionchart"

class CollectionDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      modal_standard: false,
  }
  this.tog_standard = this.tog_standard.bind(this)
  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Collection | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
          <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Collection")}
              breadcrumbItem={this.props.t("Collection Dashboard")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end">
                  
                  <div className="">
                    
                  
                  
                    <Link
                      to="#"
                      className="btn btn-add btn-md"
                      onClick={this.tog_standard}
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      Create New    {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link>
                  
                    
                  </div>
                 
                </div>
            </Col>
            </Row>
            <CollectionChart />
           <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                    <div className="row gy-2 gx-3 float-end mb20">
          
          
          
          <div className="col-sm-auto">
            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
            <select defaultValue="0" className="form-select">
              <option value="0">All...</option>
              <option value="1">Leads</option>
              <option value="2"> Contacts</option>
              <option value="3"> Contacts - Client Only</option>
              <option value="3"> Contacts - VIP Only</option>
             
            </select>
          </div>
          
          <div className="col-sm-auto">
            <button type="submit" className="btn btn-primary w-md">Filter</button>
          </div>
        </div>
        <div className="clearfix"></div>
                    <div className="table-responsive">
            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                <th className="align-middle"><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Description</th>
                  <th className="align-middle">Target</th>
                  
                  <th className="align-middle">Created date</th>
                  <th className="align-middle">Created by</th>
                  <th className="align-middle"># Accounts</th>
                  <th className="align-middle">View List</th>
                </tr>
              </thead>
                <tbody>
                    <tr>
                        <td><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></td>
                        <td>Toronto-600k</td>
                        <td>Looking for condos with maximum price of $600,000 1 BR</td>
                        <td>Leads</td>
                        <td>23 June 2023</td>
                        <td>Mohit Grover</td>
                        <td>10</td>
                        <td>
                        <UncontrolledDropdown direction="up">
                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                  Action <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem href="#">Edit</DropdownItem>
                                                  <DropdownItem href="/collection/list">View List</DropdownItem>
                                                  <DropdownItem  href="#">Delete</DropdownItem>
                                                
                                                 
                                                  
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>

                    </CardBody>
                </Card>
            </Col>
           </Row>
          </Container>
          <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                           Add New
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <Formik
                        enableReinitialize={true}
                        initialValues={{
                         
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          
                        
                         
                          
                        })}

                        onSubmit={this.handleValidSubmit}
                      >
                        {({ errors,  touched, handleChange, values, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                        <div className="modal-body">
                          <Row>
                          <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="fname">
                                    {this.props.t("Name")}
                                  </Label>
                                  <Field
                                    name="fname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.fname && touched.fname
                                        ? " is-invalid"
                                        : "")
                                    }

                                    id="fname"
                                  />
                                  <ErrorMessage
                                    name="fname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="fname">
                                    {this.props.t("Description")}
                                  </Label>
                                  <Field
                                    name="fname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.fname && touched.fname
                                        ? " is-invalid"
                                        : "")
                                    }

                                    id="fname"
                                  />
                                  <ErrorMessage
                                    name="fname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                          <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactTypeId" >
                                    {this.props.t("Target")}
                                  </Label>
                                  <Field as="select" name="contactTypeId" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.contactTypeId && touched.contactTypeId
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="client">Leads</option>
                                    <option value="client">Contact</option>

                                  </Field>
                                  <ErrorMessage
                                    name="contactTypeId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                          >
                            Add Collection
                          </button>
                        </div>
                        </Form>
                        )}

</Formik>                      
</Modal>
        </div>
      </React.Fragment>
    )
  }
}
CollectionDashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(CollectionDashboard))
