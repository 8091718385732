import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
// Crypto Redux States
import {
  ADD_LEAD_APP,
  ADD_LEAD_CALLLOG,
  ADD_LEAD_EMAILLOG,
  ADD_LEAD_NOTE,
  ADD_LEAD_LIST,
  CONVERT_LEAD_INTO_CLIENT,
  CONVERT_LEAD_INTO_CONTACT,
  CREATE_LEAD, GET_LEAD_ALL_ACT, GET_LEAD_APP, GET_LEAD_CALLLOG, GET_LEAD_DETAIL, GET_LEAD_EMAILLOG, GET_LEAD_FOLLOW, GET_LEAD_NOTE,GET_LEAD_LIST, POST_LEAD_FOLLOW, UPDATE_LEAD, UPDATE_LEAD_STAGE_BOARD, UPDATE_LEAD_STATUS_BOARD,
  GET_LEADS
} from "./actionTypes"

import { postLeadData, getLeadDetail, postLeadAPP, getLeadAPP, postLeadNote,postLeadList, getNoteLeadApi,getListLeadApi,postLeadFollow,getFollowLeadApi, getLeadAllAct, updateLeadDataApi, postLeadCalllogNOApi, postLeadCalllogVCApi, postLeadCalllogBNApi, getLeadCalllogApi, postLeadEmaillogERApi, postLeadEmaillogESApi, postLeadEmaillogBBApi, getLeadEmaillogApi, postLeadStageBoardAPI, postLeadStatusBoardAPI, postLeadCovertClientAPI, postLeadCovertContactAPI, getAllLeadUrl } from '../../helpers/backend_helper'

import { addLeadAPPSuccess, createLeadDataFail, createLeadDataSuccess, getAppLeadTabFail, getAppLeadTabSuccess, getLeadDetailFail, getLeadDetailSuccess, addLeadNoteTabSuccess,addLeadListTabSuccess, getNoteLeadTabSuccess,getListLeadTabSuccess, getNoteLeadTabFail,getListLeadTabFail, addLeadFollowTabSuccess, addLeadFollowTabFail, getFollowLeadTabSuccess, getFollowLeadTabFail, getAllActLeadTabSuccess, getAllActLeadTabFail, updateLeadFail, addLeadCallLogSuccess, addLeadCallLogFail, getLeadCallLogSuccess, getLeadCallLogFail, addLeadEmailLogSuccess, addLeadEmailLogFail, getLeadEmailLogSuccess, getLeadEmailLogFail, addLeadLeadStageBoardSuccess, addLeadLeadStageBoardFail, addLeadStatusBoardSuccess, addLeadStatusBoardFail, convertLeadIntoClientSuccess, convertLeadIntoClientFail, convertLeadIntoContactSuccess, convertLeadIntoContactFail, getLeadsSucess, getLeadsFail } from "./actions"


function* submitNewLead({ payload: { lead, history } }) {

  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadData,lead)
    yield put(createLeadDataSuccess(response))
    if(response.success==true){
      toast.dismiss();
      toast.success("New Lead Added");
      const res = yield call(getAllLeadUrl, lead.agentId)
      yield put(getLeadsSucess(res))
    }else{
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  }
  catch (error) {

    yield put(createLeadDataFail(error))
  }
}

function* fetchAllLeads({ agentId }) {
  try {
    const response = yield call(getAllLeadUrl, agentId)
    yield put(getLeadsSucess(response))
  } catch (error) {
    yield put(getLeadsFail(error))
  }
}


function* fetchLeadDetail({ leadId }) {
  try {
    const response = yield call(getLeadDetail, leadId)
    yield put(getLeadDetailSuccess(response))
  } catch (error) {
    yield put(getLeadDetailFail(error))
  }
}

function* submitLeadAPP({ payload: { appointment, history } }) {
  try {
    toast.loading("Please wait...")
    //console.log(appointment);
    const response = yield call(postLeadAPP, {
      appointmentTitle: appointment.appAjenda,
      contactName: 'Test Lead',
      contactPhone: '1234567890',
      contactEmail: 'testlead@gmail.cm',
      appointmentStartDate: appointment.appADate,
      startTime: appointment.appStat,
      endTime: appointment.appEnd,
      appointmentType: parseInt(appointment.apptype),
      appointmentLocationType: parseInt(appointment.appointmentLocationType),
      appointmentPriority: parseInt(appointment.appPriority),
      agentId: parseInt(localStorage.getItem("userId")),
      leadId: 4,

    })

    yield put(addLeadAPPSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Appointment Added");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {
    yield put(getAppLeadTabFail(error.message))
  }
}

function* fetchAppLead({ LeadId }) {
  try {
    const response = yield call(getLeadAPP, parseInt(localStorage.getItem("userId")), LeadId)

    yield put(getAppLeadTabSuccess(response))

  } catch (error) {
    yield put(getAppLeadTabFail(error))
  }
}

function* addNoteLead({ payload: { notes, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadNote, {
      remarks: notes.addNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: parseInt(notes.refId),
      addedDate: new Date()

    })
    yield put(addLeadNoteTabSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Notes Added");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* addListLead({ payload: { lists, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadList, {
      createLeadListingLists: lists.data

    })
    yield put(addLeadListTabSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Notes Added");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* fetchNoteLead({ leadId }) {
  try {
    const response = yield call(getNoteLeadApi,leadId)
    yield put(getNoteLeadTabSuccess(response))
  } catch (error) {
    yield put(getNoteLeadTabFail(error))
  }
}

function* fetchListLead({ leadId }) {
  try {
    const response = yield call(getListLeadApi,leadId)
    yield put(getListLeadTabSuccess(response))
  } catch (error) {
    yield put(getListLeadTabFail(error))
  }
}


function* submitFollowLead({ payload: { follows, history } }) {
  try {
    //console.log(follows);
    toast.loading("Please wait...")
    const response = yield call(postLeadFollow, {
      message: follows.addNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: parseInt(follows.refId),
      nextFollowupTime: "",
      nextFollowupDate: follows.nextFollowupDate,
      addedDate: new Date(),
      createdFor:parseInt(follows.refId)

    })
    
    yield put(addLeadFollowTabSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Next Follow-up Added");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadFollowTabFail())
  }
}

function* fetchFollowLead({ leadId }) {
  try {
    const response = yield call(getFollowLeadApi,leadId)
    yield put(getFollowLeadTabSuccess(response))
  } catch (error) {
    yield put(getFollowLeadTabFail(error))
  }
}

function* fetchAllActLead({ leadId }) {
  try {
    const response = yield call(getLeadAllAct,leadId)
    yield put(getAllActLeadTabSuccess(response))
  } catch (error) {
    yield put(getAllActLeadTabFail(error))
  }
}

function* onUpdateLead({ payload: lead }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateLeadDataApi, lead)
    toast.dismiss();
    toast.success("Lead  Updated");
    if(response.success=='true'){
      toast.dismiss();
      toast.success("Lead  Updated");
    }
    else{
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
    //const res = yield call(getAppointmentPriorityAPI, priority.agentId)
    //yield put(getAppointmentPrioritySuccess(res))


  } catch (error) {
    yield put(updateLeadFail(error))
  }
}

function* submitLeadCalllog({ payload: { calllog, history } }) {
  //console.log(notes.actionType);
  try {
    let eLink = '';
    if (calllog.actionType == 'No Answer') {
      eLink = postLeadCalllogNOApi;
    } else if (calllog.actionType == 'Left Voicemail') {
      eLink = postLeadCalllogVCApi;
    } else {
      eLink = postLeadCalllogBNApi;
    }

    const response = yield call(eLink, {
      remarks: calllog.callNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: parseInt(calllog.refId),
      addedDate: calllog.addedDate


    })
    yield put(addLeadCallLogSuccess(response))
    if (response.success == true) {
      toast.success("Call Log Added");
     
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadCallLogFail('Some Error'))
  }
}

function* fetchCalllog({ leadId }) {
  try {
    const response = yield call(getLeadCalllogApi, leadId)
    //console.log(response);
    yield put(getLeadCallLogSuccess(response))
  } catch (error) {
    yield put(getLeadCallLogFail(error))
  }
}


function* submitLeadEmailLog({ payload: { emaillog, history } }) {
  //console.log(notes.actionType);
  try {
    let eLink = '';
    if (emaillog.actionType == 'Email Sent') {
      eLink = postLeadEmaillogESApi;
    } else if (emaillog.actionType == 'Email Received') {
      eLink = postLeadEmaillogERApi;
    } else {
      eLink = postLeadEmaillogBBApi;
    }

    const response = yield call(eLink, {
      remarks: emaillog.remarks,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: parseInt(emaillog.refId),
      addedDate: emaillog.addedDate


    })
    yield put(addLeadEmailLogSuccess(response))
    if (response.success == true) {
      toast.success("Email Log Added");
      
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadEmailLogFail('Some Error'))
  }
}

function* fetchEmailLog({ leadId }) {
  try {
    const response = yield call(getLeadEmaillogApi, leadId)
    //console.log(response);
    yield put(getLeadEmailLogSuccess(response))
  } catch (error) {
    yield put(getLeadEmailLogFail(error))
  }
}

function* submitLeadStageBoard({ payload: { leadBoard, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadStageBoardAPI,leadBoard)
    
    yield put(addLeadLeadStageBoardSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Stage Updated");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadLeadStageBoardFail())
  }
}

function* submitLeadStatusBoard({ payload: { leadBoard, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadStatusBoardAPI,leadBoard)
    
    yield put(addLeadStatusBoardSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Status Updated");

    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadStatusBoardFail())
  }
}


function* submitLeadConvertClient({ payload: { lead, history } }) {
  try {
    //console.log(lead);
    toast.loading("Please wait...")
    const response = yield call(postLeadCovertClientAPI,lead)
      toast.dismiss();
      toast.success("Lead Converted To Client");
  }
  catch (error) {

    yield put(convertLeadIntoClientFail())
  }
}

function* submitLeadConvertContact({ payload: { lead, history } }) {
  try {
   
    toast.loading("Please wait...")
    const response = yield call(postLeadCovertContactAPI,lead)
      toast.dismiss();
      toast.success("Lead Converted To Contact");

  }
  catch (error) {

    yield put(convertLeadIntoContactFail())
  }
}


function* leadSaga() {
  yield takeEvery(CREATE_LEAD, submitNewLead)
  yield takeEvery(GET_LEADS, fetchAllLeads)
  yield takeEvery(GET_LEAD_DETAIL, fetchLeadDetail)
  yield takeEvery(UPDATE_LEAD, onUpdateLead)
  yield takeEvery(ADD_LEAD_APP, submitLeadAPP)
  yield takeEvery(GET_LEAD_APP, fetchAppLead)
  yield takeEvery(ADD_LEAD_NOTE, addNoteLead)
  yield takeEvery(ADD_LEAD_LIST, addListLead)
  yield takeEvery(GET_LEAD_NOTE, fetchNoteLead)
  yield takeEvery(GET_LEAD_LIST, fetchListLead)
  yield takeEvery(POST_LEAD_FOLLOW, submitFollowLead)
  yield takeEvery(GET_LEAD_FOLLOW, fetchFollowLead)
  yield takeEvery(GET_LEAD_ALL_ACT, fetchAllActLead)
  yield takeEvery(ADD_LEAD_CALLLOG, submitLeadCalllog)
  yield takeEvery(GET_LEAD_CALLLOG, fetchCalllog)
  yield takeEvery(ADD_LEAD_EMAILLOG, submitLeadEmailLog)
  yield takeEvery(GET_LEAD_EMAILLOG, fetchEmailLog)
  yield takeEvery(UPDATE_LEAD_STAGE_BOARD,submitLeadStageBoard)
  yield takeEvery(UPDATE_LEAD_STATUS_BOARD,submitLeadStatusBoard)
  yield takeEvery(CONVERT_LEAD_INTO_CLIENT,submitLeadConvertClient)
  yield takeEvery(CONVERT_LEAD_INTO_CONTACT,submitLeadConvertContact)
}


export default leadSaga
