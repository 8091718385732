import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../helpers/azureConfig";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";


class ProjectsGallery extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {
     
      selectedFiles: [],
      image:''
      
    }
  
   
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (image) => {
    this.setState({ image: image });
    this.setState({ deleteModal: true });
  };

  handleDeleteGallery = () => {
    const { onDeleteGallery } = this.props;
    const { image } = this.state;
    //console.log(document);
    if (image.id !== undefined) {
      onDeleteGallery(image);
      this.setState({ deleteModal: false });
    }
  };
  handleAcceptedFiles = async (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map(file => {
      file.preview = URL.createObjectURL(file);
      file.formattedSize = this.formatBytes(file.size);
      return file;
    });

    this.setState({ selectedFiles: filesWithPreview });

    filesWithPreview.forEach(file => this.uploadFileToAzure(file));
  };

  uploadFileToAzure = async (file) => {
    const sasToken = azureConfig.sasToken
    const containerName = azureConfig.containerName;
    const accountName = azureConfig.storageAccountName;
   

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasToken}`
    );
    const fileName = `${Date.now()}_${file.name}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      await blockBlobClient.uploadBrowserData(file);
    const fileUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}`;
    const data = {
        'projectId': parseInt(this.props.match.params.id),
        'agentId': parseInt(localStorage.getItem('userId')),
        'projectGalleryTitle' : fileName,
        'projectGalleryLink' : fileUrl
    }
    this.props.addProjectGallery(data, this.props.history);
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage', error);
    }
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

 
  componentDidMount() {
    const { match: { params }, onGetProjectGallery,ongetProjectUUIDAction }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectGallery(params.id);
      ongetProjectUUIDAction(params.projectId);
    }else{
      this.props.history.push('/my-project')
    }
  }
 
  render() {
    const { images, projectData } = this.props;
    const { deleteModal } = this.state;
    //console.log(this.state.files);
      return (
      <React.Fragment>
            {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteGallery}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

<MetaTags>
  <title>Campaigns | Realty Space</title>
</MetaTags>
<Container fluid className="ps-0 ">
      <Row>
          <Col sm='2' className="pe-0" >
          <ProjectSidebar/>
          </Col>
      <Col sm='10' className="ps-0">
      <Row className="project-header">
        <Col sm="7">
      <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Gallery")} />
      </Col>
      <Col sm="4">
      <h2 className="">{this.props.t( projectData.projectName)}</h2>
      </Col>
      </Row>
      <SetupMenu />
            <Card>
                  <CardBody>

                  <Form>
                  <Dropzone onDrop={this.handleAcceptedFiles}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {this.state.selectedFiles.map((f, i) => (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + '-file'}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          ))}
        </div>
                    </Form>
                      </CardBody>
                    
                      </Card>

                      <Card>
                  <CardBody>
                    <CardTitle className="h4">Gallery</CardTitle>
                    <div className="row">
                    {map(images, (image, imgkey) => (
                    <div className="col-sm-1 mb20">
                      <img
                          src={image.projectGalleryLink}
                          className=""
                          alt=""
                          style={{width:225, maxWidth:"100%"}}
                          />

                          <div className="delbtn"><Link to="#" onClick={() => this.onClickDelete(image)} className="btn-gdelete"><i className="mdi mdi-delete-forever"></i></Link></div>
                    </div>
                    ))}
                    </div>
                   
        
                  
                    
                  </CardBody>
                </Card>
                        </Col>
                        </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsGallery.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match:PropTypes.object,
  addProjectGallery:PropTypes.func,
  images:PropTypes.array,
  onDeleteGallery:PropTypes.func,
  onGetProjectGallery:PropTypes.func,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  loading:PropTypes.object
}

const mapStateToProps = ({ Project }) => ({
  images: Project.images,
  projectData: Project.projectData,
  loading:Project.loading
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectGallery:(projectId) => dispatch(getProjectGallary(projectId)),
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsGallery)))