import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import { getLeadType as onGetLeadType } from 'store/actions';

const LeadTypeOption = () => {
    const dispatch = useDispatch();
    const { leadtypes } = useSelector(state => ({
        leadtypes: state.AppoinmentObject.leadtypes,
      }));
      useEffect(() => {
        dispatch(onGetLeadType(localStorage.getItem('userId')));
    }, [dispatch, onGetLeadType]);
  
    return (
      <FormGroup className="mb-3">
        <Label htmlFor="leadTypeId">Type</Label>
        <Field as="select" name="leadTypeId" className="form-control">
          <option value="">Select</option>
          {leadtypes.map((option) => (
            <option key={option.id} value={option.id}>
              {option.leadStatusTitle}
            </option>
          ))}
        </Field>
        <ErrorMessage name="leadTypeId" component="div" className="invalid-feedback" />
      </FormGroup>
    );
  };  

export default LeadTypeOption;
