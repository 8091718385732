export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR"

export const GET_ALL_PROJECT = "GET_ALL_PROJECT"
export const GET_ALL_PROJECT_SUCCESS = "GET_ALL_PROJECT_SUCCESS"
export const GET_ALL_PROJECT_FAIL = "GET_ALL_PROJECT_FAIL"


export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"





export const GET_PROJECT_TYPE = "GET_PROJECT_TYPE"
export const GET_PROJECT_TYPE_SUCCESS = "GET_PROJECT_TYPE_SUCCESS"
export const GET_PROJECT_TYPE_FAIL = "GET_PROJECT_TYPE_FAIL"




export const ADD_PROJECT_FEATURE = "ADD_PROJECT_FEATURE"
export const ADD_PROJECT_FEATURE_SUCCESS = "ADD_PROJECT_FEATURE_SUCCESS"
export const ADD_PROJECT_FEATURE_ERROR = "ADD_PROJECT_FEATURE_ERROR"


export const GET_PROJECT_FEATURE = "GET_PROJECT_FEATURE"
export const GET_PROJECT_FEATURE_SUCCESS = "GET_PROJECT_FEATURE_SUCCESS"
export const GET_PROJECT_FEATURE_ERROR = "GET_PROJECT_FEATURE_ERROR"

export const UPDATE_PROJECT_FEATURE = "UPDATE_PROJECT_FEATURE"
export const UPDATE_PROJECT_FEATURE_SUCCESS = "UPDATE_PROJECT_FEATURE_SUCCESS"
export const UPDATE_PROJECT_FEATURE_ERROR = "UPDATE_PROJECT_FEATURE"


export const UPDATE_PROJECT_FEATURE_STANDARD = "UPDATE_PROJECT_FEATURE_STANDARD"
export const UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS = "UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS"
export const UPDATE_PROJECT_FEATURE_STANDARD_ERROR = "UPDATE_PROJECT_FEATURE_STANDARD_ERROR"

export const DELETE_PROJECT_FEATURE = "DELETE_PROJECT_FEATURE"
export const DELETE_PROJECT_FEATURE_SUCCESS = "DELETE_PROJECT_FEATURE_SUCCESS"
export const DELETE_PROJECT_FEATURE_ERROR = "DELETE_PROJECT_FEATURE_ERROR"


export const ADD_PROJECT_CONTACT = "ADD_PROJECT_CONTACT"
export const ADD_PROJECT_CONTACT_SUCCESS = "ADD_PROJECT_CONTACT_SUCCESS"
export const ADD_PROJECT_CONTACT_ERROR = "ADD_PROJECT_CONTACT_ERROR"

export const GET_PROJECT_CONTACT = "GET_PROJECT_CONTACT"
export const GET_PROJECT_CONTACT_SUCCESS = "GET_PROJECT_CONTACT_SUCCESS"
export const GET_PROJECT_CONTACT_FAIL = "GET_PROJECT_CONTACT_FAIL"

export const UPDATE_PROJECT_CONTACT = "UPDATE_PROJECT_CONTACT"
export const UPDATE_PROJECT_CONTACT_SUCCESS = "UPDATE_PROJECT_CONTACT_SUCCESS"
export const UPDATE_PROJECT_CONTACT_FAIL = "UPDATE_PROJECT_CONTACT_FAIL"

export const DELETE_PROJECT_CONTACT = "DELETE_PROJECT_CONTACT"
export const DELETE_PROJECT_CONTACT_SUCCESS = "DELETE_PROJECT_CONTACT_SUCCESS"
export const DELETE_PROJECT_CONTACT_FAIL = "DELETE_PROJECT_CONTACT_FAIL"

export const ADD_PROJECT_MEDIA = "ADD_PROJECT_MEDIA"
export const ADD_PROJECT_MEDIA_SUCCESS = "ADD_PROJECT_MEDIA_SUCCESS"
export const ADD_PROJECT_MEDIA_ERROR = "ADD_PROJECT_MEDIA_ERROR"


export const GET_PROJECT_MEDIA = "GET_PROJECT_MEDIA"
export const GET_PROJECT_MEDIA_SUCCESS = "GET_PROJECT_MEDIA_SUCCESS"
export const GET_PROJECT_MEDIA_ERROR = "GET_PROJECT_MEDIA_ERROR"



export const DELETE_PROJECT_MEDIA = "DELETE_PROJECT_MEDIA"
export const DELETE_PROJECT_MEDIA_SUCCESS = "DELETE_PROJECT_MEDIA_SUCCESS"
export const DELETE_PROJECT_MEDIA_FAIL = "DELETE_PROJECT_MEDIA_FAIL"



export const ADD_PROJECT_LINK = "ADD_PROJECT_LINK"
export const ADD_PROJECT_LINK_SUCCESS = "ADD_PROJECT_LINK_SUCCESS"
export const ADD_PROJECT_LINK_ERROR = "ADD_PROJECT_LINK_ERROR"


export const GET_PROJECT_LINK = "GET_PROJECT_LINK"
export const GET_PROJECT_LINK_SUCCESS = "GET_PROJECT_LINK_SUCCESS"
export const GET_PROJECT_LINK_ERROR = "GET_PROJECT_LINK_ERROR"



export const DELETE_PROJECT_LINK = "DELETE_PROJECT_LINK"
export const DELETE_PROJECT_LINK_SUCCESS = "DELETE_PROJECT_LINK_SUCCESS"
export const DELETE_PROJECT_LINK_FAIL = "DELETE_PROJECT_LINK_FAIL"

export const UPDATE_PROJECT_LINK = "UPDATE_PROJECT_LINK"
export const UPDATE_PROJECT_LINK_SUCCESS = "UPDATE_PROJECT_LINK_SUCCESS"
export const UPDATE_PROJECT_LINK_FAIL = "UPDATE_PROJECT_LINK_FAIL"


export const ADD_PROJECT_GALLERY = "ADD_PROJECT_GALLERY"
export const ADD_PROJECT_GALLERY_SUCCESS = "ADD_PROJECT_GALLERY_SUCCESS"
export const ADD_PROJECT_GALLERY_ERROR = "ADD_PROJECT_GALLERY_ERROR"


export const GET_PROJECT_GALLERY = "GET_PROJECT_GALLERY"
export const GET_PROJECT_GALLERY_SUCCESS = "GET_PROJECT_GALLERY_SUCCESS"
export const GET_PROJECT_GALLERY_ERROR = "GET_PROJECT_GALLERY_ERROR"



export const DELETE_PROJECT_GALLERY = "DELETE_PROJECT_LINK"
export const DELETE_PROJECT_GALLERY_SUCCESS = "DELETE_PROJECT_LINK_SUCCESS"
export const DELETE_PROJECT_GALLERY_FAIL = "DELETE_PROJECT_LINK_FAIL"



export const ADD_PROJECT_MARKET = "ADD_PROJECT_MARKET"
export const ADD_PROJECT_MARKET_SUCCESS = "ADD_PROJECT_MARKET_SUCCESS"
export const ADD_PROJECT_MARKET_ERROR = "ADD_PROJECT_MARKET_ERROR"

export const GET_PROJECT_MARKET = "GET_PROJECT_MARKET"
export const GET_PROJECT_MARKET_SUCCESS = "GET_PROJECT_MARKET_SUCCESS"
export const GET_PROJECT_MARKET_ERROR = "GET_PROJECT_MARKET_ERROR"

export const UPDATE_PROJECT_MARKET = "UPDATE_PROJECT_MARKET"
export const UPDATE_PROJECT_MARKET_SUCCESS = "UPDATE_PROJECT_MARKET_SUCCESS"
export const UPDATE_PROJECT_MARKET_ERROR = "UPDATE_PROJECT_MARKET_ERROR"

export const DELETE_PROJECT_MARKET = "DELETE_PROJECT_MARKET"
export const DELETE_PROJECT_MARKET_SUCCESS = "DELETE_PROJECT_MARKET_SUCCESS"
export const DELETE_PROJECT_MARKET_FAIL = "DELETE_PROJECT_MARKET_FAIL"


export const GET_ALL_PROJECT_UUID = "GET_ALL_PROJECT_UUID"
export const GET_ALL_PROJECT_UUID_SUCCESS = "GET_ALL_PROJECT_UUID_SUCCESS"
export const GET_ALL_PROJECT_UUID_FAIL = "GET_ALL_PROJECT_UUID_FAIL"


export const GET_ALL_PROJECT_STATUS = "GET_ALL_PROJECT_STATUS"
export const GET_ALL_PROJECT_STATUS_SUCCESS = "GET_ALL_PROJECT_STATUS_SUCCESS"
export const GET_ALL_PROJECT_STATUS_FAIL = "GET_ALL_PROJECT_STATUS_FAIL"


//Search Field



export const GET_ALL_PROJECT_SEARCH = "GET_ALL_PROJECT_SEARCH"
export const GET_ALL_PROJECT_SEARCH_SUCCESS = "GET_ALL_PROJECT_SEARCH_SUCCESS"
export const GET_ALL_PROJECT_SEARCH_FAIL = "GET_ALL_PROJECT_STATUS_FAIL"



//Update Status

export const UPDATE_MPROJECT_STAUS = "UPDATE_PROJECT_STAUS"
export const UPDATE_MPROJECT_STAUS_SUCCESS = "UPDATE_PROJECT_STAUS_SUCCESS"
export const UPDATE_MPROJECT_STAUS_FAIL = "UPDATE_PROJECT_STAUS_FAIL"


//Project Management


export const ADD_PROJECT_BUDGET_ALLOCATION = "ADD_PROJECT_BUDGET_ALLOCATION"
export const ADD_PROJECT_BUDGET_ALLOCATION_SUCCESS = "ADD_PROJECT_BUDGET_ALLOCATION_SUCCESS"
export const ADD_PROJECT_BUDGET_ALLOCATION_ERROR = "ADD_PROJECT_BUDGET_ALLOCATION_ERROR"


export const GET_PROJECT_BUDGET_ALLOCATION = "GET_PROJECT_BUDGET_ALLOCATION"
export const GET_PROJECT_BUDGET_ALLOCATION_SUCCESS = "GET_PROJECT_BUDGET_ALLOCATION_SUCCESS"
export const GET_PROJECT_BUDGET_ALLOCATION_ERROR = "GET_PROJECT_BUDGET_ALLOCATION_ERROR"

export const UPDATE_PROJECT_BUDGET_ALLOCATION = "UPDATE_PROJECT_BUDGET_ALLOCATION"
export const UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS = "UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS"
export const UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR = "UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR"


export const DELETE_PROJECT_BUDGET_ALLOCATION = "DELETE_PROJECT_BUDGET_ALLOCATION"
export const DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS = "DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS"
export const DELETE_PROJECT_BUDGET_ALLOCATION_ERROR = "DELETE_PROJECT_BUDGET_ALLOCATION_ERROR"


export const ADD_PROJECT_BUDGET_SPENT = "ADD_PROJECT_BUDGET_SPENT"
export const ADD_PROJECT_BUDGET_SPENT_SUCCESS = "ADD_PROJECT_BUDGET_SPENT_SUCCESS"
export const ADD_PROJECT_BUDGET_SPENT_ERROR = "ADD_PROJECT_BUDGET_SPENT_ERROR"

export const GET_PROJECT_BUDGET_SPENT = "GET_PROJECT_BUDGET_SPENT"
export const GET_PROJECT_BUDGET_SPENT_SUCCESS = "GET_PROJECT_BUDGET_SPENT_SUCCESS"
export const GET_PROJECT_BUDGET_SPENT_ERROR = "GET_PROJECT_BUDGET_SPENT_ERROR"

export const UPDATE_PROJECT_BUDGET_SPENT = "UPDATE_PROJECT_BUDGET_SPENT"
export const UPDATE_PROJECT_BUDGET_SPENT_SUCCESS = "UPDATE_PROJECT_BUDGET_SPENT_SUCCESS"
export const UPDATE_PROJECT_BUDGET_SPENT_ERROR = "UPDATE_PROJECT_BUDGET_SPENT_ERROR"

export const DELETE_PROJECT_BUDGET_SPENT = "DELETE_PROJECT_BUDGET_SPENT"
export const DELETE_PROJECT_BUDGET_SPENT_SUCCESS = "DELETE_PROJECT_BUDGET_SPENT_SUCCESS"
export const DELETE_PROJECT_BUDGET_SPENT_ERROR = "DELETE_PROJECT_BUDGET_SPENT_ERROR"


export const ADD_PROJECT_CAMP_LEAD = "ADD_PROJECT_CAMP_LEAD"
export const ADD_PROJECT_CAMP_LEAD_SUCCESS = "ADD_PROJECT_CAMP_LEAD_SUCCESS"
export const ADD_PROJECT_CAMP_LEAD_ERROR = "ADD_PROJECT_CAMP_LEAD_ERROR"


export const GET_PROJECT_CAMP_LEAD = "GET_PROJECT_CAMP_LEAD"
export const GET_PROJECT_CAMP_LEAD_SUCCESS = "GET_PROJECT_CAMP_LEAD_SUCCESS"
export const GET_PROJECT_CAMP_LEAD_ERROR = "GET_PROJECT_CAMP_LEAD_ERROR"

export const DELETE_PROJECT_CAMP_LEAD = "DELETE_PROJECT_CAMP_LEAD"
export const DELETE_PROJECT_CAMP_LEAD_SUCCESS = "DELETE_PROJECT_CAMP_LEAD_SUCCESS"
export const DELETE_PROJECT_CAMP_LEAD_ERROR = "DELETE_PROJECT_CAMP_LEAD_ERROR"



export const GET_PROJECT_FEATURE_STANDARD = "GET_PROJECT_FEATURE_STANDARD"
export const GET_PROJECT_FEATURE_STANDARD_SUCCESS = "GET_PROJECT_FEATURE_STANDARD_SUCCESS"
export const GET_PROJECT_FEATURE_STANDARD_ERROR = "GET_PROJECT_FEATURE_STANDARD_ERROR"





export const ADD_PROJECT_EMAIL_COMP_FILTER = "ADD_PROJECT_EMAIL_COMP_FILTER"
export const ADD_PROJECT_EMAIL_COMP_FILTER_SUCCESS = "ADD_PROJECT_EMAIL_COMP_FILTER_SUCCESS"
export const ADD_PROJECT_EMAIL_COMP_FILTER_ERROR = "ADD_PROJECT_EMAIL_COMP_FILTER_ERROR"


export const ADD_SETTING_WORKSHEET = "ADD_SETTING_WORKSHEET"
export const ADD_SETTING_WORKSHEET_SUCCESS = "ADD_SETTING_WORKSHEET_SUCCESS"
export const ADD_SETTING_WORKSHEET_ERROR = "ADD_SETTING_WORKSHEET_ERROR"


export const GET_SETTING_WORKSHEET = "GET_SETTING_WORKSHEET"
export const GET_SETTING_WORKSHEET_SUCCESS = "GET_SETTING_WORKSHEET_SUCCESS"
export const GET_SETTING_WORKSHEET_ERROR = "GET_SETTING_WORKSHEET_ERROR"

export const DELETE_SETTING_WORKSHEET = "DELETE_SETTING_WORKSHEET"
export const DELETE_SETTING_WORKSHEET_SUCCESS = "DELETE_SETTING_WORKSHEET_SUCCESS"
export const DELETE_SETTING_WORKSHEET_ERROR = "DELETE_SETTING_WORKSHEET_ERROR"


export const SEND_LEAD_WORKSHEET = "SEND_LEAD_WORKSHEET"
export const SEND_LEAD_WORKSHEET_SUCCESS = "SEND_LEAD_WORKSHEET_SUCCESS"
export const SEND_LEAD_WORKSHEET_ERROR = "SEND_LEAD_WORKSHEET_ERROR"


export const GET_ALL_PROJECT_LEAD = "GET_ALL_PROJECT_LEAD"
export const GET_ALL_PROJECT_LEAD_SUCCESS = "GET_ALL_PROJECT_LEAD_SUCCESS"
export const GET_ALL_PROJECT_LEAD_ERROR = "GET_ALL_PROJECT_LEAD_ERROR"


export const GET_ALL_PROJECT_CLIENT = "GET_ALL_PROJECT_CLIENT"
export const GET_ALL_PROJECT_CLIENT_SUCCESS = "GET_ALL_PROJECT_CLIENT_SUCCESS"
export const GET_ALL_PROJECT_CLIENT_ERROR = "GET_ALL_PROJECT_CLIENT_ERROR"

//Open Download Price
export const ADD_PROJECT_DOWNLOAD_PRICE_FILE = "ADD_PROJECT_DOWNLOAD_PRICE_FILE"
export const ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS = "ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS"
export const ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR = "ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR"

export const ADD_PROJECT_DOWNLOAD_PRICE = "ADD_PROJECT_DOWNLOAD_PRICE"
export const ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS = "ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS"
export const ADD_PROJECT_DOWNLOAD_PRICE_ERROR = "ADD_PROJECT_DOWNLOAD_PRICE_ERROR"

export const GET_ALL_PROJECT_DOWNLOAD_PRICE = "GET_ALL_PROJECT_DOWNLOAD_PRICE"
export const GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS = "GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS"
export const GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR = "GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR"


export const GET_PROJECT_DOWNLOAD_PRICE = "GET_PROJECT_DOWNLOAD_PRICE"
export const GET_PROJECT_DOWNLOAD_PRICE_SUCCESS = "GET_PROJECT_DOWNLOAD_PRICE_SUCCESS"
export const GET_PROJECT_DOWNLOAD_PRICE_ERROR = "GET_PROJECT_DOWNLOAD_PRICE_ERROR"



export const DELETE_PROJECT_DOWNLOAD_PRICE = "DELETE_PROJECT_DOWNLOAD_PRICE"
export const DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS = "DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS"
export const DELETE_PROJECT_DOWNLOAD_PRICE_ERROR = "DELETE_PROJECT_DOWNLOAD_PRICE_ERROR"


export const UPDATE_PROJECT_DOWNLOAD_PRICE = "UPDATE_PROJECT_DOWNLOAD_PRICE"
export const UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS = "UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS"
export const UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR = "UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR"



//Open House file
export const ADD_PROJECT_OPEN_HOUSE_FILE = "ADD_PROJECT_OPEN_HOUSE_FILE"
export const ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS = "ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS"
export const ADD_PROJECT_OPEN_HOUSE_FILE_ERROR = "ADD_PROJECT_OPEN_HOUSE_FILE_ERROR"


export const GET_ALL_PROJECT_OPEN_HOUSE_FILE = "GET_ALL_PROJECT_OPEN_HOUSE_FILE"
export const GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS = "GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS"
export const GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR = "GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR"

export const ADD_PROJECT_OPEN_HOUSE = "ADD_PROJECT_OPEN_HOUSE"
export const ADD_PROJECT_OPEN_HOUSE_SUCCESS = "ADD_PROJECT_OPEN_HOUSE_SUCCESS"
export const ADD_PROJECT_OPEN_HOUSE_ERROR = "ADD_PROJECT_OPEN_HOUSE_ERROR"

export const GET_ALL_PROJECT_OPEN_HOUSE = "GET_ALL_PROJECT_OPEN_HOUSE"
export const GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS = "GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS"
export const GET_ALL_PROJECT_OPEN_HOUSE_ERROR = "GET_ALL_PROJECT_OPEN_HOUSE_ERROR"


export const GET_PROJECT_OPEN_HOUSE = "GET_PROJECT_OPEN_HOUSE"
export const GET_PROJECT_OPEN_HOUSE_SUCCESS = "GET_PROJECT_OPEN_HOUSE_SUCCESS"
export const GET_PROJECT_OPEN_HOUSE_ERROR = "GET_PROJECT_OPEN_HOUSE_ERROR"



export const DELETE_PROJECT_OPEN_HOUSE = "DELETE_PROJECT_OPEN_HOUSE"
export const DELETE_PROJECT_OPEN_HOUSE_SUCCESS = "DELETE_PROJECT_OPEN_HOUSE_SUCCESS"
export const DELETE_PROJECT_OPEN_HOUSE_ERROR = "DELETE_PROJECT_OPEN_HOUSE_ERROR"


export const UPDATE_PROJECT_OPEN_HOUSE = "UPDATE_PROJECT_OPEN_HOUSE"
export const UPDATE_PROJECT_OPEN_HOUSE_SUCCESS = "UPDATE_PROJECT_OPEN_HOUSE_SUCCESS"
export const UPDATE_PROJECT_OPEN_HOUSE_ERROR = "UPDATE_PROJECT_OPEN_HOUSE_ERROR"


export const GET_PROJECT_OPEN_HOUSE_FILE = "GET_PROJECT_OPEN_HOUSE_FILE"
export const GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS = "GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS"
export const GET_PROJECT_OPEN_HOUSE_FILE_ERROR = "GET_PROJECT_OPEN_HOUSE_FILE_ERROR"


export const DELETE_PROJECT_OPEN_HOUSE_FILE = "DELETE_PROJECT_OPEN_HOUSE_FILE"
export const DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS = "DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS"
export const DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR = "DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR"



export const GET_PROJECT_DOWNLOAD_PRICE_FILE = "GET_PROJECT_DOWNLOAD_PRICE_FILE"
export const GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS = "GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS"
export const GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR = "GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR"


export const DELETE_PROJECT_DOWNLOAD_PRICE_FILE = "DELETE_PROJECT_DOWNLOAD_PRICE_FILE"
export const DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS = "DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS"
export const DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR = "DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR"



export const GET_PROJECT_WORKSHEET_LEAD = "GET_PROJECT_WORKSHEET_LEAD"
export const GET_PROJECT_WORKSHEET_LEAD_SUCCESS = "GET_PROJECT_WORKSHEET_LEAD_SUCCESS"
export const GET_PROJECT_WORKSHEET_LEAD_ERROR = "GET_PROJECT_WORKSHEET_LEAD_ERROR"


export const GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL = "GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL"
export const GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS = "GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS"
export const GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR = "GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR"

export const GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL = "GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL"
export const GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS = "GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS"
export const GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR = "GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR"


export const GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL = "GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL"
export const GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS = "GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS"
export const GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR = "GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR"



export const GET_PROJECT_CLIENT_LEAD = "GET_PROJECT_CLIENT_LEAD"
export const GET_PROJECT_CLIENT_LEAD_SUCCESS = "GET_PROJECT_CLIENT_LEAD_SUCCESS"
export const GET_PROJECT_CLIENT_LEAD_ERROR = "GET_PROJECT_CLIENT_LEAD_ERROR"




export const POST_PROJECT_INVITE = "POST_PROJECT_INVITE"
export const POST_PROJECT_INVITE_SUCCESS = "POST_PROJECT_INVITE_SUCCESS"
export const POST_PROJECT_INVITE_ERROR = "POST_PROJECT_INVITE_ERROR"

export const GET_PROJECT_INVITE = "GET_PROJECT_INVITE"
export const GET_PROJECT_INVITE_SUCCESS = "GET_PROJECT_INVITE_SUCCESS"
export const GET_PROJECT_INVITE_ERROR = "GET_PROJECT_INVITE_ERROR"

export const GET_PROJECT_INVITE_DETAIL = "GET_PROJECT_INVITE_DETAIL"
export const GET_PROJECT_INVITE_DETAIL_SUCCESS = "GET_PROJECT_INVITE_DETAIL_SUCCESS"
export const GET_PROJECT_INVITE_DETAIL_ERROR = "GET_PROJECT_INVITE_DETAIL_ERROR"

export const EDIT_PROJECT_INVITE = "EDIT_PROJECT_INVITE"
export const EDIT_PROJECT_INVITE_SUCCESS = "EDIT_PROJECT_INVITE_SUCCESS"
export const EDIT_PROJECT_INVITE_ERROR = "EDIT_PROJECT_INVITE_ERROR"

export const DELETE_PROJECT_INVITE = "DELETE_PROJECT_INVITE"
export const DELETE_PROJECT_INVITE_SUCCESS = "DELETE_PROJECT_INVITE_SUCCESS"
export const DELETE_PROJECT_INVITE_ERROR = "DELETE_PROJECT_INVITE_ERROR"

export const VIEW_PROJECT_INVITE_ATTENDEES = "VIEW_PROJECT_INVITE_ATTENDEES"
export const VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS = "VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS"
export const VIEW_PROJECT_INVITE_ATTENDEES_ERROR = "VIEW_PROJECT_INVITE_ATTENDEES_ERROR"

export const UPDATE_PROJECT_WORKSHEET_UNIT = "UPDATE_PROJECT_WORKSHEET_UNIT"
export const UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS = "UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS"
export const UPDATE_PROJECT_WORKSHEET_UNIT_ERROR = "UPDATE_PROJECT_WORKSHEET_UNIT_ERROR"


export const GET_PROJECT_WORKSHEET_UNIT_INFO = "GET_PROJECT_WORKSHEET_UNIT_INFO"
export const GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS = "GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS"
export const GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR = "GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR"


export const GET_PROJECT_LEAD_BY_WORKSHEET_STATUS = "GET_PROJECT_LEAD_BY_WORKSHEET_STATUS"
export const GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS = "GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS"
export const GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR = "GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR"

