import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "@fullcalendar/bootstrap/main.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Calendar extends Component {
  render() {
    return (
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Calendar" breadcrumbItem="Full Calendar" />
          <Row>
            <Col className="col-12">
            <Card>
              <CardBody>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  BootstrapTheme,
                ]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay",
                }}
                events={[
                  { title: "Meeting", start: "2024-08-01" },
                  { title: "Conference", start: "2024-08-05", end: "2024-08-07" }
                ]}
                editable={true}
                droppable={true}
                selectable={true}
              />
              </CardBody>
            </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Calendar;
