import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Table, FormGroup } from "reactstrap";
import { isMap, map } from "lodash"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { API_URL } from 'helpers/app_url';

class MapImportClient extends Component {
  constructor(props) {
    super(props);

    const initialMappings = this.initializeMappings(props.importLogs.actualColumnSchema);
    this.state = {
      columnMappings: initialMappings,
      importExcelTableList: this.constructImportExcelTableList(props.importLogs.actualColumnSchema, initialMappings)
    };
  }

  validationSchema = Yup.object().shape({
    columnMappings: Yup.object().shape({
      ...this.props.importLogs.actualColumnSchema.reduce((acc, column) => {
        if (column.mandatory) {
          acc[column.id] = Yup.string().required('This ' + column.displayName + ' is required');
        }
        return acc;
      }, {})
    })
  });

  initializeMappings = (actualColumnSchema) => {
    const mappings = {};
    actualColumnSchema.forEach(column => {
      if (column.excelColumnId) {
        mappings[column.id] = column.excelColumnId;
      }
    });
    return mappings;
  }

  handleDropdownChange = (event, setFieldValue, columnId) => {
    const value = event.target.value;

    //setFieldValue(`columnMappings.${columnId}`, value);
    setFieldValue(`columnMappings.${columnId}`, value, false);
    this.setState(prevState => {
      const newMappings = {
        ...prevState.columnMappings,
        [columnId]: value
      };
      return {
        columnMappings: newMappings,
        importExcelTableList: this.constructImportExcelTableList(this.props.importLogs.actualColumnSchema, newMappings)
      };
    });
  }

  constructImportExcelTableList = (actualColumnSchema, columnMappings) => {
    return actualColumnSchema.map(column => {
      const excelColumnId = columnMappings[column.id] || null;
      return {
        ...column,
        excelColumnId,
        excelColumnName: excelColumnId ? this.getExcelColumnName(excelColumnId) : null,
        //isMap: column.id.toString() === excelColumnId
        isMap: excelColumnId !== null

      };
    });
  }

  getExcelColumnName = (columnId) => {
    const { importLogs } = this.props;
    const excelColumn = importLogs.excelColumnSchema.find(col => col.columnId === columnId);
    return excelColumn ? excelColumn.columnName : null;
  }

  isNextButtonEnabled = () => {
    const { columnMappings } = this.state;
    return Object.keys(columnMappings).length > 0;
  }

  handleSubmit = (values, { setSubmitting }) => {
    const { importExcelTableList } = this.state;
    const { importLogs, onNext,onSaveData,onErrorData } = this.props;

    const payload = {
      importLogId: importLogs.id,
      importExcelTableList
    };

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    axios.post(`${API_URL}ImportLog/ValidateExcelFile`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        onSaveData(importExcelTableList);
        onErrorData(response.data);
        localStorage.setItem("MapImportClient", JSON.stringify(payload));
        onNext();
      })
      .catch(error => {
        console.error('API error:', error);
      });



  }

  render() {
    const { columnMappings, importExcelTableList } = this.state;
    const { importLogs, formikRef } = this.props;

    return (
      <React.Fragment>
        <Formik
          innerRef={formikRef}
          initialValues={{ columnMappings }}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}

        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="upload-file-container">
                <h2 className="text-center">Map columns in your file to client properties</h2>
                <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Our Database Field</th>
                        <th>Map to Field</th>
                      </tr>
                    </thead>
                    <tbody>
                      {importLogs.actualColumnSchema.map((column, index) => (
                        <tr key={index} id={`row_` + column.id}>
                          <th scope="row">
                            {column.displayName} {column.mandatory ? <span className="required">*</span> : ''}
                            <small style={{ display: 'block' }}>{column.description}</small>
                          </th>
                          <td>
                            <FormGroup>
                              <Field
                                as="select"
                                className={`form-control ${errors.columnMappings?.[column.id] && touched.columnMappings?.[column.id] ? 'is-invalid' : ''}`}
                                name={`columnMappings.${column.id}`}
                                onChange={(e) => this.handleDropdownChange(e, setFieldValue, column.id)}
                              >
                                <option value="">Select</option>
                                {importLogs.excelColumnSchema.map((excelData, index) => (
                                  <option key={index} value={excelData.columnId}>{excelData.columnName}</option>
                                ))}
                              </Field>
                              <ErrorMessage name={`columnMappings.${column.id}`} component="div" style={{ color: 'red' }} />
                            </FormGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

              </div>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

MapImportClient.propTypes = {
  t: PropTypes.any,
  importLogs: PropTypes.array,
  formikRef: PropTypes.object,
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onSaveData: PropTypes.func,
  onErrorData: PropTypes.func
};

export default connect()(withTranslation()(MapImportClient));