import React ,{Component}from "react"
import {  Container, Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import AllContact from "./AllContact";
import FilterMenuContact from "./filter-menu/filter-menu"
import RsAdNew from "components/buttons/Adnew";
import CreateContact from "./forms/contact-create"
  class AllContacts extends Component {
    constructor(props) {
      super(props)
      this.state = {
       
    }
    this.toggleAddNew = this.toggleAddNew.bind(this);
    }
    toggleAddNew() {
      this.setState({ isAddNew: !this.state.isAddNew });
      
    }
render() {
  return (
    <React.Fragment>
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <Row className="project-header">
            <Col xl="7" >
             <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("All Contacts")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
            <RsAdNew to="/contact/import-contact" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew>
            <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
           </div>
            </Col>
            </Row>  
            
            <FilterMenuContact />
             
      <Row>
        <Col lg="12">
      
        <AllContact /> 
      
      </Col>
      </Row>
      </Container>
      </div>

      <Offcanvas
        isOpen={this.state.isAddNew}
        direction="end"
        toggle={this.toggleAddNew}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={this.toggleAddNew}>
          New Contact
        </OffcanvasHeader>
        <OffcanvasBody>
            <CreateContact toggleAddNew={this.toggleAddNew} />
        </OffcanvasBody>
        </Offcanvas>
    </React.Fragment>
  )
}
}
AllContacts.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(AllContacts))

