import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class DashboardAllpiechart extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      series: [10, 10, 40, 25,15],
      options: {
        labels: ["Reviews", "Referrals", "Newsletter", "Surveys","Seller Pages"],
        colors:['#ffde59', '#ff66c4','#7ed957','#8c52ff','#5ce1e6'],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height="320"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default DashboardAllpiechart
