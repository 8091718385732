import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('mousemove', this.resetTimer);
    window.addEventListener('keypress', this.resetTimer);
    window.addEventListener('scroll', this.resetTimer);

    this.inactivityTime();
  }

  inactivityTime = () => {
    this.logoutTimer = setTimeout(() => {
      this.handleLogout();
    }, 900000); 
  }

  resetTimer = () => {
    clearTimeout(this.logoutTimer);
    this.inactivityTime(); 
  }

  componentWillUnmount() {
  
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('mousemove', this.resetTimer);
    window.removeEventListener('keypress', this.resetTimer);
    window.removeEventListener('scroll', this.resetTimer);
    clearTimeout(this.logoutTimer);
  }

  handleBeforeUnload = (e) => {
    
    this.clearLocalStorage();
    clearTimeout(this.logoutTimer);
  }

  handleLogout = () => {
   
    this.clearLocalStorage();
    clearTimeout(this.logoutTimer);
    window.location.href = "/login";
  }

  clearLocalStorage = () => {
    localStorage.removeItem('authUser');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem("userId");
    localStorage.removeItem('brokerId');
    localStorage.removeItem('userModule');
  }

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App);
