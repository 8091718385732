import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
import {  isEmpty, map, size } from "lodash";
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import { getClientDetail, addClientSellerShwoing, getClientSellerListing, getClientSellerShwoing, updateClientSellerShowing, deleteClientSellerShowing } from "../../store/clients/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import BreadcrumbsClient from "../../components/Common/BreadcrumbClient";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";

class ClientSellerShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showing:''
    }
    this.handleSellerShowingSubmit = this.handleSellerShowingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (showing) => {
    this.setState({ showing: showing });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }

  toggle2() {
    this.setState(prevState => ({
      isRight2: !prevState.isRight2
    }));
  }

  handleDeleteShowing = () => {
    const { onDeleteShowing } = this.props;
    const { showing } = this.state;
    
    if (showing.id !== undefined) {
      onDeleteShowing(showing);
      this.setState({ deleteModal: false });
    }
  };
  handleSellerShowingSubmit(value){
    //console.log(value);
    this.props.addClientSellerShwoing(value, this.props.history);
  }
  componentDidMount() {
    const {  match: { params }, onGetClientDetail, onGetClientSellerListing, onGetClientSellerShowing } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientSellerListing(params.id);
    onGetClientSellerShowing(params.id);
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { showings } = this.props;
  if (!isEmpty(showings) && size(prevProps.showings) !== size(showings)) {
    this.setState({ showings: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ showing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}

toggleRightCanvas2() {
  this.setState({ showing: "", isEdit: false, isRight2: !this.state.isRight2 });
  this.toggle2();
  console.log("asda")
}

toggleRightCanvas = arg => {
  const showing = arg;

  this.setState({
    showing: {
  
      id:showing.id,
      clientSellerShowingId: showing.clientSellerShowingId,
      clientId:showing.clientId,
      agentId: showing.agentId,
      clientListingId: showing.clientListingId,
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentName,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      feedback:showing.feedback,
      notificationMinutes: showing.notificationMinutes,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle();
};

toggleRightCanvas2 = arg => {
  const showing = arg;

  this.setState({
    showing: {
  
      id:showing.id,
      clientSellerShowingId: showing.clientSellerShowingId,
      clientId:showing.clientId,
      agentId: showing.agentId,
      clientListingId: showing.clientListingId,
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentName,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      feedback:showing.feedback,
      notificationMinutes: showing.notificationMinutes,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle2();
  console.log("sadasd")
};

  render() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const { listings, showings, clietDetail, onUpdateClientSellerShowing, loading } = this.props;
    const showing = this.state.showing;
    const { isEdit, deleteModal } = this.state;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteShowing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Showing")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientListingId: (this.state && this.state.clientListingId) || "",
                    clientListingId: (this.state && this.state.clientListingId) || "",
                    agentName: (this.state && this.state.agentName) || "",
                    agentOrganization: (this.state && this.state.agentOrganization) || "",
                    agentContact: (this.state && this.state.agentContact) || "",
                    agentEmail: (this.state && this.state.agentEmail) || "",
                    showingDate: new Date(),
                    startTime: new Date(),
                    endTime: edTime,
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id


                  }}
                  validationSchema={Yup.object().shape({
                    //clientListingId: Yup.string().required("This is Required"),
                    // agentName: Yup.string().required("This is Required"),
                    // agentOrganization: Yup.string().required("This is Required"),
                    // agentOrganization: Yup.string().required("This is Required"),
                    // agentContact: Yup.string().matches(
                    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    //   "Enter valid Contact number"
                    // ).required(
                    //   "Please Enter Contact Number"
                    // ),
                    // agentEmail: Yup.string().email("Must be a valid Email")
                    // .max(255)
                    // .required("Email is required")
                  })}

                  onSubmit={this.handleSellerShowingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card>
                        <CardBody>
                          <h5 className="card-title pheading">Add Seller Showing</h5>

                          <Row>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                   
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>

                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <h5 className="card-title">Seller Agent Detail (Optional)</h5>

                          <Row>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentName">
                                  {this.props.t("Agent Name")}
                                </Label>
                                <Field
                                  name="agentName"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentName && touched.agentName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentName"
                                />
                                <ErrorMessage
                                  name="agentName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentOrganization">
                                  {this.props.t("Agent Organization")}
                                </Label>
                                <Field
                                  name="agentOrganization"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentOrganization && touched.agentOrganization
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentOrganization"
                                />
                                <ErrorMessage
                                  name="agentOrganization"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentContact">
                                  {this.props.t("Agent Contact")}
                                </Label>
                                <Field
                                  name="agentContact"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentContact && touched.agentContact
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentContact"
                                />
                                <ErrorMessage
                                  name="agentContact"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentEmail">
                                  {this.props.t("Agent Email")}
                                </Label>
                                <Field
                                  name="agentEmail"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentEmail && touched.agentEmail
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentEmail"
                                />
                                <ErrorMessage
                                  name="agentEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentFeedback">
                                  {this.props.t("Feedback")}
                                </Label>
                                <textarea
                                  name="agentFeedback"
                                  onChange={handleChange}
                                  type="textarea"
                                  className={
                                    "form-control" +
                                    (errors.agentFeedback && touched.agentFeedback
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="addNote"
                                />
                                <ErrorMessage
                                  name="agentFeedback"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>


                          </Row> */}
                          <div className="clearfix">
                            <SaveButton />
                          </div>

                        </CardBody>
                      </Card>

                    </Form>
                  )}
                </Formik>

                <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Showing</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Listing</th>
								<th>Date Time</th>
                <th>Agent Name</th>
                <th>Agent Organization</th>
                <th>Agent Contact</th>
                <th>Agent Email</th>
                <th>Status </th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(showings, (showing, showkey) => (
							 <tr key={"_list_" + showkey}>
                  <th>{ showkey + 1 }</th>
                  <td>{showing.clientListingId}</td>
                  <td><Moment format="Do MMMM YYYY">{showing.showingDate}</Moment> / <Moment format="hh:mm A">{showing.startTime}</Moment>-<Moment format="hh:mm A">{showing.endTime}</Moment></td>
                  <td>{showing.agentName}</td>
                  <td>{showing.agentOrganization}</td>
                  <td>{showing.agentContact}</td>
                  <td>{showing.agentEmail}</td>
                  <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                      <DropdownItem onClick={() => this.toggleRightCanvas2(showing)} href="#">Offer Details</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>

               </tr>
              ))}
              <tr>
                <td>2</td>
                <td><a href="#">#M654433</a></td>
                <td>April 20th</td>
                <td>Jim Cook</td>
                <td>Top Realty</td>
                <td>416-895-6790</td>
                <td>Jim@toprealty.com</td>
                <td>
                  <select  className="form-control" >
                    <option value="">Awaiting Response</option>
                    <option value="">Not Interested</option>
                    <option value="">Offer Recieved</option>
                                
                </select>
                </td>
                <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                      <DropdownItem onClick={() => this.toggleRightCanvas2(showing)} href="#">Offer Details</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>
              </tr>

              <tr>
                <td>1</td>
                <td><a href="#">#E3454433</a></td>
                <td>May 20th</td>
                <td>Shelly</td>
                <td>Top Realty</td>
                <td>416-895-6790</td>
                <td>Shelly@prealty.com</td>
                <td>
                  <select  className="form-control" >
                    <option value="">Awaiting Response</option>
                    <option value="">Not Interested</option>
                    <option value="">Offer Recieved</option>
                                
                </select>
                </td>
                <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas2(showing)} href="#">Offer Details</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>
              </tr>

              <tr>
                <td>3</td>
                <td><a href="#">#N232343</a></td>
                <td>March 23th</td>
                <td>Jack Ryan</td>
                <td>Top Realty</td>
                <td>416-895-6790</td>
                <td>Jack@toprealty.com</td>
                <td>
                  <select  className="form-control" >
                    <option value="">Awaiting Response</option>
                    <option value="">Not Interested</option>
                    <option value="">Offer Recieved</option>
                                
                </select>
                </td>
                <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                      <DropdownItem onClick={() => this.toggleRightCanvas2(showing)} href="#">Offer Details</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>
              </tr>
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>                  

              </Col>

            </Row>
            </Col>
            </Row>
  <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:showing.id,
                    clientSellerShowingId: showing.clientSellerShowingId,
                    clientId:showing.clientId,
                    agentId: showing.agentId,
                    clientListingId: showing.clientListingId,
                    showingDate: showing.showingDate,
                    startTime: showing.startTime,
                    endTime: showing.endTime,
                    agentName: showing.agentName,
                    agentOrganization: showing.agentName,
                    agentContact: showing.agentContact,
                    agentEmail: showing.agentEmail,
                    feedback:showing.feedback,
                    notificationMinutes: showing.notificationMinutes,
                    isActive: true


                  }}
                  validationSchema={Yup.object().shape({
                    //clientListingId: Yup.string().required("This is Required"),
                    //agentName: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateShowing = {
                        id:values.id,
                        clientSellerShowingId: values.clientSellerShowingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        clientListingId: parseInt(values.clientListingId),
                        showingDate: values.showingDate,
                        startTime: values.startTime,
                        endTime: values.endTime,
                        agentName: values.agentName,
                        agentOrganization: values.agentName,
                        agentContact: values.agentContact,
                        agentEmail: values.agentEmail,
                        feedback:values.feedback,
                        notificationMinutes: values.notificationMinutes,
                        isActive: true
                      };

                      onUpdateClientSellerShowing(updateShowing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                          <h5 className="card-title">Showing Detail</h5>

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.startTime}
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.endTime}
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>

                       
                          <h5 className="card-title">Seller Agent Detail (Optional)</h5>

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentName">
                                  {this.props.t("Agent Name")}
                                </Label>
                                <Field
                                  name="agentName"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentName && touched.agentName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentName"
                                />
                                <ErrorMessage
                                  name="agentName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentOrganization">
                                  {this.props.t("Agent Organization")}
                                </Label>
                                <Field
                                  name="agentOrganization"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentOrganization && touched.agentOrganization
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentOrganization"
                                />
                                <ErrorMessage
                                  name="agentOrganization"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentContact">
                                  {this.props.t("Agent Contact")}
                                </Label>
                                <Field
                                  name="agentContact"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentContact && touched.agentContact
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentContact"
                                />
                                <ErrorMessage
                                  name="agentContact"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentEmail">
                                  {this.props.t("Agent Email")}
                                </Label>
                                <Field
                                  name="agentEmail"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentEmail && touched.agentEmail
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentEmail"
                                />
                                <ErrorMessage
                                  name="agentEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                         <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="feedback">
                                  {this.props.t("Feedback")}
                                </Label>
                                <Field
                              name="feedback"
                              onChange={handleChange}
                              as="textarea"
                              className={
                                "form-control" +
                                (errors.feedback && touched.feedback
                                  ? " is-invalid"
                                  : "")
                              }
                              id="feedback"
                            />
                           
                                <ErrorMessage
                                  name="feedback"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>


                          </Row> 
                          <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Update</button></div>

                       

                    </Form>
                  )}
                </Formik>

                      </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas
                      isOpen={this.state.isRight2}
                      direction="end"
                      toggle={this.toggleRightCanvas2}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                        Offer Accepted 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:showing.id,
                    clientPotentialListingId: showing.clientPotentialListingId,
                    clientId:showing.clientId,
                    agentId: showing.agentId,
                    mlsId: showing.mlsId,
                    mlsurl: showing.mlsurl,
                    listingDate: showing.listingDate,
                    listingStatusId: parseInt(showing.listingStatusId),
                    isInterested: showing.isInterested,
                    isActive: showing.isActive,
                    addedBy: showing.addedBy,
                    listedPrice: showing.listedPrice,
                    listingCity: showing.listingCity,
                    notes: showing.notes,
                    showingDate:showing.showingDate,
                    startTime: showing.startTime,
                    endTime: showing.endTime,

                  }}
                  validationSchema={Yup.object().shape({
                    mlsId: Yup.string().required("This is Required"),
                    mlsurl: Yup.string().required("This is Required"),
                    listingStatusId:Yup.string().required("This is Required")
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateshowing = {
                        id:values.id,
                        clientPotentialListingId: values.clientPotentialListingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        mlsId: values.mlsId,
                        mlsurl: values.mlsurl,
                        listingDate: values.listingDate,
                        listingStatusId: values.listingStatusId,
                        isInterested: values.isInterested,
                        isActive: values.isActive,
                        addedBy: values.addedBy,
                        listedPrice: values.listedPrice,
                        listingCity: values.listingCity,
                        notes: values.notes,
                        showingDate:values.showingDate,
                    startTime: values.startTime,
                    endTime: values.endTime,
                      };

                      onUpdateClientshowing(updateshowing);
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsId">
                              {this.props.t("MLS")}
                            </Label>
                            <Field
                              name="mlsId"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.mlsId && touched.mlsId
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="mlsId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="Asking_price">
                              {this.props.t("Asking Price")}
                            </Label>
                            <Field
                              name="Asking_price"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.Asking_price && touched.Asking_price
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="Asking_price"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="final_price">
                              {this.props.t("Final Price")}
                            </Label>
                            <Field
                              name="final_price"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.final_price && touched.final_price
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="final_price"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="type">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="type" id="type"  className={
                                    "form-control" +
                                    (errors.type && touched.type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Sale">Sale</option>
                                <option value="Lease">Lease</option>
                               
                              </Field>
                             <ErrorMessage
                              name="type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commision_type">
                              {this.props.t("Commision Type")}
                            </Label>
                            <Field as="select" name="commision_type" id="commision_type"  className={
                                    "form-control" +
                                    (errors.commision_type && touched.commision_type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Fixed">Fixed</option>
                                <option value="%">%</option>
                               
                              </Field>
                             <ErrorMessage
                              name="commision_type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>


                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="value">
                              {this.props.t("Value")}
                            </Label>
                            <Field
                              name="value"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.value && touched.value
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="value"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="amount">
                              {this.props.t("Amount")}
                            </Label>
                            <Field
                              name="amount"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.amount && touched.amount
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="amount"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="showingDate">
                                    {this.props.t("Schedule Date ")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </Col>
                        <Col sm="12">
                          <FormGroup>
                                <Label htmlFor="file">upload</Label>
                              <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          </FormGroup>
                        </Col>

                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="notes">
                              {this.props.t("Note")}
                            </Label>
                            <textarea
                              name="notes"
                              onChange={handleChange}
                              type="textarea"
                              value={values.notes}
                              className={
                                "form-control" +
                                (errors.notes && touched.notes
                                  ? " is-invalid"
                                  : "")
                              }
                              id="notes"
                            />
                             <ErrorMessage
                              name="notes"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        <Col sm="2">
                        <div className="clearfix">
                        <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                          </div>
                        </Col>
                    </Row>
                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerShow.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerShwoing:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  onGetClientSellerShowing:PropTypes.func,
  showings:PropTypes.array,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  onDeleteShowing:PropTypes.func,
  onUpdateClientSellerShowing:PropTypes.func,
  loading: PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  {
     clietDetail: Clients.clietDetail,
     listings:Clients.listings,
     showings:Clients.showings,
     loading: Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerShwoing: (data) => dispatch(addClientSellerShwoing(data)),
  onGetClientSellerListing:(clientId) => dispatch(getClientSellerListing(clientId)),
  onGetClientSellerShowing:(clientId) => dispatch(getClientSellerShwoing(clientId)),
  onUpdateClientSellerShowing:(data) => dispatch(updateClientSellerShowing(data)),
  onDeleteShowing: id    =>              dispatch(deleteClientSellerShowing(id))
  
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSellerShow))
