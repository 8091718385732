import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import WelcomeComp from "../WelcomeComp"
import axios from "axios"
import { API_URL } from "../../../helpers/app_url";
import Moment from 'react-moment';
import { getLeads} from "../../../store/actions"
import { Container, Row, Col, Card, CardBody, CardTitle, InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody, Label, FormGroup } from "reactstrap"
import FilterMenu from "./filter-menu";
import leadPipeActivity from "../lead-pipe-activity";
class LeadGroupReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
          groupDrops:[]
        }
       
    }
    componentDidMount() {
      const { onGetAllLeads } = this.props;
      onGetAllLeads(localStorage.getItem('userId'));
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      axios.get(API_URL + 'FieldValue/allbyname', {
        params: {
            fieldName: 'Lead:Group',

        }, headers: { Authorization: str }
    })
        .then(res => {
            const groupDrops = res.data;
            this.setState({ groupDrops });

        })
    }
    render() {
      const { allLeads } = this.props;
        const {  groupDrops } = this.state;
        const brColor = [{borderBottom:'3px solid #0D6EFD'},{borderBottom:'3px solid #198754'},{borderBottom:'3px solid #0DCAF0'}, {borderBottom:'3px solid #AB2E3C'},{borderBottom:'3px solid #FFC107'}];

        const typeStrings = [];
        
        typeStrings.push({
            "title": 'No Set',
            "leads": allLeads.filter(d => d.group === 0)
        });
        groupDrops.forEach(groupDrop => {
            typeStrings.push({

                "title": groupDrop.fieldValueName,
                'leads': allLeads.filter(d => d.group == groupDrop.id)
            })
        });
        //expectedClosing

        const allLocal = localStorage.getItem('localData')
        const localization = JSON.parse(allLocal);
        return (
            <React.Fragment>
                <div className="page-content project-page-content">
                    <MetaTags>
                        <title>Lead Dashboard | Realty Space</title>
                    </MetaTags>
                    <Container fluid className="ps-0 pe-0">

                        <Row className="project-header">
                            <Col xl="7">
                                <Breadcrumbs
                                    title={this.props.t("Lead")}
                                    breadcrumbItem={this.props.t("Group View")}
                                />
                            </Col>
                            <Col xl="5">
                                <WelcomeComp />
                            </Col>
                        </Row>
                       <FilterMenu /> 
                       <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { typeStrings.map((activite,index) => 
                            <Col sm="2" className="" key={activite.title}>
                                <div className="topHeading" style={brColor[index]}>
                                <span className="sc-kEqXSa CIzYb">{activite.title}</span>
                                 
                                </div>
                            </Col>
                         )} 
                         </Row>
                        
                        <Row className="toRowContect">
                        <Col sm="1"></Col>
                        { typeStrings.map(activite => 
                        <Col sm="2" className="" key={activite.title}>
                                <div >
                                { activite.leads.map(lead => 
                                <div className="container p-0" key={lead.id} style={{marginTop:10}}>
                                  <div className="mb-3 card custom-card">
                                   
                                    <div className="card-body">
                                    <div className="float-end ms-2"><span className="badge rounded-pill badge-soft-success font-size-12">{ lead.leadStatusId  ? localization["LeadStatus_"+ lead.leadStatusId]  : 'N/A' }</span></div>
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2 lead-box-title"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                       
                                      </div>
                                     
                                      {/* <div><i className="bx bx-phone align-middle"></i>: {lead.phone}</div>
                                      <div><i className=" bx bxs-envelope align-middle"></i>: {lead.email}</div> */}
                                      <div><i className=" bx bxs-timer align-middle"></i>: { lead.lastActivityType ? lead.lastActivityType : 'No Activity' } </div>
                                      <div> <i className=" bx  bxs-calendar align-middle"></i>:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-1">
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to={`/lead/lead-detail/`+ lead.id + "/" + lead.leadId}><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              )} 
                              
                                   
                                </div>

                               
                            </Col>
                         )}    
                          </Row>
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
                    </Container>
                </div>
                <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update Lead
                      </OffcanvasHeader>
                      <OffcanvasBody>
                   

                      </OffcanvasBody>
                    </Offcanvas>
            </React.Fragment>
        )
    }
}
LeadGroupReport.propTypes = {
    t: PropTypes.any,
    onGetAllLeads:PropTypes.func,
    allLeads:PropTypes.array
}
const mapStateToProps = ({ leads, AppoinmentObject }) => (
  {
      allLeads: leads.allLeads,
      loading: AppoinmentObject.loading
  })
const mapDispatchToProps = dispatch => ({
  onGetAllLeads: (agentId) => dispatch(getLeads(agentId)),
  
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadGroupReport))

