import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import BuilderSaga from "./builder/saga"
import projectSaga from "./projects/saga"
import contactSaga from "./contacts/saga"
import leadSaga from "./leads/saga"
import locallizationSaga from "./localization/saga"
import clientSaga from "./clients/saga"
import eventSaga from "./event/saga"
import messageSaga from "./message/saga"
import resourceSaga from "./resource/saga"
import templateSaga from "./templates/saga"
import reviewDisSaga from "./settings/objects/saga"
import appObjectSaga from "./settings/Appointment/saga"
import calendarSaga from "./calendar/saga"
import taskSaga from "./task/saga"
import campaignSaga from "./campaign/saga"
import transactionSaga from "./transaction/saga"
import activitySaga from "./activity/saga"
//console.log(BuilderSaga);

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(BuilderSaga),
    fork(projectSaga),
    fork(contactSaga),
    fork(leadSaga),
    fork(locallizationSaga),
    fork(clientSaga),
    fork(eventSaga),
    fork(messageSaga),
    fork(resourceSaga),
    fork(templateSaga),
    fork(reviewDisSaga),
    fork(appObjectSaga),
    fork(calendarSaga),
    fork(taskSaga),
    fork(campaignSaga),
    fork(transactionSaga),
    fork(activitySaga)
  ])
}
