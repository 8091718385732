import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class EligibilitypageMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'question',
                label: 'Location',
                link: `/lead/eligibility/question`
            },
            {
                id: 'decision',
                label: 'Decision Stage',
                link: `/lead/eligibility/decision`
            },
            {
                id: 'mortgage',
                label: 'Mortgage Status',
                link: `/lead/eligibility/mortgage`
            },
            {
                id: 'reason',
                label: 'Reason',
                link: `/lead/eligibility/reason`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />
                 <div className="mt-4">

<p>A Factor represents a key criterion used to assess the quality of a lead. Each factor is weighted based on
its importance.</p>
<p>By carefully entering and scoring these factors, you can effectively evaluate and prioritize your leads based on your specific criteria and business goals.</p>
</div>    
                

              
            </React.Fragment>
        )
    }
}
EligibilitypageMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(EligibilitypageMenu)
