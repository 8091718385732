import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Builder from "./builder/reducer"
import Project from "./projects/reducer"
import contact from "./contacts/reducer"
import localization from "./localization/reducer"
import leads from "./leads/reducer"
import Clients from "./clients/reducer"
import Event from "./event/reducer"
import Messages from "./message/reducer"
import Resource from "./resource/reducer"
import Template from "./templates/reducer"
import ReviewSource from "./settings/objects/reducer"
import AppoinmentObject from "./settings/Appointment/reducer"
import Calendar from "./calendar/reducer"
import Task from "./task/reducer"
import campaign from "./campaign/reducer"
import Transaction from "./transaction/reducer"
import Activity from "./activity/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Builder,
  Project,
  contact,
  localization,
  leads,
  Clients,
  Event,
  Messages,
  Resource,
  Template,
  ReviewSource,
  AppoinmentObject,
  Calendar,
  Task,
  campaign,
  Transaction,
  Activity
})

export default rootReducer
