import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import $, { param } from "jquery";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { loadEventMasterTemplate as onGetTempById } from "../../../store/templates/actions";
import EmailEditor from 'react-email-editor';
import axios from "axios";

const InEditor = props => {
  const { id, invId, templateId } = useParams();
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [templateContent, setTemplateContent] = useState('');
  const { eventMasterData, loading } = useSelector(state => ({
    eventMasterData: state.Template.eventMasterData,
    loading: state.Template.loading,
  }));
  console.log(eventMasterData);  
  useEffect(() => {
      dispatch(onGetTempById(invId, templateId));
  }, [dispatch, onGetTempById]);

  const editorRef = useRef(null);
  
 
useEffect(() => {
    const timeoutId = setTimeout(() => {
        if (eventMasterData && _.get(eventMasterData, "projectTemplatePreContent", false) !== false) {
            editorRef.current.editor.loadDesign(JSON.parse(eventMasterData.projectTemplatePreContent.templateContent));
        }
    }, 1000);
   
    return () => clearTimeout(timeoutId);
}, [eventMasterData]);

const exportHtml = () => {
  if (editorRef.current) {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : masterData.templateTitle,
        'emailCampaignContent' : html,
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      toast.loading("Please wait...");
      axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign', payload, {
              headers: headers
            })
            .then((response) => {
              toast.dismiss();
              history.push('/pre-construction/mail-send/' + response.data.projectEmailCampaign.id + '/' + response.data.projectEmailCampaign.emailCampaignProjectId + '/' + response.data.projectEmailCampaign.projectEmailCampaignId);
            })
            .catch((error) => {
             
            }) 
    });
  }
};

const saveasdraft = () => {
  if (editorRef.current) {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : 'Draft',
        'emailCampaignContent' : html,
        'emailCampaignPublishStatus' : 'draft',
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      toast.loading("Please wait...");
      axios.put('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign/updateProjectEmailCampaignSaveDraft', payload, {
              headers: headers
            })
            .then((response) => {
              toast.dismiss();
              toast.success("Email Saved as Draft");
              history.push('/pre-construction/my-project');
              
            })
            .catch((error) => {
             
            }) 
    });
  }
};

const sendPreview = () => {
  if (editorRef.current) {
    toast.loading("Please wait...");
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : 'Preview Email',
        'emailCampaignSubject' : 'Preview Email',
        'emailCampaignContent' : html,
        'emailCampaignCss' : '',
        //'emailCampaignPublishStatus' : 'draft',
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      //toast.loading("Please wait...");
      axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign/previewprojectemailcampaign', payload, {
              headers: headers
            })
            .then((response) => {
              //toast.dismiss();
              toast.success("Preview Mail Send");
              //history.push('/pre-construction/my-project');
              
            })
            .catch((error) => {
             
            }) 
    });
  }
};

  return (
   
    <div style={{ marginTop: 30 }}>
      {loading ? <div className="loading">Loading&#8230;</div> : null}
      <ToastContainer autoClose={2000} />
      <Container fluid>
      <div className="overlay-email">
      <Row className="mb20">
      <Col sm="6">
      <Link className="btn btn-primary ms-2" style={{ marginTop: 34 }} to={`/pre-construction/campaign/send-email/${id}/${invId}`} >Back</Link>
      </Col>
          <Col sm="6">
            {/* <Link className="btn btn-primary" to={`/pre-construction/campaign/send-email/${id}/${projectId}`} >Back</Link> */}
            <span className="float-end">
            <button
                type="button"
                className="btn btn-warning w-md me-2"
                style={{ marginTop: 34 }}
                
            >
                                 Save as Draft
                                </button>{" "}
                                <button
                                  type="button"
                                  className="btn btn-success w-md me-2"
                                  style={{ marginTop: 34 }}
                                 
                                >
                                  Send Preview Mail
                                </button>{" "}
        <Link to={`/pre-construction/invite-send/${id}/${invId}/${templateId}`}><button
              
              className="btn btn-primary w-md me-2"
              style={{ marginTop: 34 }}
            >
              Save & Continue
            </button></Link>
            </span>
          </Col>
        </Row>

     
        <Row className="mb20">
          <Col sm="12">
          <EmailEditor projectId={228097} style={{minHeight:800}} ref={editorRef}     />
          </Col>
        </Row>
        </div>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default withRouter(InEditor);
