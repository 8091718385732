// ImportContact.js
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Card, Button } from "reactstrap";
import UploadContact from "./upload-file";
import MapImportContact from "./map-import-contact";
import FinalImportContact from "./final-import-contact"
import { API_URL } from 'helpers/app_url';
import axios from 'axios';
class ImportContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      fileUploaded: false,
      rejectedFiles: [],
      uploadedFileName: '',
      uploadedFileSize: '',
      importLogs: [],
      finalPayload: null,
      columnMappings: {},
      importExcelTableList: [],
      errorTableList: []
    };
    this.formikRef = React.createRef();
  }

  handleAcceptedFiles = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      this.setState({
        fileUploaded: true,
        rejectedFiles: [],
        uploadedFileName: file.name,
        uploadedFileSize: (file.size / 1024 / 1024).toFixed(2) + ' MB'
      });
    }
  };

  handleRejectedFiles = (fileRejections) => {
    const rejectedFiles = fileRejections.map(rejection => rejection.file.name);
    this.setState({ rejectedFiles });
  };

  removeFile = () => {
    this.setState({
      fileUploaded: false,
      uploadedFileName: '',
      uploadedFileSize: ''
    });
  };

  // nextStep = () => {
  //   let { currentStep } = this.state;
  //   currentStep = currentStep >= 2 ? 3 : currentStep + 1;
  //   this.setState({ currentStep });
  // }

  // nextStep = async () => {
  //   if (this.state.currentStep === 2) {
  //     const formik = this.formikRef.current;

  //     if (formik) {
  //       await formik.validateForm();
  //       formik.handleSubmit();
  //     }
  //   } else {
  //     this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  //   }
  // };

  nextStep = async () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      const formik = this.formikRef.current;

      if (formik) {
        await formik.validateForm();
        formik.handleSubmit();
      }
    } else if (currentStep === 3) {
      this.submitFinalPayload();
    } else {
      this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
    }
  };
  prevStep = () => {
    let { currentStep } = this.state;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({ currentStep });
  }
  handleSchemaExtracted = (schema) => {
    //console.log(schema);
    this.setState({ importLogs: schema });
  };

  handleSaveData = (data) => {
    //console.log(schema);
    this.setState({ importExcelTableList: data });
  };
  handleErrorData = (data) => {
    //console.log(schema);
    this.setState({ errorTableList: data });
  };


  handleMapSubmit = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  };
  submitFinalPayload = () => {

    const importExcelTableList = this.state.importExcelTableList;

    const payload = {
      importLogId: this.state.importLogs.id,
      importExcelTableList
    };

    console.log("Final Payload:", payload);

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    axios.post(`${API_URL}ImportLog/UploadExcelFileToDatabase`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {

      })
      .catch(error => {
        console.error('API error:', error);
      });
    const finalData = localStorage.getItem("MapImportContact");



  };
  render() {
    const { currentStep, fileUploaded, rejectedFiles, uploadedFileName, uploadedFileSize, columnMappings } = this.state;

    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Import Contact | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="project-header">
              <Breadcrumbs
                title={this.props.t("Contact")}
                breadcrumbItem={this.props.t("Import contacts")}
              />
            </Row>
            <Card>
              <Row>
                <Col md="3"></Col>
                <Col md="6">
                  {currentStep === 1 && (
                    <UploadContact
                      handleAcceptedFiles={this.handleAcceptedFiles}
                      handleRejectedFiles={this.handleRejectedFiles}
                      fileUploaded={fileUploaded}
                      rejectedFiles={rejectedFiles}
                      uploadedFileName={uploadedFileName}
                      uploadedFileSize={uploadedFileSize}
                      removeFile={this.removeFile}
                      onSchemaExtracted={this.handleSchemaExtracted}
                    />
                  )}
                  {currentStep === 2 && (
                    <div>
                      <MapImportContact
                        importLogs={this.state.importLogs}
                        formikRef={this.formikRef}
                        onNext={this.handleMapSubmit}
                        onSaveData={this.handleSaveData}
                        onErrorData={this.handleErrorData}
                      />

                    </div>
                  )}
                  {currentStep === 3 && (
                    <div>
                      <FinalImportContact
                        importLogs={this.state.importLogs}
                        importExcelTableList={this.state.importExcelTableList}
                        errorTableList={this.state.errorTableList}
                      />
                    </div>
                  )}
                  <div className="clearfix"></div>
                  <Button
                    type="button"
                    className="btn btn-secondary float-start"
                    onClick={this.prevStep}
                    disabled={currentStep === 1}
                  >
                    Back
                  </Button>
                  {currentStep === 1 && (
                    <Button
                      type="button"
                      className="btn btn-next float-end mb20 w-md"
                      onClick={this.nextStep}
                      disabled={!fileUploaded && currentStep === 1}
                    >
                      Next1
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <Button
                      type="submit"
                      className="btn btn-next float-end mb20 w-md"
                      onClick={this.nextStep}
                    >
                      Next2
                    </Button>
                  )}
                  {currentStep === 3 && (
                    <Button
                      type="button"
                      className="btn btn-next float-end mb20 w-md"
                      onClick={this.nextStep}
                      disabled={!fileUploaded && currentStep === 1}
                    >
                      Finish
                    </Button>
                  )}
                </Col>
                <Col md="3"></Col>
              </Row>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ImportContact.propTypes = {
  t: PropTypes.any
};

export default connect()(withTranslation()(ImportContact));
