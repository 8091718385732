import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import all from "../../assets/images/rsicons/all.png"
import appointment from "../../assets/images/rsicons/appointment.png"
import notes from "../../assets/images/rsicons/notes.png"
import phonelog from "../../assets/images/rsicons/phone-log.png"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import contactIcon from "../../assets/images/rsicons/contactCard.png"
import emaillog from "../../assets/images/rsicons/email-log.png"
import investor from "../../assets/images/rsicons/investor.png"
import referal from "../../assets/images/rsicons/referral.png"
import transaction from "../../assets/images/rsicons/transaction.png"
import prefrence from "../../assets/images/rsicons/prefrence.png"
import { getContactDetail, bqapiError, addBuyerQuest, addTabNote, addTabEmail, 
  addTabCall, getTabCall, getTabEmail, getTabNote, addContactAPP, getAppContatTab, getAppContatDetailTab, getAllContactActivityTab, addContact } from "../../store/contacts/actions"
  import { apiError } from "../../store/actions"
import Moment from 'react-moment';
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import { AvField, AvForm, AvCheckboxGroup, AvCheckbox, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentAppModal from "./AppModal";
import ContactEdit from "./forms/contact-edit";

// import { apiError, addContact,getContactDetail } from "../../store/actions"
class ContactDetail extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null,
      this.state = {
        col9: false,
        col10: false,
        col11: false,
        col12: true,
        col15: false,
        selectedGroup: null,
        activeTab: "1",
        verticalActiveTab: "1",
        buyersQuestions: [],
        sellerQuestions: [],
        investorQuestions: [],
        buyersData: [],
        selectedvalue: '',
        emailsubmit1: "1",
        emailsubmit2: "2",
        emailsubmit3: "3",
        emailNote: '',

        callsubmit1: "1",
        callsubmit2: "2",
        callsubmit3: "3",
        callNote: '',
        appTypes: [],
        appPriories: [],
        appLocTypes: [],
        logDate: '',
        callLogHistory: [],
        selectedValue: '',
        value: 0,
        viewmodal: false,
        modal: false,
        titles:[],
        statuses : [],
      types : [],
      connects : [],
      countries : [],
      state : this.initialState(),
        
      }
      this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
      this.toggleRightCanvasEdit = this.toggleRightCanvasEdit.bind(this);
      this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
    this.toggle = this.toggle.bind(this);
    this.handelValidApp = this.handelValidApp.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleSubmitNote = this.handleSubmitNote.bind(this)
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this)
    this.handleSubmitCall = this.handleSubmitCall.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this)

  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvasEdit() {
    this.setState({ isRightEdit: !this.state.isRightEdit });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleValidSubmit(event, values) {

    this.props.addBuyerQuest(values, this.props.history)
    //console.log(values);
  }

  handleSubmitNote(event, values) {
    this.props.addTabNote(values, this.props.history)
  }

  handleSubmitEmail(value) {
    this.props.addTabEmail(value, this.props.history)
    console.log(value);

  }
  handleChange(e) {

    this.setState({ selectedValue: e.target.value });
  }
  handleInputChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }
  handleSubmitCall(value) {
    //console.log("event: ", event);
    this.props.addTabCall(value, this.props.history)
    //console.log(value);

  }

  handelValidApp(value) {
    this.props.addContactAPP(value, this.props.history)
    //console.log(value)
  }
  handleAppClicks = () => {
    this.toggle();
  };
  handleRangeChange = value => {
    this.setState({
      value: value
    })
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };
  componentDidMount() {
    this.props.apiError("");
    const {
      match: { params }, onGetContactDetail, onGetTabCall, onGetTabEmail, onGetTabNote, onGetAppContact, onGetAllContactAct, onGetAppContactDetail } = this.props;

    if (params && params.id) {
      onGetContactDetail(params.id);
      onGetTabCall(params.id);
      onGetTabEmail(params.id);
      onGetTabNote(params.id);
      onGetAppContact(parseInt(localStorage.getItem("userId")),params.id);
      onGetAppContactDetail(params.id);
      onGetAllContactAct(params.id);
    } else {
      onGetContactDetail(2);

    }
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
      params: {
        forType: 1,

      }, headers: { Authorization: str }
    })
      .then(res => {
        const buyersQuestions = res.data;
        this.setState({ buyersQuestions });
        //console.log('Buyer Question');
        //console.log(buyersQuestions);
      }),
      axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
        params: {
          forType: 2,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const sellerQuestions = res.data;
          this.setState({ sellerQuestions });
        }),
      axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
        params: {
          forType: 3,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const investorQuestions = res.data;
          this.setState({ investorQuestions });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactQuestionAnswer/allfortype', {
        params: {
          ContactId: params.id,
          ContactTypeId: 1

        }, headers: { Authorization: str }
      })
        .then(res => {
          const buyersData = res.data;
          this.setState({ buyersData });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appTypes = res.data;
          this.setState({ appTypes });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
          params: {
            fieldName: 'Agent:Title',
          }, headers: { Authorization: str }
        })
          .then(res => {
            const titles = res.data;
            this.setState({ titles });
          }),

          axios.get('https://rscoreapi.azurewebsites.net/api/Country/all',{ headers: { Authorization: str } })
    .then(res => {
      const countries = res.data;
      this.setState({ countries });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/ContactType/all',{  headers: { Authorization: str } })
    .then(res => {
        const types = res.data;
        this.setState({ types });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactStatus/all',{  headers: { Authorization: str } })
    .then(res => {
        const statuses = res.data;
        this.setState({ statuses });
      }),
      axios.get('https://rscoreapi.azurewebsites.net//api/LeadSource/all',{  headers: { Authorization: str } })
      .then(res => {
          const connects = res.data;
          this.setState({ connects });
        })




  }

  initialState() {
    return {
      name: '',
      street_address: '',
      city: '',
      states: '',
      zip_code: '',
      googleMapLink: ''

    }
  }


  // handlePlaceSelect() {
  //   const addressObject = this.autocomplete.getPlace()
  //   const address = addressObject.address_components;
  //   //console.log(addressObject);
  //   this.setState({
  //     name: addressObject.name,

  //   })
  // }

  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace()
    const address = addressObject.address_components;
    console.log(addressObject);
    this.setState({
      name: addressObject.name,
      street_address: `${address[0].long_name} ${address[1].long_name}`,
      city: address[2].long_name,
      states: address[4].short_name,
      zip_code: address[6].short_name,
      //googleMapLink: addressObject.url
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }




  callbuildPayload = submitName => {
    return { [`submit${submitName}`]: submitName, remark: this.state.callNote };
  };
  callchangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callsubmitHandler = (submitName) => {
    const payload = this.callbuildPayload(submitName);
    //console.log(payload.submit2);
    this.props.addTabCall(payload, this.props.history)
  };


  render() {
    const { value } = this.state
    const { selectedGroup } = this.state;
    const { callLogs, emailLogs, noteLogs, appointments } = this.props;
    const { contactDetail, appDetails, allactivities } = this.props;
    const datafill = this.state.buyersData;
    const { emailNote, emailsubmit1, emailsubmit2, emailsubmit3, callsubmit1, callsubmit2, callsubmit3 } = this.state;
    this.state.buyersQuestions.forEach(function (record) {
      record.selectedvalue = '';
      record.selectedid = 0;
      datafill.forEach(function (buyervalue) {
        if (buyervalue.questionAnswerId == record.id) {
          record.selectedvalue = buyervalue.answersOption;
          record.selectedid = buyervalue.id;
        }

      });
    });

    const selectedValue = this.state.selectedValue;



    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
   
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
   
    return (
      <React.Fragment>
        
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <ToastContainer autoClose={2000} />
            <Row>
              <Col sm='3'>
              {/* {!isEmpty(contactDetail) && (
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft pb-1">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3">
                            <p>Type: {contactDetail.contactTypeId} <br />
                              Investor: No <br />
                              Last Contact: Nov, 13 2021
                            </p>
                            <Link
                      to=""
                      className="btn btn-primary btn-md"
                    >
                      Generate Client Account    {" "}<i className="mdi mdi-home-import-outline ms-1"/>
                    </Link>

                          </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          <img src={contactIcon} alt="" width='100px' className="img-fluid me-0 mb-3" />
                        </Col>s
                      </Row>
                    </div>

                  </Card>
                )} */}
           
                {!isEmpty(contactDetail) && (
                  <Card>
                    <CardBody>
                    
                      <h4 className="card-title mb-5"> <span className="float-end">
                        {/* <Link to={"/contact/contact-edit/" + contactDetail.contactId}>Edit</Link> */}
                        <RsLink onClick={this.toggleRightCanvasEdit} className="btn-purpul w-md float-end" iconClass=" bx bx-edit-alt">Edit</RsLink>
                        
                        </span></h4>
                      <div className="table-responsive">
                        <Table className="table mb-0">

                          <tbody>

                            <tr>
                              <th scope="row">Email</th>
                              <td>{contactDetail.email}</td>

                            </tr>
                            <tr>
                              <th scope="row">Mobile</th>
                              <td>{contactDetail.mobile}</td>

                            </tr>
                            <tr>
                              <th scope="row">Home Phone</th>
                              <td>{contactDetail.phone}</td>

                            </tr>
                            <tr>
                              <th scope="row">Office Phone</th>
                              <td>{contactDetail.officePhone}</td>

                            </tr>

                            <tr>
                              <th scope="row">Address</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row">City</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row">Postal Code</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row"> State/Province</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Country</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Last Contact Date</th>
                              <td>  {contactDetail.lastContact}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Company Name</th>
                              <td>  {contactDetail.organizationName} </td>

                            </tr>
                            <tr>
                              <th scope="row"> Creation Date</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Client</th>
                              <td>  {contactDetail.isClient}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Vendor</th>
                              <td>{contactDetail.isVendor}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Job Title</th>
                              <td> {contactDetail.jobTitle}   </td>

                            </tr>
                            <tr>
                              <th scope="row">Status</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row">Last Login</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>  Silver  </td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>

                  </Card>
                )}
              </Col>
              <Col sm='9'>
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Detail")}
            />
            {/* <Link to="/all-contacts" className="mb-4 text-black" ><div className="mb-2"><img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/> {" "}Back to Contacts </div></Link> */}
            </Col>
            <Col sm='4'>
                <h2>{contactDetail.firstName + " " + contactDetail.lastName }</h2>
            </Col>
            </Row>
              <Card>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushOne">
                      <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col15} style={{ cursor: "pointer" }}>
                      <span className="tab-heading"> <img src={prefrence} className="primg" />   Additional &nbsp;<span className="text-blue"> Profile </span></span>
                      </button>
                    </h2>


                    <Collapse isOpen={this.state.col15} className="accordion-collapse">
                      <div className="accordion-body">
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientId: this.props.match.params.id,
                    // secondaryTitle: (this.secondaryTitle && this.state.secondaryTitle) || "",
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    secondaryTitle : Yup.string().required("This Field is Required"),
                    secondaryContactFName :Yup.string().max(100,'Only 100 Characters are allowed').required("This Field is Required"),
                    secondaryContactLName :Yup.string().max(100,'Only 100 Characters are allowed') ,
                    secondaryMobile :Yup.string().min(10,"Only 10 Digits are allowed").max(10,'Only 10 Characters are allowed').required("This Field is Required") ,
                    secondaryEmail :Yup.string().max(100,'Only 100 Characters are allowed').email().required("This Field is Required") ,
                    secondaryOrganization :Yup.string() ,
                    secondaryJobTitle :Yup.string() ,
                    secondaryOffice :Yup.string().min(10,"Only 10 Digits are allowed").max(10,'Only 10 Digits are allowed') ,
                    secondaryPhoneExt :Yup.string().min(3,"Digits should be more than 3").max(6,'Only 6 Digits are allowed') ,
                    secondaryIndustry :Yup.string().max(100,'Only 100 Characters are allowed') ,
                    // dateTitle: Yup.string().required("This is Required"),
                    // dateMonth: Yup.string().required("This is Required"),
                    // dateDay: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleImportantDateSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card> 
                    <CardBody>
                    <h5 className="card-title">Secondary Contact Details</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field as="select" name="secondaryTitle" className={
                                "form-control" +
                                (errors.secondaryTitle && touched.secondaryTitle
                                  ? " is-invalid"
                                  : "")
                              } onChange={handleChange}>
                            <option value="">Select</option>
                            {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="secondaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactFName">
                              {this.props.t("First Name*")}
                            </Label>
                            <Field
                              name="secondaryContactFName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactFName && touched.secondaryContactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactFName"
                            />
                             <ErrorMessage
                              name="secondaryContactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="secondaryContactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactLName && touched.secondaryContactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactLName"
                            />
                             <ErrorMessage
                              name="secondaryContactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryMobile">
                              {this.props.t("Mobile*")}
                            </Label>
                            <Field
                              name="secondaryMobile"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryMobile && touched.secondaryMobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryMobile"
                            />
                             <ErrorMessage
                              name="secondaryMobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryEmail">
                              {this.props.t("Email*")}
                            </Label>
                            <Field
                              name="secondaryEmail"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryEmail && touched.secondaryEmail
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryEmail"
                            />
                             <ErrorMessage
                              name="secondaryEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                 
                        </Row>
                           
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOrganization">
                              {this.props.t("Company Name")}
                            </Label>
                            <Field
                              name="secondaryOrganization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOrganization && touched.secondaryOrganization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOrganization"
                            />
                             <ErrorMessage
                              name="secondaryOrganization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryJobTitle">
                              {this.props.t("Job Title")}
                            </Label>
                            <Field
                              name="secondaryJobTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryJobTitle && touched.secondaryJobTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryJobTitle"
                            />
                             <ErrorMessage
                              name="secondaryJobTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOffice">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="secondaryOffice"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOffice && touched.secondaryOffice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOffice"
                            />
                             <ErrorMessage
                              name="secondaryOffice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryPhoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="secondaryPhoneExt"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryPhoneExt && touched.secondaryPhoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryPhoneExt"
                            />
                             <ErrorMessage
                              name="secondaryPhoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryIndustry">
                              {this.props.t("Industry")}
                            </Label>
                            <Field
                              name="secondaryIndustry"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryIndustry && touched.secondaryIndustry
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryIndustry"
                            />
                             <ErrorMessage
                              name="secondaryIndustry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>
             
             </CardBody>
                </Card>
</Form>
                  )}
                  </Formik>



<Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientId: this.props.match.params.id,
                    agentId: localStorage.getItem('userId'),
                    dateTitle: (this.state && this.state.dateTitle) || "",
                    dateMonth: (this.state && this.state.dateMonth) || "",
                    dateDay: (this.state && this.state.dateDay) || "",
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    dateTitle: Yup.string().required("This is Required"),
                    dateMonth: Yup.string().required("This is Required"),
                    dateDay: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleImportantDateSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => 
                    {const selectedMonth = values.dateMonth ;
                    const selectedYear = new Date().getFullYear();
                    const daysInMonth = selectedMonth ? new Date(selectedYear, selectedMonth, 0).getDate() : 31;
                    return(
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Important dates </h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateTitle">
                              {this.props.t("Type of date*")}
                            </Label>
                            <Field
                              name="dateTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.dateTitle && touched.dateTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="dateTitle"
                            />
                             <ErrorMessage
                              name="dateTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateMonth">
                              {this.props.t("Month*")}
                            </Label>
                            <Field as="select" name="dateMonth" className={`form-control${errors.dateMonth && touched.dateMonth ? " is-invalid" : ""}`} onChange={(e) => {
                              handleChange(e);
                              const selectedMonth = parseInt(e.target.value, 10);
                              // setFieldValue("dateDay", ''); // Reset day value
                              setFieldValue("dateMonth", e.target.value); // Set month value
                            }}>
                              <option value="">Select</option>
                              {[...Array(12)].map((_, index) => (
                                <option key={index + 1} value={index + 1}>{this.props.t(new Date(2000, index, 1).toLocaleString(undefined, { month: 'long' }))}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="dateMonth" component="div" className="invalid-feedback" />
                          
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateDay">
                              {this.props.t("Day*")}
                            </Label>
                            <Field as="select" name="dateDay" className={`form-control${errors.dateDay && touched.dateDay ? " is-invalid" : ""}`} onChange={handleChange}>
                                <option value="">Select</option>
                                {[...Array(daysInMonth)].map((_, index) => (
                                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                                ))}
                              </Field>
                              <ErrorMessage name="dateDay" component="div" className="invalid-feedback" />
        
                          </FormGroup>

                          </Col>
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                   
                   <Row>
                    <Col sm="12">
                    <h5 className="card-title">Importants Date</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Title</th>
								<th style={{width:"80%"}}>Month</th>
                <th style={{width:"80%"}}>Day</th>             
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
						
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form> )}
  }
  </Formik>

  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    
                    ///clientId: this.props.match.params.id,
                    childName: (this.state && this.state.dateTitle) || "",
                    birthDay: (this.state && this.state.birthDay) || "",
  
                  }}
                  validationSchema={Yup.object().shape({
                    childName: Yup.string().required("This is Required"),
                    birthDay: Yup.string().required("This is Required"),
                  })}

                  onSubmit={this.handleChildSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Add children</h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="childName">
                              {this.props.t("Child Name*")}
                            </Label>
                            <Field
                              name="childName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.childName && touched.childName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="childName"
                            />
                             <ErrorMessage
                              name="childName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb15">

                              <Label htmlFor="birthDay">
                                {this.props.t("Brith Day*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.birthDay && touched.birthDay
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                
                                onChange={(value) => setFieldValue('birthDay', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="birthDay"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                         
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                    <Row>
                    <Col sm="12">
                    <h5 className="card-title">Childs</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Name</th>
								<th style={{width:"80%"}}>BirthDay</th>            
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
						
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form>
  )}
  </Formik>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushOne">
                      <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col9} style={{ cursor: "pointer" }}>
                      <span className="tab-heading"><span className="text-blue">
                      <img src={prefrence} className="primg" /> 
                       Buying</span> &nbsp; Preferences</span>
                      </button>
                    </h2>


                    <Collapse isOpen={this.state.col9} className="accordion-collapse">
                      {/* <div className="accordion-body">
                        <Card>
                          <AvForm className="needs-validation"
                            method="post"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            <CardBody>
                              <Row className="mb15">
                                {
                                  this.state.buyersQuestions
                                    .map(buyersQuestion =>

                                      <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                        <FormGroup>
                                          <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                            {buyersQuestion.question}
                                          </Label>
                                          {(() => {
                                            if (buyersQuestion.questionType == 1) {

                                              const asns = buyersQuestion.answersOptions;
                                              const res = asns.split(',');
                                              const result = res.map((item, index) => <option key={index} value={item}



                                              >{item}</option>);
                                              return (
                                                <div>
                                                  <AvField type="select" value={buyersQuestion.selectedvalue} name={"buyerQuest[" + buyersQuestion.id + "]"} >
                                                    <option value="">Select</option>
                                                    {result}
                                                  </AvField>
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 2) {

                                              return (
                                                <div className="square-switch">
                                                  <input
                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    type="checkbox"
                                                    id={"square-switch" + buyersQuestion.id}


                                                    onChange={() =>
                                                      this.setState({ sq1: !this.state.sq1 })
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={"square-switch" + buyersQuestion.id}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                  />

                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 6) {
                                              return (
                                                <div>
                                                  <AvField

                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    value={buyersQuestion.selectedvalue}
                                                    validate={{ date: { format: 'MM/DD/YYYY' } }}

                                                  />
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 7) {
                                              return (
                                                <div>
                                                  <AvField

                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="text"
                                                    value={buyersQuestion.selectedvalue}
                                                    placeholder=""

                                                  />
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 8) {
                                              return (
                                                <div>
                                                  <AvField
                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="textarea"
                                                    value={buyersQuestion.selectedvalue}
                                                    placeholder=""
                                                  />
                                                </div>
                                              )
                                            } else {
                                              return (
                                                <div></div>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </div>



                                    )}
                              </Row>

                              <Row className="mb15">
                                {!isEmpty(contactDetail) && (
                                  <AvField type="hidden" name="contactId" value={contactDetail.id} />
                                )}
                                <Col lg="12">
                                  <Button
                                    color="primary"
                                  >
                                    Save Questions
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </AvForm>
                        </Card>
                      </div> */}
                      <div className="accordion-body">
                              <Card>
                              <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      leadId: 4,
                                      CurrentRent: (this.state && this.state.CurrentRent) || "",
                                      DownPayment: (this.state && this.state.DownPayment) || "",
                                      PaymentValue: (this.state && this.state.PaymentValue) || "",
                                      PriceRangeStart: (this.state && this.state.PriceRangeStart) || '',
                                      PriceRangeEnd: (this.state && this.state.PriceRangeEnd) || '',
                                      BuyBeforeSell: (this.state && this.state.BuyBeforeSell) || false,
                                      SignedToSell: (this.state && this.state.SignedToSell) || false ,
                                      PreapprovedLender: (this.state && this.state.PreapprovedLender) || false ,
                                      BedRoom: (this.state && this.state.BedRoom) || '',
                                      Bath: (this.state && this.state.Bath) || '',
                                      SqFt: (this.state && this.state.SqFt) || '',
                                      PrefferedLocation: (this.state && this.state.PrefferedLocation) || "",
                                      PropType: (this.state && this.state.PropType) || "",
                                      // Date: (this.state && this.state.Date) ||  new Date(),
                                      Desc: (this.state && this.state.Desc) || "",
                                    
                                    }}
                                    validationSchema={Yup.object().shape({
                                      CurrentRent: Yup.string().required("This is Required"),
                                      DownPayment: Yup.string().required("This is Required"),
                                      PaymentValue: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      PriceRangeStart: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      PriceRangeEnd: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required").when('PriceRangeStart', (PriceRangeStart) => {
                                        if (PriceRangeStart) {
                                            return Yup.number("Must be a number").min(PriceRangeStart +1, 'Max Price Must be Greater than Min Price')
                                                .typeError('This is required')}}),
                                      BuyBeforeSell: Yup.bool(),
                                      SignedToSell: Yup.bool(),
                                      PreapprovedLender: Yup.bool(),
                                      BedRoom: Yup.number().min(1,'Value must be greater than 0').required("This is Required"),
                                      Bath: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      SqFt: Yup.number("Must be a number").min(1,'Value must be greater than 0'),
                                      PrefferedLocation: Yup.string().required("This is Required"),
                                      PropType: Yup.string().required("This is Required"),
                                      Date: Yup.string().required("This is Required"),
                                      Desc: Yup.string().required("This is Required"),

                                    })}

                                    onSubmit={this.handleLeadNoteSubmit}
                                  >
                                    {({ errors,  touched, values, handleChange, setFieldValue }) => (
                                      <Form className="needs-validation" >
                                        <CardBody>
                                          <Row className="mb15">
                                          <FormGroup>
                                            <div className="row mb-3">
                                              <div className='col-sm-12 col-md-2 col-xl-2  label-container'>
                                                <Label htmlFor='CurrentRent' className='form-label label-text'>Current Rent or Own?*</Label>
                                                <Field as="select" className={
                                                                    "form-control" +
                                                                    (errors.CurrentRent && touched.CurrentRent
                                                                      ? " is-invalid"
                                                                      : "")
                                                                  } 
                                                      name='CurrentRent' id='CurrentRent'>
                                                  <option value="">Select</option>
                                                  <option value="Rent">Rent </option>
                                                  <option value="Own">Own</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="CurrentRent"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-2 col-xl-2 label-container'>
                                                <Label htmlFor='DownPayment' className='form-label label-text '>Down Payment Type*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.DownPayment && touched.DownPayment
                                              ? " is-invalid"
                                              : "")
                                          }  name='DownPayment' id='DownPayment'>
                                                  <option value="">Select</option>
                                                  <option value="1">Percent</option>
                                                  <option value="2">Amount</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="DownPayment"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-2 col-xl-2  label-container'>
                                                <Label htmlFor='PaymentValue' className='form-label label-text'>Down Payment Value*</Label>
                                                <Field type="number" className={
                                            "form-control" +
                                            (errors.PaymentValue && touched.PaymentValue
                                              ? " is-invalid"
                                              : "")
                                          }  name='PaymentValue' id='PaymentValue'/>
                                          <ErrorMessage
                                                  name="PaymentValue"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-2 col-xl-2  label-container'>
                                                <Label htmlFor='PriceRangeStart' className='form-label label-text'>Min Price*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.PriceRangeStart && touched.PriceRangeStart
                                              ? " is-invalid"
                                              : "")
                                          } name='PriceRangeStart' id='PriceRangeStart'>
                                                  <option value="">Select</option>
                                                  <option value="15000">15000</option>
                                                  <option value="25000">25000</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="PriceRangeStart"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-2 col-xl-2  label-container'>
                                                <Label htmlFor='PriceRangeEnd' className='form-label label-text'>Max Price*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.PriceRangeEnd && touched.PriceRangeEnd
                                              ? " is-invalid"
                                              : "")
                                          } name='PriceRangeEnd' id='PriceRangeEnd'>
                                                  <option value="">Select</option>
                                                  <option value="15000">15000</option>
                                                  <option value="45000">45000</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="PriceRangeEnd"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row mb-3">
                                              <div>
                                                <Field type='checkbox' className='me-1' name='BuyBeforeSell' id='BuyBeforeSell'/>
                                                <Label htmlFor='BuyBeforeSell' className='form-label label-text'>Do you need to sell before you buy?</Label>
                                              </div>

                                              <div>
                                                <Field type='checkbox' className='me-1' name='SignedToSell' id='SignedToSell'/>
                                                <Label htmlFor='SignedToSell' className='form-label label-text'>Have you signed a listing aggrement to sell your home</Label>
                                              </div>

                                              <div>
                                                <Field type='checkbox' className='me-1' name='PreapprovedLender' id='PreapprovedLender'/>
                                                <Label htmlFor='PreapprovedLender' className='form-label label-text'>Are you pre-approved by a lender</Label>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row mb-3">
                                              <div className='col-sm-12 col-md-3 col-xl-3  label-container'>
                                                <Label htmlFor='BedRoom' className='form-label label-text'>Bedroom*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.BedRoom && touched.BedRoom
                                              ? " is-invalid"
                                              : "")
                                          } id='BedRoom' name='BedRoom'>
                                                  <option value="">Select</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                  <option value="5">5</option>
                                                  <option value="6">6</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="BedRoom"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-3 col-xl-3 label-container'>
                                                <Label htmlFor='Bath' className='form-label label-text'>Bath*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.Bath && touched.Bath
                                              ? " is-invalid"
                                              : "")
                                          } id='Bath' name='Bath'>
                                                  <option value="">Select</option>
                                                  <option value="1">1</option>
                                                  <option value="1.5">1.5</option>
                                                  <option value="2">2</option>
                                                  <option value="2.5">25</option>
                                                  <option value="3">3</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="Bath"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-3 col-xl-3  label-container'>
                                                <Label htmlFor='SqFt' className='form-label label-text'>How much Sq. ft</Label>
                                                <Field type="number" className={
                                            "form-control" +
                                            (errors.SqFt && touched.SqFt
                                              ? " is-invalid"
                                              : "")
                                          }  id='SqFt' name='SqFt'/>
                                          <ErrorMessage
                                                  name="SqFt"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>

                                              <div className='col-sm-12 col-md-3 col-xl-3  label-container'>
                                                <Label htmlFor='PrefferedLocation' className='form-label label-text'>Preferred location</Label>
                                                <Field type="text" className='form-control'  id='PrefferedLocation' name='PrefferedLocation'/>
                                              </div>

                                              <div className='col-sm-12 col-md-3 col-xl-3  label-container'>
                                                <Label htmlFor='PropType' className='form-label label-text'>Type</Label>
                                                <Field as="select" className='form-control' id='PropType' name='PropType'>
                                                  <option value="">Select</option>
                                                  <option value="1">Condo</option>
                                                  <option value="2">Townhouse</option>
                                                  <option value="3">Semi-Detached</option>
                                                  <option value="4">Detached</option>
                                                </Field>
                                              </div>

                                              <div className='col-sm-12 col-md-4 col-xl-4  label-container'>
                                                <Label htmlFor='Date' className='form-label label-text'>By what are you looking to move in</Label>
                                                <Field type="date" className='form-control ' name='Date'  id='Date'/>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row">
                                              <div className="  label-container">
                                                <Label htmlFor='Desc' className='form-label label-text label-container'>What else are you looking for in your home?</Label>
                                                <Field as='textarea' Row='5'   className='form-control' id='Desc' name='Desc'/>
                                              </div>
                                            </div>

                                          </FormGroup>
                                          </Row>

                                          <Row className="mb15">
                                             <Col sm="12" md='6'>
                                             <SaveButton />
                                               
                                             </Col>
                                             <Col sm='12' md='6' className="text-end">
                                             <SaveButton iconClass=" bx bx-plus-medical">Add New</SaveButton>
                                             </Col>
                                           </Row>
                                          </CardBody>
                                        </Form>
                                      
                                    )}
                      </Formik>
                        </Card>
                              </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushTwo">
                      <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col10} style={{ cursor: "pointer" }}>
                      <span className="tab-heading"><span className="text-blue">
                      <img src={prefrence} className="primg" /> 
                       Selling</span> &nbsp; Preferences</span>
                      </button>
                    </h2>

                    <Collapse isOpen={this.state.col10} className="accordion-collapse">
                      <div className="accordion-body">
                        {/* <Card>
                          <CardBody>
                            <Row className="mb15">
                              {
                                this.state.sellerQuestions
                                  .map(sellerQuestion =>

                                    <div key={sellerQuestion.key} className={sellerQuestion.layoutClass}>
                                      <FormGroup>
                                        <Label htmlFor={"sellerQuest" + sellerQuestion.id}>
                                          {sellerQuestion.question}
                                        </Label>
                                        {(() => {
                                          if (sellerQuestion.questionType == 1) {
                                            const asns = sellerQuestion.answersOptions;
                                            const res = asns.split(',');
                                            //console.log(res);
                                            const result = res.map((item, index) => <option key={index} value={item}>{item}</option>);
                                            return (
                                              <div>
                                                <AvField type="select" name={"sellerQuest" + sellerQuestion.id} >
                                                  <option value=""></option>
                                                  {result}
                                                </AvField>
                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 2) {
                                            return (
                                              <div className="square-switch">
                                                <input
                                                  name={"sellerQuest" + sellerQuestion.id}
                                                  type="checkbox"
                                                  id={"square-switch" + sellerQuestion.id}


                                                  onChange={() =>
                                                    this.setState({ sq1: !this.state.sq1 })
                                                  }
                                                />
                                                <label
                                                  htmlFor={"square-switch" + sellerQuestion.id}
                                                  data-on-label="Yes"
                                                  data-off-label="No"
                                                />

                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 7) {
                                            return (
                                              <div>
                                                <input
                                                  name={"sellerQuest" + sellerQuestion.id}
                                                  className="form-control"
                                                  type="text"
                                                  placeholder=""

                                                />
                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 8) {
                                            return (
                                              <div>
                                                <textarea
                                                  className="form-control"
                                                  type="text"
                                                  placeholder=""
                                                ></textarea>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div></div>
                                            )
                                          }
                                        })()}



                                      </FormGroup>
                                    </div>



                                  )}
                            </Row>
                            <Row className="mb15">
                              <Col lg="12">
                                <Button
                                  color="primary"
                                >
                                  Save Questions
                                </Button>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card> */}
                        <Card>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                    
                                          leadId: 4,
                                          Reason: (this.state && this.state.Reason) || "",
                                          ExpectedPrice: (this.state && this.state.ExpectedPrice) || "",
                                          ByWhen: (this.state && this.state.ByWhen) || "",
                                          ListedProperty: (this.state && this.state.ListedProperty) || false ,
                                          LookingForNew: (this.state && this.state.LookingForNew) || false ,
                                          Neighbourhood: (this.state && this.state.Neighbourhood) || "" ,
                                          Desc: (this.state && this.state.Desc) || ""

                                    }}
                                  validationSchema={Yup.object().shape({
                                    Reason: Yup.string(),
                                    ExpectedPrice: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                    ByWhen: Yup.string(),
                                    ListedProperty: Yup.bool(),
                                    LookingForNew: Yup.bool(),
                                    Neighbourhood: Yup.string(),
                                    Desc: Yup.string(),
                                  })}

                                  onSubmit={values => {
                                    // setTimeout(() => {
                                    //   alert(JSON.stringify(values, '', 2));
                                    // }, 500);
                                  }}
                              >
                                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                                <Form className="needs-validation" >
                                    <CardBody>

                                      <Row className="mb15">
                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="Reason" className="label-text">Reason to Sell
                                          </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.Reason && touched.Reason
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            id="Reason"
                                            type="text"
                                            placeholder=""
                                            name="Reason"
                                          />  
                                          <ErrorMessage
                                                  name="Reason"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="ExpectedPrice" className="label-text"> Expected price* </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.ExpectedPrice && touched.ExpectedPrice
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            type="number"
                                            placeholder=""
                                            name="ExpectedPrice"
                                          />  
                                          <ErrorMessage
                                                  name="ExpectedPrice"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="ByWhen" className="label-text"> By When to move out</Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.ByWhen && touched.ByWhen
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            type="text"
                                            name='ByWhen'
                                            placeholder=""
                                          />  
                                          <ErrorMessage
                                                  name="ByWhen"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        
                                        {/* <Col lg="3">
                                        <FormGroup>
                                            <Label htmlFor='test' className='form-label label-text'>Type</Label>
                                            <Field as="select" className='form-control' id='test'>
                                                  <option value="">Select</option>
                                                  <option value="">Option 1</option>
                                                  <option value="">Option 2</option>
                                            </Field>
                                        </FormGroup>
                                        </Col> */}
                                      </Row>

                                        <hr className="mt-2 mb-3"/>


                                        <Row className="">
                                          <FormGroup>
                                            <Field type='checkbox' className='me-1' name='ListedProperty' id='ListedProperty'/>
                                            <Label htmlFor='ListedProperty' className='form-label label-text'>Have you listed the property previously</Label>
                                          </FormGroup>
                                       </Row>

                                       <Row className="">
                                          <FormGroup>
                                            <Field type='checkbox' className='me-1' name='LookingForNew' id='LookingForNew'/>
                                            <Label htmlFor='LookingForNew' className='form-label label-text'>Are you looking to buy a new property after selling</Label>
                                          </FormGroup>
                                       </Row>

                                       <hr className="mt-2 mb-3"/>


                                        <Row className="mb15">
                                        <Col lg="12">
                                        <FormGroup>
                                        <Label htmlFor="Neighbourhood" className="label-text"> Tell a little bit about your home/neighbourhood </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.Neighbourhood && touched.Neighbourhood
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            as="textarea"
                                            name="Neighbourhood"
                                            placeholder="Recently upgraded backyard. Excellent neighbors."
                                          />
                                          <ErrorMessage
                                                  name="Neighbourhood"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        </Row>
                                        {/* <hr className="mt-2 mb-3"/> */}

                                        <Row className="mb15">
                                        <Col lg="12">
                                        <FormGroup>
                                        <Label htmlFor="Desc" className="label-text"> Seller notes </Label>
                                        <Field
                                            className={
                                              "form-control" +
                                              (errors.Desc && touched.Desc
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            as="textarea"
                                            placeholder="Motivated to sell."
                                            name="Desc"
                                          />
                                          <ErrorMessage
                                                  name="Desc"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        </Row>

                                        <Row className="mb15">
                                        <Col lg="12">
                                        <SaveButton>Save Questions</SaveButton>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                    </Form>
                                )}
                                    </Formik>
                                </Card>
                      </div>
                      
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushThree">
                      <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col11} style={{ cursor: "pointer" }}>
                      <span className="tab-heading"><span className="text-blue">
                      <img src={prefrence} className="primg" />
                        Investment </span>  &nbsp;Preferences</span>

                      </button>
                    </h2>
                    <Collapse isOpen={this.state.col11} className="accordion-collapse">
                      <div className="accordion-body">
                        {/* <AvForm className="needs-validation">
                          <Card>
                            <CardBody>
                              <Row className="mb15">
                                {
                                  this.state.investorQuestions
                                    .map(investorQuestion =>

                                      <div key={investorQuestion.key} className={investorQuestion.layoutClass}>
                                        <FormGroup>
                                          <Label htmlFor={"invQuest" + investorQuestion.id}>
                                            {investorQuestion.question}
                                          </Label>
                                          {(() => {
                                            if (investorQuestion.questionType == 1) {
                                              const asns = investorQuestion.answersOptions;
                                              const res = asns.split(',');
                                              //console.log(res);
                                              const result = res.map((item, index) => <option key={index} value={item}>{item}</option>);
                                              return (
                                                <div>
                                                  <AvField type="select" name={"invQuest" + investorQuestion.id} >
                                                    <option value=""></option>
                                                    {result}
                                                  </AvField>
                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 2) {
                                              return (
                                                <div className="square-switch">
                                                  <input
                                                    name={"sellerQuest" + investorQuestion.id}
                                                    type="checkbox"
                                                    id={"square-switch" + investorQuestion.id}


                                                    onChange={() =>
                                                      this.setState({ sq1: !this.state.sq1 })
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={"square-switch" + investorQuestion.id}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                  />

                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 7) {
                                              return (
                                                <div>
                                                  <input
                                                    name={"invQuest" + investorQuestion.id}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""

                                                  />
                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 8) {
                                              return (
                                                <div>
                                                  <textarea
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                  ></textarea>
                                                </div>
                                              )
                                            } else {
                                              return (
                                                <div></div>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </div>



                                    )}
                              </Row>

                              <Row className="mb15">
                                <Col lg="12">
                                  <Button
                                    color="primary"
                                  >
                                    Save Questions
                                  </Button>
                                </Col>
                              </Row>

                            </CardBody>
                          </Card>
                        </AvForm> */}
                        <Card>

                        <Formik
                              enableReinitialize={true}
                              initialValues={{
                              
                                    leadId: 4,
                                    Location: (this.state && this.state.Location) || "",
                                    PropertyType: (this.state && this.state.PropertyType) || "",
                                    PriceRangeStart: (this.state && this.state.PriceRangeStart) || "",
                                    PriceRangeEnd: (this.state && this.state.PriceRangeEnd) || "",
                                    ExpectedProfit: (this.state && this.state.ExpectedProfit) || "",
                                    RiskCapacity: (this.state && this.state.RiskCapacity) || "",
                                    Strategy: (this.state && this.state.Strategy) || "",
                                    Notes: (this.state && this.state.Notes) || "",
                                    HowManyDeals: (this.state && this.state.Notes) || "",

                              }}
                            validationSchema={Yup.object().shape({
                              Location: Yup.string().required("This is Required"),
                              PropertyType: Yup.string().required("This is Required"),
                              HowManyDeals: Yup.number().min(1,'Value must be greater than 0'),
                              PriceRangeStart: Yup.number().min(1,'Value must be greater than 0'),
                              PriceRangeEnd: Yup.number().when('PriceRangeStart', (PriceRangeStart) => {
                                if (PriceRangeStart) {
                                    return Yup.number("Must be a number").min(PriceRangeStart +1, 'Max Price Must be Greater than Min Price')
                                        .typeError('This is required')}}),
                              ExpectedProfit: Yup.number(),
                              RiskCapacity: Yup.string(),
                              Strategy: Yup.string(),
                              Notes: Yup.string(),
                            })}

                            onSubmit={values => {
                              // setTimeout(() => {
                              //   alert(JSON.stringify(values, '', 2));
                              // }, 500);
                            }}
                        >
                          {({ errors,  touched, values, handleChange, setFieldValue }) => (
                        <Form className="needs-validation" >
                            <CardBody>
                              <Row className="mb15">
                                <Col lg="4">
                                  <FormGroup>
                                    <Label htmlFor="Location" className="label-text">Geographic locations preferred* </Label>
                                    <Field type='text' 
                                    className={
                                      "form-control" +
                                      (errors.Location && touched.Location
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    id='Location' name="Location" />
                                    <ErrorMessage
                                          name="Location"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <Label htmlFor="PropertyType" className="label-text">Type of property interested* </Label>
                                    <Field as='select' 
                                    className={
                                      "form-control" +
                                      (errors.PropertyType && touched.PropertyType
                                        ? " is-invalid"
                                        : "")
                                    }  
                                    id='PropertyType'  name="PropertyType">
                                      <option value="0">Select...</option>
                                      <option value="1">Residential</option>
                                      <option value="2">Commercial</option>  
                                    </Field>
                                    <ErrorMessage
                                          name="PropertyType"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <Label htmlFor="PriceRangeStart" className="label-text">Price range </Label>
                                    <Field type='number' 
                                    className={
                                      "form-control" +
                                      (errors.PriceRangeStart && touched.PriceRangeStart
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    name="PriceRangeStart" id='PriceRangeStart'/>
                                    <ErrorMessage
                                          name="PriceRangeStart"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <Label htmlFor="PriceRangeEnd" className="label-text">Price range End</Label>
                                    <Field type='number' 
                                    className={
                                      "form-control" +
                                      (errors.PriceRangeEnd && touched.PriceRangeEnd
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    name="PriceRangeEnd" id='PriceRangeEnd'/>
                                    <ErrorMessage
                                          name="PriceRangeEnd"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                  </FormGroup>
                                </Col>
                              </Row>
                              

                                <Row className="mb15">
                                <Col lg="4">
                                <FormGroup>
                                <Label htmlFor="HowManyDeals" className="label-text"> How many deals do you typically have going at one time?  </Label>
                                  <Field
                                    className={
                                      "form-control" +
                                      (errors.HowManyDeals && touched.HowManyDeals
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    type="number"
                                    name="HowManyDeals"
                                    // placeholder="2-3"
                                  />  
                                  <ErrorMessage
                                          name="HowManyDeals"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                </FormGroup>
                                
                                </Col>
                                
                                <Col lg="4">
                                <FormGroup>
                                <Label htmlFor="ExpectedProfit" className="label-text">Expected profit / deal </Label>
                                  <Field
                                    className={
                                      "form-control" +
                                      (errors.ExpectedProfit && touched.ExpectedProfit
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    type="number"
                                    name="ExpectedProfit"
                                    placeholder="30%"
                                  />  
                                  <ErrorMessage
                                          name="ExpectedProfit"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                </FormGroup>
                                
                                </Col>
                                <Col lg="3">
                                <FormGroup>
                                <Label htmlFor="RiskCapacity" className="label-text">Risk capacity  </Label>
                                  
                                  <Field as='select' name="RiskCapacity" className={
                                      "form-control" +
                                      (errors.RiskCapacity && touched.RiskCapacity
                                        ? " is-invalid"
                                        : "")
                                    } >
                                  <option value="0">Select...</option>
                                  <option value="1">Low Risk</option>
                                  <option value="2">Medium Risk</option>
                                  <option value="2">High Risk</option>
                                  
                                </Field>
                                <ErrorMessage
                                          name="RiskCapacity"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                </FormGroup>
                                
                                </Col>
                                </Row>

                                <Row className="mb15">
                                <Col lg="12">
                                <FormGroup>
                                <Label className="label-text" >Investment strategy </Label>
                                <br/>
                                {/* <Label >Investment strategy </Label> */}
                                  <div className="form-check form-check-inline">
                                  <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox1" value="option1" />
                                  <Label className="form-check-label label-text" htmlFor="inlineCheckbox1">Flip</Label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox2" value="option2" />
                                  <Label className="form-check-label label-text" htmlFor="inlineCheckbox2">Hold/Rent</Label>
                                </div> 
                                <div className="form-check form-check-inline">
                                  <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox3" value="option3" />
                                  <Label className="form-check-label label-text" htmlFor="inlineCheckbox3">Sell</Label>
                                </div> 
                                </FormGroup>
                                
                                </Col>
                                
                                </Row>
                                

                                <Row className="mb15">
                                <Col lg="12">
                                <FormGroup>
                                <Label htmlFor="Notes" className="label-text">Investor notes </Label>
                                  <Field
                                    className={
                                      "form-control" +
                                      (errors.Notes && touched.Notes
                                        ? " is-invalid"
                                        : "")
                                    } 
                                    as="textarea"
                                    name="Notes"
                                    placeholder=""
                                  ></Field>
                                  <ErrorMessage
                                          name="Notes"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                </FormGroup>
                                </Col>
                                </Row>

                              

                                <Row className="mb15">
                                <Col lg="12">
                                <SaveButton>
                                    Save Questions
                                  </SaveButton>
                                </Col>
                                </Row>
                            </CardBody>
                            </Form>
                          )}
                            </Formik>
                        </Card>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushThree">
                      <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col12} style={{ cursor: "pointer" }}>
                         <span className="tab-heading"><span className="text-blue">
                         <img src={prefrence} className="primg" /> 
                         Activities</span></span>
                      </button>
                    </h2>
                    <Collapse isOpen={this.state.col12} className="accordion-collapse">
                      <div className="accordion-body">
                        <Card>
                          <CardBody>
                          {/* <Nav tabs className="projectTab projectNav contact-detail-nav ">
                    <NavItem>
                      <Link className= {this.state.list_filter=="all" ? "nav-link active":"nav-link"} onClick={() => this.setState({ list_filter: "all" })}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("All")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className= {this.state.list_filter=="agent" ? "nav-link active":"nav-link"} onClick={() => this.setState({ list_filter: "agent" })}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Sent")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className= {this.state.list_filter=="client" ? "nav-link active":"nav-link"} onClick={() => this.setState({ list_filter: "client" })}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Recived")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className= {this.state.list_filter=="visited" ? "nav-link active":"nav-link"} onClick={() => this.setState({ list_filter: "visited" })}
                        >
                          <span className="d-none d-sm-block">{this.props.t("Visited")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav> */}
                            <Nav tabs className="projectTab projectNav contact-detail-nav">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "1",
                                  })}
                                  onClick={() => {
                                    this.toggle("1")
                                  }}
                                >
                                  <span className="">{this.props.t("All Activities")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "2",
                                  })}
                                  onClick={() => {
                                    this.toggle("2")
                                  }}
                                >

                                  <span className="">{this.props.t("Meetings")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "3",
                                  })}
                                  onClick={() => {
                                    this.toggle("3")
                                  }}
                                >

                                  <span className="">{this.props.t("Notes")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "6",
                                  })}
                                  onClick={() => {
                                    this.toggle("6")
                                  }}
                                >

                                  <span className="">{this.props.t("Email Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggle("5")
                                  }}
                                >

                                  <span className="k">{this.props.t("Call Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "7",
                                  })}
                                  onClick={() => {
                                    this.toggle("7")
                                  }}
                                >

                                  <span className="">{this.props.t("Referrals")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "8",
                                  })}
                                  onClick={() => {
                                    this.toggle("8")
                                  }}
                                >

                                  <span className="">{this.props.t("Transactions")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "9",
                                  })}
                                  onClick={() => {
                                    this.toggle("9")
                                  }}
                                >

                                  <span className="">{this.props.t("Interested In")}</span>
                                </NavLink>
                              </NavItem>



                            </Nav>
                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                              <TabPane tabId="1">
                                <div className="mb-5 h4 card-title">All Activities</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(allactivities, (allactivity, allactkey) => (

                                    <li className="event-list eventlist" key={"_allact_" + allactkey}>
                                      <div className="event-timeline-dot">
                                       {(() => {
                                                  if(allactivity.activitySubType==8 || allactivity.activitySubType==9 || allactivity.activitySubType==7){
                                                    return (
                                                      <>
                                                       <img src={emaillog} />
                                                      </>
                                                    )
                                                  }else if(allactivity.activitySubType==3 || allactivity.activitySubType==4 || allactivity.activitySubType==5){
                                                    return (
                                                      <>
                                                       <img src={phonelog} />
                                                      </>
                                                    )
                                                  }else{
                                                    return (
                                                      <>
                                                       <img src={notes} />
                                                      </>
                                                    )

                                                  }
                                            })()}
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{allactivity.addedDate}</Moment>

                                            
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ allactivity.activityType+"_"+allactivity.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{allactivity.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>
                              <TabPane tabId="2">
                                <div className="h4 card-title">Set Meeting</div>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: edTime,
                                    contactName: contactDetail.firstName + " " + contactDetail.lastName,
                                    contactPhone: contactDetail.mobile,
                                    contactEmail: contactDetail.email,
                                    refId: contactDetail.id
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    apptype : Yup.number().required("This field is required"),
                                    appPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                  })}

                                  onSubmit={this.handelValidApp}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                    className="needs-validation"
                                  >
                                    <Row>
                                      <Col md="5">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="appAjenda">
                                            {this.props.t("Agenda*")}
                                          </Label>
                                          <input
                                            name="appAjenda"
                                            type="text"
                                            value={values.appAjenda}
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.appAjenda && touched.appAjenda
                                                ? " is-invalid"
                                                : "")
                                            }
                                            id="appAjenda"
                                          />
                                          <ErrorMessage
                                            name="appAjenda"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appADate">
                                            {this.props.t("Date")}
                                          </Label>
                                          <Flatpickr
                                            className={
                                              "form-control" +
                                              (errors.appADate && touched.appADate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder=""
                                            value={values.appADate}
                                            onChange={(value) => setFieldValue('appADate', value[0])}
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d",
                                              minDate:'today'
                                            }}
                                          />
                                          <ErrorMessage
                                            name="appADate"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="2">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appStat">
                                            {this.props.t("Start Time")}
                                          </Label>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              value={values.appStat}
                                              onChange={(value) => setFieldValue('appStat', value[0])}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                maxDate: values.appEnd,
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>

                                        </FormGroup>
                                      </Col>
                                      <Col md="2">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appEnd">
                                            {this.props.t("End Time")}
                                          </Label>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              value={values.appEnd}
                                              onChange={(value) => setFieldValue('appEnd', value[0])}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                minDate: values.appStat,
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="apptype">
                                            {this.props.t("Appointment Type*")}
                                          </Label>
                                          <Field as="select" name="apptype" className={
                                              "form-control" +
                                              (errors.apptype && touched.apptype
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value="">Select</option>
                                            {
                                              this.state.appTypes
                                                .map(appType =>
                                                  <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="apptype"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appPriority">
                                            {this.props.t("Appointment Priority*")}
                                          </Label>
                                          <Field as="select" name="appPriority" className={
                                              "form-control" +
                                              (errors.appPriority && touched.appPriority
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value="">Select</option>
                                            {
                                              this.state.appPriories
                                                .map(appPriorie =>
                                                  <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="appPriority"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appointmentLocationType">
                                            {this.props.t("Location Type*")}
                                          </Label>
                                          <Field as="select" name="appointmentLocationType" className={
                                              "form-control" +
                                              (errors.appointmentLocationType && touched.appointmentLocationType
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value=''>Select</option>
                                            {
                                              this.state.appLocTypes
                                                .map(appLocType =>
                                                  <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="appointmentLocationType"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">
                                          {(() => {
                                            if (selectedValue == 3) {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Location")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="autocomplete"
                                                  />
                                                </>
                                              )
                                            } else if (selectedValue == 2) {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Phone")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="appointmentLocation"
                                                  />
                                                </>
                                              )
                                            } else {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Online Link")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="appointmentLocation"
                                                  />
                                                </>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </Col>
                                    </Row>


                                

                                    <div className="clearfix"><SaveButton /></div>
                                  </Form>
                                  )}
                                </Formik>

                                <div className="mb-5 h4 card-title mt-20">Meeting History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(appointments, (appointments, appointmentskey) => (

                                    <li className="event-list eventlist" key={"_app_" + appointmentskey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{appointments.appointmentStartDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"><a  onClick={this.toggleRightCanvas}>{appointments.appointmentTitle}</a></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{appointments.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                               

                              </TabPane>
                              <TabPane tabId="3">
                                <div className="mb-5 h4 card-title">Notes</div>
                                <AvForm className="needs-validation"
                                  method="post"
                                  onValidSubmit={this.handleSubmitNote}
                                >
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="contactNote">
                                          {this.props.t("Add Note*")}
                                        </Label>
                                        <AvField
                                          name="contactNote"

                                          type="textarea"
                                          errorMessage={this.props.t("Enter Note (500 Characters Only)")}
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          maxLength={500}
                                          id="contactNote"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix">

                                    {!isEmpty(contactDetail) && (
                                      <AvField type="hidden" name="contactId" value={contactDetail.id} />
                                    )}
                                   <SaveButton /></div>
                                </AvForm>
                                <div className="mb-5 h4 card-title">Notes  History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(noteLogs, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>


                              <TabPane tabId="5">
                                <div className="mb-5 h4 card-title">Call Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          actionType: '',
                          callNote:'',
                          contactId: contactDetail.id,
                          logDate: new Date(),
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          callNote : Yup.string().max(500,"Only 500 characters are allowed")
                        })}

                        onSubmit={this.handleSubmitCall}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <div className="form-group has-feedback">
                                      <Field type="hidden" name="contactId" id="contactId" value={contactDetail.id}  />
                                      </div>
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="No Answer"  onChange={handleChange}  />
                                          No Answer
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail"  onChange={handleChange} />
                                          Left Voicemail
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number"  onChange={handleChange}  />
                                          Bad Number
                                        </label>
                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                        
                                      </div>
                                      

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="callNote">
                                          {this.props.t("Add Message (Optional)")}
                                        </Label>
                                        <textarea
                                          name="callNote"
                                          onChange={handleChange}
                                          type="textarea"
                                         
                                          className={`form-control${errors.callNote && touched.callNote ? " is-invalid" : ""}`}
                                         
                                          id="callNote"

                                        />
                                        <ErrorMessage
                                              name="callNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(callLogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>

                              <TabPane tabId="6">
                                <div className="mb-5 h4 card-title">Email Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          actionType: '',
                          contactId: contactDetail.id,
                          logDate: new Date(),
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          emailNote : Yup.string().max(500,"Only 500 characters are allowed")
                        })}

                        onSubmit={this.handleSubmitEmail}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                           
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent"  onChange={handleChange}  />
                                          Email Sent
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Email Received"  onChange={handleChange} />
                                          Email Received
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced"  onChange={handleChange}  />
                                          Email Bounced
                                        </label>
                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                      </div>
                                      

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Add Message (Optional)")}
                                        </Label>
                                        <Field
                                         
                                          name="emailNote"
                                          onChange={handleChange}
                                          as="textarea"
                                         
                                          className={`form-control${errors.emailNote && touched.emailNote ? " is-invalid" : ""}`}
                                         
                                          id="emailNote"

                                        />
                                        <ErrorMessage
                                              name="emailNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emailLogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
                              </TabPane>
                              

                              <TabPane tabId="7">
                                <div className="mb-5 h4 card-title">Referrals</div>
                                <Table>
                                  <thead>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Creation Date</th>
                                    <th>Current Status</th>
                                    <th>Transactions</th>
                                  </thead>

                                </Table>

                              </TabPane>




                            </TabContent>
                          </CardBody>
                        </Card>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Card>
              </Col>
            </Row>
          
            {/* <h1 className="mb-0">{contactDetail.firstName + " " + contactDetail.lastName} </h1>
            <Link to="/all-contacts" className="mb-4 text-black" ><div className="mb-2"><img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/> {" "}Back to Contacts </div></Link> */}
           
          
          </Container>
        </div>

        <Offcanvas
                      isOpen={this.state.isRightEdit}
                      direction="end"
                      toggle={this.toggleRightCanvasEdit}
                      style={{width:800}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvasEdit}>
                        Update Contact
                      </OffcanvasHeader>
                      <OffcanvasBody>
                        <ContactEdit />

                      </OffcanvasBody>
                      </Offcanvas>
                      
        <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Appointment Detail
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      {!isEmpty(appDetails) && (
                      <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Agenda : {appDetails.appointmentTitle}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Date : <Moment format="D MMM YY">{appDetails.appointmentStartDate}</Moment></th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Start Time - End Time : <Moment format="hh:mm: A">{appDetails.startTime}</Moment> - <Moment format="hh:mm A">{appDetails.endTime}</Moment></th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Appointment Type : {appDetails.appointmentType}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Appointment Priority : {appDetails.appointmentPriority}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Location Type :  {appDetails.appointmentLocationType}</th>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                      )}
                      </OffcanvasBody>
                    </Offcanvas>
      </React.Fragment>
    )
  }
}
ContactDetail.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  t: PropTypes.any,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  onGetTabCall: PropTypes.func,
  onGetTabEmail: PropTypes.func,
  onGetTabNote: PropTypes.func,
  history: PropTypes.object,
  addBuyerQuest: PropTypes.func,
  addTabNote: PropTypes.func,
  addTabEmail: PropTypes.func,
  addTabCall: PropTypes.func,
  addContactAPP: PropTypes.func,
  onGetAppContact: PropTypes.func,
  onGetAppContactDetail: PropTypes.object,
  onGetAllContactAct:PropTypes.func,
  contactDetail: PropTypes.object,
  callLogs: PropTypes.array,
  emailLogs: PropTypes.array,
  noteLogs: PropTypes.array,
  appointments: PropTypes.array,
  appDetails: PropTypes.object,
  allactivities:PropTypes.array,


}

const mapStateToProps = ({ contact }) => (
  //console.log(contact),
  {
    contactDetail: contact.contactDetail,
    callLogs: contact.callLogs,
    emailLogs: contact.emailLogs,
    noteLogs: contact.noteLogs,
    appointments: contact.appointments,
    allactivities: contact.allactivities,
    appDetails:contact.appDetails
    
  })

const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
  onGetTabCall: id => dispatch(getTabCall(id)),
  onGetTabEmail: id => dispatch(getTabEmail(id)),
  onGetTabNote: id => dispatch(getTabNote(id)),
  addBuyerQuest: (data) => dispatch(addBuyerQuest(data)),
  addTabNote: (data) => dispatch(addTabNote(data)),
  addTabEmail: (data) => dispatch(addTabEmail(data)),
  addTabCall: (data) => dispatch(addTabCall(data)),
  addContactAPP: (data) => dispatch(addContactAPP(data)),
  onGetAppContact: (agentId, id) => dispatch(getAppContatTab(agentId, id)),
  onGetAppContactDetail: id => dispatch(getAppContatDetailTab(id)),
  onGetAllContactAct: id => dispatch(getAllContactActivityTab(id)),
  apiError,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactDetail))
