import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ClientBuyerMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'listings',
                label: 'Listings',
                link: `/clients/buyer/listings/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'questions',
                label: 'Buyer Questions',
                link: `/clients/buyer/questions/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'preferences',
                label: 'Home Preferences',
                link: `/clients/buyer/preferences/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'checklists',
                label: 'Checklists',
                link: `/clients/buyer/checklists/${this.props.match.params.id}/${this.props.match.params.clientId}`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />             

              
            </React.Fragment>
        )
    }
}
ClientBuyerMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ClientBuyerMenu)
