import { UPDATE_PROJECT_STATUS, UPDATE_PROJECT_STATUS_FAIL, UPDATE_PROJECT_STATUS_SUCCESS } from "store/settings/Appointment/actionTypes"
import {
  GET_PROJECT_TYPE,
  GET_PROJECT_TYPE_SUCCESS,
  GET_PROJECT_TYPE_FAIL,
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  GET_ALL_PROJECT,
  GET_ALL_PROJECT_SUCCESS,
  GET_ALL_PROJECT_FAIL,
  ADD_PROJECT_FEATURE,
  ADD_PROJECT_FEATURE_SUCCESS,
  ADD_PROJECT_FEATURE_ERROR,
  ADD_PROJECT_CONTACT,
  ADD_PROJECT_CONTACT_SUCCESS,
  ADD_PROJECT_CONTACT_ERROR,
  GET_PROJECT_CONTACT,
  GET_PROJECT_CONTACT_SUCCESS,
  GET_PROJECT_CONTACT_FAIL,
  ADD_PROJECT_MEDIA,
  ADD_PROJECT_MEDIA_SUCCESS,
  ADD_PROJECT_MEDIA_ERROR,
  GET_PROJECT_MEDIA,
  GET_PROJECT_MEDIA_SUCCESS,
  ADD_PROJECT_MARKET,
  ADD_PROJECT_MARKET_SUCCESS,
  ADD_PROJECT_MARKET_ERROR,
  GET_PROJECT_MARKET,
  GET_PROJECT_MARKET_SUCCESS,
  GET_PROJECT_MARKET_ERROR,
  GET_ALL_PROJECT_UUID,
  GET_ALL_PROJECT_UUID_SUCCESS,
  GET_ALL_PROJECT_UUID_FAIL,
  GET_PROJECT_FEATURE,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_FEATURE_ERROR,
  ADD_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_CONTACT,
  UPDATE_PROJECT_CONTACT_SUCCESS,
  UPDATE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_FEATURE,
  UPDATE_PROJECT_FEATURE_SUCCESS,
  UPDATE_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_CONTACT,
  DELETE_PROJECT_CONTACT_SUCCESS,
  DELETE_PROJECT_CONTACT_FAIL,
  DELETE_PROJECT_MEDIA,
  DELETE_PROJECT_MEDIA_SUCCESS,
  DELETE_PROJECT_MEDIA_FAIL,
  UPDATE_PROJECT_MARKET,
  UPDATE_PROJECT_MARKET_SUCCESS,
  UPDATE_PROJECT_MARKET_ERROR,
  DELETE_PROJECT_MARKET,
  DELETE_PROJECT_MARKET_SUCCESS,
  DELETE_PROJECT_MARKET_FAIL,
  DELETE_PROJECT_LINK,
  DELETE_PROJECT_LINK_SUCCESS,
  DELETE_PROJECT_LINK_FAIL,
  GET_PROJECT_LINK,
  GET_PROJECT_LINK_SUCCESS,
  GET_PROJECT_LINK_ERROR,
  ADD_PROJECT_LINK,
  ADD_PROJECT_LINK_SUCCESS,
  ADD_PROJECT_LINK_ERROR,
  ADD_PROJECT_GALLERY,
  ADD_PROJECT_GALLERY_SUCCESS,
  ADD_PROJECT_GALLERY_ERROR,
  GET_PROJECT_GALLERY,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_GALLERY_ERROR,
  DELETE_PROJECT_GALLERY,
  DELETE_PROJECT_GALLERY_FAIL,
  DELETE_PROJECT_GALLERY_SUCCESS,
  GET_ALL_PROJECT_STATUS,
  GET_ALL_PROJECT_STATUS_SUCCESS,
  GET_ALL_PROJECT_STATUS_FAIL,

  GET_ALL_PROJECT_SEARCH,
  GET_ALL_PROJECT_SEARCH_SUCCESS,
  GET_ALL_PROJECT_SEARCH_FAIL,
  UPDATE_MPROJECT_STAUS,
  UPDATE_MPROJECT_STAUS_SUCCESS,
  UPDATE_MPROJECT_STAUS_FAIL,
  DELETE_PROJECT_FEATURE,
  DELETE_PROJECT_FEATURE_SUCCESS,
  DELETE_PROJECT_FEATURE_ERROR,
  ADD_PROJECT_BUDGET_ALLOCATION,
  ADD_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  ADD_PROJECT_BUDGET_ALLOCATION_ERROR,
  GET_PROJECT_BUDGET_ALLOCATION,
  GET_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  GET_PROJECT_BUDGET_ALLOCATION_ERROR,
  DELETE_PROJECT_BUDGET_ALLOCATION,
  DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  DELETE_PROJECT_BUDGET_ALLOCATION_ERROR,
  UPDATE_PROJECT_BUDGET_ALLOCATION,
  UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR,
  ADD_PROJECT_BUDGET_SPENT,
  ADD_PROJECT_BUDGET_SPENT_SUCCESS,
  ADD_PROJECT_BUDGET_SPENT_ERROR,
  GET_PROJECT_BUDGET_SPENT,
  GET_PROJECT_BUDGET_SPENT_SUCCESS,
  GET_PROJECT_BUDGET_SPENT_ERROR,
  DELETE_PROJECT_BUDGET_SPENT,
  DELETE_PROJECT_BUDGET_SPENT_SUCCESS,
  DELETE_PROJECT_BUDGET_SPENT_ERROR,
  UPDATE_PROJECT_BUDGET_SPENT,
  UPDATE_PROJECT_BUDGET_SPENT_SUCCESS,
  UPDATE_PROJECT_BUDGET_SPENT_ERROR,
  ADD_PROJECT_CAMP_LEAD,
  ADD_PROJECT_CAMP_LEAD_SUCCESS,
  ADD_PROJECT_CAMP_LEAD_ERROR,
  GET_PROJECT_CAMP_LEAD,
  GET_PROJECT_CAMP_LEAD_SUCCESS,
  GET_PROJECT_CAMP_LEAD_ERROR,
  DELETE_PROJECT_CAMP_LEAD,
  DELETE_PROJECT_CAMP_LEAD_SUCCESS,
  DELETE_PROJECT_CAMP_LEAD_ERROR,
  GET_PROJECT_FEATURE_STANDARD,
  GET_PROJECT_FEATURE_STANDARD_SUCCESS,
  GET_PROJECT_FEATURE_STANDARD_ERROR,
  UPDATE_PROJECT_FEATURE_STANDARD,
  UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS,
  UPDATE_PROJECT_FEATURE_STANDARD_ERROR,
  ADD_PROJECT_EMAIL_COMP_FILTER,
  ADD_PROJECT_EMAIL_COMP_FILTER_SUCCESS,
  ADD_PROJECT_EMAIL_COMP_FILTER_ERROR,
  ADD_SETTING_WORKSHEET,
  ADD_SETTING_WORKSHEET_SUCCESS,
  ADD_SETTING_WORKSHEET_ERROR,
  GET_SETTING_WORKSHEET,
  GET_SETTING_WORKSHEET_SUCCESS,
  GET_SETTING_WORKSHEET_ERROR,
  DELETE_SETTING_WORKSHEET,
  DELETE_SETTING_WORKSHEET_SUCCESS,
  DELETE_SETTING_WORKSHEET_ERROR,
  SEND_LEAD_WORKSHEET,
  SEND_LEAD_WORKSHEET_SUCCESS,
  SEND_LEAD_WORKSHEET_ERROR,
  GET_ALL_PROJECT_LEAD,
  GET_ALL_PROJECT_LEAD_SUCCESS,
  GET_ALL_PROJECT_LEAD_ERROR,
  GET_ALL_PROJECT_CLIENT,
  GET_ALL_PROJECT_CLIENT_SUCCESS,
  GET_ALL_PROJECT_CLIENT_ERROR,
  ADD_PROJECT_OPEN_HOUSE_FILE,
  ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  ADD_PROJECT_OPEN_HOUSE_FILE_ERROR,
  ADD_PROJECT_OPEN_HOUSE,
  ADD_PROJECT_OPEN_HOUSE_SUCCESS,
  ADD_PROJECT_OPEN_HOUSE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE,
  GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS,
  GET_ALL_PROJECT_OPEN_HOUSE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR,
  GET_PROJECT_OPEN_HOUSE,
  GET_PROJECT_OPEN_HOUSE_SUCCESS,
  GET_PROJECT_OPEN_HOUSE_ERROR,
  DELETE_PROJECT_OPEN_HOUSE,
  DELETE_PROJECT_OPEN_HOUSE_SUCCESS,
  DELETE_PROJECT_OPEN_HOUSE_ERROR,
  UPDATE_PROJECT_OPEN_HOUSE,
  UPDATE_PROJECT_OPEN_HOUSE_SUCCESS,
  UPDATE_PROJECT_OPEN_HOUSE_ERROR,
  ADD_PROJECT_DOWNLOAD_PRICE_FILE,
  ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  ADD_PROJECT_DOWNLOAD_PRICE,
  ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  ADD_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_ALL_PROJECT_DOWNLOAD_PRICE,
  GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_PROJECT_DOWNLOAD_PRICE,
  GET_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  GET_PROJECT_DOWNLOAD_PRICE_ERROR,
  DELETE_PROJECT_DOWNLOAD_PRICE,
  DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  DELETE_PROJECT_DOWNLOAD_PRICE_ERROR,
  UPDATE_PROJECT_DOWNLOAD_PRICE,
  UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_PROJECT_OPEN_HOUSE_FILE,
  GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  GET_PROJECT_OPEN_HOUSE_FILE_ERROR,
  DELETE_PROJECT_OPEN_HOUSE_FILE,
  DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR,
  GET_PROJECT_DOWNLOAD_PRICE_FILE,
  GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  DELETE_PROJECT_DOWNLOAD_PRICE_FILE,
  DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  GET_PROJECT_WORKSHEET_LEAD,
  GET_PROJECT_WORKSHEET_LEAD_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_DETAIL_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR,
  GET_PROJECT_CLIENT_LEAD,
  GET_PROJECT_CLIENT_LEAD_SUCCESS,
  GET_PROJECT_CLIENT_LEAD_ERROR,
  POST_PROJECT_INVITE,
  POST_PROJECT_INVITE_SUCCESS,
  POST_PROJECT_INVITE_ERROR,
  GET_PROJECT_INVITE,
  GET_PROJECT_INVITE_SUCCESS,
  GET_PROJECT_INVITE_ERROR,
  DELETE_PROJECT_INVITE,
  DELETE_PROJECT_INVITE_SUCCESS,
  DELETE_PROJECT_INVITE_ERROR,
  VIEW_PROJECT_INVITE_ATTENDEES,
  VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS,
  VIEW_PROJECT_INVITE_ATTENDEES_ERROR,
  UPDATE_PROJECT_LINK,
  UPDATE_PROJECT_LINK_SUCCESS,
  UPDATE_PROJECT_LINK_FAIL,
  UPDATE_PROJECT_WORKSHEET_UNIT,
  UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS,
  UPDATE_PROJECT_WORKSHEET_UNIT_ERROR,
  GET_PROJECT_WORKSHEET_UNIT_INFO,
  GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS,
  GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR,
  GET_PROJECT_INVITE_DETAIL,
  GET_PROJECT_INVITE_DETAIL_SUCCESS,
  GET_PROJECT_INVITE_DETAIL_ERROR,
} from "./actionTypes"

export const addProject = (project, history) => {
  return {
    type: ADD_PROJECT,
    payload: { project, history },
  }
}

export const addProjectSuccess = project => {
  return {
    type: ADD_PROJECT_SUCCESS,
    payload: project,
  }
}

export const addProjectError = error => {
  return {
    type: ADD_PROJECT_ERROR,
    payload: error,
  }
}


export const deleteProject = id => ({
  type: DELETE_PROJECT,
  payload: id,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})


export const projectUpdate = project => {
  return {
    type: UPDATE_PROJECT,
    payload: project,
  }
}

export const projectUpdateSuccess = project => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: project,
  }
}

export const projectUpdateError = error => {
  return {
    type: UPDATE_PROJECT_FAIL,
    payload: error,
  }
}



export const getProjectType = projectType => {
  return {
    type: GET_PROJECT_TYPE,
    payload: projectType,
  }
}

export const getProjectTypeSuccess = projectType => {
  return {
    type: GET_PROJECT_TYPE_SUCCESS,
    payload: projectType,
  }
}


export const projectapiError = error => {
  return {
    type: GET_PROJECT_TYPE_FAIL,
    payload: error,
  }
}

export const getAllProjectAction = (agentId, brokerId) => (
  {
    type: GET_ALL_PROJECT,
    payload: { agentId, brokerId }
  })

export const getAllProjectActionSuccess = projects => ({
  type: GET_ALL_PROJECT_SUCCESS,
  payload: projects,
})

export const getAllProjectActionFail = error => ({
  type: GET_ALL_PROJECT_FAIL,
  payload: error,
})


export const addProjectFeature = (feature, history) => {
  return {
    type: ADD_PROJECT_FEATURE,
    payload: { feature, history },
  }
}

export const addProjectFeatureSuccess = feature => {
  return {
    type: ADD_PROJECT_FEATURE_SUCCESS,
    payload: feature,
  }
}
export const addProjectFeatureFail = error => {
  return {
    type: ADD_PROJECT_FEATURE_ERROR,
    payload: error,
  }
}


export const addProjectContact = (contact, history) => {
  return {
    type: ADD_PROJECT_CONTACT,
    payload: { contact, history },
  }
}

export const addProjectContactSuccess = contact => {
  return {
    type: ADD_PROJECT_CONTACT_SUCCESS,
    payload: contact,
  }
}
export const addProjectContactFail = error => {
  return {
    type: ADD_PROJECT_CONTACT_ERROR,
    payload: error,
  }
}

export const getProjectContact = (projectId) => (
  {
    type: GET_PROJECT_CONTACT,
    projectId
  })

export const getProjectContactSuccess = contacts => ({
  type: GET_PROJECT_CONTACT_SUCCESS,
  payload: contacts,
})

export const getProjectContactFail = error => ({
  type: GET_PROJECT_CONTACT_FAIL,
  payload: error,
})



export const addProjectMedia = (document, history) => {
  return {
    type: ADD_PROJECT_MEDIA,
    payload: { document, history },
  }
}

export const addProjectMediaSuccess = documents => {
  return {
    type: ADD_PROJECT_MEDIA_SUCCESS,
    payload: documents,
  }
}
export const addProjectMediaFail = error => {
  return {
    type: ADD_PROJECT_MEDIA_ERROR,
    payload: error,
  }
}


export const getProjectMedia = (projectId) => (
  {
    type: GET_PROJECT_MEDIA,
    projectId
  })

export const getProjectMediaSuccess = documents => ({
  type: GET_PROJECT_MEDIA_SUCCESS,
  payload: documents,
})

export const getProjectMediaFail = error => ({
  type: GET_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const addProjectMarket = (market, history) => {
  return {
    type: ADD_PROJECT_MARKET,
    payload: { market, history },
  }
}

export const addProjectMarketSuccess = markets => {
  return {
    type: ADD_PROJECT_MARKET_SUCCESS,
    payload: markets,
  }
}
export const addProjectMarketFail = error => {
  return {
    type: ADD_PROJECT_MARKET_ERROR,
    payload: error,
  }
}


export const getProjectMarket = (projectId) => (
  {
    type: GET_PROJECT_MARKET,
    projectId
  })

export const getProjectMarketSuccess = markets => ({
  type: GET_PROJECT_MARKET_SUCCESS,
  payload: markets,
})

export const getProjectMarketFail = error => ({
  type: GET_PROJECT_MARKET_ERROR,
  payload: error,
})


export const getProjectUUIDAction = (uuid) => ({
  type: GET_ALL_PROJECT_UUID,
  uuid
})

export const getProjectUUIDActionSuccess = projectData => ({
  type: GET_ALL_PROJECT_UUID_SUCCESS,
  payload: projectData,
})

export const getProjectUUIDActionFail = error => ({
  type: GET_ALL_PROJECT_UUID_FAIL,
  payload: error,
})


export const getProjectFeatureAction = (projectId) => ({
  type: GET_PROJECT_FEATURE,
  projectId
})

export const getProjectFeatureActionSuccess = projectFeatures => ({
  type: GET_PROJECT_FEATURE_SUCCESS,
  payload: projectFeatures,
})

export const getProjectFeatureActionFail = error => ({
  type: GET_PROJECT_FEATURE_ERROR,
  payload: error,
})


export const updateProjectContact = contact => ({
  type: UPDATE_PROJECT_CONTACT,
  payload: contact,
})

export const updateProjectContactSuccess = contact => ({
  type: UPDATE_PROJECT_CONTACT_SUCCESS,
  payload: contact,
})

export const updateProjectContactFail = error => ({
  type: UPDATE_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const updateProjectFeature = projectFeature => ({
  type: UPDATE_PROJECT_FEATURE,
  payload: projectFeature,
})

export const updateProjectFeatureSuccess = projectFeature => ({
  type: UPDATE_PROJECT_FEATURE_SUCCESS,
  payload: projectFeature,
})

export const updateProjectFeatureFail = error => ({
  type: UPDATE_PROJECT_FEATURE_ERROR,
  payload: error,
})


export const updateProjectFeatureStandard = projectStandardFeature => ({
  type: UPDATE_PROJECT_FEATURE_STANDARD,
  payload: projectStandardFeature,
})

export const updateProjectFeatureStandardSuccess = projectStandardFeature => ({
  type: UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS,
  payload: projectStandardFeature,
})

export const updateProjectFeatureStandardFail = error => ({
  type: UPDATE_PROJECT_FEATURE_STANDARD_ERROR,
  payload: error,
})


export const deleteProjectFeature = id => ({
  type: DELETE_PROJECT_FEATURE,
  payload: id,
})

export const deleteProjectFeatureSuccess = projectFeature => ({
  type: DELETE_PROJECT_FEATURE_SUCCESS,
  payload: projectFeature,
})

export const deleteProjectFeatureFail = error => ({
  type: DELETE_PROJECT_FEATURE_ERROR,
  payload: error,
})

export const deleteProjectContact = id => ({
  type: DELETE_PROJECT_CONTACT,
  payload: id,
})

export const deleteProjectContactSuccess = contact => ({
  type: DELETE_PROJECT_CONTACT_SUCCESS,
  payload: contact,
})

export const deleteProjectContactFail = error => ({
  type: DELETE_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const deleteProjectDocument = id => ({
  type: DELETE_PROJECT_MEDIA,
  payload: id,
})

export const deleteProjectDocumentSuccess = document => ({
  type: DELETE_PROJECT_MEDIA_SUCCESS,
  payload: document,
})

export const deleteProjectDocumentFail = error => ({
  type: DELETE_PROJECT_MEDIA_FAIL,
  payload: error,
})



export const updateProjectMarket = market => ({
  type: UPDATE_PROJECT_MARKET,
  payload: market,
})

export const updateProjectMarketSuccess = markets => ({
  type: UPDATE_PROJECT_MARKET_SUCCESS,
  payload: markets,
})

export const updateProjectMarketFail = error => ({
  type: UPDATE_PROJECT_MARKET_ERROR,
  payload: error,
})


export const deleteProjectMarket = id => ({
  type: DELETE_PROJECT_MARKET,
  payload: id,
})

export const deleteProjectMarketSuccess = market => ({
  type: DELETE_PROJECT_MARKET_SUCCESS,
  payload: market,
})

export const addProjectLink = (link, history) => {
  return {
    type: ADD_PROJECT_LINK,
    payload: { link, history },
  }
}

export const addProjectLinkSuccess = links => {
  return {
    type: ADD_PROJECT_LINK_SUCCESS,
    payload: links,
  }
}
export const addProjectLinkFail = error => {
  return {
    type: ADD_PROJECT_LINK_ERROR,
    payload: error,
  }
}

export const getProjectLink = (projectId) => (
  {
    type: GET_PROJECT_LINK,
    projectId
  })

export const getProjectLinkSuccess = links => ({
  type: GET_PROJECT_LINK_SUCCESS,
  payload: links,
})

export const getProjectLinkFail = error => ({
  type: GET_PROJECT_LINK_ERROR,
  payload: error,
})

export const updateProjectLink = link => ({
  type: UPDATE_PROJECT_LINK,
  payload: link,
})

export const updateProjectLinkSuccess = links => ({
  type: UPDATE_PROJECT_LINK_SUCCESS,
  payload: links,
})

export const updateProjectLinkFail = error => ({
  type: UPDATE_PROJECT_LINK_FAIL,
  payload: error,
})




export const deleteProjectMarketFail = error => ({
  type: DELETE_PROJECT_MARKET_FAIL,
  payload: error,
})


export const deleteProjectLink = id => ({
  type: DELETE_PROJECT_LINK,
  payload: id,
})

export const deleteProjectLinkSuccess = link => ({
  type: DELETE_PROJECT_LINK_SUCCESS,
  payload: link,
})

export const deleteProjectLinkFail = error => ({
  type: DELETE_PROJECT_LINK_FAIL,
  payload: error,
})

export const addProjectGallery = (gallery, history) => {
  return {
    type: ADD_PROJECT_GALLERY,
    payload: { gallery, history },
  }
}

export const addProjectGallerySuccess = images => {
  return {
    type: ADD_PROJECT_GALLERY_SUCCESS,
    payload: images,
  }
}
export const addProjectGalleryFail = error => {
  return {
    type: ADD_PROJECT_GALLERY_ERROR,
    payload: error,
  }
}

export const getProjectGallary = (projectId) => (
  {
    type: GET_PROJECT_GALLERY,
    projectId
  })

export const getProjectGallarySuccess = images => ({
  type: GET_PROJECT_GALLERY_SUCCESS,
  payload: images,
})

export const getProjectGallaryFail = error => ({
  type: GET_PROJECT_GALLERY_ERROR,
  payload: error,
})

export const deleteProjectGallery = id => ({
  type: DELETE_PROJECT_GALLERY,
  payload: id,
})

export const deleteProjectGallerySuccess = image => ({
  type: DELETE_PROJECT_GALLERY_SUCCESS,
  payload: image,
})

export const deleteProjectGalleryFail = error => ({
  type: DELETE_PROJECT_GALLERY_FAIL,
  payload: error,
})

export const getAllProjectStatus = () => (
  {
    type: GET_ALL_PROJECT_STATUS
  })

export const getAllProjectStatusSuccess = statuses => ({
  type: GET_ALL_PROJECT_STATUS_SUCCESS,
  payload: statuses,
})
export const getAllProjectStatusFail = error => ({
  type: GET_ALL_PROJECT_STATUS_FAIL,
  payload: error,
})


export const getSearchAllProject = (searchProjectQuery) => (
  {
    type: GET_ALL_PROJECT_SEARCH,
    payload: { searchProjectQuery }

  })

export const getSearchAllProjectSuccess = projectsss => ({
  type: GET_ALL_PROJECT_SEARCH_SUCCESS,
  payload: projectsss,
})

export const getSearchAllProjectFail = error => ({
  type: GET_ALL_PROJECT_SEARCH_FAIL,
  payload: error,
})


export const updateProjectStatus = project => {
  return {
    type: UPDATE_MPROJECT_STAUS,
    payload: project,
  }
}

export const updateProjectStatusSuccess = project => {
  return {
    type: UPDATE_MPROJECT_STAUS_SUCCESS,
    payload: project,
  }
}

export const updateProjectStatusError = error => {
  return {
    type: UPDATE_MPROJECT_STAUS_FAIL,
    payload: error,
  }
}


export const addProjectBudgeAllocation = (budget, history) => {
  return {
    type: ADD_PROJECT_BUDGET_ALLOCATION,
    payload: { budget, history },
  }
}

export const addProjectBudgeAllocationSuccess = allocations => {
  return {
    type: ADD_PROJECT_BUDGET_ALLOCATION_SUCCESS,
    payload: allocations,
  }
}
export const addProjectBudgeAllocationFail = error => {
  return {
    type: ADD_PROJECT_BUDGET_ALLOCATION_ERROR,
    payload: error,
  }
}

export const getProjectBudgeAllocation = (projectId) => (
  {
    type: GET_PROJECT_BUDGET_ALLOCATION,
    projectId
  })

export const getProjectBudgeAllocationSuccess = allocations => ({
  type: GET_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  payload: allocations,
})

export const getProjectBudgeAllocationFail = error => ({
  type: GET_PROJECT_BUDGET_ALLOCATION_ERROR,
  payload: error,
})


export const deleteProjectBudgeAllocation = id => ({
  type: DELETE_PROJECT_BUDGET_ALLOCATION,
  payload: id,
})

export const deleteProjectBudgeAllocationSuccess = allocation => ({
  type: DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  payload: allocation,
})

export const deleteProjectBudgeAllocationFail = error => ({
  type: DELETE_PROJECT_BUDGET_ALLOCATION_ERROR,
  payload: error,
})

export const updateProjectBudgeAllocation = allocation => ({
  type: UPDATE_PROJECT_BUDGET_ALLOCATION,
  payload: allocation,
})

export const updateProjectBudgeAllocationSuccess = allocations => ({
  type: UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  payload: allocations,
})

export const updateProjectBudgeAllocationFail = error => ({
  type: UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR,
  payload: error,
})


export const addProjectBudgeSpent = (spent, history) => {
  return {
    type: ADD_PROJECT_BUDGET_SPENT,
    payload: { spent, history },
  }
}

export const addProjectBudgeSpentSuccess = spents => {
  return {
    type: ADD_PROJECT_BUDGET_SPENT_SUCCESS,
    payload: spents,
  }
}
export const addProjectBudgeSpentFail = error => {
  return {
    type: ADD_PROJECT_BUDGET_SPENT_ERROR,
    payload: error,
  }
}


export const getProjectBudgeSpent = (projectId) => (
  {
    type: GET_PROJECT_BUDGET_SPENT,
    projectId
  })

export const getProjectBudgeSpentSuccess = spents => ({
  type: GET_PROJECT_BUDGET_SPENT_SUCCESS,
  payload: spents,
})

export const getProjectBudgeSpentFail = error => ({
  type: GET_PROJECT_BUDGET_SPENT_ERROR,
  payload: error,
})


export const deleteProjectBudgeSpent = id => ({
  type: DELETE_PROJECT_BUDGET_SPENT,
  payload: id,
})

export const deleteProjectBudgeSpentSuccess = spent => ({
  type: DELETE_PROJECT_BUDGET_SPENT_SUCCESS,
  payload: spent,
})

export const deleteProjectBudgeSpentFail = error => ({
  type: DELETE_PROJECT_BUDGET_SPENT_ERROR,
  payload: error,
})


export const updateProjectBudgeSpent = spent => ({
  type: UPDATE_PROJECT_BUDGET_SPENT,
  payload: spent,
})

export const updateProjectBudgeSpentSuccess = spents => ({
  type: UPDATE_PROJECT_BUDGET_SPENT_SUCCESS,
  payload: spents,
})

export const updateProjectBudgeSpentFail = error => ({
  type: UPDATE_PROJECT_BUDGET_SPENT_ERROR,
  payload: error,
})


export const addProjectCampLead = (lead, history) => {
  return {
    type: ADD_PROJECT_CAMP_LEAD,
    payload: { lead, history },
  }
}

export const addProjectCampLeadSuccess = leads => {
  return {
    type: ADD_PROJECT_CAMP_LEAD_SUCCESS,
    payload: leads,
  }
}
export const addProjectCampLeadFail = error => {
  return {
    type: ADD_PROJECT_CAMP_LEAD_ERROR,
    payload: error,
  }
}



export const getProjectCampLead = (agentId, projectId) => (
  {
    type: GET_PROJECT_CAMP_LEAD,
    payload:{agentId, projectId}
  })

export const getProjectCampLeadSuccess = campleads => ({
  type: GET_PROJECT_CAMP_LEAD_SUCCESS,
  payload: campleads,
})

export const getProjectCampLeadFail = error => ({
  type: GET_PROJECT_CAMP_LEAD_ERROR,
  payload: error,
})



export const deleteProjectCampLead = id => ({
  type: DELETE_PROJECT_CAMP_LEAD,
  payload: id,
})

export const deleteProjectCampLeadSuccess = camplead => ({
  type: DELETE_PROJECT_CAMP_LEAD_SUCCESS,
  payload: camplead,
})

export const deleteProjectCampLeadFail = error => ({
  type: DELETE_PROJECT_CAMP_LEAD_ERROR,
  payload: error,
})

export const getProjectFeatureStandard = (projectId) => ({
  type: GET_PROJECT_FEATURE_STANDARD,
  projectId
})

export const getProjectFeatureStandardSuccess = projectStandardFeatures => ({
  type: GET_PROJECT_FEATURE_STANDARD_SUCCESS,
  payload: projectStandardFeatures,
})

export const getProjectFeatureStandardFail = error => ({
  type: GET_PROJECT_FEATURE_STANDARD_ERROR,
  payload: error,
})


export const addProjectEmailCampFilter = (emailFilter, history) => {
  return {
    type: ADD_PROJECT_EMAIL_COMP_FILTER,
    payload: { emailFilter, history },
  }
}

export const addProjectEmailCampFilterSuccess = emailFilters => {
  return {
    type: ADD_PROJECT_EMAIL_COMP_FILTER_SUCCESS,
    payload: emailFilters,
  }
}
export const addProjectEmailCampFilterFail = error => {
  return {
    type: ADD_PROJECT_EMAIL_COMP_FILTER_ERROR,
    payload: error,
  }
}

export const addProjectSettingWorksheet = (worksheet, history) => {
  console.log(worksheet)
  return {
    type: ADD_SETTING_WORKSHEET,
    payload: { worksheet, history },
  }
}

export const addProjectSettingWorksheetSuccess = worksheets => {
  return {
    type: ADD_SETTING_WORKSHEET_SUCCESS,
    payload: worksheets,
  }
}
export const addProjectSettingWorksheetFail = error => {
  return {
    type: ADD_SETTING_WORKSHEET_ERROR,
    payload: error,
  }
}



export const getProjectSettingWorksheet = (projectId) => ({
  type: GET_SETTING_WORKSHEET,
  projectId
})

export const getProjectSettingWorksheetSuccess = worksheets => ({
  type: GET_SETTING_WORKSHEET_SUCCESS,
  payload: worksheets,
})

export const getProjectSettingWorksheetFail = error => ({
  type: GET_SETTING_WORKSHEET_ERROR,
  payload: error,
})

export const deleteProjectSettingWorksheet = id => ({
  type: DELETE_SETTING_WORKSHEET,
  payload: id,
})

export const deleteProjectSettingWorksheetSuccess = worksheet => ({
  type: DELETE_SETTING_WORKSHEET_SUCCESS,
  payload: worksheet,
})

export const deleteProjectSettingWorksheetFail = error => ({
  type: DELETE_SETTING_WORKSHEET_ERROR,
  payload: error,
})


export const sendProjectLeadWorksheet = (leadworksheet, history) => {
  return {
    type: SEND_LEAD_WORKSHEET,
    payload: { leadworksheet, history },
  }
}

export const sendProjectLeadWorksheetSuccess = leadworksheets => {
  return {
    type: SEND_LEAD_WORKSHEET_SUCCESS,
    payload: leadworksheets,
  }
}
export const sendProjectLeadWorksheetFail = error => {
  return {
    type: SEND_LEAD_WORKSHEET_ERROR,
    payload: error,
  }
}



export const getAllProjectLead = (agentId) => (
  {
    type: GET_ALL_PROJECT_LEAD,
    agentId
  })

export const getAllProjectLeadSuccess = allleads => ({
  type: GET_ALL_PROJECT_LEAD_SUCCESS,
  payload: allleads,
})

export const getAllProjectLeadFail = error => ({
  type: GET_ALL_PROJECT_LEAD_ERROR,
  payload: error,
})


export const getAllProjectClient = (agentId) => (
  {
    type: GET_ALL_PROJECT_CLIENT,
    agentId
  })

export const getAllProjectClientSuccess = allclients => ({
  type: GET_ALL_PROJECT_CLIENT_SUCCESS,
  payload: allclients,
})

export const getAllProjectClientFail = error => ({
  type: GET_ALL_PROJECT_CLIENT_ERROR,
  payload: error,
})


//ProjectOpenHouse
export const addProjectOpenHouseFile = (data, history) => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE_FILE,
    payload: { data, history },
  }
}

export const getAllProjectOpenHouseFile = () => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE_FILE,
  }
}

export const getAllProjectOpenHouseFileSuccess = data => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
    payload: data,
  }
}

export const getAllProjectOpenHouseFileError = error => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR,
    payload: error,
  }
}


export const addProjectOpenHouseFileSuccess = data => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
    payload: data,
  }
}

export const addProjectOpenHouseFileError = error => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE_FILE_ERROR,
    payload: error,
  }
}

export const addProjectOpenHouse = (data) => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE,
    payload: { data },
  }
}

export const addProjectOpenHouseSuccess = data => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE_SUCCESS,
    payload: data,
  }
}

export const addProjectOpenHouseError = error => {
  return {
    type: ADD_PROJECT_OPEN_HOUSE_ERROR,
    payload: error,
  }
}

export const getAllProjectOpenHouse = (agentId, projectId) => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE,
    payload:{agentId, projectId}
  }
}

export const getAllProjectOpenHouseSuccess = data => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS,
    payload: data,
  }
}

export const getAllProjectOpenHouseError = error => {
  return {
    type: GET_ALL_PROJECT_OPEN_HOUSE_ERROR,
    payload: error,
  }
}

export const getProjectOpenHouse = id => {
  return {
    type: GET_PROJECT_OPEN_HOUSE,
    payload: id,
  }
}

export const getProjectOpenHouseSuccess = data => {
  return {
    type: GET_PROJECT_OPEN_HOUSE_SUCCESS,
    payload: data,
  }
}

export const getProjectOpenHouseError = error => {
  return {
    type: GET_PROJECT_OPEN_HOUSE_ERROR,
    payload: error,
  }
}

export const deleteProjectOpenHouse = id => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE,
    payload: id,
  }
}

export const deleteProjectOpenHouseSuccess = data => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE_SUCCESS,
    payload: data,
  }
}

export const deleteProjectOpenHouseError = error => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE_ERROR,
    payload: error,
  }
}

export const updateProjectOpenHouse = (data) => {
  return {
    type: UPDATE_PROJECT_OPEN_HOUSE,
    payload: { data },
  }
}

export const updateProjectOpenHouseSuccess = data => {
  return {
    type: UPDATE_PROJECT_OPEN_HOUSE_SUCCESS,
    payload: data,
  }
}

export const updateProjectOpenHouseError = error => {
  return {
    type: UPDATE_PROJECT_OPEN_HOUSE_ERROR,
    payload: error,
  }
}



//ProjectDownloadPrice
export const addProjectDownloadPriceFile = (data, history) => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE_FILE,
    payload: { data, history },
  }
}

export const addProjectDownloadPriceFileSuccess = data => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
    payload: data,
  }
}

export const addProjectDownloadPriceFileError = error => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
    payload: error,
  }
}

export const addProjectDownloadPrice = (data) => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE,
    payload: { data },
  }
}

export const addProjectDownloadPriceSuccess = data => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS,
    payload: data,
  }
}

export const addProjectDownloadPriceError = error => {
  return {
    type: ADD_PROJECT_DOWNLOAD_PRICE_ERROR,
    payload: error,
  }
}

export const getAllProjectDownloadPrice = () => {
  return {
    type: GET_ALL_PROJECT_DOWNLOAD_PRICE,
  }
}

export const getAllProjectDownloadPriceSuccess = data => {
  return {
    type: GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS,
    payload: data,
  }
}

export const getAllProjectDownloadPriceError = error => {
  return {
    type: GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR,
    payload: error,
  }
}

export const getProjectDownloadPrice = id => {
  return {
    type: GET_PROJECT_DOWNLOAD_PRICE,
    payload: id,
  }
}

export const getProjectDownloadPriceSuccess = data => {
  return {
    type: GET_PROJECT_DOWNLOAD_PRICE_SUCCESS,
    payload: data,
  }
}

export const getProjectDownloadPriceError = error => {
  return {
    type: GET_PROJECT_DOWNLOAD_PRICE_ERROR,
    payload: error,
  }
}

export const deleteProjectDownloadPrice = id => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE,
    payload: id,
  }
}

export const deleteProjectDownloadPriceSuccess = data => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
    payload: data,
  }
}

export const deleteProjectDownloadPriceError = error => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE_ERROR,
    payload: error,
  }
}





export const updateProjectDownloadPrice = (data) => {
  return {
    type: UPDATE_PROJECT_DOWNLOAD_PRICE,
    payload: { data },
  }
}

export const updateProjectDownloadPriceSuccess = data => {
  return {
    type: UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
    payload: data,
  }
}

export const updateProjectDownloadPriceError = error => {
  return {
    type: UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR,
    payload: error,
  }
}

export const getProjectOpenHouseFiles = (agentId) => (
  {
    type: GET_PROJECT_OPEN_HOUSE_FILE,
    agentId
  })

export const getProjectOpenHouseFilesSuccess = opfiles => ({
  type: GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  payload: opfiles,
})

export const getProjectOpenHouseFilesFail = error => ({
  type: GET_PROJECT_OPEN_HOUSE_FILE_ERROR,
  payload: error,
})

export const deleteProjectOpenHouseFile = id => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE_FILE,
    payload: id,
  }
}

export const deleteProjectOpenHouseFileSuccess = opfile => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
    payload: opfile,
  }
}

export const deleteProjectOpenHouseFileError = error => {
  return {
    type: DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR,
    payload: error,
  }
}


export const getProjectDownloadPriceFiles = (agentId) => (
  {
    type: GET_PROJECT_DOWNLOAD_PRICE_FILE,
    agentId
  })

export const getProjectDownloadPriceFilesSuccess = dpfiles => ({
  type: GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  payload: dpfiles,
})

export const getProjectDownloadPriceFilesFail = error => ({
  type: GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  payload: error,
})

export const deleteProjectDownloadPriceFile = id => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE_FILE,
    payload: id,
  }
}

export const deleteProjectDownloadPriceFileSuccess = dpfile => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
    payload: dpfile,
  }
}

export const deleteProjectDownloadPriceFileError = error => {
  return {
    type: DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
    payload: error,
  }
}


export const getProjectWorksheetLead = (projectId, statusId) => (
  {
    type: GET_PROJECT_WORKSHEET_LEAD,
   payload:{ projectId, statusId }
  })

export const getProjectWorksheetLeadSuccess = worksheetLeads => ({
  type: GET_PROJECT_WORKSHEET_LEAD_SUCCESS,
  payload: worksheetLeads,
})

export const getProjectWorksheetLeadFail = error => ({
  type: GET_PROJECT_WORKSHEET_LEAD_ERROR,
  payload: error,
})


export const getProjectWorksheetLeadPurDetail = (id) => (
  {
    type: GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL,
    id
  })

export const getProjectWorksheetLeadPurDetailSuccess = worksheetPurData => ({
  type: GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS,
  payload: worksheetPurData,
})

export const getProjectWorksheetLeadPurDetailFail = error => ({
  type: GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR,
  payload: error,
})


export const getProjectWorksheetLeadUnitDetail = (id) => (
  {
    type: GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL,
    id
  })

export const getProjectWorksheetLeadUnitDetailSuccess = worksheetUnitData => ({
  type: GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS,
  payload: worksheetUnitData,
})

export const getProjectWorksheetLeadUnitDetailFail = error => ({
  type: GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR,
  payload: error,
})


export const getProjectWorksheetLeadDocDetail = (id) => (
  {
    type: GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL,
    id
  })

export const getProjectWorksheetLeadDocDetailSuccess = worksheetDocData => ({
  type: GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS,
  payload: worksheetDocData,
})

export const getProjectWorksheetLeadDocDetailFail = error => ({
  type: GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR,
  payload: error,
})

export const addProjectInvite = (data) => {
 // console.log(data)
  return {
    type: POST_PROJECT_INVITE,
    payload: { data },
  }
}

export const addProjectInviteSuccess = data => {
  return {
    type: POST_PROJECT_INVITE_SUCCESS,
    payload: data,
  }
}

export const addProjectInviteError = error => {
  return {
    type: POST_PROJECT_INVITE_ERROR,
    payload: error,
  }
}


export const getProjectInvite = (id) => {
  return {
    type: GET_PROJECT_INVITE,
    id,
  }
}

export const getProjectInviteSuccess = invitesdata => {
  return {
    type: GET_PROJECT_INVITE_SUCCESS,
    payload: invitesdata,
  }
}

export const getProjectInviteError = error => {
  return {
    type: GET_PROJECT_INVITE_ERROR,
    payload: error,
  }
}


export const getProjectInviteDetail = (id) => {
  return {
    type: GET_PROJECT_INVITE_DETAIL,
    id,
  }
}

export const getProjectInviteDetailSuccess = inviteDetail => {
  return {
    type: GET_PROJECT_INVITE_DETAIL_SUCCESS,
    payload: inviteDetail,
  }
}

export const getProjectInviteDetailError = error => {
  return {
    type: GET_PROJECT_INVITE_DETAIL_ERROR,
    payload: error,
  }
}



export const deleteProjectInvite = id => {
  return {
    type: DELETE_PROJECT_INVITE,
    payload: id,
  }
}

export const deleteProjectInviteSuccess = invitedata => {
  return {
    type: DELETE_PROJECT_INVITE_SUCCESS,
    payload: invitedata,
  }
}

export const deleteProjectInviteError = error => {
  return {
    type: DELETE_PROJECT_INVITE_ERROR,
    payload: error,
  }
}


export const getProjectInviteAtt = (EventId) => {
  return {
    type: VIEW_PROJECT_INVITE_ATTENDEES,
    EventId,
  }
}

export const getProjectInviteAttSuccess = attdatas => {
  return {
    type: VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS,
    payload: attdatas,
  }
}

export const getProjectInviteAttError = error => {
  return {
    type: VIEW_PROJECT_INVITE_ATTENDEES_ERROR,
    payload: error,
  }
}


export const updateProjectWorksheetUnit = project => {
  return {
    type: UPDATE_PROJECT_WORKSHEET_UNIT,
    payload: project,
  }
}

export const updateProjectWorksheetUnitSuccess = project => {
  return {
    type: UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS,
    payload: project,
  }
}

export const updateProjectWorksheetUnitError = error => {
  return {
    type: UPDATE_PROJECT_WORKSHEET_UNIT_ERROR,
    payload: error,
  }
}



export const getProjectWorksheetUnitinfo = (id) => (
  {
    type: GET_PROJECT_WORKSHEET_UNIT_INFO,
    id
  })

export const getProjectWorksheetUnitinfoSuccess = unitdata => ({
  type: GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS,
  payload: unitdata,
})

export const getProjectWorksheetUnitinfoFail = error => ({
  type: GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR,
  payload: error,
})


export const getProjectLeadByWorsheetStatus = (agentId, projectId, unitStatusText, isWorkSheetFilled) => (
  {
    type: GET_PROJECT_LEAD_BY_WORKSHEET_STATUS,
    payload: { agentId, projectId, unitStatusText, isWorkSheetFilled }
  })

export const getProjectLeadByWorsheetStatusSuccess = worksheetleads => ({
  type: GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS,
  payload: worksheetleads,
})

export const getProjectLeadByWorsheetStatusFail = error => ({
  type: GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR,
  payload: error,
})