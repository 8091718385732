import React from 'react';
import PropTypes from 'prop-types';
import "./rscard.css"

const RsCard = ({ title, description, iconClass }) => {
  return (
    <div className="card rs-card">
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h3 className="mb-0 font-size-18">{title}</h3>
            <h4 className="mb-0">{description}</h4>
          </div>
          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
            <span className="avatar-title">
              <i className={iconClass + " font-size-24"} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

RsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  iconClass: PropTypes.string,
};

RsCard.defaultProps = {
  title: 'Default Title',
  description: 'Default Description',
  iconClass: 'bx-default-icon',
};

export default RsCard;