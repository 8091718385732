import {
  ADD_LEAD_APP,
  ADD_LEAD_APP_FAIL,
  ADD_LEAD_APP_SUCCESS,
  ADD_LEAD_CALLLOG,
  ADD_LEAD_CALLLOG_FAIL,
  ADD_LEAD_CALLLOG_SUCCESS,
  ADD_LEAD_EMAILLOG,
  ADD_LEAD_EMAILLOG_FAIL,
  ADD_LEAD_EMAILLOG_SUCCESS,
  ADD_LEAD_LIST,
  ADD_LEAD_NOTE,
  ADD_LEAD_LIST_FAIL,
  ADD_LEAD_NOTE_FAIL,
  ADD_LEAD_LIST_SUCCESS,
  ADD_LEAD_NOTE_SUCCESS,
  CONVERT_LEAD_INTO_CLIENT,
  CONVERT_LEAD_INTO_CLIENT_FAIL,
  CONVERT_LEAD_INTO_CLIENT_SUCCESS,
  CONVERT_LEAD_INTO_CONTACT,
  CONVERT_LEAD_INTO_CONTACT_FAIL,
  CONVERT_LEAD_INTO_CONTACT_SUCCESS,
  CREATE_LEAD,
  CREATE_LEAD_FAIL,
  CREATE_LEAD_SUCCESS,
  GET_LEADS,
  GET_LEADS_ERROR,
  GET_LEADS_SUCCESS,
  GET_LEAD_ALL_ACT,
  GET_LEAD_ALL_ACT_FAIL,
  GET_LEAD_ALL_ACT_SUCCESS,
  GET_LEAD_APP,
  GET_LEAD_APP_FAIL,
  GET_LEAD_APP_SUCCESS,
  GET_LEAD_CALLLOG,
  GET_LEAD_CALLLOG_FAIL,
  GET_LEAD_CALLLOG_SUCCESS,
  GET_LEAD_DETAIL,
  GET_LEAD_DETAIL_FAIL,
  GET_LEAD_DETAIL_SUCCESS,
  GET_LEAD_EMAILLOG,
  GET_LEAD_EMAILLOG_FAIL,
  GET_LEAD_EMAILLOG_SUCCESS,
  GET_LEAD_FOLLOW,
  GET_LEAD_FOLLOW_FAIL,
  GET_LEAD_FOLLOW_SUCCESS,
  GET_LEAD_NOTE,
  GET_LEAD_NOTE_FAIL,
  GET_LEAD_NOTE_SUCCESS,
  GET_LEAD_LIST,
  GET_LEAD_LIST_FAIL,
  GET_LEAD_LIST_SUCCESS,
  POST_LEAD_FOLLOW,
  POST_LEAD_FOLLOW_FAIL,
  POST_LEAD_FOLLOW_SUCCESS,
  UPDARE_LEAD_STAGE_BOARD,
  UPDARE_LEAD_STAGE_BOARD_FAIL,
  UPDARE_LEAD_STAGE_BOARD_SUCCESS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_STAGE_BOARD,
  UPDATE_LEAD_STAGE_BOARD_FAIL,
  UPDATE_LEAD_STAGE_BOARD_SUCCESS,
  UPDATE_LEAD_STATUS_BOARD,
  UPDATE_LEAD_STATUS_BOARD_FAIL,
  UPDATE_LEAD_STATUS_BOARD_SUCCESS,
  UPDATE_LEAD_SUCCESS
} from "./actionTypes"

export const getLeads = (agentId) => ({
  type: GET_LEADS,
  agentId
})
export const getLeadsSucess = allLeads => ({
  type: GET_LEADS_SUCCESS,
  payload:allLeads
})
export const getLeadsFail = error => ({
  type: GET_LEADS_ERROR,
  payload: error,
})
export const createLeadData = (lead, history) => {
  return {
    type: CREATE_LEAD,
    payload: { lead, history },
  }
}


export const createLeadDataSuccess = lead => ({
  type: CREATE_LEAD_SUCCESS,
  payload: lead,
})

export const createLeadDataFail = error => ({
  type: CREATE_LEAD_FAIL,
  payload: error,
})




export const getLeadDetail = leadId => ({
  type: GET_LEAD_DETAIL,
  leadId,
})

export const getLeadDetailSuccess = leads => ({
  type: GET_LEAD_DETAIL_SUCCESS,
  payload: leads,
})

export const getLeadDetailFail = error => ({
  type: GET_LEAD_DETAIL_FAIL,
  payload: error,
})

export const addLeadAPP = (appointment, history) => {
  return {
    type: ADD_LEAD_APP,
    payload: { appointment, history },
  }
}


export const addLeadAPPSuccess = appointment => ({
  type: ADD_LEAD_APP_SUCCESS,
  payload: appointment,
})

export const addLeadAPPFail = error => ({
  type: ADD_LEAD_APP_FAIL,
  payload: error,
})

export const getAppLeadTab = LeadId => (
  {
    type: GET_LEAD_APP,
    LeadId,
  })

export const getAppLeadTabSuccess = appointments => ({
  type: GET_LEAD_APP_SUCCESS,
  payload: appointments,
})

export const getAppLeadTabFail = error => ({
  type: GET_LEAD_APP_FAIL,
  payload: error,
})


export const addLeadNoteTab = (notes, history) => {
  return {
    type: ADD_LEAD_NOTE,
    payload: { notes, history },
  }
}

export const addLeadListTab = (lists, history) => {
  return {
    type: ADD_LEAD_LIST,
    payload: { lists, history },
  }
}


export const addLeadNoteTabSuccess = notes => ({
  type: ADD_LEAD_NOTE_SUCCESS,
  payload: notes,
})

export const addLeadNoteTabFail = error => ({
  type: ADD_LEAD_NOTE_FAIL,
  payload: error,
})

export const addLeadListTabSuccess = notes => ({
  type: ADD_LEAD_LIST_SUCCESS,
  payload: notes,
})

export const addLeadListTabFail = error => ({
  type: ADD_LEAD_LIST_FAIL,
  payload: error,
})

export const getNoteLeadTab = leadId => (
  {
    type: GET_LEAD_NOTE,
    leadId,
  })

  export const getListLeadTab = leadId => (
    {
      type: GET_LEAD_LIST,
      leadId,
    })

    export const getListLeadTabSuccess = leadNotes => (
      {
        type: GET_LEAD_LIST_SUCCESS,
        payload: leadNotes,
      })
      
      export const getListLeadTabFail = error => ({
        type: GET_LEAD_LIST_FAIL,
        payload: error,
      })

export const getNoteLeadTabSuccess = leadNotes => (
{
  type: GET_LEAD_NOTE_SUCCESS,
  payload: leadNotes,
})

export const getNoteLeadTabFail = error => ({
  type: GET_LEAD_NOTE_FAIL,
  payload: error,
})



export const addFollowNoteTab = (follows, history) => {
  return {
    type: POST_LEAD_FOLLOW,
    payload: { follows, history },
  }
}


export const addLeadFollowTabSuccess = follows => ({
  type: POST_LEAD_FOLLOW_SUCCESS,
  payload: follows,
})

export const addLeadFollowTabFail = error => ({
  type: POST_LEAD_FOLLOW_FAIL,
  payload: error,
})

export const getFollowLeadTab = leadId => (
  {
    type: GET_LEAD_FOLLOW,
    leadId,
  })

export const getFollowLeadTabSuccess = leadFollows => ({
  type: GET_LEAD_FOLLOW_SUCCESS,
  payload: leadFollows,
})

export const getFollowLeadTabFail = error => ({
  type: GET_LEAD_FOLLOW_FAIL,
  payload: error,
})


export const getAllActLeadTab = leadId => (
  {
    type: GET_LEAD_ALL_ACT,
    leadId,
  })

export const getAllActLeadTabSuccess = activities => ({
  type: GET_LEAD_ALL_ACT_SUCCESS,
  payload: activities,
})

export const getAllActLeadTabFail = error => ({
  type: GET_LEAD_ALL_ACT_FAIL,
  payload: error,
})

export const updateLead = lead => (
  {

    type: UPDATE_LEAD,
    payload: lead,
  })

export const updateLeadSuccess = lead => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead,
})

export const updateLeadFail = error => ({
  type: UPDATE_LEAD_FAIL,
  payload: error,
})


export const addLeadCallLog = (calllog, history) => {
  return {
    type: ADD_LEAD_CALLLOG,
    payload: { calllog, history },
  }
}


export const addLeadCallLogSuccess = calllogs => ({
  type: ADD_LEAD_CALLLOG_SUCCESS,
  payload: calllogs,
})

export const addLeadCallLogFail = error => ({
  type: ADD_LEAD_CALLLOG_FAIL,
  payload: error,
})

export const getLeadCallLog = leadId => (
 
  {
    type: GET_LEAD_CALLLOG,
    leadId,
  })

export const getLeadCallLogSuccess = calllogs => (
{
  type: GET_LEAD_CALLLOG_SUCCESS,
  payload: calllogs,
})

export const getLeadCallLogFail = error => ({
  type: GET_LEAD_CALLLOG_FAIL,
  payload: error,
})


export const addLeadEmailLog = (emaillog, history) => {
  return {
    type: ADD_LEAD_EMAILLOG,
    payload: { emaillog, history },
  }
}


export const addLeadEmailLogSuccess = emaillogs => ({
  type: ADD_LEAD_EMAILLOG_SUCCESS,
  payload: emaillogs,
})

export const addLeadEmailLogFail = error => ({
  type: ADD_LEAD_EMAILLOG_FAIL,
  payload: error,
})


export const getLeadEmailLog = leadId => (
 
  {
    type: GET_LEAD_EMAILLOG,
    leadId,
  })

export const getLeadEmailLogSuccess = emaillogs => (
{
  type: GET_LEAD_EMAILLOG_SUCCESS,
  payload: emaillogs,
})

export const getLeadEmailLogFail = error => ({
  type: GET_LEAD_EMAILLOG_FAIL,
  payload: error,
})


export const addLeadLeadStageBoard = (leadBoard, history) => {
  return {
    type: UPDATE_LEAD_STAGE_BOARD,
    payload: { leadBoard, history },
  }
}


export const addLeadLeadStageBoardSuccess = leadBoards => ({
  type: UPDATE_LEAD_STAGE_BOARD_SUCCESS,
  payload: leadBoards,
})

export const addLeadLeadStageBoardFail = error => ({
  type: UPDATE_LEAD_STAGE_BOARD_FAIL,
  payload: error,
})


export const addLeadStatusBoard = (leadBoard, history) => {
  return {
    type: UPDATE_LEAD_STATUS_BOARD,
    payload: { leadBoard, history },
  }
}


export const addLeadStatusBoardSuccess = leadBoards => ({
  type: UPDATE_LEAD_STATUS_BOARD_SUCCESS,
  payload: leadBoards,
})

export const addLeadStatusBoardFail = error => ({
  type: UPDATE_LEAD_STATUS_BOARD_FAIL,
  payload: error,
})


export const convertLeadIntoClient = (lead, history) => {
  return {
    type: CONVERT_LEAD_INTO_CLIENT,
    payload: { lead, history },
  }
}


export const convertLeadIntoClientSuccess = leads => ({
  type: CONVERT_LEAD_INTO_CLIENT_SUCCESS,
  payload: notes,
})

export const convertLeadIntoClientFail = error => ({
  type: CONVERT_LEAD_INTO_CLIENT_FAIL,
  payload: error,
})


export const convertLeadIntoContact = (lead, history) => {
  return {
    type: CONVERT_LEAD_INTO_CONTACT,
    payload: { lead, history },
  }
}


export const convertLeadIntoContactSuccess = leads => ({
  type: CONVERT_LEAD_INTO_CONTACT_SUCCESS,
  payload: notes,
})

export const convertLeadIntoContactFail = error => ({
  type: CONVERT_LEAD_INTO_CONTACT_FAIL,
  payload: error,
})