import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link, withRouter, BrowserRouter as Router } from "react-router-dom"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { map } from "lodash"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from 'axios';
import { addNewActivity, postActivityFilter, changeActivityStatus, deleteMainActivity, updateMainActivity, getSellerShowingDetail, getBuyerShowingDetail, getOpenhouseShowingDetail, getProjectInviteDetail } from "../../store/actions"
import DeleteModal from "../../components/Common/DeleteModal";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import RsAdNew from "components/buttons/Adnew";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//i18n
import { withTranslation } from "react-i18next"
import Moment from "react-moment";
import moment from 'moment';
import SaveButton from "components/buttons/save";
class Activity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'All',
      activePeriod: 'Today',
      isLoading: false,
      activity: '',
      showFlatpickr: false,
      dateRange: [new Date(), moment().add(1, 'day').toDate()]
    }
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleSellerShowing = this.toggleSellerShowing.bind(this);
    this.toggleOpenhouseShowing = this.toggleOpenhouseShowing.bind(this);
    this.toggleBuyerShowing = this.toggleBuyerShowing.bind(this)
    this.toggleEventShowing = this.toggleEventShowing.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
    }));
};
onClickDelete = (activity) => {
    this.setState({ activity: activity });
    this.setState({ deleteModal: true });
};


convertISOTo12Hour = (isoString) => {
  const date = new Date(isoString);
  let hours = date.getHours();
  const originalMinutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const roundedMinutes = Math.round(originalMinutes / 15) * 15;
  const minutes = roundedMinutes % 60;
  if (roundedMinutes === 60) {
    hours = (hours % 12) + 1;
    if (hours === 12) {
      ampm = ampm === 'AM' ? 'PM' : 'AM';
    }
  }

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const minInt = parseInt(formattedMinutes)
  return { hours, minInt, ampm };
};
handleDeleteActivity = () => {
    const { onDeleteActivity } = this.props;
    const { activity } = this.state;

    if (activity.id !== undefined) {
        onDeleteActivity(activity);
        this.setState({ deleteModal: false });
    }
};
  
  sellerShowingToggle() {
    this.setState(prevState => ({
        isSellerShowing: !prevState.isSellerShowing
    }));
  }
 
  toggleSellerShowing = arg => {
    const activity = arg;
    const {  onGetSellerShowingDetail } = this.props;
    onGetSellerShowingDetail(activity.id);
    this.sellerShowingToggle();
  };

  buyerShowingToggle() {
    this.setState(prevState => ({
        isBuyerShowing: !prevState.isBuyerShowing
    }));
  }
  toggleBuyerShowing = arg => {
    const activity = arg;
    const {  onGetBuyerShowingDetail } = this.props;
    onGetBuyerShowingDetail(activity.id);
    this.buyerShowingToggle();
  };

  eventShowingToggle() {
    this.setState(prevState => ({
        isEventShowing: !prevState.isEventShowing
    }));
  }
  toggleEventShowing = arg => {
    const activity = arg;
    const {  onGetProjectInviteDetail } = this.props;
    onGetProjectInviteDetail(activity.id);
    this.eventShowingToggle();
  };

  openhouseShowingToggle() {
    this.setState(prevState => ({
        isOpenhouseShowing: !prevState.isOpenhouseShowing
    }));
  }
  toggleOpenhouseShowing = arg => {
    const activity = arg;
    const {  onGetOpenhouseShowingDetail } = this.props;
    onGetOpenhouseShowingDetail(activity.id);
    this.openhouseShowingToggle();
  };

toggleEdit() {
  this.setState({ activity: "", isEdit: false, iseditOpen: !this.state.iseditOpen });
  this.editToggle();
}
editToggle() {
  this.setState(prevState => ({
      iseditOpen: !prevState.iseditOpen
  }));
}
toggleEdit = arg => {
  const activity = arg;
  this.setState({
    activity: {
            id: activity.id,
            activityId: activity.activityId,
            agentId: activity.agentId,
            title: activity.title,
            contactName: activity.contactName,
            contactPhone: activity.contactPhone,
            contactEmail: activity.contactEmail,
            activityStartDate: activity.activityStartDate,
            startTime: activity.startTime,
            endTime: activity.endTime,
            activityType: activity.activityType,
            activityStatus: activity.activityStatus,
            activityRefId: activity.activityRefId,
            activityDescription: activity.activityDescription,
            activityLocationType: activity.activityLocationType,
            activityLocation: activity.activityLocation,
            activityLatitude: activity.activityLatitude,
            activityLongitude: activity.activityLongitude,
            isActive: activity.isActive
      },
      isEdit: true,
  });
  this.editToggle();
};
  handleChangeStatus = (activity, newStatus) => {
    this.setState({
      updateActivity: {
        id: activity.id,
        activityId: activity.activityId,
        agentId: parseInt(activity.agentId),
        title: activity.title,
        contactName: activity.contactName,
        contactPhone: activity.contactPhone,
        contactEmail: activity.contactEmail,
        activityStartDate: activity.activityStartDate,
        startTime: activity.startTime,
        endTime: activity.endTime,
        activityType: parseInt(activity.activityType),
        activityStatus: newStatus,
        activityRefId: activity.activityRefId,
        activityDescription: activity.activityDescription,
        activityLocationType: parseInt(activity.activityLocationType),
        activityLocation: activity.activityLocation,
        activityLatitude: activity.activityLatitude ? activity.activityLatitude : null,
        activityLongitude: activity.activityLongitude ? activity.activityLongitude : null,
        isActive: activity.isActive
      }
    }, () => {
      this.props.changeActivityStatus(this.state.updateActivity)
    });
  };
  handleStartTime = (setFieldValue, values) => {
    const { startHrs, startMin, startDif } = values;
    const hours = parseInt(startHrs, 10);
    const minutes = parseInt(startMin, 10);
    const meridiem = startDif === 'PM' && hours < 12 ? hours + 12 : hours;
    const date = new Date();
    const formattedTime = moment()
      .set({ hour: meridiem, minute: minutes })
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    setFieldValue('startTime', formattedTime);

  };

  handleEndTime = (setFieldValue, values) => {
    const { endHrs, endMin, endDif } = values;
    const hours = parseInt(endHrs, 10);
    const minutes = parseInt(endMin, 10);
    const meridiem = endDif === 'PM' && hours < 12 ? hours + 12 : hours;
    const date = new Date();
    const formattedTime = moment()
      .set({ hour: meridiem, minute: minutes })
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    setFieldValue('endTime', formattedTime);

  };
  
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });
    
  }
  componentDidMount() {
    this.fetchData();
  }
  handleSelect = (tab) => {
    this.setState({ activeTab: tab, isLoading: true }, this.fetchData);
  };
  handlePeriodSelect = (period) => {
    if (period === 'Select Period') {
      this.setState({ showFlatpickr: true });
    } else {
      this.setState({ activePeriod: period, dateRange: [], isLoading: true, showFlatpickr: false }, this.fetchData);
    }
  };
  handleDateChange = (selectedDates) => {
    const { selection } = selectedDates;
    this.setState({
      dateRange: [selection.startDate, selection.endDate],
      activePeriod: 'Select Period',
      //activePeriod: `${moment(selection.startDate).format('DD/MM/YYYY')} - ${moment(selection.endDate).format('DD/MM/YYYY')}`,
      showFlatpickr: false,
      isLoading: true
    }, this.fetchData);
  };
  convertDateRange = (activityFromDate, activityToDate) => {
    const from = moment(activityFromDate).startOf('day');
    const to = moment(activityToDate).endOf('day');
    return { from, to };
};

 getDateRange = (period, dateRange = [new Date(), new Date()]) => {
    const today = moment().startOf('day');
    switch (period) {
      case 'Today':
        return {
          from: today.clone().set({ hour: 0, minute: 0, second: 1 }),
          to: today.clone().set({ hour: 23, minute: 59, second: 59 })
        };
      case 'Tomorrow':
        const tomorrow = today.clone().add(1, 'days').startOf('day');
        return {
          from: tomorrow.clone().set({ hour: 0, minute: 0, second: 1 }),
          to: tomorrow.clone().set({ hour: 23, minute: 59, second: 59 })
        };
      case 'This Week':
        const startOfWeek = today.clone().startOf('week').set({ hour: 0, minute: 0, second: 1 });
        const endOfWeek = today.clone().endOf('week').set({ hour: 23, minute: 59, second: 59 });
        return { from: startOfWeek, to: endOfWeek };
      case 'Select Period':
      
      if (dateRange.length !== 2 || !moment(dateRange[0]).isValid() || !moment(dateRange[1]).isValid()) {
        throw new Error("dateRange must be an array with two valid dates [startDate, endDate]");
      }
        const [startDate, endDate] = dateRange;
        console.log(startDate);

        return {
          from: moment(startDate).set({ hour: 0, minute: 0, second: 1 }),
          to: moment(endDate).set({ hour: 23, minute: 59, second: 59 })
        };
      default:
        console.log('No Selection');
        return {
          from: today.clone().set({ hour: 0, minute: 0, second: 1 }),
          to: today.clone().set({ hour: 23, minute: 59, second: 59 })
        };
    }
};
 
  fetchData = () => {
    const { activeTab, activePeriod, dateRange   } = this.state;
    const activityTypeIdMap = {
      'All': 0,
      'Calls': 3,
      'Meetings': 1,
      'Task': 2,
      'Emails': 4,
      'Deadlines': 5,
      'Seller Showings': 6,
      'Buyer showing': 7,
      'Open house': 8,
      'Event Invites': 9,
      'Follow Ups' : 10
    };

    const isAllSelected = activeTab === 'All';
    const activityTypeId = isAllSelected ? 0 : activityTypeIdMap[activeTab];
    const { from, to } = this.getDateRange(activePeriod, dateRange );
    const { from: convertedFrom, to: convertedTo } = this.convertDateRange(from, to);
    const requestData = {
      activityTypeId: [activityTypeId],
      activityFromDate: convertedFrom,
      activityToDate: convertedTo,
      agentId: parseInt(localStorage.getItem("userId"))
    };
    this.props.postActivityFilter(requestData)
   
  };
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setFieldValue('activityLocation', place.formatted_address);
        setFieldValue('activityLatitude', longitude);
        setFieldValue('activityLongitude', latitude);
      })
      .catch(error => console.error('Error', error));
  };
  handleSubmit = (values, actions) => {
    const payload = {
      agentId : parseInt(values.agentId),
      title: values.title,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      contactEmail: values.contactEmail,
      activityStartDate: values.activityStartDate,
      startTime: values.startTime,
      endTime: values.endTime,
      activityStatus:1,
      activityType: parseInt(values.activityType),
      activityRefId:values.activityRefId,
      activityDescription: values.activityDescription,
      activityLocationType: parseInt(values.activityLocationType),
      activityLocation:values.activityLocation,
      activityLatitude: values.activityLatitude ? values.activityLatitude : null,
      activityLongitude: values.activityLongitude ? values.activityLongitude : null,
      isActive: values.isActive
    };
    this.props.addNewActivity(payload)
    this.toggleAddNew();
  }
  render() {
    const { t, activities, onUpdateMainActivity, openhouseshowingdetail, sellershowingdetail, buyershowingdetail, inviteDetail } = this.props;
    const { activeTab, activePeriod, activity, showFlatpickr, dateRange , deleteModal, isEdit,     } = this.state;
    const initialStartTime = (activity.activityType === 1 ) ? this.convertISOTo12Hour( activity.startTime ) : '';
    const initialEndTime = (activity.activityType === 1 ) ? this.convertISOTo12Hour( activity.endTime ) : '';
    //console.log(openhouseshowingdetail)
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
          <ToastContainer autoClose={2000} />
          <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteActivity}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Activity | Realty Space</title>
          </MetaTags>
         
          <Container fluid className="">
           
             <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Activities")}
              breadcrumbItem={this.props.t("Activities")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
            <RsAdNew onClick={this.toggleAddNew}>Add New</RsAdNew>
            </div>
            </Col>
            </Row>
           
            <Card>
           
              <Row className="">
                <Col lg="9">
                <Router>
                <Nav className="projectTab actTabs" variant="tabs">
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'All'} onClick={() => this.handleSelect('All')}>
              <span className="d-none d-sm-block">{t("All")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Calls'} onClick={() => this.handleSelect('Calls')}>
              <span className="d-none d-sm-block"><i className="bx bx-phone-call act-icon"></i> {t("Calls")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Meetings'} onClick={() => this.handleSelect('Meetings')}>
              <span className="d-none d-sm-block"><i className="bx bx-calendar act-icon"></i> {t("Meetings")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Task'} onClick={() => this.handleSelect('Task')}>
              <span className="d-none d-sm-block"><i className="bx bx-notepad act-icon"></i> {t("Task")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Emails'} onClick={() => this.handleSelect('Emails')}>
              <span className="d-none d-sm-block"><i className="bx bx-mail-send act-icon"></i> {t("Emails")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Seller Showings'} onClick={() => this.handleSelect('Seller Showings')}>
              <span className="d-none d-sm-block"><i className="mdi mdi-sign-real-estate  act-icon"></i> {t("Seller Showings")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Buyer showing'} onClick={() => this.handleSelect('Buyer showing')}>
              <span className="d-none d-sm-block"><i className="mdi mdi-home-search-outline act-icon"></i> {t("Buyer showing")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Open house'} onClick={() => this.handleSelect('Open house')}>
              <span className="d-none d-sm-block"><i className="dripicons-user-group act-icon"></i> {t("Open house")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Event Invites'} onClick={() => this.handleSelect('Event Invites')}>
              <span className="d-none d-sm-block"><i className="dripicons-user-group act-icon"></i> {t("Event Invites")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="#" active={activeTab === 'Follow Ups'} onClick={() => this.handleSelect('Follow Ups')}>
              <span className="d-none d-sm-block"><i className="bx bx-right-arrow-alt act-icon"></i> {t("Follow Ups")}</span>
            </NavLink>
          </NavItem>
                </Nav>
                </Router>
                </Col>
                <Col lg="3">
                <Router>
                <Nav tabs className="float-end projectTab actTabs">
              <NavItem>
              <NavLink as={Link} to="#" active={activePeriod === 'Today'} onClick={() => this.handlePeriodSelect('Today')}>
                  <span className="d-none d-sm-block">{t("Today")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink as={Link} to="#" active={activePeriod === 'Tomorrow'} onClick={() => this.handlePeriodSelect('Tomorrow')}>
                  <span className="d-none d-sm-block">{t("Tomorrow")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink as={Link} to="#" active={activePeriod === 'This Week'} onClick={() => this.handlePeriodSelect('This Week')}>
                  <span className="d-none d-sm-block">{t("This Week")}</span>
               </NavLink>
              </NavItem>
              {/* <NavItem>
              <NavLink as={Link} to="#" active={activePeriod.includes('/') ? true : false} onClick={() => this.handlePeriodSelect('Select Period')}>
                <span className="d-none d-sm-block">{activePeriod.includes('/') ? activePeriod : t("Select Period")}</span>
               </NavLink>
              </NavItem> */}
            </Nav>
           
            </Router>
                </Col>
              </Row>
              { showFlatpickr && (
                 <DateRangePicker
                 ranges={[{ startDate: this.state.dateRange[0], endDate: this.state.dateRange[1], key: 'selection' }]}
                 onChange={this.handleDateChange}
                 months={2}
                 direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                 minDate={new Date()}
               />
              )}
            
            </Card>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                        <thead className="">
                          <tr>
                           
                            <th className="align-middle">Done</th>
                            <th className="align-middle">Subject</th>
                            <th className="align-middle">Name</th>

                            <th className="align-middle">Email</th>
                            <th className="align-middle">Phone</th>
                            <th className="align-middle">Due Date</th>

                            <th className="align-middle">Assingned To</th>
                          </tr>
                        </thead>
                        <tbody>
                        {map(activities, (activity, prokey) => (
                          <tr key={prokey}>
                           
                            <td>
                                 {
                                      activity.activityStatus !== null && activity.activityType >= 1 && activity.activityType <= 5 ? (
                                        activity.activityStatus < 2 ? (
                                          <Link to="#"  onClick={() => this.handleChangeStatus(activity, 3)} className="status-check not-done">
                                            <i className="bx bxs-check-circle"></i>
                                          </Link>
                                        ) : (
                                          <Link to="#" onClick={() => this.handleChangeStatus(activity, 1)} className="status-check done">
                                            <i className="bx bxs-check-circle"></i>
                                          </Link>
                                        )
                                      ) : (
                                       
                                        <></>
                                      )
                                    }
                            </td>
                          
                            <td>
                            {
                                activity.activityType === 5 ? (
                                  <Link to="#" onClick={() => this.toggleEdit(activity)}>
                                     <i className="bx bx-calendar"></i> {activity.title}
                                  </Link>
                                ) : activity.activityType === 1 ? (
                                  
                                 <span> <Link to="#" onClick={() => this.toggleEdit(activity)}><i className="bx bx-calendar"></i> {activity.title} </Link></span>
                                ) : activity.activityType === 2 ? (
                                  
                                  <span> <Link to="#" onClick={() => this.toggleEdit(activity)}><i className="bx bx-notepad"></i> {activity.title} </Link></span>
                                ) : activity.activityType === 3 ? (
                                  
                                  <span> <Link to="#" onClick={() => this.toggleEdit(activity)}><i className="bx bx-phone-call"></i> {activity.title} </Link></span>

                                ) : activity.activityType === 4 ? (
                                  
                                  <span> <Link to="#" onClick={() => this.toggleEdit(activity)}><i className="bx bx-mail-send"></i> {activity.title} </Link></span>
                                
                                ) : activity.activityType === 6 ? (
                                
                                  <span> <Link to="#" onClick={() => this.toggleSellerShowing(activity)}><i className="mdi mdi-sign-real-estate"></i> { activity.title} </Link></span>
                                ) : activity.activityType === 7 ? (
                                
                                  <span><Link to="#" onClick={() => this.toggleBuyerShowing(activity)}><i className="mdi mdi-home-search-outline"></i>  {activity.title} </Link></span>
                                ) : activity.activityType === 8 ? (
                                
                                  <span><Link to="#" onClick={() => this.toggleOpenhouseShowing(activity)}><i className="dripicons-user-group"></i>  {activity.title} </Link></span>
                                ) : activity.activityType === 9 ? (
                                
                                  <span><Link to="#" onClick={() => this.toggleEventShowing(activity)}><i className="dripicons-user-group"></i> {activity.title} </Link></span>
                                ) : (
                                  <>
                                    <i className="bx bx-right-arrow-alt"></i><span>{activity.title}</span>
                                    </>
                                  )
                              }
                            </td>
                            <td>{activity.contactName}</td>
                            <td>{activity.contactEmail}</td>
                            <td>{activity.contactPhone}</td>
                            <td>{activity.activityStartDate ? <Moment format="D MMM YY">{activity.activityStartDate}</Moment> : '' } </td>
                            <td>Agent 1</td>
                          </tr>
                       ))}
                        </tbody>
                      </table>


                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
        <Offcanvas
          isOpen={this.state.isAddNew}
          direction="end"
          toggle={this.toggleAddNew}
        >
          <OffcanvasHeader toggle={this.toggleAddNew}>
            New Activity
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                agentId: parseInt(localStorage.getItem('userId')),
                title: '',
                contactName: '',
                contactPhone: '',
                contactEmail: '',
                activityStartDate:'',
                startTime:'',
                endTime:'',
                activityType: '3',
                activityRefId:0,
                activityStatus: 0,
                activityDescription:'',
                activityLocationType:'3',
                activityLocation:'',
                activityLatitude:'',
                activityLongitude:'',
                isActive:true
              }}
              validationSchema={Yup.object().shape({
                activityType: Yup.string().required("This is Required"),
                title: Yup.string().required("This is Required"),
                contactName: Yup.string().required("This is Required"),
                activityStartDate: Yup.string().required("This is Required")
              })}

              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Type Of Activity")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">
                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio4" value="3" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio4">Call</label>

                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio5" value="1" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Meeting</label>

                          <Field type="radio" className="btn-check" onChange={handleChange} name="activityType" id="btnradio6"  value="2" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio6">Task</label>
                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio7"  value="4" autoComplete="off" />
                          <label className="btn btn-outline-secondary"  htmlFor="btnradio7">Email</label>
                          
                        </div>
                        <ErrorMessage
                          name="activityType"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="title">
                          {this.props.t("Subject")}
                        </Label>
                        <Field
                              name="title"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.title && touched.title
                                  ? " is-invalid"
                                  : "")
                              }
                              id="title"
                              
                            /> 
                           <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactName">
                          {this.props.t("Name")}
                        </Label>
                        <Field
                              name="contactName"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactName && touched.contactName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactName"
                              
                            /> 
                           <ErrorMessage
                          name="contactName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactEmail">
                          {this.props.t("Email")}
                        </Label>
                        <Field
                              name="contactEmail"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactEmail && touched.contactEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactEmail"
                              
                            /> 
                           <ErrorMessage
                          name="contactEmail"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactPhone">
                          {this.props.t("Phone")}
                        </Label>
                        <Field
                              name="contactPhone"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactPhone && touched.contactPhone
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactPhone"
                              
                            /> 
                           <ErrorMessage
                          name="contactPhone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
          
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="activityStartDate">
                          {this.props.t("Date")}
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.activityStartDate && touched.activityStartDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          onChange={(value) => setFieldValue('activityStartDate', value[0])}
                          options={{
                            altInput: false,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                         <ErrorMessage
                          name="activityStartDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    { values.activityType === '1' && (
                    <>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="startTime">
                          {this.props.t("Start Time")}
                        </Label>
                        <Row>
                        <Col md="4">
                    <Field
                        name="startHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startHrs && touched.startHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="startHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startMin: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startMin && touched.startMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="startMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startDif && touched.startDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="startDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                        </Row>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="endTime">
                          {this.props.t("End Time")}
                        </Label>
                        <Row>
                        <Col md="4">
                    <Field
                        name="endHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endHrs && touched.endHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endMin && touched.endMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="endMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endDif && touched.endDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="endDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    </>
                    )}
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="activityDescription">
                          {this.props.t("Notes")}
                        </Label>
                        <Field
                          name="activityDescription"
                          onChange={handleChange}                            
                          type="text"
                          className={
                            "form-control" +
                            (errors.activityDescription && touched.activityDescription
                              ? " is-invalid"
                              : "")
                          }
                          id="activityDescription"
                          
                        /> 
                      </FormGroup>
                    </Col>
                    { values.activityType === '1' && (
                    <>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="notes">
                          {this.props.t("Location")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">

                          <Field type="radio" className="btn-check" name="activityLocationType" id="btnradio9" value="3" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio9">In-person</label>
                          <Field type="radio" className="btn-check" name="activityLocationType" id="btnradio10" value="1" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio10">Online</label>
                        </div>

                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addressLine1">
                          {this.props.t("Location")}
                        </Label>
                        {values.activityLocationType === '3' ? (
                        <PlacesAutocomplete
                          value={values.activityLocation}
                          onChange={(value) => setFieldValue('activityLocation', value)}
                          onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address...',
                                  className: 'form-control',

                                })}
                              />
                              <div className="">
                                {loading ? <div>Loading...</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                    : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        ) : (
                          <Field
                          name="activityLocation"
                          onChange={handleChange}                            
                          type="text"
                          className={
                            "form-control" +
                            (errors.activityLocation && touched.activityLocation
                              ? " is-invalid"
                              : "")
                          }
                          id="activityLocation"
                          
                        /> 
                        )}
                      </FormGroup>
                    </Col>
                    </>
                    )}


                    {/* <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="notes">
                          {this.props.t("Assigned to")}
                        </Label>
                        <Field
                          name="activityLocation"
                          onChange={handleChange}                            
                          type="text"
                          className={
                            "form-control" +
                            (errors.activityLocation && touched.activityLocation
                              ? " is-invalid"
                              : "")
                          }
                          id="activityLocation"
                          
                        /> 
                      </FormGroup>
                    </Col> */}



                  </Row>

                  <Row>

                    <div className="clearFix">
                      <SaveButton>Save</SaveButton>
                      </div>
                  </Row>

                </Form>
              )}
            </Formik>

          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
                            isOpen={this.state.iseditOpen}
                            direction="end"
                            toggle={this.toggleEdit}
                        >
                            <OffcanvasHeader toggle={this.toggleEdit}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <Formik
              enableReinitialize={true}
              initialValues={{
                        id: activity.id,
                        activityId: activity.activityId,
                        agentId: parseInt(activity.agentId),
                        title: activity.title,
                        contactName: activity.contactName,
                        contactPhone: activity.contactPhone,
                        contactEmail: activity.contactEmail,
                        activityStartDate: activity.activityStartDate,
                        startTime: activity.startTime,
                        endTime: activity.endTime,
                        startHrs: (activity.activityType==1) ? initialStartTime.hours : '',
                        startMin: (activity.activityType==1) ?  initialStartTime.minInt: '',
                        startDif: (activity.activityType==1) ? initialStartTime.ampm : '',
                        endHrs:   (activity.activityType==1) ? initialEndTime.hours : '',
                        endMin:   (activity.activityType==1) ? initialEndTime.minInt: '',
                        endDif:   (activity.activityType==1) ? initialEndTime.ampm : '',
                        activityType: String(activity.activityType),
                        activityStatus: activity.activityType,
                        activityRefId: activity.activityRefId,
                        activityDescription: activity.activityDescription,
                        activityLocationType: String(activity.activityLocationType),
                        activityLocation: activity.activityLocation,
                        activityLatitude: activity.activityLatitude,
                        activityLongitude: activity.activityLongitude,
                        isActive: activity.isActive
              }}
              validationSchema={Yup.object().shape({
                activityType: Yup.string().required("This is Required"),
                title: Yup.string().required("This is Required"),
                contactName: Yup.string().required("This is Required"),
                activityStartDate: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                    const updateData = {
                      id: values.id,
                      activityId: values.activityId,
                      agentId: parseInt(values.agentId),
                      title: values.title,
                      contactName: values.contactName,
                      contactPhone: values.contactPhone,
                      contactEmail: values.contactEmail,
                      activityStartDate: values.activityStartDate,
                      startTime: values.startTime,
                      endTime: values.endTime,
                      activityType: parseInt(values.activityType),
                      activityStatus: parseInt(values.activityType),
                      activityRefId: parseInt(values.activityRefId),
                      activityDescription: values.activityDescription,
                      activityLocationType: parseInt(values.activityLocationType),
                      activityLocation: values.activityLocation,
                      activityLatitude: values.activityLatitude ? values.activityLatitude : null,
                      activityLongitude: values.activityLongitude ? values.activityLongitude : null,
                      isActive: values.isActive
                    };
                    onUpdateMainActivity(updateData);
                } else {

                }
                this.editToggle();
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Type Of Activity")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">
                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio4" value="3" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio4">Call</label>

                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio5" value="1" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Meeting</label>

                          <Field type="radio" className="btn-check" onChange={handleChange} name="activityType" id="btnradio6"  value="2" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio6">Task</label>
                          <Field type="radio" className="btn-check" onChange={handleChange}       name="activityType" id="btnradio7"  value="4" autoComplete="off" />
                          <label className="btn btn-outline-secondary"  htmlFor="btnradio7">Email</label>
                          
                        </div>
                        <ErrorMessage
                          name="activityType"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="title">
                          {this.props.t("Subject")}
                        </Label>
                        <Field
                              name="title"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.title && touched.title
                                  ? " is-invalid"
                                  : "")
                              }
                              id="title"
                              
                            /> 
                           <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactName">
                          {this.props.t("Name")}
                        </Label>
                        <Field
                              name="contactName"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactName && touched.contactName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactName"
                              
                            /> 
                           <ErrorMessage
                          name="contactName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactEmail">
                          {this.props.t("Email")}
                        </Label>
                        <Field
                              name="contactEmail"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactEmail && touched.contactEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactEmail"
                              
                            /> 
                           <ErrorMessage
                          name="contactEmail"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactPhone">
                          {this.props.t("Phone")}
                        </Label>
                        <Field
                              name="contactPhone"
                              onChange={handleChange}                            
                              type="text"
                              className={
                                "form-control" +
                                (errors.contactPhone && touched.contactPhone
                                  ? " is-invalid"
                                  : "")
                              }
                              id="contactPhone"
                              
                            /> 
                           <ErrorMessage
                          name="contactPhone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
          
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="activityStartDate">
                          {this.props.t("Date")}
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.activityStartDate && touched.activityStartDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.activityStartDate}
                          onChange={(value) => setFieldValue('activityStartDate', value[0])}
                          options={{
                            altInput: false,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                         <ErrorMessage
                          name="activityStartDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    { values.activityType === "1" && (
                    <>       
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="startTime">
                          {this.props.t("Start Time")}
                        </Label>
                        <Row>
                        <Col md="4">
                    <Field
                        name="startHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startHrs && touched.startHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="startHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startMin: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startMin && touched.startMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="startMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startDif && touched.startDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="startDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                        </Row>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="endTime">
                          {this.props.t("End Time")}
                        </Label>
                        <Row>
                        <Col md="4">
                    <Field
                        name="endHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endHrs && touched.endHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endMin && touched.endMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="endMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endDif && touched.endDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="endDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                        </>
                    )}
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="activityDescription">
                          {this.props.t("Notes")}
                        </Label>
                        <Field
                          name="activityDescription"
                          onChange={handleChange}                            
                          type="text"
                          className={
                            "form-control" +
                            (errors.activityDescription && touched.activityDescription
                              ? " is-invalid"
                              : "")
                          }
                          id="activityDescription"
                          
                        /> 
                      </FormGroup>
                    </Col>
                    { values.activityType === "1" && (
                    <>       
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="notes">
                          {this.props.t("Location")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">

                          <Field type="radio" className="btn-check" name="activityLocationType" id="btnradio9" value="3"
                             onChange={e => {
                              handleChange(e);
                              setFieldValue('activityLocation', '');
                              setFieldValue('activityLatitude', '');
                              setFieldValue('activityLongitude', '');
                            }}
                          
                          autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio9">In-person</label>
                          <Field type="radio" className="btn-check" name="activityLocationType" id="btnradio10" value="1"
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('activityLocation', '');
                            setFieldValue('activityLatitude', '');
                            setFieldValue('activityLongitude', '');
                          }}
                          autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio10">Online</label>
                        </div>

                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addressLine1">
                          {this.props.t("Location")}
                        </Label>
                        {values.activityLocationType === "3" ? (
                        <PlacesAutocomplete
                          value={values.activityLocation}
                          onChange={(value) => setFieldValue('activityLocation', value)}
                          onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address...',
                                  className: 'form-control',

                                })}
                              />
                              <div className="">
                                {loading ? <div>Loading...</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                    : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        ) : (
                          <Field
                          name="activityLocation"
                          onChange={handleChange}                            
                          type="text"
                          className={
                            "form-control" +
                            (errors.activityLocation && touched.activityLocation
                              ? " is-invalid"
                              : "")
                          }
                          id="activityLocation"
                          
                        /> 
                        )}
                      </FormGroup>
                    </Col>
                    </>
                    )}
                  </Row>

                  <Row>

                    <div className="clearFix">
                      <button type="submit" className="btn btn-primary w-md  mt20" name="addActivity" id="addActivity"> {this.props.t("Update")}</button>{" "}
                      <button type="button" className="btn btn-del-cancle w-md  mt20" onClick={this.toggleEdit}> {this.props.t("Cancel")}</button>{" "}
                      <button type="button" className="btn btn-del-danger w-md  mt20" onClick={() => this.onClickDelete(activity)}> {this.props.t("Delete")}</button></div>
                  </Row>

                </Form>
              )}
            </Formik>
                            </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isSellerShowing}
          direction="end"
          toggle={this.toggleSellerShowing}
      >
          <OffcanvasHeader toggle={this.toggleSellerShowing}>
              Seller Showing
          </OffcanvasHeader>
          <OffcanvasBody>
          <div className="table-responsive">
          { sellershowingdetail ? (
          <Table className="table table-sm m-0">
                        
                        <tbody>
                          <tr>
                            <th scope="row">Listing</th>
                            <td>{sellershowingdetail.clientListing.mlsId}</td>

                          </tr>
                          <tr>
                          <th scope="row">Openhouse Date</th>
                            <td>{sellershowingdetail.showingDate ? <Moment format="D MMM YY">{sellershowingdetail.showingDate}</Moment> : '' }</td>      
                          </tr>
                          <tr>
                            <th scope="row">Start Time</th>
                            <td><Moment format="hh:mm A">{sellershowingdetail.startTime}</Moment></td>
                           
                          </tr>
                          <tr>
                          <th scope="row">End Time</th>
                          <td><Moment format="hh:mm A">{sellershowingdetail.endTime}</Moment></td>
                          
                          </tr>

                          <tr>
                          <th scope="row">Agent Name</th>
                          <td>{sellershowingdetail.agentName}</td>
                          </tr>
                          <tr>
                          <th scope="row">Agent Organization</th>
                          <td>{sellershowingdetail.agentOrganization}</td>
                          </tr>
                          <tr>
                          <th scope="row">Agent Contact</th>
                          <td>{sellershowingdetail.agentContact}</td>
                          </tr>
                          <tr>
                          <th scope="row">Agent Email</th>
                          <td>{sellershowingdetail.agentEmail}</td>
                          </tr>
                                               
                        </tbody>
                      </Table>
          ): null}
                    </div>    
          </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
          isOpen={this.state.isBuyerShowing}
          direction="end"
          toggle={this.toggleBuyerShowing}
      >
          <OffcanvasHeader toggle={this.toggleBuyerShowing}>
              Buyer Showing
          </OffcanvasHeader>
          <OffcanvasBody>
          <div className="table-responsive">
          { buyershowingdetail ? (
          <Table className="table table-sm m-0">
                        
                        <tbody>
                          <tr>
                            <th scope="row">Listing</th>
                            <td>{buyershowingdetail.mlsId}</td>

                          </tr>
                          <tr>
                          <th scope="row">Listing Date</th>
                            <td>{buyershowingdetail.listingDate ? <Moment format="D MMM YY">{buyershowingdetail.listingDate}</Moment> : '' }</td>      
                          </tr>
                          <tr>
                            <th scope="row">Listing Status</th>
                            <td>{buyershowingdetail.listingStatusId}</td>
                           
                          </tr>
                          <tr>
                          <th scope="row">Listed Price</th>
                          <td>${buyershowingdetail.listedPrice}</td>
                          
                          </tr>

                          <tr>
                          <th scope="row">Listing Type</th>
                          <td>{buyershowingdetail.listingType}</td>
                          
                          </tr>

                          <tr>
                          <th scope="row">Bedroom</th>
                          <td>{buyershowingdetail.listingBedroom}</td>
                          </tr>
                          <tr>
                          <th scope="row">Bathroom</th>
                          <td>{buyershowingdetail.listingBathroom}</td>
                          </tr>
                          <tr>
                          <th scope="row">Parking</th>
                          <td>{buyershowingdetail.listingParking}</td>
                          </tr>
                          <tr>
                          <th scope="row">Address</th>
                          <td>{buyershowingdetail.listingAddress}</td>
                          </tr>
                          <tr>
                          <th scope="row">City</th>
                          <td>{buyershowingdetail.listingCity}</td>
                          </tr>
                          <tr>
                          <th scope="row">Notes</th>
                          <td>{buyershowingdetail.notes}</td>
                          </tr>
                                               
                        </tbody>
                      </Table>
          ): null}
                    </div>    
          </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
          isOpen={this.state.isOpenhouseShowing}
          direction="end"
          toggle={this.toggleOpenhouseShowing}
      >
          <OffcanvasHeader toggle={this.toggleOpenhouseShowing}>
              Open House Showing
          </OffcanvasHeader>
          <OffcanvasBody>
          <div className="table-responsive">
          { openhouseshowingdetail ? (
          <Table className="table table-sm m-0">
                        
                        <tbody>
                          <tr>
                            <th scope="row">Listing</th>
                            <td>{openhouseshowingdetail.clientListing.mlsId}</td>

                          </tr>
                          <tr>
                          <th scope="row">Openhouse Date</th>
                            <td>{openhouseshowingdetail.openhouseDate ? <Moment format="D MMM YY">{openhouseshowingdetail.openhouseDate}</Moment> : '' }</td>      
                          </tr>
                          <tr>
                            <th scope="row">Start Time</th>
                            <td><Moment format="hh:mm A">{openhouseshowingdetail.startTime}</Moment></td>
                           
                          </tr>
                          <tr>
                          <th scope="row">End Time</th>
                          <td><Moment format="hh:mm A">{openhouseshowingdetail.endTime}</Moment></td>
                          
                          </tr>
                                               
                        </tbody>
                      </Table>
          ): null}
            
          </div>    
          </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
          isOpen={this.state.isEventShowing}
          direction="end"
          toggle={this.toggleEventShowing}
      >
          <OffcanvasHeader toggle={this.toggleEventShowing}>
              Event Invites
          </OffcanvasHeader>
          <OffcanvasBody>
          <div className="table-responsive">
          { inviteDetail ? (
          <Table className="table table-sm m-0">
                        
                        <tbody>
                          <tr>
                            <th scope="row">Project Name</th>
                            <td>{inviteDetail.projectName}</td>

                          </tr>
                          <tr>
                            <th scope="row">Event Name</th>
                            <td>{inviteDetail.eventName}</td>

                          </tr>
                          <tr>
                          <th scope="row">Event Date</th>
                            <td>{inviteDetail.eventDate ? <Moment format="D MMM YY">{inviteDetail.eventDate}</Moment> : '' }</td>      
                          </tr>
                          <tr>
                            <th scope="row">Start Time</th>
                            <td><Moment format="hh:mm A" parse="HH:mm">{inviteDetail.eventStartTime}</Moment></td>
                           
                          </tr>
                          <tr>
                          <th scope="row">End Time</th>
                          <td><Moment format="hh:mm A" parse="HH:mm">{inviteDetail.eventEndTime}</Moment></td>
                          
                          </tr>

                          <tr>
                          <th scope="row">Location</th>
                          <td>{inviteDetail.address ? inviteDetail.address : inviteDetail.location }</td>
                          
                          </tr>

                         
                                               
                        </tbody>
                      </Table>
          ): null}
                    </div>    
          </OffcanvasBody>
          </Offcanvas>
      </React.Fragment >
    )

  }
}

Activity.propTypes = {
  t: PropTypes.any,
  loading:PropTypes.object,
  addNewActivity:PropTypes.func,
  postActivityFilter:PropTypes.func,
  activities:PropTypes.array,
  changeActivityStatus:PropTypes.func,
  onDeleteActivity:PropTypes.func,
  onUpdateMainActivity:PropTypes.func,
  onGetSellerShowingDetail:PropTypes.func,
  sellershowingdetail:PropTypes.object,
  onGetOpenhouseShowingDetail:PropTypes.func,
  openhouseshowingdetail:PropTypes.object,
  onGetBuyerShowingDetail:PropTypes.func,
  buyershowingdetail:PropTypes.object,
  onGetProjectInviteDetail:PropTypes.func,
  inviteDetail:PropTypes.object

}
const mapStateToProps = ({ Activity, Project }) => (
  {
    loading: Activity.loading,
    activities:Activity.activities,
    sellershowingdetail:Activity.sellershowingdetail,
    openhouseshowingdetail:Activity.openhouseshowingdetail,
    buyershowingdetail:Activity.buyershowingdetail,
    inviteDetail:Project.inviteDetail
  })
const mapDispatchToProps = dispatch => ({
  addNewActivity: (data) => dispatch(addNewActivity(data)),
  postActivityFilter : (data) => dispatch(postActivityFilter(data)),
  changeActivityStatus : (data) => dispatch(changeActivityStatus(data)),
  onDeleteActivity:(id) =>  dispatch(deleteMainActivity(id)),
  onUpdateMainActivity:(data) => dispatch(updateMainActivity(data)),
  onGetSellerShowingDetail:(id) => dispatch(getSellerShowingDetail(id)),
  onGetOpenhouseShowingDetail:(id) => dispatch(getOpenhouseShowingDetail(id)),
  onGetBuyerShowingDetail:(id) => dispatch(getBuyerShowingDetail(id)),
  onGetProjectInviteDetail:(id) => dispatch(getProjectInviteDetail(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Activity)))