import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EligibilitypagesMenu from "../menu/eligibilitypages-menu";
import LeadEligibilityBreadcrumb from "components/Common/LeadEligibilityBreadcrumb";

class MortgageEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
       
        isRight:false
    }
   
   
  }
 

 
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">


            <Row className="mb20 project-header">
              <Col xl="6">
                <LeadEligibilityBreadcrumb
                  title={this.props.t("Lead")}

                  breadcrumbItem={this.props.t("Eligibility Questions")}
                />

              </Col>

            </Row>
            <Row>
              

              <Col xl="12">
               
                      <Card>
                       <CardBody>
                       <EligibilitypagesMenu />
                       <div className="link-page">
                      
                      <div className="open-bg" style={{marginTop:10, marginBottom:10}}>
                        <h5>Purpose of Location factor: To assess the financial readiness of a lead.</h5>

                        <h5>What to Enter</h5>

                        <ul>
                            <li><strong>Weight</strong>: Assign a numerical weight (1-10) to indicate the importance of mortgage status.</li>
                            <li><strong>Scoring Values</strong>: Assign scores (1-10) for different mortgage statuses (e.g., Pre-approved, No approval).</li>
                            
                        </ul>
                       
                        
                      </div>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    locationName:(this.state && this.state.question) || "",
                    optionSel: (this.state && this.state.points) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    locationName: Yup.string().required("This is required"),
                    optionSel: Yup.string().required("This is required"),


                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col sm="1">
                          <h4>Weight</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                      <Row>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="eventLocation"
                                          className="form-control"
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                        <Col sm="6">
                          <h4>Scoring value for Mortgage Status</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Approved")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="eventLocation"
                                          className="form-control"
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Not Approved")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="eventLocation"
                                          className="form-control"
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                   
                 
                     
                      <button type="button" className="btn btn-primary w-md">{this.props.t("Edit")}</button>
                      </Form>
                  )}
                  </Formik>
                      </div>
                       </CardBody>
                      </Card>
                 


                  
              </Col>

            </Row>
           

         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
MortgageEligibility.propTypes = {
  t: PropTypes.any,
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

   

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MortgageEligibility))
